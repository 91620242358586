import Badge from 'lib/components/badge/badge'

interface AdminBadgesProps {
  shared: boolean
  isPartnership: boolean
}

const AdminBadges = ({ shared, isPartnership }: AdminBadgesProps) => {
  return (
    <>
      {shared ? (
        <Badge color="success" style="pill">
          Published
        </Badge>
      ) : (
        <Badge color="light" style="pill">
          Draft
        </Badge>
      )}
      {isPartnership && (
        <Badge color="primary" style="pill">
          Partnered
        </Badge>
      )}
    </>
  )
}

export default AdminBadges
