import { useMemo, useState } from 'react'

import ConfirmationModal from 'components/elements/confirmation-modal'
import { AnnotationRecord } from 'lib/api/annotations/annotations'

import { useMediaContext } from '../media/media-provider'
import { useAnnotationsContext } from '../providers/annotations-provider'
import AnnotationListItem from './annotation-list-item'
import ImageAnnotationForm from './image-annotation-form'
import { getAnnotations, getCurrentlyViewedFile } from './utils'
import { DetailTaskType } from '../types/detail-task'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { useUserContext } from 'providers/user-provider'
import { toast } from 'lib/components/toast/toast'

export default function AnnotationsList() {
  const [annotationToDelete, setAnnotationToDelete] = useState<AnnotationRecord<AnnotoriousAnnotation>>()
  const { extractedPreviewIndex, selectedFile } = useMediaContext()
  const { removeAnnotation, showAnnotationCreator } = useAnnotationsContext()

  const annotations = useMemo(() => getAnnotations(selectedFile), [selectedFile])

  const currentlyViewedFile = useMemo(
    () => getCurrentlyViewedFile(selectedFile, extractedPreviewIndex),
    [selectedFile, extractedPreviewIndex],
  )

  function handleDeleteClick(annotation: AnnotationRecord<AnnotoriousAnnotation>) {
    setAnnotationToDelete(annotation)
  }

  async function handleConfirmAction() {
    try {
      await removeAnnotation(currentlyViewedFile, annotationToDelete)
    } catch (e) {
      toast.error('There was an error deleting the annotation. Refresh and try again.')
      console.error('Error deleting annotation', e)
    } finally {
      setAnnotationToDelete(null)
    }
  }

  function handleCancelAction() {
    setAnnotationToDelete(null)
  }

  function showAnnotationForm() {
    return (
      showAnnotationCreator === DetailTaskType.VIDEO_ANNOTATION ||
      showAnnotationCreator === DetailTaskType.IMAGE_ANNOTATION
    )
  }

  const { settings } = useUserContext()
  const customBranding = settings?.customBranding
  const emptyImageSrc = useMemo(() => {
    if (customBranding && customBranding?.logo) {
      return customBranding?.logo
    }
    return '/images/icons/thinking_pickle.svg'
  }, [customBranding])

  return (
    <div className="tw-pt-6">
      <h2 className="tw-m-0 tw-text-base">Annotations</h2>
      <p>Use the annotation tool to add directions to be reviewed by the request owner</p>
      {showAnnotationForm() && <ImageAnnotationForm ticketFile={currentlyViewedFile} />}
      {!annotations.length && !showAnnotationCreator && (
        <div className="tw-flex tw-h-auto tw-w-full tw-max-w-lg tw-flex-col tw-items-center tw-justify-center">
          {!!emptyImageSrc && <img src={emptyImageSrc} alt="Empty Image" className="tw-h-auto tw-w-64" />}
          <h4 className="tw-text-neutral-500">It looks like you haven&apos;t added any annotations</h4>
          <p className="tw-text-neutral-500">Want to add annotations? Use the pencil tool above</p>
        </div>
      )}
      {annotations.map((annotation) => (
        <AnnotationListItem annotation={annotation} key={annotation.id} onDeleteClick={handleDeleteClick} />
      ))}
      <ConfirmationModal
        title="Delete Annotation"
        message={`Are you sure you want to delete this annotation? All of your data will be permanently removed from our servers forever. This action cannot be undone.`}
        confirmBtnText="Yes, Delete"
        confirmAction={handleConfirmAction}
        cancelAction={handleCancelAction}
        visible={!!annotationToDelete}
      />
    </div>
  )
}
