import { createContext, useContext, useState } from 'react'
import { getCompanyBrands } from 'lib/api/companies/companies'
import { Brand } from 'interfaces/brand'

interface ContextProps {
  children: JSX.Element
}

interface BrandsContextValue {
  getBrandsByCompany: (companyId: number) => Promise<Brand[]>
  isLoading: boolean
}

const BrandsContext = createContext({})

export function useBrandsContext(): BrandsContextValue {
  return useContext(BrandsContext) as BrandsContextValue
}

export default function BrandsProvider({ children }: ContextProps): JSX.Element {
  const [companyBrands, setCompanyBrands] = useState<Brand[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  async function getBrandsByCompany(companyId: number) {
    if (companyBrands) {
      return companyBrands
    }
    try {
      const { brands } = await getCompanyBrands(companyId)
      setCompanyBrands(brands)
      return brands
    } catch (e) {
      console.error('Brands could not be fetched.', e)
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const context: BrandsContextValue = {
    getBrandsByCompany,
    isLoading,
  }

  return <BrandsContext.Provider value={context}>{children}</BrandsContext.Provider>
}
