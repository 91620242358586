import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import Button from 'components/core/button'
import SearchInput from 'lib/components/search-input/search-input'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SubscriptionTypeIcon } from '../deliverable-type'
import { SubscriptionType } from 'interfaces/project-template'
import { useMutation, useQuery } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { toast } from 'sonner'
import { SkillCategorySkill } from 'lib/api/skills/skills'
import { cn } from 'lib/util/cn'
import QuantitySelector from 'lib/components/quantity-selector/quantity-selector'

const CreateDeliverablesTableTitleCell = ({ skill }: { skill: SkillCategorySkill }) => (
  <div className="tw-flex tw-items-center tw-gap-2">
    <img src={skill.imageUrl} alt={skill.name} className="tw-h-16 tw-w-16 tw-rounded-t-lg tw-object-cover" />
    <div className="tw-flex tw-flex-col tw-justify-center tw-gap-1">
      <div className="tw-font-bold">{skill.name}</div>
      <div>{skill.skillCategoryName}</div>
    </div>
  </div>
)

interface CreateDeliverablesTableProps {
  onFinished: () => void
  onCancel: () => void
  projectId: number
}

const CreateDeliverablesTable = ({ onFinished, onCancel, projectId }: CreateDeliverablesTableProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedRows, setSelectedRows] = useState<SkillCategorySkill[]>([])

  const createAndAddDeliverables = () => {
    const skillIds = selectedRows.map(({ id }) => id)
    bulkCreateMutation.mutate({ skillIds })
  }

  const handleDecrement = useCallback(
    (rowOriginal: SkillCategorySkill) => {
      if (!setSelectedRows) return

      setSelectedRows((prev: SkillCategorySkill[]) => {
        const index = prev.findIndex(({ id }) => id === rowOriginal.id)
        if (index !== -1) {
          return [...prev.slice(0, index), ...prev.slice(index + 1)]
        }
        return prev
      })
    },
    [setSelectedRows],
  )

  const handleIncrement = useCallback(
    (rowOriginal: SkillCategorySkill) => {
      if (!setSelectedRows) return

      setSelectedRows((prev: SkillCategorySkill[]) => [...prev, rowOriginal])
    },
    [setSelectedRows],
  )

  const handleDelete = useCallback(
    (rowOriginal: SkillCategorySkill) => {
      if (!setSelectedRows) return

      setSelectedRows((prev: SkillCategorySkill[]) => prev.filter(({ id }) => id !== rowOriginal.id))
    },
    [setSelectedRows],
  )

  const columns: ColumnDef<SkillCategorySkill>[] = [
    {
      id: 'title',
      header: 'Title',
      cell: (props) => <CreateDeliverablesTableTitleCell skill={props.row.original} />,
    },
    {
      id: 'type',
      header: 'Request Type',
      accessorKey: 'subscriptionType',
      cell: (props) => <SubscriptionTypeIcon type={props.cell.getValue() as SubscriptionType} />,
    },
    {
      id: 'quantity',
      header: 'Quantity',
      cell: ({ row }) => {
        const quantity = selectedRows?.filter(({ id }) => id === row.original.id).length

        return (
          <QuantitySelector
            quantity={quantity || 0}
            handleDecrement={() => handleDecrement(row.original)}
            handleIncrement={() => handleIncrement(row.original)}
            handleDelete={() => handleDelete(row.original)}
          />
        )
      },
    },
  ]

  const { data, isLoading, error } = useQuery({
    queryKey: ['skills'],
    queryFn: () => request({ endpoint: 'getSkills' }),
  })

  const bulkCreateMutation = useMutation({
    mutationFn: ({ skillIds }: { skillIds: number[] }) => {
      return request({
        endpoint: 'bulkCreateTickets',
        body: {
          skill_ids: skillIds,
          project_id: projectId,
        },
      })
    },
    onSuccess: () => {
      onFinished()
    },
    onError: (e) => {
      console.error('Error creating tasks', e)
      toast.error('Failed to create tasks')
    },
  })

  const tableData = useMemo(
    () =>
      data
        ?.flatMap((category) => category.skills)
        .filter((skill) => skill.name.toLowerCase().includes(searchValue.toLowerCase())) ?? [],
    [data, searchValue],
  )

  const table = useReactTable({
    columns,
    data: tableData,
    getRowId: (row) => row.id.toString(),
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (error) {
      toast.error('Failed to fetch skills')
      console.error('Failed to fetch skills', error)
    }
  }, [error])

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <SearchInput value={searchValue} onChange={setSearchValue} placeholder="Search requests" />
      <h5 className="tw-m-0 tw-p-0">{tableData.length} requests found</h5>
      <div>
        <table className="tw-w-full">
          <thead>
            <tr className="tw-sticky tw--top-4 tw-z-10 tw-h-12 tw-bg-peppercorn-50">
              {table.getFlatHeaders().map((header) => (
                <th
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="tw-pl-4 tw-font-medium tw-uppercase tw-text-black"
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={cn('tw-h-24 tw-border-s-2 tw-border-solid tw-border-peppercorn-100 tw-p-2', {
                  'tw-bg-cornflower-50': row.getIsSelected(),
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td className="tw-pl-4" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && <Skeleton className="tw-mb-4 tw-h-24 tw-w-full tw-bg-neutral-200" data-testid="skeleton" />}
      </div>
      <div className="tw-sticky tw-bottom-0 tw-mt-4 tw-flex tw-w-full tw-justify-between tw-gap-2 tw-bg-white tw-py-4">
        <h5>
          {selectedRows.length} request{selectedRows.length !== 1 && 's'} selected
        </h5>
        <div className="tw-flex tw-gap-2">
          <Button color="lightGray" type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="purple" type="button" onClick={createAndAddDeliverables} disabled={selectedRows.length === 0}>
            Create and add to project
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateDeliverablesTable
