import { useQuery } from '@tanstack/react-query'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Project } from 'interfaces/project'
import { requestQuery } from 'lib/api/fetch-api'
import { MenuItem } from 'lib/components/menu-item/menu-item'
import { Popover, PopoverContent, PopoverTrigger } from 'lib/components/popover/popover'
import SearchInput from 'lib/components/search-input/search-input'
import Tooltip from 'lib/components/tooltip/tooltip'
import { cn } from 'lib/util/cn'
import { Pencil, PlusIcon } from 'lucide-react'
import { ReactNode, useState } from 'react'

interface ProjectsPopoverProps {
  isOpen: boolean
  onSelectProject: (project: Project) => void
  project: Project
  setIsOpen: (isOpen: boolean) => void
  children?: ReactNode
}

export default function ProjectsPopover({
  isOpen,
  onSelectProject,
  project,
  setIsOpen,
  children,
}: ProjectsPopoverProps) {
  const [search, setSearch] = useState('')
  const { data, isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: requestQuery({
      endpoint: 'getProjects',
    }),
  })

  const projects = data?.data

  const filteredProjects = projects?.filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))

  const handleSelectProject = (clickedProject: Project) => {
    onSelectProject(clickedProject.id === project?.id ? null : clickedProject)
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div>{children || <TextOnlyTrigger project={project} isOpen={isOpen} />}</div>
      </PopoverTrigger>
      <PopoverContent
        className="tw-max-h-96 tw-w-72 tw-overflow-y-auto"
        intent="flyout"
        align="start"
        alignOffset={-10}
      >
        <h4 className="tw-text-md tw-m-0 tw-mb-2 tw-ml-2 tw-font-bold tw-text-neutral-900">Projects</h4>
        <SearchInput
          value={search}
          onChange={(searchText) => setSearch(searchText)}
          placeholder="Search projects"
          className="tw-mx-1 tw-my-4"
        />
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <ProjectList projects={filteredProjects} project={project} onSelectProject={handleSelectProject} />
        )}
      </PopoverContent>
    </Popover>
  )
}

interface TriggerProps {
  project: Project
  isOpen: boolean
}

function TextOnlyTrigger({ project, isOpen }: TriggerProps) {
  const Wrapper = ({ children }: { children: ReactNode }) => (
    <div className="tw-group tw-flex tw-min-w-40 tw-cursor-pointer tw-items-center tw-gap-2 tw-break-all tw-text-xs tw-text-neutral-500">
      {children}
    </div>
  )

  if (project?.name) {
    const trimmedProjectName = project?.name?.length > 40 ? `${project?.name?.substring(0, 40)}...` : project?.name

    return (
      <TooltipWrapper showTooltip={!isOpen} text={project?.name} direction="up">
        <Wrapper>
          <div
            className={cn(
              'tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-rounded-md tw-p-2 tw-text-neutral-800 hover:tw-bg-neutral-100',
              isOpen && 'tw-ring tw-ring-cornflower-500',
            )}
          >
            <div>{trimmedProjectName}</div>
            <div className="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-p-1">
              <Pencil className="tw-invisible tw-h-4 tw-w-4 group-hover:tw-visible" />
            </div>
          </div>
        </Wrapper>
      </TooltipWrapper>
    )
  }

  return (
    <Wrapper>
      <div
        className={cn(
          'tw-rounded-md tw-p-2 hover:tw-bg-neutral-100 hover:tw-text-neutral-800',
          isOpen && 'tw-ring tw-ring-cornflower-500',
        )}
      >
        <PlusIcon className="tw-h-4 tw-w-4" />
        Add project
      </div>
    </Wrapper>
  )
}

interface ProjectListProps {
  projects: Project[]
  project: Project
  onSelectProject: (project: Project) => void
}

function ProjectList({ projects, project, onSelectProject }: ProjectListProps) {
  const restProjects = projects?.filter((p) => p.id !== project?.id) || []
  const sortedProjects = project ? [project, ...restProjects] : restProjects

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {sortedProjects.map((p) => (
        <button
          key={p?.id}
          onClick={() => onSelectProject(p)}
          className="tw-w-full tw-border-none tw-bg-transparent tw-text-left tw-outline-none"
        >
          <MenuItem
            item={{ label: p?.name }}
            selected={p?.id === project?.id}
            className="tw-inline-block tw-w-full tw-text-ellipsis tw-whitespace-nowrap"
          />
        </button>
      ))}
    </div>
  )
}

interface TooltipWrapperProps {
  children: ReactNode
  showTooltip: boolean
  text: string
  direction?: 'up' | 'down'
}

const TooltipWrapper = ({ children, showTooltip, text, direction = 'down' }: TooltipWrapperProps) => {
  if (showTooltip) {
    return (
      <Tooltip content={text} direction={direction}>
        {children}
      </Tooltip>
    )
  }
  return <div>{children}</div>
}
