import { ReactElement, useEffect } from 'react'

import { useAdminMediaContext } from '../providers/admin-media-provider'
import AdminMediaViewer from './admin-media-viewer'
import AdminMediaViewerHeader from './admin-media-viewer-header'
import { TriangleAlert } from 'lucide-react'
import { useThreeResizableContext } from 'lib/components/resizable/resizable'

const classNames = {
  buttons: {
    button: 'btn btn-icon tw-absolute tw-px-0 tw-cursor-pointer',
    chevron: 'tw-h-6 tw-w-6',
    close: 'tw-bg-white tw-rounded-r-none tw-rounded-l-md tw-border tw-border-r-0 tw-border-gray-200',
    container: 'tw-absolute tw-right-0 tw-top-1/2 tw-z-20',
  },
  mediaView: {
    aside: 'tw-flex-none tw-h-full tw-w-full tw-relative tw-duration-500 tw-bg-gray-50',
    container: 'tw-h-full tw-border-gray-200 tw-border-0 tw-border-r tw-border-solid tw-flex tw-flex-col',
    controls: 'tw-text-right',
    figure: 'tw-w-full tw-text-center tw-p-0 tw-m-0 tw-relative',
    metadataContainer: 'tw-flex tw-items-center tw-justify-center tw-gap-2 tw-w-full tw-pt-4',
    metadataItem: 'tw-flex tw-items-center tw-justify-center tw-gap-2',
    figureVideo: 'tw-object-contain tw-w-full tw-h-full',
  },
}

export default function AdminMediaContainer(): ReactElement {
  const { currentSelectedPage, selectedFile } = useAdminMediaContext()
  const { middleRef } = useThreeResizableContext()

  useEffect(() => {
    if (selectedFile) {
      middleRef?.current?.expand()
    }
  }, [selectedFile, middleRef])

  return (
    <>
      <aside className={`${classNames.mediaView.aside} `}>
        {selectedFile && (
          <div className={`${classNames.mediaView.container} `}>
            {selectedFile && selectedFile.state === 'rejected' && (
              <div
                className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-bg-yellow-50 tw-p-4 tw-font-semibold tw-text-yellow-800"
                data-testid="rejectedNotice"
              >
                <TriangleAlert size="16" />
                {"This file is kept for record and won't be delivered to the client."}
              </div>
            )}
            {selectedFile && <AdminMediaViewerHeader />}
            {selectedFile && <AdminMediaViewer currentTicketFile={currentSelectedPage} selectedFile={selectedFile} />}
          </div>
        )}
      </aside>
    </>
  )
}
