import { getFileExtension } from 'lib/util/file/file'

const FileType = ({ fileName, className, size = 24 }: { fileName: string; className?: string; size?: number }) => {
  return (
    <img
      src={`/images/file_placeholders/${getFileExtension(fileName)}.png`}
      width={size}
      height={size}
      className={className}
    />
  )
}

export default FileType
