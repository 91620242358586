import { ReactElement, useState } from 'react'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { ArrowDownUp, Link, Merge, Split } from 'lucide-react'
import { useAdminUserContext } from 'providers/admin-user-provider'
import SplitRequestModal from './split-request-modal/split-request-modal'
import MergeRequestModal from './merge-request-modal/merge-request-modal'
import TransferRequestModal from './transfer-request-modal/transfer-request-modal'
import { toast } from 'lib/components/toast/toast'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'

const classNames = {
  flyoutContainer: 'tw-flex tw-items-center tw-gap-2',
}

export default function AdminHeaderOverflowMenu(): ReactElement {
  const { user } = useAdminUserContext()
  const { ticket } = useAdminTicketContext()
  const [showSplitRequestModal, setShowSplitRequestModal] = useState<boolean>(false)
  const [showMergeModal, setShowMergeModal] = useState<boolean>(false)
  const [showTransferModal, setShowTransferModal] = useState<boolean>(false)

  async function handleCopyLinkToRequest() {
    await navigator.clipboard.writeText(`${window.location.origin}/tickets/${ticket.id}`)

    toast.success('Successfully copied link')
  }

  return (
    <div className="tw-z-30">
      <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']}>
        <IconFlyoutMenu.Button onClick={handleCopyLinkToRequest}>
          <div className={classNames.flyoutContainer}>
            <Link className="lu-light lu-md" />
            <div>Copy link to request</div>
          </div>
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => null}>
          <div className={classNames.flyoutContainer}>
            <img src="/images/icons/intercom.svg" className="tw-w-5" alt="intercom icon" />
            <div>Send to intercom</div>
          </div>
        </IconFlyoutMenu.Button>
        {!user.isDPMember && (
          <>
            <IconFlyoutMenu.Button onClick={() => setShowSplitRequestModal(true)}>
              <div className={classNames.flyoutContainer}>
                <Split className="lu-light lu-md" />
                <div>Split</div>
              </div>
            </IconFlyoutMenu.Button>
            <IconFlyoutMenu.Button onClick={() => setShowMergeModal(true)}>
              <div className={classNames.flyoutContainer}>
                <Merge className="lu-light lu-md" />
                <div>Merge and close</div>
              </div>
            </IconFlyoutMenu.Button>
            <IconFlyoutMenu.Button onClick={() => setShowTransferModal(true)}>
              <div className={classNames.flyoutContainer}>
                <ArrowDownUp className="lu-light lu-md" />
                <div>Transfer</div>
              </div>
            </IconFlyoutMenu.Button>
          </>
        )}
      </IconFlyoutMenu>

      <SplitRequestModal open={showSplitRequestModal} setOpen={setShowSplitRequestModal} />
      <MergeRequestModal open={showMergeModal} setOpen={setShowMergeModal} />
      <TransferRequestModal open={showTransferModal} setOpen={setShowTransferModal} />
    </div>
  )
}
