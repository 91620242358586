import { Link } from '@tiptap/extension-link'

declare module '@tiptap/extension-link' {
  interface LinkAttributes {
    'data-asset-id'?: string
  }
}

export const AssetLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      'data-asset-id': {
        default: null,
        parseHTML: (element) => element.getAttribute('data-asset-id'),
        renderHTML: (attributes) =>
          attributes['data-asset-id'] ? { 'data-asset-id': attributes['data-asset-id'] } : {},
      },
    }
  },
})
