import { getPresignedUrl } from 'lib/api/file-urls/file-urls'

/**
 * Parses HTML content and replaces images and videos with data-asset-id with pre-signed S3 URLs
 * @param html The HTML content to parse
 * @returns A promise that resolves to the HTML content with pre-signed URLs
 */
export async function parseAssetUrls(html: string): Promise<string> {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const elements = [
    ...Array.from(doc.getElementsByTagName('img')),
    ...Array.from(doc.getElementsByTagName('video')),
    ...Array.from(doc.getElementsByTagName('a')),
  ]
  const promises: Promise<void>[] = []

  for (const element of elements) {
    const assetId = element.getAttribute('data-asset-id')
    if (assetId) {
      promises.push(
        getPresignedUrl(assetId).then((presignedUrl) => {
          if (element.tagName.toLowerCase() === 'a') {
            element.setAttribute('href', presignedUrl)
          } else {
            element.setAttribute('src', presignedUrl)
          }
        }),
      )
    }
  }
  await Promise.all(promises)
  return doc.documentElement.innerHTML
}
