import { SkillCategorySkill } from 'lib/api/skills/skills'
import placeholderImage from 'images/requests/request-type-placeholder.png'
import { useEffect, useState } from 'react'
import { MOST_USED_SKILL_CATEGORY_NAME, useSkillsContext } from './skills-provider'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Card, HorizontalCard } from 'lib/components/card/card'

export type SupportedSizes = 'md' | 'lg'

const classNames = {
  image: {
    default: `tw-rounded-t-lg tw-object-contain tw-h-full tw-w-full`,
  },
  skillsContainer: {
    default: 'tw-flex tw-flex-wrap',
    md: 'tw-gap-3',
    lg: 'tw-gap-8',
  },
}

type ClassNameKeys = keyof typeof classNames

function generatedClassNames(name: ClassNameKeys, size: 'md' | 'lg'): string {
  return `${classNames[name].default} ${size ? classNames[name][size] : ''}`
}

interface SkillImageProps {
  size: SupportedSizes
  skill: SkillCategorySkill
}

function SkillImage({ size, skill }: SkillImageProps): JSX.Element {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const image = new Image()
    image.src = skill.imageUrl
    image.onload = () => setImageLoaded(true)
    return () => (image.onload = null)
  }, [skill.imageUrl])

  return (
    <img
      src={imageLoaded ? skill.imageUrl : placeholderImage}
      alt={skill.name}
      className={`${generatedClassNames('image', size)}`}
    />
  )
}

interface SkillCardProps {
  size: SupportedSizes
  selectSkill: (SkillCategorySkill) => void
  skill: SkillCategorySkill
  selected: boolean
}

function SkillCard({ size, selectSkill, skill, selected = false }: SkillCardProps) {
  return (
    <Card size={size} isSelected={selected} onClick={() => selectSkill(skill)}>
      <Card.Body centered>
        <SkillImage size={size} skill={skill} />
      </Card.Body>
      <Card.Footer className="tw-bg-white">{skill.name}</Card.Footer>
    </Card>
  )
}

interface SkillsProps {
  skills: SkillCategorySkill[]
  size: SupportedSizes
  selectedSkill: SkillCategorySkill
  onSelect: (SkillCategorySkill) => void
}

function Skills({ skills, size, selectedSkill, onSelect }: SkillsProps) {
  return (
    <div className={generatedClassNames('skillsContainer', size)}>
      {skills.map((skill: SkillCategorySkill) => (
        <SkillCard
          size={size}
          key={skill.id}
          selectSkill={onSelect}
          skill={skill}
          selected={selectedSkill ? selectedSkill.id === skill.id : false}
        />
      ))}
    </div>
  )
}

interface SkillCategoriesProps {
  size?: SupportedSizes
  onSelect: (SkillCategorySkill) => void
  selectedSkill?: SkillCategorySkill
  showOtherSkills?: boolean
  fetchSkills?: boolean
}

export default function SkillCategories({
  size = 'lg',
  onSelect,
  selectedSkill,
  showOtherSkills = false,
  fetchSkills = true,
}: SkillCategoriesProps): JSX.Element {
  const { filteredSkillCategories, isLoading, getSkillsByCategory, otherSkills } = useSkillsContext()

  useEffect(() => {
    if (fetchSkills) {
      getSkillsByCategory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSkills])

  if (isLoading) {
    return <LoadingScreen size="2xl" />
  }

  if (filteredSkillCategories.length === 0) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <img src="/images/states/not-found.svg" alt="No search results" />
        {showOtherSkills ? (
          <>
            <h5>Uh oh! We weren&apos;t able to find any request types that matched your search.</h5>
            <p>But don&apos;t worry, we&apos;ve got you. Select from these categories.</p>
            <Skills skills={otherSkills} onSelect={onSelect} selectedSkill={selectedSkill} size={size} />
            <p className="tw-mt-6">
              Want to learn more about request types? Access our knowledge base articles{' '}
              <a href="https://help.designpickle.com/en/articles/7265090" target="_blank" rel="noreferrer">
                here.
              </a>
            </p>
          </>
        ) : (
          <>
            <h5>Uh oh! We weren&apos;t able to find any request types.</h5>
            <p>Try adjusting your search to find what you&apos;re looking for.</p>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      {filteredSkillCategories?.map((category) => (
        <div key={category.id || category.name}>
          <h4 className="tw-m-0 tw-py-6">{category.name}</h4>
          {category.name === MOST_USED_SKILL_CATEGORY_NAME ? (
            <div className="tw-flex tw-flex-wrap tw-gap-4">
              {category.skills.map((skill: SkillCategorySkill) => (
                <HorizontalCard
                  key={skill.id}
                  image={skill.imageUrl || placeholderImage}
                  title={skill.name}
                  description={skill.skillCategoryName}
                  onClick={() => onSelect(skill)}
                />
              ))}
            </div>
          ) : (
            <Skills skills={category.skills} onSelect={onSelect} selectedSkill={selectedSkill} size={size} />
          )}
        </div>
      ))}
    </>
  )
}
