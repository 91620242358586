import { ChangeEvent, ReactElement, useRef, useState } from 'react'
import Textarea from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'
import { ConversationTimelineItem } from 'lib/api/timeline/timeline'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'

const classNames = {
  container: 'tw-flex tw-items-center tw-gap-2 tw-pb-4',
  textarea: 'tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-p-2',
}

interface TimelineConversationEditProps {
  conversation: ConversationTimelineItem
  onCancel: () => void
  onSubmit: (body: string) => void | Promise<void>
}

export default function TimelineConversationEdit({
  conversation,
  onCancel,
  onSubmit,
}: TimelineConversationEditProps): ReactElement {
  const bodyRef = useRef<HTMLTextAreaElement>()
  const [value, setValue] = useState<string>(conversation.body)
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  function handleOnCancel() {
    onCancel()
  }

  function handleOnChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setValue(event.target.value)
  }

  async function handleOnSubmit() {
    onSubmit(value)
  }

  return (
    <div className={classNames.container}>
      {isFeatureFlagEnabled('request_wysiwyg') ? (
        <WYSIWYGTextarea defaultValue={conversation.body} onChange={setValue} />
      ) : (
        <Textarea
          ref={bodyRef}
          className={classNames.textarea}
          maxLength={-1}
          onChange={handleOnChange}
          placeholder="Start typing your reply."
          rows={2}
          resize={{ vertical: true }}
          value={value}
        />
      )}
      <div style={{ minWidth: '40px' }}>
        <IconButton color="secondary" icon={['far', 'check']} onClick={handleOnSubmit} />
      </div>
      <span onMouseDown={(e) => e.preventDefault()}>
        <IconButton color="secondary" icon={['far', 'times']} onClick={handleOnCancel} />
      </span>
    </div>
  )
}
