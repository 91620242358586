import { PATHS } from 'lib/constants/paths'

export default function RolesLink({
  text,
  textSize,
}: {
  isAdmin: boolean
  text: string
  textSize: 'xs' | 'sm'
}): JSX.Element {
  return (
    <a
      href={PATHS.KB_PERMISSIONS}
      className={`tw-text-${textSize} tw-text-cornflower-500 hover:tw-cursor-pointer`}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  )
}
