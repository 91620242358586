import { useState } from 'react'
import Modal from '../modals/modal'
import { User } from 'interfaces/user'
import bannerImage from 'images/onboarding/onboarding_banner_image.png'
import digitalAdsThumbnail from 'images/onboarding/digital_ads_and_marketing.jpg'
import logosBrandingThumbnail from 'images/onboarding/logos_and_branding.jpg'
import illustrationsThumbnail from 'images/onboarding/illustrations.jpg'
import printMerchandiseThumbnail from 'images/onboarding/print_and_merchandise.jpg'
import presentationsPowerpointThumbnail from 'images/onboarding/presentations_and_powerpoint.jpg'
import animatedVideosThumbnail from 'images/onboarding/animated_videos.jpg'
import Button from 'components/core/button'
import SelectBox from '../dropdown/select-box'
import { BrushIcon, PenToolIcon, PresentationIcon, VideoIcon } from 'lucide-react'
import { cn } from 'lib/util/cn'
import { updatePreferences } from 'lib/api/onboarding/onboarding'
import Tooltip from '../tooltip/tooltip'

type OnboardingProps = {
  enabled: boolean
  user: User
  designerTimezones: { label: string; value: string }[]
}

type skill = {
  title: string
  icon: React.ReactNode
}

const skills: Record<string, skill> = {
  graphicDesign: {
    title: 'Graphic Design',
    icon: <PenToolIcon />,
  },
  customIllustration: {
    title: 'Custom Illustration',
    icon: <BrushIcon />,
  },
  presentationDesign: {
    title: 'Presentation Design',
    icon: <PresentationIcon />,
  },
  motionGraphics: {
    title: 'Motion Graphics',
    icon: <VideoIcon />,
  },
}

type deliverable = {
  name: string
  id: string
  skill: skill
  category: string
  thumbnail: string
}

const deliverables: deliverable[] = [
  {
    name: 'Digital Ads & Marketing',
    id: 'projects_digital_ads_and_marketing',
    skill: skills.graphicDesign,
    category: 'Web & Digital',
    thumbnail: digitalAdsThumbnail,
  },
  {
    name: 'Logos & Branding',
    id: 'projects_logos_and_branding',
    skill: skills.graphicDesign,
    category: 'Visual Identity',
    thumbnail: logosBrandingThumbnail,
  },
  {
    name: 'Illustrations',
    id: 'projects_illustrations',
    skill: skills.customIllustration,
    category: 'Custom Illustrations',
    thumbnail: illustrationsThumbnail,
  },
  {
    name: 'Print & Merchandise',
    id: 'projects_print_and_merchandise',
    skill: skills.graphicDesign,
    category: 'Merchandise',
    thumbnail: printMerchandiseThumbnail,
  },
  {
    name: 'Presentations & Powerpoint',
    id: 'projects_presentations_and_powerpoint',
    skill: skills.presentationDesign,
    category: 'Presentations',
    thumbnail: presentationsPowerpointThumbnail,
  },
  {
    name: 'Animated Videos',
    id: 'projects_motion_graphics',
    skill: skills.motionGraphics,
    category: 'Motion Graphics',
    thumbnail: animatedVideosThumbnail,
  },
]

export default function Onboarding({ enabled, user, designerTimezones }: OnboardingProps) {
  const [open, setOpen] = useState(enabled)
  const [selectedDeliverables, setSelectedDeliverables] = useState<deliverable['id'][]>([])
  const [selectedTimeZone, setSelectedTimeZone] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function handleUpdatePreferences(selectedDeliverables: deliverable['id'][], selectedTimeZone: string) {
    setIsSubmitting(true)
    try {
      await updatePreferences({
        want_to_create: selectedDeliverables,
        current_time_zone: selectedTimeZone,
      })

      setOpen(false)
    } catch (e) {
      console.error('Error updating preferences:', e)
      setError('An unexpected error occurred. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  if (!enabled || !user || !designerTimezones) return null

  const timezones = designerTimezones.map((timezone) => ({
    displayElement: <>{timezone.label}</>,
    value: timezone.value,
  }))

  const allowSubmit = selectedDeliverables.length > 0 && selectedTimeZone

  return (
    <Modal open={open} setOpen={setOpen} clickOutsideToClose={false} className="tw-max-w-screen-lg tw-p-6">
      <div className="tw-flex tw-flex-col tw-gap-6 tw-leading-tight tw-text-neutral-800">
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-8 tw-rounded-lg tw-bg-neutral-800 tw-p-4 lg:tw-flex-row">
          <div className="tw-flex tw-flex-col tw-gap-2 tw-text-white">
            <h4 className="tw-m-0 tw-p-0 tw-text-xl tw-font-bold tw-text-white">
              {user.fullName}, welcome to Design Pickle!
            </h4>
            <p className="tw-m-0 tw-p-0 tw-text-sm">
              We’re thrilled to start working with you! To kick things off, we just need a bit more information to
              ensure everything gets off to a great start. Please take a moment to complete the details below.{' '}
            </p>
          </div>
          <img src={bannerImage} alt="Onboarding Banner" className="tw-h-auto tw-w-full tw-max-w-56" />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <div className="tw-font-semibold">Preferred time zone*</div>
          </div>
          <SelectBox
            options={timezones}
            placeholder="Select..."
            multiple={false}
            handleChange={(value) => setSelectedTimeZone(value as string)}
            selectedValue={selectedTimeZone}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <div className="tw-font-semibold">What are you looking to create?</div>
            <div>Select as many types of deliverables as you&apos;d like us to bring to life.*</div>
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-2 lg:tw-grid-cols-3">
            {deliverables.map((deliverable) => (
              <Deliverable
                deliverable={deliverable}
                key={deliverable.id}
                selected={selectedDeliverables.includes(deliverable.id)}
                onClick={() => {
                  setSelectedDeliverables((prev) =>
                    prev.includes(deliverable.id)
                      ? prev.filter((id) => id !== deliverable.id)
                      : [...prev, deliverable.id],
                  )
                }}
              />
            ))}
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-6">
          <div className="tw-h-px tw-w-full tw-bg-neutral-200" />
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
            <div className="tw-font-semibold">
              {selectedDeliverables.length} {selectedDeliverables.length === 1 ? 'format' : 'formats'} selected
            </div>
            {!allowSubmit ? (
              <Tooltip
                content="Please select a timezone and at least one deliverable to continue."
                direction="up"
                topOffset={-10}
              >
                <Button color="purple" disabled>
                  Take me to the Jar!
                </Button>
              </Tooltip>
            ) : (
              <Button
                color="purple"
                disabled={!allowSubmit}
                onClick={() => handleUpdatePreferences(selectedDeliverables, selectedTimeZone)}
              >
                {isSubmitting ? 'Saving...' : 'Take me to the Jar!'}
              </Button>
            )}
          </div>
          {error && <div className="tw-text-right tw-text-red-500">{error}</div>}
        </div>
      </div>
    </Modal>
  )
}

const Deliverable = ({
  deliverable,
  selected,
  onClick,
}: {
  deliverable: deliverable
  selected: boolean
  onClick: () => void
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'tw-flex tw-cursor-pointer tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-neutral-200 tw-p-2 tw-transition-all tw-duration-100 tw-ease-out',
        selected && 'tw-border-cornflower-200 tw-bg-cornflower-100',
      )}
    >
      <img src={deliverable.thumbnail} alt={deliverable.name} className="tw-aspect-square tw-w-[72px] tw-rounded-md" />
      <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2">
        <div className="tw-flex tw-h-5 tw-items-center tw-gap-1 tw-rounded-md tw-bg-neutral-100 tw-px-1.5 tw-font-normal">
          <div className="tw-leading-[0] [&>svg]:tw-h-3 [&>svg]:tw-w-3">{deliverable.skill.icon}</div>
          <div className="tw-text-xs">{deliverable.skill.title}</div>
        </div>
        <div className="tw-flex tw-flex-col tw-leading-tight">
          <div className="tw-text-sm tw-font-semibold">{deliverable.name}</div>
          <div className="tw-text-xs tw-text-neutral-500">{deliverable.category}</div>
        </div>
      </div>
    </div>
  )
}
