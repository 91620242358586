import { DesignerAllocation } from 'lib/api/company-designer-allocations/company-designer-allocations'
import DesignerRow from './designer-row'
import { useDesignerAllocationsContext } from './designer-allocations-provider'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { snakeCaseKeys } from 'lib/object/utils'

const classNames = {
  header: 'tw-p-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wide tw-text-gray-500',
  row: 'tw-border-0 tw-border-solid tw-border-b tw-border-peppercorn-100',
  table: 'tw-min-w-full tw-mb-4',
  wrapper: 'tw-mt-4',
}

export default function DesignerTable() {
  const { companyAllocation, isLoading, updateCompanyAllocation } = useDesignerAllocationsContext()
  const { designerAllocations } = companyAllocation

  function reorder(list: DesignerAllocation[], startIndex: number, endIndex: number): DesignerAllocation[] {
    const result: DesignerAllocation[] = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const reorderedDesignerAllocations: DesignerAllocation[] = reorder(
      designerAllocations,
      result.source.index,
      result.destination.index,
    )

    updateCompanyAllocation(
      snakeCaseKeys({
        id: companyAllocation.id,
        designerAllocationsAttributes: reorderedDesignerAllocations.map((d, index) => ({
          id: d.id,
          position: index + 1,
        })),
      }),
    )
  }

  return (
    <div className={classNames.wrapper}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <table className={classNames.table} ref={provided.innerRef}>
              <thead>
                <tr className={classNames.row}>
                  <th></th>
                  <th scope="col" className={classNames.header}>
                    Position
                  </th>
                  <th scope="col" className={classNames.header}>
                    Creative
                  </th>
                  <th scope="col" className={classNames.header}>
                    Timezone
                  </th>
                  <th scope="col" className={classNames.header}>
                    Scopes
                  </th>
                  <th scope="col" className={classNames.header}>
                    Total Allocated Companies
                  </th>
                  <th scope="col" className={classNames.header}>
                    Daily Capacity
                  </th>
                  <th scope="col" className={classNames.header}></th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <LoadingScreenTableRow />
                </tbody>
              ) : (
                <tbody>
                  {designerAllocations.map((allocation: DesignerAllocation, index: number) => {
                    return (
                      <Draggable
                        key={allocation.id.toString()}
                        draggableId={allocation.id.toString()}
                        index={index}
                        isDragDisabled={false}
                      >
                        {(provided) => (
                          <DesignerRow
                            key={allocation.id}
                            designerAllocation={allocation}
                            index={index}
                            draggableProvided={provided}
                          />
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                  <DesignerRow
                    key={0}
                    position={designerAllocations.length > 0 ? designerAllocations.at(-1).position + 1 : 1}
                    index={designerAllocations.length}
                  />
                </tbody>
              )}
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
