import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { DetailTaskTimelineItem, TimelineItemTypes } from 'lib/api/timeline/timeline'
import IconButton from 'lib/components/buttons/icon-button'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'

import { useDirectionsContext } from '../providers/timeline-directions-provider'
import DetailTask from '../types/detail-task'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import WYSIWYGTextarea, { footerClassNames } from 'lib/components/wysiwyg/wysiwyg-textarea'
import { cn } from 'lib/util/cn'

interface TimelineDirectionEditProps {
  direction: DetailTaskTimelineItem
  disableEditMode: () => void
}

const PLACEHOLDER = 'Start typing general direction'

const minimumLength = 3

export default function TimelineDirectionEdit({
  direction,
  disableEditMode,
}: TimelineDirectionEditProps): ReactElement {
  const inputRef = useRef(null)
  const [value, setValue] = useState(direction?.description || '')
  const { update, showDirectionErrors } = useDirectionsContext()
  const [showAttemptedSubmitError, setShowAttemptedSubmitError] = useState(false)
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const showError = useMemo(() => {
    return showDirectionErrors || (showAttemptedSubmitError && !hasMinimumLength(value))
  }, [showAttemptedSubmitError, showDirectionErrors, value])

  const showCharacterCounter = useMemo(() => direction?.taskType !== TimelineItemTypes.COPY, [direction?.taskType])

  function hasMinimumLength(s: string) {
    return s.trim().length >= minimumLength
  }

  function onTextAreaChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value
    onChange(newValue)
  }

  function onChange(newValue: string) {
    setValue(newValue)

    if (hasMinimumLength(newValue)) {
      setShowAttemptedSubmitError(false)
    }
  }

  async function save() {
    if (!hasMinimumLength(value)) {
      setShowAttemptedSubmitError(true)
      return
    }

    setShowAttemptedSubmitError(false)

    // TODO: updateTimelineItem
    await update(direction as unknown as DetailTask, value.trim())
    disableEditMode()
  }

  async function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      await save()
    } else if (e.key === 'Escape') {
      disableEditMode()
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      if (isFeatureFlagEnabled('request_wysiwyg')) {
        inputRef.current?.editor?.commands?.focus?.()
      } else {
        inputRef.current.focus()
        inputRef.current.scrollTop = inputRef.current.scrollHeight
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length)
      }
    }
  }, [isFeatureFlagEnabled])

  const footer = (
    <div className={cn(footerClassNames, 'tw-min-w-10')}>
      <span>
        <IconButton color="primary" disabled={!hasMinimumLength(value)} icon={['far', 'check']} onClick={save} />
      </span>
      <span onMouseDown={(e) => e.preventDefault()}>
        <IconButton color="secondary" icon={['far', 'times']} onClick={disableEditMode} />
      </span>
    </div>
  )

  return (
    <>
      <div className="tw-relative tw-flex tw-w-full tw-items-center tw-gap-2">
        {isFeatureFlagEnabled('request_wysiwyg') ? (
          <WYSIWYGTextarea
            ref={inputRef}
            defaultValue={value}
            placeholder={PLACEHOLDER}
            onChange={onChange}
            footer={footer}
          />
        ) : (
          <>
            <Textarea
              value={value}
              onChange={onTextAreaChange}
              placeholder={PLACEHOLDER}
              error={showError}
              maxLength={direction.taskType === TimelineItemTypes.COPY ? -1 : undefined}
              onKeyDown={onKeyDown}
              ref={inputRef}
            />
            {footer}
          </>
        )}
      </div>
      {showCharacterCounter && !isFeatureFlagEnabled('request_wysiwyg') && (
        <TextCounter value={value} className="tw-mr-24 tw-text-right" />
      )}
    </>
  )
}
