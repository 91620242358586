import { useQuery } from '@tanstack/react-query'
import Checkbox from 'components/core/checkbox'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Project } from 'interfaces/project'
import { requestQuery } from 'lib/api/fetch-api'
import { Popover, PopoverContent, PopoverTrigger } from 'lib/components/popover/popover'
import SearchInput from 'lib/components/search-input/search-input'
import Tooltip from 'lib/components/tooltip/tooltip'
import { ChevronDownIcon, XIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

interface ProjectsFilterProps {
  selectedProjectIds: number[]
  onUpdate: (selectedProjectIds: number[]) => void
}

export default function ProjectsFilter({ selectedProjectIds, onUpdate }: ProjectsFilterProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [currentSelectedProjects, setCurrentSelectedProjects] = useState<Project[]>([])

  const { data, isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: requestQuery({
      endpoint: 'getProjects',
    }),
  })

  const projects = data?.data

  const filteredProjects = projects?.filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))

  const handleSelectProject = (clickedProject: Project) => {
    const newProjects = currentSelectedProjects.includes(clickedProject)
      ? currentSelectedProjects.filter((p) => p.id !== clickedProject.id)
      : [...currentSelectedProjects, clickedProject]
    setCurrentSelectedProjects(newProjects)
    onUpdate(newProjects.map((p) => p.id))
  }

  useEffect(() => {
    if (projects && selectedProjectIds.length > 0) {
      setCurrentSelectedProjects(projects.filter((p) => selectedProjectIds.includes(p.id)))
    }
  }, [projects, selectedProjectIds])

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div>
          <MultiSelectFilterTrigger
            selectedProjects={currentSelectedProjects}
            setSelectedProjects={setCurrentSelectedProjects}
            onUpdate={onUpdate}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="tw-max-h-96 tw-w-72 tw-overflow-y-auto"
        intent="flyout"
        align="start"
        alignOffset={-10}
      >
        <h4 className="tw-text-md tw-m-0 tw-mb-2 tw-ml-2 tw-font-bold tw-text-neutral-900">Projects</h4>
        <SearchInput
          value={search}
          onChange={(searchText) => setSearch(searchText)}
          placeholder="Search projects"
          className="tw-mx-1 tw-my-4"
        />
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <ProjectCheckboxes
            projects={filteredProjects}
            selectedProjects={currentSelectedProjects}
            onSelectProject={handleSelectProject}
          />
        )}
      </PopoverContent>
    </Popover>
  )
}

interface MultiSelectFilterTriggerProps {
  selectedProjects: Project[]
  setSelectedProjects: (projects: Project[]) => void
  onUpdate: (projectIds: number[]) => void
}

function MultiSelectFilterTrigger({ selectedProjects, setSelectedProjects, onUpdate }: MultiSelectFilterTriggerProps) {
  const selectedProjectNames = selectedProjects?.map((p) => p.name).join(', ')

  const handleXClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setSelectedProjects([])
    onUpdate([])
  }

  return (
    <div className="tw-flex tw-w-48 tw-items-center tw-justify-between tw-gap-2 tw-rounded-md tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-px-1.5 tw-py-2.5 tw-text-xs tw-text-neutral-500 hover:tw-border-picklegreen-500">
      {selectedProjects.length > 0 ? (
        <>
          <div className="tw-w-32 tw-truncate">{selectedProjectNames}</div>
          <button
            onClick={handleXClick}
            data-testid="projects-filter-x-button"
            className="tw-ml-2 tw-border-none tw-bg-transparent tw-text-xs tw-text-neutral-500 hover:tw-text-neutral-700"
          >
            <XIcon className="lu-sm" />
          </button>
        </>
      ) : (
        <div>Projects</div>
      )}
      <ChevronDownIcon className="tw-h-4 tw-w-4" />
    </div>
  )
}

interface ProjectCheckboxesProps {
  projects: Project[]
  selectedProjects: Project[]
  onSelectProject: (project: Project) => void
}

function ProjectCheckboxes({ projects, selectedProjects, onSelectProject }: ProjectCheckboxesProps) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {projects.map((p) => (
        <Tooltip content={p.name} key={p.id} direction="right">
          <div className="tw-flex tw-min-h-5 tw-w-full tw-items-center tw-gap-2 tw-truncate">
            <Checkbox
              checked={selectedProjects.includes(p)}
              onChange={() => onSelectProject(p)}
              label={p.name}
              className="tw-w-full"
            />
          </div>
        </Tooltip>
      ))}
    </div>
  )
}
