import videojs from 'video.js'
import AnnotationComments from '@contently/videojs-annotation-comments'

import jquery from 'jquery'
import 'video.js/dist/video-js.css'
import './videojs.scss'
global.$ ??= jquery

import {
  VideoJSAnnotationObject,
  VideoJSAnnotationLocation,
  VideoAnnotationPayload,
} from 'lib/api/ticket-files/ticket-files'
import debounce from 'lib/util/debounce'

type AnnotationCreatedSubscriptionCallback = (annotation: VideoJSAnnotationObject) => void | Promise<void>
type AnnotationStartedSubscriptionCallback = (detail: VideoJSAnnotationLocation) => void | Promise<void>
type AnnotationListUpdatedSubscriptionCallback = (allAnnotations: VideoJSAnnotationObject[]) => void | Promise<void>
type AnnotationUpdatedSubscriptionCallback = (
  commentId: string,
  annotation: VideoJSAnnotationObject,
) => void | Promise<void>

interface AnnotationUser {
  id: number
  fullName: string
}

type SubscriptionCallback =
  | AnnotationCreatedSubscriptionCallback
  | AnnotationListUpdatedSubscriptionCallback
  | AnnotationStartedSubscriptionCallback
  | AnnotationUpdatedSubscriptionCallback

type SubscriptionEventType = 'annotationCreated' | 'annotationStarted' | 'annotationListUpdated' | 'annotationUpdated'

function deepClone(obj) {
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch (error) {
    console.error('VideoPlayer deep clone error:', error)
    return obj instanceof Array ? [] : {}
  }
}

export default class VideoPlayer {
  #annotations: VideoJSAnnotationObject[] = []
  #cancelCallback: () => void
  #selectCallback: (annotation: VideoJSAnnotationObject) => void
  readonly #el: HTMLElement
  player
  #plugin
  #subscriptions = []
  readonly #ticketFileId: number
  readonly #user: AnnotationUser

  constructor(
    div: HTMLDivElement,
    ticketFileId: number,
    url: string,
    user: AnnotationUser,
    cancelCallback: () => void,
    selectCallback: (annotation: VideoJSAnnotationObject) => void,
  ) {
    this.#user = user
    this.#cancelCallback = cancelCallback
    this.#selectCallback = selectCallback
    this.#ticketFileId = ticketFileId
    this.#el = document.createElement('video-js')
    this.#el.className = 'vjs-big-play-centered vjs-16-9 !tw-pt-0 !tw-h-full'
    div.appendChild(this.#el)
    this.player = videojs(this.#el, {
      autoplay: false,
      controls: true,
      preload: 'auto',
      sources: [
        {
          src: url,
          type: 'video/mp4',
        },
      ],
    })
  }

  annotate() {
    if (!this.player.isDisposed() && this.#plugin) {
      this.#plugin.fire('addingAnnotation')
    }
  }

  cancel() {
    if (!this.player.isDisposed() && this.#plugin) {
      this.#plugin.fire('cancelAddingAnnotation')
      this.#cancelCallback()
    }
  }

  #click(event) {
    if (event.target.classList.contains('vac-shape')) {
      event.stopPropagation()
      const t = Math.floor(this.player.currentTime())

      const { style } = event.target
      const shape = {
        x1: parseInt(style.left),
        x2: parseInt(style.left) + parseInt(style.width),
        y1: parseInt(style.top),
        y2: parseInt(style.top) + parseInt(style.height),
      }

      const detectedAnnotation = this.#annotations.find((annotation) => {
        return (
          annotation.range.start === t &&
          annotation.shape.x1 === shape.x1 &&
          annotation.shape.x2 === shape.x2 &&
          annotation.shape.y1 === shape.y1 &&
          annotation.shape.y2 === shape.y2
        )
      })

      if (detectedAnnotation) {
        this.#selectCallback(detectedAnnotation)
      }
    }
  }

  create(bodyText: string, detail: VideoJSAnnotationLocation) {
    if (this.player.isDisposed() || !this.#plugin) {
      throw new Error('Video Player no longer exists')
    }
    const payload = {
      id: detail.commentId,
      range: detail.range,
      shape: detail.shape,
      commentStr: bodyText,
    }
    this.#plugin.fire('newAnnotation', payload)
  }

  destroy() {
    if (!this.player.isDisposed()) {
      this.player.dispose()
      this.player = null
      this.#unsubscribeAll()
    }
  }

  edit(id: number, uuid: string, bodyText: string) {
    // Finds the annotation that has the comment with the given uuid and updates the body of the comment
    this.#annotations.map((annotation) => {
      if (annotation.comments[0].id === uuid) {
        annotation.comments[0].body = bodyText
      }
    })

    // Build the payload for the annotationUpdated event
    const updatedAnnotationPayload = {
      id: id,
      body: bodyText,
      data: this.#findAnnotationByCommentId(uuid),
    }

    this.#sync()
    this.#publish('annotationUpdated', updatedAnnotationPayload)
    this.#publish('annotationListUpdated', this.#annotations)
  }

  #findAnnotationForComment(uuid: string) {
    return this.#annotations.reduce((acc, annotation) => {
      if (!acc) {
        const comment = this.#findComment(annotation.comments, uuid)
        if (comment) {
          return annotation
        }
      }
      return acc
    }, null)
  }

  #findAnnotationByCommentId(uuid) {
    return this.#annotations.find((annotation) => annotation.comments[0].id === uuid)
  }

  #findComment(comments, uuid) {
    return comments.find((comment) => comment.id === uuid)
  }

  #publish(eventType: SubscriptionEventType, payload) {
    this.#subscriptions.forEach((subscription) => {
      if (subscription.eventType === eventType) {
        subscription.callback(payload)
      }
    })
  }

  remove(uuid: string) {
    if (!this.player.isDisposed() && this.#plugin) {
      this.#plugin.fire('destroyComment', { id: uuid })
    }
  }

  seed(annotations: VideoJSAnnotationObject[] = [], onReady: () => void) {
    this.#annotations = annotations

    if (!videojs.getPlugin('annotationComments')) {
      videojs.registerPlugin('annotationComments', AnnotationComments(videojs))
    }

    this.#sync()

    this.#plugin.onReady(() => {
      this.#plugin.player.addClass('vac-active')

      this.#plugin.player.on('click', (event) => this.#click(event))

      const debouncedAddingAnnotationDataChanged = debounce((event) => {
        const t = Math.floor(this.player.currentTime())
        const detail: VideoJSAnnotationLocation = {
          range: { start: t, stop: t },
          shape: event.detail.shape,
        }
        this.#publish('annotationStarted', detail)
      }, 500)

      const debouncedOnStateChanged = debounce((event) => {
        const existingAnnotationIds = this.#annotations.map((annotation) => annotation.id)
        const createdAnnotation = event.detail.find((annotation) => !existingAnnotationIds.includes(annotation.id))

        this.#annotations = deepClone(event.detail)

        if (createdAnnotation) {
          this.#publish('annotationCreated', createdAnnotation)
        } else {
          this.#publish('annotationListUpdated', event.detail)
        }
      }, 500)

      this.#plugin.registerListener('addingAnnotationDataChanged', (event: VideoAnnotationPayload) => {
        debouncedAddingAnnotationDataChanged(event)
      })

      this.#plugin.registerListener('onStateChanged', (event: VideoAnnotationPayload) => {
        debouncedOnStateChanged(event)
      })

      onReady()
    })
  }

  select(uuid: string) {
    if (!this.player.isDisposed() && this.#plugin) {
      const annotation = this.#findAnnotationForComment(uuid)
      if (annotation) {
        this.#plugin.fire('openAnnotation', { id: annotation.id })
      }
    }
  }

  subscribe(eventType: SubscriptionEventType, callback: SubscriptionCallback) {
    this.#subscriptions.push({ eventType, callback })
  }

  #sync() {
    const annotationPluginOptions = {
      active: true,
      annotationsObjects: deepClone(this.#annotations),
      bindArrowKeys: false,
      internalCommenting: false,
      meta: { user_id: this.#user.id, user_name: this.#user.fullName },
      showCommentList: false,
      showControls: false,
      showFullScreen: false,
      showMarkerShapeAndTooltips: false,
      startInAnnotationMode: true,
    }

    this.#plugin = this.player.annotationComments(annotationPluginOptions)
  }

  get ticketFileId() {
    return this.#ticketFileId
  }

  #unsubscribeAll() {
    this.#subscriptions = []
  }
}
