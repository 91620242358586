import {
  ChangeEvent,
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import axios from 'axios'
import { EmptyTicketFilters, TicketFilters } from 'components/pages/requests/ticket-list-filters'
import { Brand } from 'interfaces/brand'
import { Ticket } from 'interfaces/ticket'
import { User } from 'interfaces/user'
import { getSingleQTicketsAndRequests, getStateFromQueryString, TicketData, UrlState } from 'lib/api/tickets/tickets'
import { reorder } from 'lib/array/utils'
import { hasOnlyFalsyValues } from 'lib/object/utils'
import { isStatusCompleted, isStatusOnDeck } from 'lib/ticket/utils'
import { isEnabled } from 'lib/api/feature-flags/feature-flags'
import { toast } from 'lib/components/toast/toast'
import { updateParams } from 'lib/util/url'

const DEFAULT_TAB = 'active'

const SORTABLE_COLUMNS_FOR_USER = ['position', 'friendly_status_name', 'project']

export const DEFAULT_SORT_COLUMN: Record<string, SortTuple> = {
  active: ['friendly_status_name', 'ASC'],
  draft: ['id', 'ASC'],
  archived: ['last_updated_for_user', 'DESC'],
}

export type ActiveTab = 'active' | 'draft' | 'archived'

export type SortDirection = 'ASC' | 'DESC'

export type SortTuple = [string, SortDirection]

export interface BulkActionId {
  ticketId: bigint | number
  requestId?: bigint | number
}

interface SingleQueueProviderProps {
  children: ReactNode
  userId: number
}

interface SingleQueueContextValue {
  activeTab: ActiveTab
  brands: Brand[]
  bulkActionIds: BulkActionId[]
  bulkSelect: (event: ChangeEvent, id: bigint, friendlyStatusName: string, requestId?: bigint) => void
  bulkStateSelected: string | null
  categories: string[]
  changeTab: (string: string) => void
  currentPage: number
  dataLoaded: boolean
  dragDisable: boolean
  fetchTickets: (params?: FetchTicketsParams) => Promise<TicketData>
  filters: TicketFilters
  handleFilterChange: (filters: TicketFilters) => void
  handlePageChange: (selected: { selected: number }) => void
  loading: boolean
  onDragEnd: (result, items?) => void
  oops: (err) => void
  pageCount: number
  priorityCancelModal: boolean
  priorityConfirmModal: boolean
  priorityMode: boolean
  priorityModeEnabled: boolean
  setActiveTab: Dispatch<SetStateAction<ActiveTab>>
  setBulkActionIds: (ids: Array<BulkActionId>) => void
  setBulkStateSelected: (string) => void
  setCurrentPage: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<TicketFilters>>
  setPriorityCancelModal: Dispatch<SetStateAction<boolean>>
  setPriorityConfirmModal: Dispatch<SetStateAction<boolean>>
  setPriorityMode: Dispatch<SetStateAction<boolean>>
  setUrlState: Dispatch<SetStateAction<UrlState>>
  setUserCanSort: Dispatch<SetStateAction<boolean>>
  sortBy: (column?, direction?) => void
  sortColumn: string | null
  sortDirection: 'ASC' | 'DESC'
  sortTickets: (reorderedTickets, params?) => void
  statuses: string[]
  tabs: string[]
  tickets: Ticket[]
  updateTicket: (ticket: Ticket) => void
  urlState: UrlState
  userCanSort: boolean | null
  users: User[]
}

export interface FetchTicketsParams {
  priority_mode?: boolean
  sort_column?: string
  sort_direction?: SortDirection
}

const SingleQueueContext = createContext({})

function goToPage(page: number): void {
  const params = new URLSearchParams(window.location.search)
  params.set('page', page.toString())
  updateParams(params)
}

function goToTab(tab: ActiveTab): void {
  const params = new URLSearchParams(window.location.search)
  params.set('tab', tab)
  params.delete('page')
  updateParams(params)
}

function setParam(params, key, value) {
  if (value !== null && value !== undefined && value !== '' && value?.length !== 0) {
    params.set(key, value.toString())
  }
}

export function useSingleQueueContext(): SingleQueueContextValue {
  return useContext(SingleQueueContext) as SingleQueueContextValue
}

export default function SingleQueueProvider({ children, userId }: SingleQueueProviderProps): ReactElement {
  const [defaultColumn, defaultDirection] = DEFAULT_SORT_COLUMN[DEFAULT_TAB]

  const [activeTab, setActiveTab] = useState<ActiveTab>(DEFAULT_TAB)
  const [brands, setBrands] = useState<Brand[]>([])
  const [bulkActionIds, setBulkActionIds] = useState<BulkActionId[]>([])
  const [bulkStateSelected, setBulkStateSelected] = useState<string | null>(null)
  const [categories, setCategories] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [filters, setFilters] = useState<TicketFilters>(EmptyTicketFilters)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>(1)
  const [sortColumn, setSortColumn] = useState<string>(defaultColumn)
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>(defaultDirection)
  const [statuses, setStatuses] = useState<string[]>([])
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [userCanSort, setUserCanSort] = useState<boolean | null>(null)
  const [users, setUsers] = useState<User[]>([])
  const [priorityMode, setPriorityMode] = useState<boolean>(false)
  const [priorityModeEnabled, setPriorityModeEnabled] = useState<boolean>(false)
  const [priorityCancelModal, setPriorityCancelModal] = useState<boolean>(false)
  const [priorityConfirmModal, setPriorityConfirmModal] = useState<boolean>(false)
  const [urlState, setUrlState] = useState<UrlState>(getStateFromQueryString())

  const oops = useCallback((err) => {
    const message = err?.response?.data?.message || 'Oops! Something went wrong. Please try again.'
    toast.error(message)
    console.error(err)
  }, [])

  useEffect(() => {
    isEnabled('ticket_prioritization').then((enabled) => {
      setPriorityModeEnabled(enabled)
    })
  }, [])

  function sortResults(sort: SortTuple): void {
    const params = new URLSearchParams(window.location.search)
    setParam(params, 'sort', sort.join('|'))
    setParam(params, 'page', '1')
    updateParams(params)
  }

  function sortBy(column = 'position', direction: SortDirection = 'ASC') {
    setCurrentPage(1)

    sortResults([column, direction])
    fetchTickets().catch(() => null)
  }

  const dragDisable = useMemo(() => {
    return (
      activeTab !== 'active' ||
      !hasOnlyFalsyValues(filters) ||
      !SORTABLE_COLUMNS_FOR_USER.includes(sortColumn) ||
      sortDirection === 'DESC'
    )
  }, [activeTab, filters, sortColumn, sortDirection])

  const tabs = ['active', 'draft', 'archived']

  function bulkSelect(event, id, status, requestId) {
    event.stopPropagation()
    if (bulkActionIds.some((bulkActionId) => bulkActionId.ticketId === id || bulkActionId.requestId === requestId)) {
      setBulkActionIds((prevBulkId) =>
        prevBulkId.filter((bulkActionId) => bulkActionId.ticketId !== id || bulkActionId.requestId !== requestId),
      )
      if (bulkActionIds.length === 1) {
        setBulkStateSelected(null)
      }
    } else {
      setBulkActionIds((prevBulkActionIds) => [...prevBulkActionIds, { ticketId: id, requestId: requestId }])
      if (bulkStateSelected === null) {
        setBulkStateSelected(status)
      }
    }
  }

  async function sortTickets(reorderedTickets, params = {}) {
    if (SORTABLE_COLUMNS_FOR_USER.includes(sortColumn)) {
      try {
        const { data } = await axios.post(window.Routes.apiInternalTicketsUrl(), {
          tickets: reorderedTickets.map((ticket) => ticket.id),
          tab: activeTab,
          sort_column: sortColumn,
          sort_direction: sortDirection,
          page: currentPage,
          ...params,
        })

        setTickets(data.tickets)
        setPageCount(Math.ceil(data.count / data.per_page))

        if (priorityModeEnabled) {
          toast.success('Requests have been reprioritized. Your queue will be reassigned based on priority.')
        } else {
          toast.success('Tickets Sorted')
        }
      } catch {
        toast.error(
          'Due to your currently assigned role, you are unable to change request priority. Please contact your company administrator to change roles.',
        )
      }
    }
  }

  async function fetchTickets(params: FetchTicketsParams = {}) {
    setLoading(true)

    const { data, column, direction } = await getSingleQTicketsAndRequests({ params, userId, dataLoaded })

    setTickets(data.tickets)
    setBulkActionIds([])
    setBulkStateSelected(null)
    setPageCount(Math.ceil(data.count / data.per_page))
    setSortColumn(column)

    setSortDirection(direction)
    if (!dataLoaded) {
      setStatuses(data.statuses)
      setCategories(data.categories)
      setUsers(data.users)
      setBrands(data.brands)
      setDataLoaded(true)
    }

    setLoading(false)

    return data
  }

  function onDragEnd(result, items = tickets) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const offsetPosition = items[0].position || 1

    const ticketsToReorder = priorityMode
      ? items.filter(({ friendlyStatusName }) => isStatusOnDeck(friendlyStatusName))
      : items
    const reorderedTickets = reorder(ticketsToReorder, result.source.index, result.destination.index)

    function isInImmovableState(friendlyStatusName) {
      return !isStatusOnDeck(friendlyStatusName)
    }

    const ticketsAllowedToBeReordered = reorderedTickets
      .filter(({ friendlyStatusName }) => isStatusOnDeck(friendlyStatusName))
      .map((ticket: Ticket, index) => {
        ticket.position = index + offsetPosition
        return ticket
      })

    const immovableTicketsAboveOnDeck = reorderedTickets.filter(
      ({ friendlyStatusName }) => isInImmovableState(friendlyStatusName) && !isStatusCompleted(friendlyStatusName),
    )
    const immovableTicketsBelowOnDeck = reorderedTickets.filter(
      ({ friendlyStatusName }) => isInImmovableState(friendlyStatusName) && isStatusCompleted(friendlyStatusName),
    )

    const newPositionTickets = [
      ...immovableTicketsAboveOnDeck,
      ...ticketsAllowedToBeReordered,
      ...immovableTicketsBelowOnDeck,
    ]

    if (!priorityModeEnabled) sortTickets(newPositionTickets).catch(() => null)
    setTickets(newPositionTickets as Ticket[])
  }

  function changeTab(selectedTab) {
    goToTab(selectedTab)
    setActiveTab(selectedTab)
    setCurrentPage(1)
    fetchTickets().catch(() => null)
  }

  function handlePageChange({ selected }) {
    const page = selected + 1
    setCurrentPage(selected + 1)
    goToPage(page)
    fetchTickets().catch(() => null)
    window.scrollTo(0, 0)
  }

  function setFiltersInQueryString(activeTab: ActiveTab, filters: TicketFilters, currentPage: number): void {
    const params = new URLSearchParams()
    setParam(params, 'brand', filters.brandIdEq)
    setParam(params, 'page', currentPage)
    setParam(params, 'skill', filters.skillSkillCategorySubscriptionTypeEq)
    setParam(params, 'sort', DEFAULT_SORT_COLUMN[activeTab].join('|'))
    setParam(params, 'status', filters.friendlyStatus)
    setParam(params, 'tab', activeTab)
    setParam(params, 'title', filters.titleQuery)
    setParam(params, 'user', filters.userIdEq)
    setParam(params, 'company_tags_id_in_any', filters.companyTagsIdInAny)
    setParam(params, 'project_id_in', filters.projectIdIn)
    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`)
  }

  function handleFilterChange(filters: TicketFilters) {
    setFilters(filters)
    setFiltersInQueryString(activeTab, filters, 1)
  }

  function updateTicket(ticket: Ticket) {
    setTickets((prevTickets) =>
      prevTickets.map((t) => {
        // This accounts for the draft tab where there are ticketIds and ids
        if (Number(t.ticketId || t.id) === Number(ticket.ticketId || ticket.id)) {
          return { ...t, ...ticket }
        }
        return t
      }),
    )
  }

  const context: SingleQueueContextValue = {
    activeTab,
    brands,
    bulkActionIds,
    bulkSelect,
    bulkStateSelected,
    categories,
    changeTab,
    currentPage,
    dataLoaded,
    dragDisable,
    fetchTickets,
    filters,
    handleFilterChange,
    handlePageChange,
    loading,
    onDragEnd,
    oops,
    pageCount,
    priorityCancelModal,
    priorityConfirmModal,
    priorityMode,
    priorityModeEnabled,
    setActiveTab,
    setBulkActionIds,
    setBulkStateSelected,
    setCurrentPage,
    setFilters,
    setPriorityCancelModal,
    setPriorityConfirmModal,
    setPriorityMode,
    setUrlState,
    setUserCanSort,
    sortBy,
    sortColumn,
    sortDirection,
    sortTickets,
    statuses,
    tabs,
    tickets,
    updateTicket,
    urlState,
    userCanSort,
    users,
  }

  return <SingleQueueContext.Provider value={context}>{children}</SingleQueueContext.Provider>
}
