import { useMutation, useQueryClient } from '@tanstack/react-query'
import GrowingTextInput from 'components/core/text-input/growing-text-input'
import { AdminProjectTemplate, SkillWithCategoryFields } from 'interfaces/project-template'
import { endpoints, getUrl, request } from 'lib/api/fetch-api'
import CopyTextButton from 'lib/components/buttons/copy-text-button'
import IconButton from 'lib/components/buttons/icon-button'
import { Drawer } from 'lib/components/drawer/drawer'
import { displayDate } from 'lib/util/date'
import { ArrowRightToLineIcon, LinkIcon, LoaderCircleIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import { toast } from 'sonner'
import TemplateRequestsTable from './template-requests-table'
import Textarea from 'lib/components/textarea/textarea'
import { Skill } from 'interfaces/skill'
import ProjectPartnershipForm from 'components/pages/admin/project-templates/project-partnership-form'
import { Partner, PartnerParams } from 'interfaces/partner'
import { InlineImageViewUploader, useBytescaleUploader } from 'lib/components/file-uploader/file-uploader'
import Switch from 'lib/components/switch'
import { baseRequest, Params, patchApi } from 'lib/api/api'
import { snakeCaseKeys } from 'lib/object/utils'
import { cn } from 'lib/util/cn'
import { differenceBy } from 'lodash'
import ProjectsPartnerPreview from './project-partner-preview'
import AdminBadges from './admin-badges'

interface ProjectTemplateDrawerProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  template?: AdminProjectTemplate
}

export default function ProjectTemplateDrawer({ isOpen, setOpen, template }: ProjectTemplateDrawerProps) {
  return (
    <Drawer isOpen={isOpen} setOpen={setOpen} size="md">
      <TemplateDetailsContent setOpen={setOpen} template={template} />
    </Drawer>
  )
}

interface TemplateDetailsContentProps {
  setOpen: (open: boolean) => void
  template: AdminProjectTemplate
}

const blankPartner: Partner = {
  id: null,
  body: '',
  header: '',
  label: 'Expertly crafted by',
  link: '',
  linkText: '',
  logo: '',
  name: '',
  blogPostLink: '',
}

const TemplateDetailsContent = ({ setOpen, template }: TemplateDetailsContentProps) => {
  const [templateName, setTemplateName] = useState(template?.name || '')
  const [description, setDescription] = useState(template?.description || '')
  const [shared, setShared] = useState(template?.shared || false)
  const [selectedTickets, setSelectedTickets] = useState<Skill[] | SkillWithCategoryFields[]>(
    template?.ticketTemplates.map((ticket) => ticket.skill) || [],
  )
  const [partner, setPartner] = useState<Partner>(template?.partner || blankPartner)
  const [newLogoFile, setNewLogoFile] = useState<File>()
  const [newTemplateFile, setNewTemplateFile] = useState<File>()
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [isPartnership, setIsPartnership] = useState(!!template?.partner?.id)
  const [showAddRequests, setShowAddRequests] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const queryClient = useQueryClient()
  const { upload } = useBytescaleUploader()

  const templateImageUrl = useMemo(() => {
    return newTemplateFile ? URL.createObjectURL(newTemplateFile) : template?.imageUrl
  }, [template?.imageUrl, newTemplateFile])

  const uploadTemplateImage = async (templateId: number, image: File) => {
    const url = getUrl(`/api/internal${endpoints.updateProjectTemplate.path}`, { id: templateId })
    const params = new FormData()
    params.append('project_template[image]', image)
    await baseRequest(url, patchApi, params as unknown as Params)
  }

  const partnerWithLogoName = async (): Promise<Partner> => {
    if (isPartnership) {
      const logoFileName = newLogoFile ? await uploadLogo() : undefined

      return {
        ...partner,
        logo: logoFileName,
      } as Partner
    }

    return undefined
  }

  const validateForm = () => {
    if (!validateTemplateForm()) {
      setShowValidationErrors(true)
      toast.error('Please fill out all fields')
      return
    }

    if (!template && selectedTickets.length === 0) {
      setShowValidationErrors(true)
      toast.error('Please select at least one request')
      return
    }

    if (isPartnership && !validatePartnershipForm()) {
      setShowValidationErrors(true)
      toast.error('Please fill out all required fields')
      return
    }

    return true
  }

  const saveTemplate = async () => {
    if (shared && !validateForm()) {
      return
    }

    if (template) {
      updateTemplateMutation.mutate({
        name: templateName.trim(),
        description: description.trim(),
        image: newTemplateFile ? newTemplateFile : undefined,
        partner: await partnerWithLogoName(),
        selectedTickets: selectedTickets as Skill[],
        shared,
      })
    } else {
      createTemplateMutation.mutate({
        name: templateName.trim(),
        description: description.trim(),
        selectedTickets: selectedTickets as Skill[],
        image: newTemplateFile,
        partner: await partnerWithLogoName(),
        shared,
      })
    }
  }

  const createTemplateMutation = useMutation({
    mutationFn: async ({
      name,
      description,
      selectedTickets,
      image,
      partner,
      shared,
    }: {
      name?: string
      description?: string
      selectedTickets: Skill[]
      image: File
      partner?: Partner
      shared: boolean
    }) => {
      const { data } = await request({
        endpoint: 'createProjectTemplate',
        body: {
          project_template: {
            name,
            description,
            shared,
            image,
            ticket_templates_attributes: selectedTickets.map((ticket) => ({
              skill_id: ticket.id,
              subject: ticket.name,
            })),
            partner_attributes: isPartnership ? snakeCaseKeys(partner) : undefined,
          },
        },
      })

      await uploadTemplateImage(data.id, image)
    },
    onSuccess: async () => {
      toast.success('Project template created')
      queryClient.invalidateQueries({ queryKey: ['projectTemplates'] })
      setOpen(false)
    },
    onError: (error) => {
      toast.error('Failed to create project template')
      console.error('Failed to create project template', error)
    },
  })

  const getPartnerParams = (partner: Partner): PartnerParams => {
    if (!isPartnership && template?.partner?.id) {
      return {
        id: template?.partner?.id,
        _destroy: true,
      } as PartnerParams
    } else if (!isPartnership) {
      return undefined
    }

    return snakeCaseKeys(partner)
  }

  const updateTemplateMutation = useMutation({
    mutationFn: async ({
      name,
      description,
      image,
      partner,
      selectedTickets,
      shared,
    }: {
      name?: string
      description?: string
      image?: File
      partner?: Partner
      selectedTickets: Skill[]
      shared: boolean
    }) => {
      const difference = differenceBy(
        template.ticketTemplates.map((ticket) => ticket.skill),
        selectedTickets,
        'id',
      )

      const ticketTemplatesDestroy = template.ticketTemplates
        .filter((ticketTemplate) => difference.includes(ticketTemplate.skill))
        .map((ticketTemplate) => ({
          id: ticketTemplate.id,
          _destroy: true,
        }))

      const ticketTemplatesParams = [
        ...ticketTemplatesDestroy,
        ...selectedTickets.map((skill) => ({
          skill_id: skill.id,
          subject: skill.name,
        })),
      ]

      await request({
        endpoint: 'updateProjectTemplate',
        query: { id: template.id },
        body: {
          project_template: {
            name,
            description,
            shared,
            partner_attributes: getPartnerParams(partner),
            ticket_templates_attributes: ticketTemplatesParams,
          },
        },
      })
      // This has to be done separately because the image it not a url like the logo image
      if (image) {
        await uploadTemplateImage(template.id, image)
      }
    },
    onSuccess: async () => {
      toast.success('Project template updated')
      queryClient.invalidateQueries({ queryKey: ['projectTemplates'] })
      setOpen(false)
    },
    onError: (error) => {
      toast.error('Failed to update project template')
      console.error('Failed to update project template', error)
    },
  })

  const uploadLogo = async () => {
    const result = await upload({ data: newLogoFile })
    return result.uploadedFileName
  }

  const validateTemplateForm = () => {
    return !!(templateName.trim().length > 0 && description.trim().length > 0 && templateImageUrl)
  }

  const validatePartnershipForm = () => {
    const validationFields = [
      partner.name.trim(),
      partner.label.trim(),
      partner.header.trim(),
      partner.body.trim(),
      partner.linkText.trim(),
      partner.link.trim(),
      partner.logo.trim(),
      partner.blogPostLink.trim(),
    ]

    if (validationFields.some((field) => field === '')) {
      return false
    }

    return true
  }

  const isLoading = updateTemplateMutation.isPending || createTemplateMutation.isPending

  const getSubmitText = () => {
    if (isLoading) return <LoaderCircleIcon className="tw-animate-spin" />

    if (showAddRequests) return 'Add to project template'

    if (template) return 'Save'

    return 'Create project template'
  }

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-4 tw-pb-2 tw-pt-4">
          <div className="tw-flex tw-w-full tw-items-center tw-gap-4">
            <IconButton size="xs" color="transparent" onClick={() => setOpen(false)} className="tw-mr-2">
              <ArrowRightToLineIcon className="tw-text-neutral-700" />
            </IconButton>
            <InlineImageViewUploader
              id="template-upload"
              types={['image/png', 'image/jpeg']}
              onChange={(image) => setNewTemplateFile(image)}
              imageUrl={templateImageUrl}
              error={showValidationErrors && !templateImageUrl}
            />
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
              <div className="tw-flex tw-items-center tw-gap-2">
                <GrowingTextInput
                  data-testid="project-name-input"
                  placeholder="Enter a project name*"
                  value={templateName || ''}
                  onChange={(e) => setTemplateName(e.target.value)}
                  name="project-name"
                  className="tw-w-full tw-max-w-96"
                  extraWidth={54}
                  hasError={showValidationErrors && templateName.trim().length === 0}
                />
                <AdminBadges shared={shared} isPartnership={isPartnership} />
              </div>
              {template && <div>Last updated on {displayDate(template?.updatedAt)}</div>}
            </div>
          </div>
          {template && (
            <div>
              <CopyTextButton textToCopy={window.location.href} defaultIcon={<LinkIcon />} />
            </div>
          )}
        </div>
      </Drawer.Header>
      {showAddRequests ? (
        <Drawer.Body>
          <TemplateRequestsTable
            setSelectedRows={setSelectedTickets}
            selectedRows={selectedTickets as Skill[]}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isBlankTemplate={false}
          />
        </Drawer.Body>
      ) : (
        <Drawer.Body>
          <div>
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
              <div className="tw-py-2">
                <h4 className="tw-text-normal tw-m-0 tw-p-0 tw-font-semibold tw-text-black">
                  Project Status ({shared ? 'Published' : 'Draft'})
                </h4>
                <p className="tw-m-0 tw-p-0 tw-text-sm tw-text-neutral-500">
                  Specifies the project as a Draft (hidden from customers) and Published (visible to customers)
                </p>
              </div>
              <Switch value={shared} onClick={() => setShared((prev) => !prev)} data-testid="shared-switch" />
            </div>
            <h4
              className={cn(
                'tw-text-normal tw-m-0 tw-p-0 tw-pt-2 tw-font-semibold tw-text-black',
                showValidationErrors && description.trim() === '' && 'tw-text-flushpink-500',
              )}
            >
              Description*
            </h4>
            <div className="tw-flex tw-items-center tw-gap-2">
              <Textarea
                className="tw-flex-1"
                placeholder="Enter a description for the project"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={showValidationErrors && description.trim() === ''}
              />
            </div>
          </div>
          {!template?.blankTemplate && (
            <>
              <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
                <div className="tw-py-2">
                  <h4 className="tw-text-normal tw-m-0 tw-p-0 tw-font-semibold tw-text-black">Partnered project</h4>
                  <p className="tw-m-0 tw-p-0 tw-text-sm tw-text-neutral-500">
                    Specifies the project as being promoted by one of our partners
                  </p>
                </div>
                <Switch
                  value={isPartnership}
                  onClick={() => setIsPartnership((prev) => !prev)}
                  data-testid="partner-switch"
                />
              </div>
              {isPartnership && (
                <>
                  <ProjectPartnershipForm
                    partner={partner}
                    setPartner={setPartner}
                    onLogoFileChange={(logoFile) => {
                      setNewLogoFile(logoFile)
                      setPartner({ ...partner, logo: URL.createObjectURL(logoFile) })
                    }}
                    showValidationErrors={showValidationErrors}
                  />
                  <div className="tw-my-2 tw-text-neutral-800">Preview</div>
                  <ProjectsPartnerPreview partner={partner} />
                </>
              )}
            </>
          )}
          {template?.blankTemplate ? (
            <div className="tw-flex tw-h-full tw-max-h-96 tw-items-center tw-justify-center tw-bg-neutral-50">
              <p>The Blank Project includes all request types by default and cannot be modified or deleted.</p>
            </div>
          ) : (
            <TemplateRequestsTable
              template={template}
              setSelectedRows={setSelectedTickets}
              selectedRows={selectedTickets as Skill[]}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              isBlankTemplate={false}
              onAddRequestsClick={() => setShowAddRequests(true)}
            />
          )}
        </Drawer.Body>
      )}
      <Drawer.Footer
        className="tw-z-20"
        submitText={getSubmitText()}
        onCancel={() => (showAddRequests ? setShowAddRequests(false) : setOpen(false))}
        onSubmit={() => (showAddRequests ? setShowAddRequests(false) : saveTemplate())}
        submitDisabled={isLoading}
      >
        <h5>
          {selectedTickets.length} request{selectedTickets.length !== 1 && 's'} selected
        </h5>
      </Drawer.Footer>
    </>
  )
}
