import { AppSettings, CurrentUser } from 'interfaces/app'
import { bindJWT, getApi } from 'lib/api/api'
import AllCompanyTagsProvider from 'providers/all-company-tags-provider'
import ConfirmationModalProvider from 'providers/confirmation-modal-provider'
import QueryClientProvider from 'providers/query-client-provider'
import { Search } from './search'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import { Toaster } from 'lib/components/toast/toast'
import UserProvider from 'providers/user-provider'

export interface SearchPageProps {
  settings: AppSettings
  user: CurrentUser
}

export default function SearchPage({ settings, user }: SearchPageProps) {
  return (
    <QueryClientProvider>
      <Toaster />
      <FeatureFlagsProvider>
        <ConfirmationModalProvider>
          <UserProvider settings={settings} user={user}>
            <AllCompanyTagsProvider>
              <Search />
            </AllCompanyTagsProvider>
          </UserProvider>
        </ConfirmationModalProvider>
      </FeatureFlagsProvider>
    </QueryClientProvider>
  )
}

// Testing
export async function getSearch() {
  const getWithJWT = bindJWT(getApi, null)
  const response = await getWithJWT(`/api/internal/search?query=test`)
  return response.data
}
