import { UploadDropzone, UploadDropzoneProps } from '@bytescale/upload-widget-react'
import type { UploadWidgetResult } from '@bytescale/upload-widget'
import * as Bytescale from '@bytescale/sdk'
import Modal from '../modals/modal'
import { useEnvVars } from 'hooks/use-env-vars'
import { cn } from 'lib/util/cn'
import { CircleAlertIcon, ImagePlusIcon, PencilIcon } from 'lucide-react'
import { UploadResult } from '@bytescale/sdk'

const _100MB = 1024 * 1024 * 100
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-cornflower-500')

const defaultOptions: UploadDropzoneProps['options'] = {
  apiKey: '',
  maxFileCount: 50,
  styles: {
    colors: {
      primary: primaryColor ? primaryColor : '#576EE7',
    },
  },
  maxFileSizeBytes: _100MB,
  mimeTypes: [
    'application/illustrator',
    'application/postscript',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-fontobject',
    'application/eps',
    'image/x-eps',
    'application/postscript',
    'application/figma',
    'image/svg+xml',
    'application/x-indesign',
    'application/vnd.apple.keynote',
    'application/mogrt',
    'font/otf',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/prproj',
    'application/vnd.rar',
    'application/x-rar-compressed',
    'application/sketch',
    'font/ttf',
    'font/woff',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/zip',
    'application/octet-stream',
    'application/x-zip-compressed',
    'multipart/x-zip',
    'audio/*',
    'image/*',
    'text/*',
    'video/*',
    '.fig',
    /*
     * Concerning the `.fig` entry
     *
     * The `mimeTypes` option has UNDOCUMENTED support for exact file extensions: eg '.fig', which is helpful for some file types.
     * `.fig` allows users to upload Figma files directly from a file browser, as opposed to only being able to upload them via drag-n-drop.
     * Figma files technically have a mime type of application/zip, which is why they can be uploaded via drag-n-drop even without a '.fig' entry.
     */
  ],
  showFinishButton: true,
  showRemoveButton: true,
  editor: {
    images: {
      crop: false,
      preview: false,
    },
  },
  locale: {
    finishBtn: 'Upload',
  },
}

interface FileUploaderProps {
  options?: Omit<UploadDropzoneProps['options'], 'apiKey'> & {
    path?: {
      folderPath?: string
    }
  }
  onUpdate?: UploadDropzoneProps['onUpdate']
  onComplete?: UploadDropzoneProps['onComplete']
  className?: HTMLDivElement['className']
}

function FileUploader({ options, onUpdate, onComplete, className }: FileUploaderProps) {
  const { bytescalePublicApiKey, bytescaleS3AliasBucket } = useEnvVars()

  const requiredVars = { bytescalePublicApiKey, bytescaleS3AliasBucket }
  Object.entries(requiredVars).forEach(([key, value]) => {
    if (!value) console.warn(`${key} is not set`)
  })

  const apiOptions = {
    apiKey: bytescalePublicApiKey,
    path: {
      folderPath: options?.path?.folderPath
        ? `/${bytescaleS3AliasBucket}${options.path.folderPath}`
        : `/${bytescaleS3AliasBucket}`,
    },
  }

  const combinedOptions = { ...defaultOptions, ...options, ...apiOptions }

  return (
    <UploadDropzone
      options={combinedOptions}
      onUpdate={onUpdate}
      onComplete={onComplete}
      className={cn('tw-w-full', className)}
    />
  )
}

export type { UploadWidgetResult as FileUploaderResult }

export function InlineFileUploader({ options, onUpdate, onComplete, className }: FileUploaderProps) {
  return <FileUploader options={options} onUpdate={onUpdate} onComplete={onComplete} className={className} />
}

interface InlineImageViewUploaderProps {
  id: string
  types?: string[]
  onChange: (file: File) => void
  imageUrl?: string
  error?: boolean
  label?: string
  fileTypeText?: string
}

export function InlineImageViewUploader({
  id,
  types = ['image/*'],
  onChange,
  imageUrl,
  label,
  error = false,
  fileTypeText,
}: InlineImageViewUploaderProps) {
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      onChange(file)
    }
  }

  return (
    <div>
      <label htmlFor={id} className={cn('tw-text-black', error && 'tw-text-flushpink-500')}>
        {label && <div className="tw-mb-2 tw-text-sm">{label}</div>}
        <div
          className={cn(
            'tw-group tw-relative tw-flex tw-h-24 tw-w-24 tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-border tw-border-dashed tw-border-neutral-200 tw-bg-neutral-50 tw-p-2',
            error && 'tw-border-flushpink-500',
          )}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="Uploaded" className="tw-h-full tw-w-full tw-object-cover" />
          ) : (
            <>
              {error ? <CircleAlertIcon /> : <ImagePlusIcon />}
              <div className="tw-text-sm">Add image</div>
            </>
          )}
          <div className="tw-invisible tw-absolute tw-right-0 tw-top-0 tw-z-10 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-transparent group-hover:tw-visible">
            <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white">
              <PencilIcon className="tw-h-4 tw-w-4 tw-text-black" />
            </div>
          </div>
          <div className="tw-invisible tw-absolute tw-right-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-black tw-p-1 tw-opacity-60 tw-transition-all group-hover:tw-visible group-hover:tw-bg-black"></div>
        </div>
        {fileTypeText && (
          <div className={cn('tw-mt-1 tw-text-xs', error ? 'tw-text-flushpink-500' : 'tw-text-neutral-500')}>
            {fileTypeText}
          </div>
        )}
      </label>
      <input type="file" accept={types.join(',')} id={id} className="tw-hidden" onChange={handleImageChange} />
    </div>
  )
}

export function ModalFileUploader({
  options,
  onUpdate,
  onComplete,
  isOpen,
  setIsOpen,
}: FileUploaderProps & { isOpen?: boolean; setIsOpen?: (open: boolean) => void }) {
  return (
    <Modal
      open={isOpen ?? true}
      setOpen={setIsOpen ?? (() => {})}
      size="md"
      className="w-full tw-flex tw-items-center tw-justify-stretch [&>div]:tw-w-full"
    >
      <FileUploader options={options} onUpdate={onUpdate} onComplete={onComplete} />
    </Modal>
  )
}

export function useBytescaleUploader() {
  const { bytescalePublicApiKey, bytescaleS3AliasBucket } = useEnvVars()

  if (!bytescalePublicApiKey) {
    console.error('bytescalePublicApiKey is not set')
    return null
  }

  const uploadManager = new Bytescale.UploadManager({
    apiKey: bytescalePublicApiKey,
  })

  return {
    ...uploadManager,
    upload: async (
      options: Parameters<typeof uploadManager.upload>[0],
    ): Promise<UploadResult & { uploadedFileName: string }> => {
      const result = await uploadManager.upload({
        ...{
          maxConcurrentUploads: 3,
          path: {
            folderPath: `/${bytescaleS3AliasBucket}`,
          },
        },
        ...options,
      })
      return {
        ...result,
        uploadedFileName: result.filePath.split('/').pop(),
      }
    },
  }
}
