import { createContext, ReactElement, ReactNode, useContext } from 'react'
import { AppSettings } from 'interfaces/app'

export interface AdminUser {
  canSelfQR: boolean
  email: string
  fullName: string
  id: number
  isCreative: boolean
  isDPAdmin: boolean
  isDPManager: boolean
  isDPMember: boolean
  isDPSuperAdmin: boolean
  productTicketExperience: boolean
  designerId: number
}

interface AdminUserContextProps {
  children: ReactNode
  settings: AppSettings
  user: AdminUser
}

interface AdminUserContextValue {
  user: AdminUser
  settings: AppSettings
}

export const AdminUserContext = createContext({})

export default function AdminUserProvider({ children, settings, user }: AdminUserContextProps): ReactElement {
  const context: AdminUserContextValue = {
    settings,
    user,
  }

  return <AdminUserContext.Provider value={context}>{children}</AdminUserContext.Provider>
}

export function useAdminUserContext(): AdminUserContextValue {
  return useContext(AdminUserContext) as AdminUserContextValue
}
