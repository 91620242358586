import { useInfiniteQuery } from '@tanstack/react-query'
import placeholderImage from 'images/requests/request-type-placeholder.png'
import { request } from 'lib/api/fetch-api'
import { toast } from 'lib/components/toast/toast'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { ProjectTemplate } from 'interfaces/project-template'
import ProjectCard from './project-card'
import ProjectTemplatesSkeleton from './project-templates-skeleton'

interface ProjectTemplatesProps {
  partner?: boolean
  selectProjectTemplate: (projectTemplate: ProjectTemplate) => void
}

export default function ProjectTemplates({ partner = false, selectProjectTemplate }: ProjectTemplatesProps) {
  const { ref, inView } = useInView()

  const {
    data: projectTemplates,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['projectTemplates', partner],
    queryFn: ({ pageParam = 1 }) =>
      request({ endpoint: 'getAllCustomProjectTemplates', query: { page: pageParam, partner: partner } }),
    getNextPageParam: (lastPage, pages) => (lastPage.meta?.nextPage ? pages.length + 1 : undefined),
    retry: 3,
  })

  useEffect(() => {
    if (error) {
      toast.error('Failed to fetch project templates')
      console.error('Failed to fetch project templates', error)
    }
  }, [error])

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetchingNextPage])

  return (
    <>
      {projectTemplates?.pages.length > 0 || !isLoading ? (
        <>
          <div className="tw-grid tw-max-w-[1440px] tw-grid-cols-5 tw-gap-4">
            {projectTemplates?.pages
              .flatMap((page) => page.data)
              .map((projectTemplate) => (
                <ProjectCard
                  key={projectTemplate.id}
                  imgSrc={projectTemplate.imageUrl || placeholderImage}
                  title={projectTemplate.name}
                  subtitle={projectTemplate.description}
                  color={projectTemplate?.partner && '#FFFFFF'}
                  onClick={() => selectProjectTemplate(projectTemplate)}
                />
              ))}
            <div ref={ref} className="tw-h-4" />
            {isFetchingNextPage && <div className="tw-py-4 tw-text-center">Loading more...</div>}
          </div>
        </>
      ) : (
        <ProjectTemplatesSkeleton count={5} />
      )}
    </>
  )
}
