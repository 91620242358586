import Pagination from 'components/elements/pagination'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import { SkillSize } from 'interfaces/skill-size'
import { getSkillSizes, skillSizeRoutes } from 'lib/api/admin/skill-sizes/skill-sizes'
import { snakeCaseKeys } from 'lib/object/utils'
import { useCallback, useEffect, useState } from 'react'
import AdminSkillSizesPageFilters from './admin-skill-sizes-page-filters'
import { Skill } from 'interfaces/skill'
import { routes } from 'lib/api/admin/skills/skills'
import { LinkButton } from 'components/core/button'
import { Toaster, toast } from 'lib/components/toast/toast'

const classNames = {
  container: 'tw-container tw-px-3',
  header: 'tw-flex tw-justify-between',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white tw-rounded-lg tw-shadow-md',
  wrapper: 'tw-flex tw-w-full tw-flex-col',
}

export interface Filters {
  nameCont?: string
}

export const emptyFilters: Filters = {
  nameCont: '',
}

function AdminSkillSizes({ skill }: { skill: Skill }) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [count, setCount] = useState<number>(0)
  const [filters, setFilters] = useState<Filters>(emptyFilters)
  const [skillSizes, setSkillSizes] = useState<SkillSize[]>([])

  useEffect(() => {
    fetchSkillSizes(currentPage, filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const fetchSkillSizes = useCallback(
    async (currentPage: number, filters: Filters) => {
      setIsLoading(true)
      try {
        const { skillSizes, meta } = await getSkillSizes(skill.id, { page: currentPage + 1, q: snakeCaseKeys(filters) })
        setSkillSizes(skillSizes)
        setPageCount(Math.ceil(meta.total / meta.pageSize))
        setCount(meta.total)
      } catch (error) {
        console.error(error)
        toast.error('Failed to fetch skill sizes')
      } finally {
        setIsLoading(false)
      }
    },
    [skill.id],
  )

  function onPageClick({ selected }) {
    setCurrentPage(selected)
    window.scrollTo(0, 0)
  }

  function handleSubmit(e) {
    e.preventDefault()
    fetchSkillSizes(currentPage, filters)
  }

  function handleClear(e) {
    e.preventDefault()
    setFilters(emptyFilters)
    fetchSkillSizes(0, emptyFilters)
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <h4>Skill Sizes for {skill.name}</h4>
        {!isLoading && `Showing ${skillSizes.length} out of ${count} skills`}
      </div>
      <div className={classNames.container}>
        <a href={routes.index} className="tw-mb-4 tw-block tw-text-sm tw-text-gherkin-500">
          Back to Skills
        </a>
        <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
          <AdminSkillSizesPageFilters
            filters={filters}
            setFilters={setFilters}
            onSubmit={handleSubmit}
            handleClear={handleClear}
          />
          <LinkButton url={skillSizeRoutes.new(skill.id)} className="tw-mt-4">
            Create Skill Size for {skill.name}
          </LinkButton>
        </div>
        <div className={classNames.tableWrapper}>
          <table className={classNames.table}>
            <TableHeader />
            <tbody>
              {isLoading ? (
                <LoadingScreenTableRow />
              ) : (
                skillSizes.map(({ id, imageUrl, details, height, name, skill, units, width }) => (
                  <tr key={id} className={classNames.tableRow}>
                    <td className={classNames.tableCell}>
                      <img src={imageUrl} height="50px" />
                    </td>
                    <td className={classNames.tableCell}>
                      <a href={skillSizeRoutes.edit(id, skill.id)}>{name}</a>
                    </td>
                    <td className={classNames.tableCell}>{details}</td>
                    <td className={classNames.tableCell}>{width}</td>
                    <td className={classNames.tableCell}>{height}</td>
                    <td className={classNames.tableCell}>{units}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination pageCount={pageCount} onPageChange={onPageClick} forcePage={currentPage} />
      </div>
    </div>
  )
}

const TableHeader = () => (
  <thead>
    <tr>
      <th className={classNames.tableHeadCell}></th>
      <th className={classNames.tableHeadCell}>Name</th>
      <th className={classNames.tableHeadCell}>Details</th>
      <th className={classNames.tableHeadCell}>Width</th>
      <th className={classNames.tableHeadCell}>Height</th>
      <th className={classNames.tableHeadCell}>Units</th>
    </tr>
  </thead>
)

export default function AdminSkillSizesPage({ skill }: { skill: Skill }) {
  return (
    <>
      <Toaster />
      <AdminSkillSizes skill={skill} />
    </>
  )
}
