import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { LoaderCircleIcon, Trash2Icon } from 'lucide-react'
import ConfirmationModal from '../confirmation-modal'
import { Project } from 'interfaces/project'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { toast } from 'sonner'

const DeleteProjectFlyoutButton = ({ project, onSuccess }: { project: Project; onSuccess?: () => void }) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const queryClient = useQueryClient()

  const deleteProjectMutation = useMutation({
    mutationFn: () => {
      setLoading(true)
      return request({
        endpoint: 'deleteProject',
        query: { id: project.id },
      })
    },
    onSuccess: () => {
      toast.success('Project deleted successfully')
      queryClient.invalidateQueries({ queryKey: ['projects'] })
      setVisible(false)
      onSuccess?.()
    },
    onError: (error) => {
      toast.error('Error deleting project')
      console.error(error)
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  const modalContent = (
    <>
      <p>Are you sure you want to delete the project &quot;{project.name}&quot;</p>
      <p>Deleting this project will remove the deliverables from it, and they will no longer be part of any project.</p>
    </>
  )

  return (
    <>
      <IconFlyoutMenu.Button onClick={() => setVisible(true)}>
        <Trash2Icon className="tw-h-4 tw-w-4 tw-text-flushpink-500" />
        <span className="tw-ml-2 tw-text-flushpink-500">Delete</span>
      </IconFlyoutMenu.Button>
      <ConfirmationModal
        visible={visible}
        confirmBtnText={loading ? <LoaderCircleIcon className="lu-md tw-animate-spin" /> : 'Yes, Delete'}
        title="Delete Project?"
        message={modalContent}
        cancelAction={() => setVisible(false)}
        confirmAction={deleteProjectMutation.mutate}
      />
    </>
  )
}

export default DeleteProjectFlyoutButton
