import ActionLinkItem from 'components/elements/dropdown/items/action-link-item'
import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'
import { AbilityResponse, fetchAbility } from 'lib/api/ability/ability'
import { isStatusCompleted, isStatusOnDeck, showDownloadAction } from 'lib/ticket/utils'
import { Loader2 } from 'lucide-react'
import { useUserContext } from 'providers/user-provider'
import { useEffect, useState } from 'react'
import WatchTicketItem from '../items/watch-ticket-item'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { duplicateTicket } from 'lib/api/tickets/tickets'
import { toast } from 'sonner'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import { DownloadFilesButton } from 'lib/components/download-assets-button/download-assets-button'
import { DownloadCurrentVersionResponse, fetchDownloadableFiles } from 'lib/api/tickets/tickets'

interface ActiveTabMenuProps {
  id: bigint | number
  subject: string
  currentVersion: number
  filesDownloadUrl: string
  status: string
  userId: number
  menuActionBtn: (route: string, data: Record<string, unknown>, method: string) => void
  ticketFilesCount: bigint | number
  hideMoveToTop?: boolean
}

export default function ActiveTabMenu({
  id,
  subject,
  currentVersion,
  status,
  userId,
  menuActionBtn,
  ticketFilesCount,
  hideMoveToTop = false,
}: ActiveTabMenuProps): JSX.Element {
  const { user: loggedInUser } = useUserContext()
  const { fetchTickets, setPriorityMode, onDragEnd } = useSingleQueueContext()
  const [ticketAbility, setTicketAbility] = useState<AbilityResponse>(null)
  const [downloadableFiles, setDownloadableFiles] = useState<DownloadCurrentVersionResponse['data'] | null>(null)
  const markArchivedTicketUrl = window.Routes.apiInternalMarkArchivedUrl()
  const editTicketUrl = window.Routes.ticket(id, { edit_request: true })
  const cancelTicketUrl = window.Routes.cancelApiInternalTicket(id)
  const cancelButtonData = { id: id, user: userId }
  const markArchivedButtonData = {
    ticket_ids: [id],
    user_id: userId,
    update_action: 'mark_archived',
  }

  const cancelModalData = {
    title: 'Cancel Request?',
    message: `Are you sure you want to cancel ${subject}?`,
    confirmBtnText: 'Yes, Cancel',
    confirmAction: () => {
      menuActionBtn(cancelTicketUrl, cancelButtonData, 'PATCH')
    },
  }

  const onDuplicateClick = async () => {
    try {
      const newTicket = await duplicateTicket(id.toString())
      window.location.assign('/tickets/' + newTicket.id)
    } catch (error) {
      console.error('Error duplicating ticket:', error)
      toast.error('Error duplicating ticket')
    }
  }

  const moveToTop = async (id) => {
    try {
      const { tickets } = await fetchTickets({ priority_mode: true })
      const ticketIndex = tickets.findIndex((ticket) => ticket.id === id)
      const result = {
        destination: { droppableId: 'on_deck', index: 0 },
        source: { droppableId: 'on_deck', index: ticketIndex },
        draggableId: `ticket-${id}`,
      }
      setPriorityMode(true)
      onDragEnd(result, tickets)
      window.scrollTo(0, 0)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchTicketAbility = async () => {
      const ability = await fetchAbility('Ticket', Number(id))
      setTicketAbility(ability)
    }
    const fetchTicketDownloadableFiles = async () => {
      const files = await fetchDownloadableFiles(Number(id))
      setDownloadableFiles(files)
    }
    fetchTicketAbility()
    fetchTicketDownloadableFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadDate = new Date().toLocaleDateString().replaceAll('/', '')
  const version = currentVersion === 0 ? '' : `_V${currentVersion}`
  const downloadZipName = `${id}_${subject}_${downloadDate}${version}`

  if (ticketAbility == null || downloadableFiles == null) {
    return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-py-4 tw-opacity-50">
        <Loader2 className="tw-animate-spin" />
      </div>
    )
  }

  return (
    <>
      {!hideMoveToTop && isStatusOnDeck(status) && ticketAbility?.can('moveToTop') && (
        <ActionLinkItem
          label="Move to Top"
          iconClass="far fa-arrow-to-top"
          onClick={() => moveToTop(id)}
          menuActionBtn={menuActionBtn}
        />
      )}
      {isStatusOnDeck(status) && ticketAbility?.can('edit') && (
        <ExternalActionLinkItem label="Edit" iconClass="far fa-pen" route={editTicketUrl} />
      )}
      {showDownloadAction(status, ticketFilesCount) && downloadableFiles?.length > 0 && (
        <DownloadFilesButton
          files={downloadableFiles}
          buttonColor="transparent"
          buttonText="Download Assets"
          className="tw-flex tw-w-full tw-items-center tw-gap-0.5 tw-rounded-none tw-border-0 !tw-px-4 !tw-py-2 tw-text-sm tw-text-peppercorn-700 hover:tw-bg-peppercorn-100 hover:tw-text-peppercorn-900"
          zipName={downloadZipName}
        />
      )}
      {isStatusCompleted(status) && ticketAbility?.can('markArchived') && (
        <ActionLinkItem
          label="Archive"
          iconClass="far fa-check"
          route={markArchivedTicketUrl}
          method="PATCH"
          data={markArchivedButtonData}
          menuActionBtn={menuActionBtn}
        />
      )}
      {ticketAbility?.can('duplicate') && (
        <IconFlyoutMenu.Button onClick={onDuplicateClick}>
          <i className="far fa-copy"></i>
          &nbsp; Duplicate
        </IconFlyoutMenu.Button>
      )}
      {!isStatusCompleted(status) && ticketAbility?.can('cancel') && (
        <ActionLinkItem
          label="Cancel"
          iconClass="fas fa-ban"
          data={cancelButtonData}
          menuActionBtn={menuActionBtn}
          modalData={cancelModalData}
        />
      )}
      {loggedInUser.id !== userId && (
        <WatchTicketItem
          ticketId={String(id)}
          className="tw-px-4 tw-py-2 tw-text-sm tw-text-peppercorn-700 hover:tw-bg-peppercorn-100 hover:tw-text-peppercorn-900"
        />
      )}
    </>
  )
}
