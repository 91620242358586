import { downloadFile } from 'lib/util/file/file'
import { useEffect, useState } from 'react'

interface QueuedDownload {
  name: string
  url: string
}

export function useDownloadQueue(delay = 500) {
  const [queue, setQueue] = useState<QueuedDownload[]>([])
  const [isProcessing, setIsProcessing] = useState(false)

  const addToQueue = (name: string, url: string) => {
    setQueue((prev) => [...prev, { name, url }])
  }

  useEffect(() => {
    const processQueue = () => {
      if (isProcessing) return
      if (queue.length > 0) {
        setIsProcessing(true)
        setTimeout(() => {
          downloadFile(queue[0].name, queue[0].url)
          setQueue((prev) => prev.slice(1))
          setIsProcessing(false)
        }, delay)
      }
    }

    if (queue.length > 0) {
      processQueue()
    }
  }, [queue, delay, isProcessing])

  return { addToQueue }
}
