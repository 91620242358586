import RequestTypePopover from './request-type-popover'
import RequestSizePopover from './request-size-popover'
import RequestFormatsPopover from './request-formats-popover'
import BrandsPopover from 'components/elements/brands-popover'
import { useEffect, useState } from 'react'
import { CustomSize, Skill, SkillCategorySkill, SkillSize } from 'lib/api/skills/skills'
import { Brand } from 'interfaces/brand'
import { useRequestContext } from '../providers/request-provider'
import { useUserContext } from 'providers/user-provider'
import CompanyTaggingPopover from 'components/elements/company-tagging/company-tagging-popover'
import CompanyTaggingProvider from 'providers/company-tagging-provider'
import useSelectedTicketTags from 'components/pages/request/request-header/use-selected-ticket-tags'

function Line() {
  return <div className="tw-relative tw--top-2 tw-h-5 tw-bg-neutral-700" style={{ width: '1px' }} />
}

export default function HeaderDropdowns() {
  const [skill, setSkill] = useState<Skill>(null)
  const { user } = useUserContext()
  const { dispatch, skillSizes, ticket, invalidFields, showValidationErrors, fetchAndSetTicket } = useRequestContext()
  const { selectedTags } = useSelectedTicketTags(ticket)

  function onChangeSkill(skill: SkillCategorySkill) {
    dispatch('skill', { skill })
  }

  function onChangeSkillFormats(formats: string[]) {
    dispatch('formats', { formats })
  }

  function onChangeSizes(sizes: { skillSizes?: SkillSize[]; customSizes?: CustomSize[] }) {
    dispatch('sizes', sizes)
  }

  function onChangeSkillBrands(brand: Brand) {
    dispatch('brand', { brand })
  }

  useEffect(() => {
    if (ticket?.skill?.id !== skill?.id) {
      setSkill(ticket.skill)
    }
  }, [ticket?.skill, skill])

  return (
    <div className="tw-flex tw-items-center">
      <RequestTypePopover
        skill={skill}
        onChange={onChangeSkill}
        showValidationError={invalidFields?.includes('skill') && showValidationErrors}
        disabled={ticket?.status?.toLowerCase() !== 'draft'}
      />
      <Line />
      <RequestSizePopover
        skillSizes={skillSizes}
        onChange={onChangeSizes}
        ticketSkillSizes={ticket?.selectedSkillSizes}
        ticketCustomSizes={ticket?.size || ''}
        className="tw-ml-4"
        showValidationError={invalidFields?.includes('sizes') && showValidationErrors}
      />
      <Line />
      <div className="tw-ml-2">
        <RequestFormatsPopover
          availableFormats={ticket?.availableFormats || []}
          onChange={onChangeSkillFormats}
          ticketSelectedFormats={ticket?.selectedFormats}
          showValidationError={invalidFields?.includes('formats') && showValidationErrors}
        />
      </div>
      <Line />
      <div className="tw-ml-2">
        <BrandsPopover onChange={onChangeSkillBrands} brand={ticket?.selectedBrand} companyId={user.companyId} />
      </div>
      <>
        <Line />
        <div className="tw-ml-4">
          <CompanyTaggingProvider
            taggableId={ticket.id}
            taggableType="Ticket"
            updateTaggableState={fetchAndSetTicket}
            selectedTags={selectedTags}
          >
            <CompanyTaggingPopover />
          </CompanyTaggingProvider>
        </div>
      </>
    </div>
  )
}
