import { HTMLAttributes, ReactElement } from 'react'
import SelectBox from 'lib/components/dropdown/select-box'
import { asTimeDuration } from 'lib/util/date'

interface AdminTimeSelectorProps {
  selectedTime: number
  setSelectedTime: (selectedTime: number) => void
  label?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
}

export default function AdminTimeSelector({
  selectedTime,
  setSelectedTime,
  label,
  className,
}: AdminTimeSelectorProps): ReactElement {
  const options = generateOptions()

  return (
    <>
      {label && <label htmlFor="ticket_need_more_time_select">{label}</label>}
      <SelectBox
        options={options}
        handleChange={(selectedValue: number) => {
          setSelectedTime(selectedValue)
        }}
        selectedValue={selectedTime}
        className={className}
      />
    </>
  )
}

function generateOptions() {
  const options = [
    {
      displayElement: <div>Not Assigned to Today</div>,
      value: null,
    },
  ]

  for (let value = 0; value <= 7; value += 0.25) {
    const minutes = value * 60
    options.push({ displayElement: <>{asTimeDuration(minutes)}</>, value })
  }

  return options
}
