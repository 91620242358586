import { columnIds, useTable } from './use-table'
import Breadcrumb from 'lib/components/breadcrumb/breadcrumb'
import { AnimatedLoadingScreen, EmptySearchScreen } from '../requests/empty-screens'
import Filters from './filters'
import Button from 'components/core/button'
import { LucideRefreshCcw } from 'lucide-react'
import { useFirstRender } from './use-first-render'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import SavedSearches from './saved-searches'
import SearchRequestsTable from './search-requests-table'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'lib/components/tabs'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'

export const getResultsTitle = (search: string, count: number) => {
  if (search) {
    return `Results for "${search.slice(0, 50)}${search.length > 50 ? '...' : ''}" (${count})`
  }
  return `Results (${count})`
}

export const Search = () => {
  const { query, count, loading, error, table } = useTable()
  const { isModalVisible, modalData, setModalVisible } = useConfirmationModalContext()
  const { loading: loadingFirstRender } = useFirstRender()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  if (loading || loadingFirstRender) {
    return (
      <div className="tw-flex tw-h-[60vh] tw-items-center tw-justify-center">
        <AnimatedLoadingScreen
          variant="spinner"
          text="We're searching your content..."
          subText="Hang tight while we locate what you're looking for."
        />
      </div>
    )
  }

  const emptyScreen = query.data?.data?.length === 0 && <EmptySearchScreen />
  const errorScreen = error && (
    <>
      <AnimatedLoadingScreen
        variant="errorHole"
        text="Uh oh! Looks like your search didn't load..."
        subText="You can retry or head back to your queue."
      />
      <Button
        onClick={() => {
          window.location.reload()
        }}
        color="purple"
        className="tw-mt-4"
      >
        <LucideRefreshCcw className="tw-mr-1" /> Retry
      </Button>
    </>
  )

  return (
    <>
      <ConfirmationModal
        title={modalData['title']}
        message={modalData['message']}
        confirmBtnText={modalData['confirmBtnText']}
        confirmAction={modalData['confirmAction']}
        cancelAction={() => setModalVisible(false)}
        visible={isModalVisible}
      />
      <Breadcrumb className="tw-mt-3" text="Back to requests" href={`/requests`} />
      <Tabs defaultValue="all">
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <h1 className="tw-text-2xl">
              {getResultsTitle(
                Array.isArray(table.getColumn(columnIds.query).getFilterValue())
                  ? table.getColumn(columnIds.query).getFilterValue()[0]
                  : table.getColumn(columnIds.query).getFilterValue(),
                count,
              )}
            </h1>
            <SavedSearches setFilters={table.setColumnFilters} />
          </div>
          <div>
            {isFeatureFlagEnabled('search_v2') && (
              <TabsList>
                <TabsTrigger value="all">All</TabsTrigger>
                <TabsTrigger value="projects">Projects</TabsTrigger>
                <TabsTrigger value="requests">Requests</TabsTrigger>
              </TabsList>
            )}
          </div>
          <div className="tw-flex tw-items-center tw-gap-4">
            <Filters table={table} />
          </div>
        </div>
        {errorScreen || emptyScreen ? (
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center"> {errorScreen || emptyScreen}</div>
        ) : (
          <>
            <TabsContent value="all">
              <SearchRequestsTable />
            </TabsContent>
            <TabsContent value="projects">Projects</TabsContent>
            <TabsContent value="requests">
              <SearchRequestsTable />
            </TabsContent>
          </>
        )}
      </Tabs>
    </>
  )
}
