export enum FileExportStatus {
  waiting = 'waiting',
  processing = 'processing',
  finished = 'finished',
  error = 'error',
}

export interface FileExport {
  id: number
  createdAt: string
  outputFormat: string
  status: FileExportStatus
  temporaryLinks: string[]
  ticketFileId: number
  updatedAt: string
  fileName: string
  display: boolean
}
