import { Partner } from 'interfaces/partner'
import { InlineImageViewUploader } from 'lib/components/file-uploader/file-uploader'
import TextInput from 'lib/components/text-input/text-input'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'

interface ProjectPartnershipFormProps {
  partner: Partner
  setPartner: (partner: Partner) => void
  showValidationErrors: boolean
  onLogoFileChange: (file: File) => void
}

export default function ProjectPartnershipForm({
  partner,
  setPartner,
  showValidationErrors,
  onLogoFileChange,
}: ProjectPartnershipFormProps) {
  return (
    <div>
      <div className="tw-auto-rows tw-grid tw-grid-cols-2 tw-gap-4 tw-py-2">
        <div>
          <TextInput
            id="partner-name"
            label="Partner name*"
            required
            value={partner.name}
            onChange={(e) => setPartner({ ...partner, name: e.target.value })}
            error={showValidationErrors && partner.name === ''}
          />
        </div>
        <div>
          <TextInput
            id="partner-label"
            label="Label*"
            required
            disabled
            value={partner.label}
            onChange={(e) => setPartner({ ...partner, label: e.target.value })}
            error={showValidationErrors && partner.label === ''}
          />
        </div>
        <div>
          <Textarea
            id="partner-header"
            label="Header*"
            required
            resize="both"
            maxLength={60}
            value={partner.header}
            onChange={(e) => setPartner({ ...partner, header: e.target.value })}
            error={showValidationErrors && partner.header === ''}
          />
          <TextCounter value={partner.header} maxLength={60} />
        </div>
        <div>
          <Textarea
            id="partner-body"
            label="Body*"
            required
            resize="both"
            maxLength={120}
            value={partner.body}
            onChange={(e) => setPartner({ ...partner, body: e.target.value })}
            error={showValidationErrors && partner.body === ''}
          />
          <TextCounter value={partner.body} maxLength={120} />
        </div>
        <div>
          <Textarea
            id="partner-link-text"
            label="Link text*"
            required
            resize="both"
            maxLength={60}
            value={partner.linkText}
            onChange={(e) => setPartner({ ...partner, linkText: e.target.value })}
            error={showValidationErrors && partner.linkText === ''}
          />
          <TextCounter value={partner.linkText} maxLength={60} />
        </div>
        <div>
          <TextInput
            id="partner-link"
            label="Affiliate link*"
            required
            value={partner.link}
            onChange={(e) => setPartner({ ...partner, link: e.target.value })}
            error={showValidationErrors && partner.link === ''}
          />
          <TextInput
            id="partner-blog-post-link"
            label="Blog post link*"
            value={partner.blogPostLink}
            onChange={(e) => setPartner({ ...partner, blogPostLink: e.target.value })}
            error={showValidationErrors && partner.blogPostLink === ''}
          />
        </div>
        <div className="tw-flex tw-gap-24">
          <InlineImageViewUploader
            id="logo-upload"
            label="Logo*"
            types={['image/svg+xml', 'image/png', 'image/jpeg']}
            onChange={onLogoFileChange}
            imageUrl={partner?.logo}
            error={showValidationErrors && !partner?.logo}
            fileTypeText=".svg, .png, .jpg, .jpeg"
          />
        </div>
      </div>
    </div>
  )
}
