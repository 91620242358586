import { useCollaboratorTicketContext } from './collaborator-ticket-provider'
import { useMediaContext } from '../request/media/media-provider'
import { useMemo } from 'react'
import { generateRangeTo } from 'lib/util/range'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import MediaList from '../request/media/media-list'
import MediaUploadButton from '../request/media/media-upload-button'
import { DesignRequestStatus } from 'lib/api/tickets/tickets'
import { FileImageIcon } from 'lucide-react'
import IconButton from 'lib/components/buttons/icon-button'
import { useThreeResizableContext } from 'lib/components/resizable/resizable'

const classNames = {
  aside:
    'tw-flex-none tw-w-60 tw-relative tw-bg-gray-50 tw-flex tw-flex-col tw-border-r-gray-200 tw-border-0 tw-border-r tw-border-solid',
  header: 'tw-flex-none tw-h-min tw-m-4 tw-mb-0',
  h2: 'tw-text-base tw-mb-1',
  hr: 'tw-border-0 tw-border-t-1 tw-border-neutral-400 tw-mt-4 tw-mb-2',
  container: 'tw-flex-auto tw-overflow-y-scroll',
  footer: 'tw-flex-none tw-h-min tw-p-5 tw-bg-gray-50',
}

export default function LeftSidebar() {
  const { ticket } = useCollaboratorTicketContext()
  const { files, selectedVersion, setSelectedVersion } = useMediaContext()

  const { toggleLeftCollapsed, leftCollapsed } = useThreeResizableContext()
  const canUploadMedia = ticket.status === DesignRequestStatus.draft || ticket.status === DesignRequestStatus.review

  const versions = useMemo(() => {
    return generateRangeTo(ticket?.lastDeliveredVersion)
  }, [ticket?.lastDeliveredVersion])

  function handleVersionChange(version: number) {
    setSelectedVersion(version)
  }

  return (
    <aside className="tw-relative tw-flex tw-h-full tw-flex-row tw-border-0 tw-bg-white">
      <div className="tw-sticky tw-left-0 tw-top-0 tw-z-10 tw-flex tw-h-full tw-flex-col tw-gap-2 tw-border-0 tw-border-r tw-border-solid tw-border-gray-200 tw-p-3">
        <IconButton
          color={leftCollapsed ? 'secondary' : 'primary'}
          invert={!leftCollapsed}
          selected={!leftCollapsed}
          onClick={toggleLeftCollapsed}
        >
          <FileImageIcon className="lu-light lu-md" />
        </IconButton>
      </div>
      <div className="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-bg-white">
        {!!files?.length && (
          <>
            <header className={classNames.header}>
              <h2 className={classNames.h2}>Delivered Assets</h2>
              <TicketVersionSelectBox
                selectedVersion={selectedVersion}
                setSelectedVersion={handleVersionChange}
                versions={versions}
              />
              <hr className={classNames.hr} />
            </header>
            <div className="tw-overflow-y-auto">
              <MediaList />
            </div>
          </>
        )}
        {canUploadMedia && (
          <div className="tw-h-min tw-flex-none tw-bg-gray-50 tw-p-5" data-testid="media-upload-buttons">
            <p className="tw-mb-2 tw-mt-0 tw-text-sm tw-font-semibold tw-text-neutral-800">
              Need to add additional media?
            </p>
            <MediaUploadButton />
          </div>
        )}
      </div>
    </aside>
  )
}
