import Button, { LinkLikeButton } from 'components/core/button'
import { Project } from 'interfaces/project'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import DeleteProjectFlyoutButton from './delete-project-flyout-button'
import { FolderOpenIcon } from 'lucide-react'
import ProjectDrawer from './project-drawer'
import { useEffect, useState } from 'react'
import { updateParams } from 'lib/util/url'

export interface ProjectListProps {
  projects: Project[]
  onUpdate?: () => void
}

export default function ProjectList({ projects, onUpdate }: ProjectListProps) {
  const [isProjectDrawerOpen, setIsProjectDrawerOpen] = useState(false)
  const projectId = parseInt(new URLSearchParams(window.location.search).get('projectId')) || null

  const handleProjectClick = (project: Project) => {
    const params = new URLSearchParams(window.location.search)
    params.set('projectId', project.id.toString())
    updateParams(params)
    setIsProjectDrawerOpen(true)
  }

  function handleSetIsProjectDrawerOpen(open: boolean) {
    const params = new URLSearchParams(window.location.search)
    if (!open) {
      params.delete('projectId')
      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`)
      setIsProjectDrawerOpen(false)
    }

    setIsProjectDrawerOpen(open)
  }

  useEffect(() => {
    const urlState = new URLSearchParams(window.location.search)
    setIsProjectDrawerOpen(urlState.get('projectId') !== null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])

  return (
    <>
      <ProjectDrawer
        isOpen={isProjectDrawerOpen}
        setOpen={handleSetIsProjectDrawerOpen}
        projectId={projectId}
        onUpdate={onUpdate}
      />
      <div className="tw-flex tw-flex-wrap tw-gap-2 tw-pb-2">
        {projects.length === 0 && (
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-2 tw-text-center">
            <div className="tw-rounded-md tw-bg-neutral-50 tw-p-2">
              <FolderOpenIcon className="lu-xl" />
            </div>
            <h4 className="tw-m-0 tw-text-neutral-500">You don&apos;t have any projects yet</h4>
            <p className="tw-m-0 tw-max-w-prose tw-text-neutral-500">
              Organize and bring your creative vision to life. Start with expert-crafted design recipes, explore
              ready-to-go templates, or customize your own project to fit your needs.
            </p>
            <Button color="neutralGray" onClick={() => window.location.assign('/create?tab=projects')}>
              Get Started with Projects
            </Button>
          </div>
        )}
        {projects?.map((project) => (
          <div
            key={project.id}
            className="tw-h-13 tw-flex tw-w-80 tw-items-center tw-justify-between tw-rounded-md tw-bg-white tw-py-4 tw-pl-4 tw-pr-2 tw-shadow-sm"
          >
            <div className="tw-flex tw-w-10/12 tw-items-center tw-gap-2 tw-overflow-hidden">
              <div className="tw-overflow-hidden">
                <LinkLikeButton
                  className="tw-m-0 tw-w-full tw-p-0 tw-text-start tw-text-sm tw-font-semibold"
                  onClick={() => handleProjectClick(project)}
                >
                  <div title={project.name} className="tw-truncate">
                    {project.name}
                  </div>
                </LinkLikeButton>
                <p className="tw-m-0 tw-w-full tw-truncate tw-text-xs tw-text-neutral-500">{project.description}</p>
              </div>
            </div>
            <div className="tw-flex tw-w-20 tw-items-center tw-gap-1">
              {project.ticketsRequiringAttentionCount > 0 && (
                <div className="tw-flex tw-aspect-square tw-min-h-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-flushpink-500 tw-text-white">
                  {project.ticketsRequiringAttentionCount}
                </div>
              )}
              <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']}>
                {/* <IconFlyoutMenu.Button onClick={() => null}>
                  <PlusIcon className="tw-h-4 tw-w-4" />
                  <span className="tw-ml-2">Add requests</span>
                </IconFlyoutMenu.Button> */}
                {/* <IconFlyoutMenu.Button onClick={() => null}>
                  <CopyIcon className="tw-h-4 tw-w-4" />
                  <span className="tw-ml-2">Duplicate</span>
                </IconFlyoutMenu.Button> */}
                <DeleteProjectFlyoutButton project={project} />
              </IconFlyoutMenu>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
