import { ReactElement, useEffect, useRef, useState } from 'react'
import { PenLine } from 'lucide-react'

import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import IconButton from 'lib/components/buttons/icon-button'

import { useAnnotationsContext } from '../providers/annotations-provider'

interface VideoJsPlayerProps {
  ticketFile: TicketFile
}

enum Tool {
  Annotate = 'annotate',
}

export default function VideoJsPlayer({ ticketFile }: VideoJsPlayerProps): ReactElement {
  const [selectedTool, setSelectedTool] = useState<Tool>(null)
  const videoRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef(null)
  const { canEditAnnotation, createVideoPlayer, destroyVideoPlayer, setShowVideoAnnotationCreator } =
    useAnnotationsContext()
  function switchTool(previous: Tool, next: Tool) {
    if (previous !== next) {
      if (next === Tool.Annotate) {
        playerRef.current.annotate()
      }
      if (previous === Tool.Annotate) {
        playerRef.current.cancel()
      }
      setSelectedTool(next)
    } else {
      playerRef.current.cancel()
      setSelectedTool(null)
    }
  }

  function cancelCallback() {
    setSelectedTool(null)
  }

  useEffect(() => {
    const annotationTicketFile = {
      handle: ticketFile?.handle,
      id: ticketFile.id,
      annotations: ticketFile.annotations,
      url: ticketFile.url,
    }

    playerRef.current = createVideoPlayer(videoRef.current, annotationTicketFile, cancelCallback)
    playerRef.current.subscribe('annotationCreated', () => {
      setSelectedTool(null)
    })

    return () => {
      setShowVideoAnnotationCreator(false)
      setSelectedTool(null)
      playerRef.current.destroy()
      destroyVideoPlayer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketFile.id])

  return (
    <div className="tw-relative tw-h-full">
      {canEditAnnotation() && (
        <div className="tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-flex tw-h-0 tw-w-full tw-justify-center tw-text-center">
          <IconButton
            className="tw-m-2"
            color={selectedTool === Tool.Annotate ? 'primary' : 'secondary'}
            invert={selectedTool === Tool.Annotate}
            onClick={() => switchTool(selectedTool, Tool.Annotate)}
            selected={selectedTool === Tool.Annotate}
            size="lg"
          >
            <PenLine className="lu-light lu-md" />
          </IconButton>
        </div>
      )}
      <div className="tw-relative tw-flex tw-h-full tw-flex-row tw-items-center" ref={videoRef} />
    </div>
  )
}
