import { FileImageIcon, Palette, UserRound } from 'lucide-react'
import { ReactElement, useMemo } from 'react'

import OpenButton from 'components/elements/open-button'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'

import { useAdminMediaContext } from '../providers/admin-media-provider'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AdminMediaButtonsFooter from './admin-media-buttons-footer'
import AdminMediaList from './admin-media-list'
import IconButton from 'lib/components/buttons/icon-button'
import Tooltip from 'lib/components/tooltip/tooltip'
import { useThreeResizableContext } from 'lib/components/resizable/resizable'

interface SidebarNavProps {
  selected: string
  select: (selected: string) => void
}

const classNames = {
  container: 'tw-flex-auto tw-overflow-y-auto tw-p-4',
  footer: 'tw-flex-none tw-h-min tw-p-5 tw-bg-gray-50',
  h2: 'tw-text-base tw-mb-1',
  hr: 'tw-border-gray-200 tw-mt-4 tw-m-0 tw-p-0',
  qaFailDateContainer: 'tw-flex tw-justify-center tw-my-4',
  dropdownContainer: 'tw-mx-4 tw-pt-2',
  header: 'tw-flex-none tw-h-min tw-m-4 tw-mb-0',
  nav: {
    container: 'tw-flex tw-border-0 tw-border-b tw-border-solid tw-border-gray-200',
    icon: 'lu-light lu-sm',
    item: 'tw-flex-1 tw-p-2 tw-text-center tw-flex tw-items-center tw-justify-center tw-gap-2',
    unselectedItem: 'tw-text-gray-500 tw-cursor-pointer hover:tw-bg-peppercorn-50',
    selectedItem: 'tw-text-gray-800 tw-cursor-default tw-border-0 tw-border-b-2 tw-border-solid tw-border-gray-800',
  },
}

export default function AdminMediaSidebar(): ReactElement {
  const { filters, selectedFile, selectedVersion, setFilters, versions } = useAdminMediaContext()
  const { currentView, setMainContent } = useAdminTicketContext()

  const { leftCollapsed, toggleLeftCollapsed } = useThreeResizableContext()

  const selected = useMemo(() => {
    return filters.isUploadedByCreative ? 'creative' : 'client'
  }, [filters.isUploadedByCreative])

  function onNavSelect(selection: string) {
    setFilters((previous) => ({ ...previous, isUploadedByCreative: selection === 'creative' }))
  }

  function onVersionSelect(version: number) {
    setFilters((previous) => ({ ...previous, version }))
  }

  function openMainContent() {
    setMainContent('show')
  }

  return (
    <aside className="tw-relative tw-flex tw-h-full tw-flex-row tw-border-0 tw-bg-white">
      <div className="tw-sticky tw-bottom-0 tw-left-0 tw-top-0 tw-z-10 tw-flex tw-h-full tw-flex-col tw-gap-2 tw-border-0 tw-border-r tw-border-solid tw-border-gray-200 tw-p-3">
        <Tooltip direction="up" content="Assets">
          <IconButton
            color={leftCollapsed ? 'secondary' : 'primary'}
            invert={!leftCollapsed}
            selected={!leftCollapsed}
            onClick={toggleLeftCollapsed}
          >
            <FileImageIcon className="lu-light lu-md" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-pt-5">
        <SidebarNav select={onNavSelect} selected={selected} />

        <div className={classNames.dropdownContainer}>
          <TicketVersionSelectBox
            versions={versions}
            selectedVersion={selectedVersion}
            setSelectedVersion={onVersionSelect}
          />
        </div>
        <div className={classNames.container}>
          <AdminMediaList />
        </div>
        <AdminMediaButtonsFooter selected={selected} />
        {!currentView.mainContent && !!selectedFile && <OpenButton onClick={openMainContent} />}
      </div>
    </aside>
  )
}

function SidebarNav({ selected, select }: SidebarNavProps): ReactElement {
  function handleClick(item: string) {
    return () => {
      select(item)
    }
  }

  return (
    <nav className={classNames.nav.container}>
      <div
        className={`${selected === 'client' ? classNames.nav.selectedItem : classNames.nav.unselectedItem} ${
          classNames.nav.item
        }`}
        onClick={handleClick('client')}
      >
        <UserRound className={classNames.nav.icon} />
        Client
      </div>
      <div
        className={`${selected === 'creative' ? classNames.nav.selectedItem : classNames.nav.unselectedItem} ${
          classNames.nav.item
        }`}
        onClick={handleClick('creative')}
      >
        <Palette className={classNames.nav.icon} />
        Creative
      </div>
    </nav>
  )
}
