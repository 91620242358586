import { ReactElement } from 'react'
import { FolderArchiveIcon, RefreshCw } from 'lucide-react'
import { GenAiResultTypes } from 'lib/api/gen-ai/gen-ai-requests'
import { LoadingScreen } from '../../requests/empty-screens'
import CreateAssistOriginalPromptModal from '../ai/modals/create-assist-original-prompt-modal'
import { EditAssistOriginalPromptModal } from '../ai/modals/edit-assist-results-modal'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { useMediaContext } from './media-provider'
import MultipageNavigation from 'lib/components/multipage/multipage-navigation'
import MediaViewer from './media-viewer'
import Banner from 'lib/components/banner/banner'
import MediaDrawer from './media-drawer'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'

import Button from 'components/core/button'
import FileDownloadModal from './file-download-modal'

import FileType from 'lib/components/file-type/file-type'
import { MediaActions, ShareAction } from './media-actions'
import StateButton from './state-button'
import Badge from 'lib/components/badge/badge'

interface MediaContainerProps {
  show: boolean
  showOverflow: boolean
  isColab?: boolean
}

const classNames = {
  mediaView: {
    aside: 'tw-flex-none tw-relative tw-duration-500 tw-bg-gray-50 tw-h-full',
    container: 'tw-h-full tw-border-gray-200 tw-border-0 tw-border-r tw-border-l tw-border-solid',
    controls: 'tw-text-right',
    figure: 'tw-w-full tw-text-center tw-p-0 tw-m-0 tw-relative',
    ticketFileName: 'tw-neutral-800 tw-flex tw-gap-2 tw-whitespace-nowrap tw-items-center',
  },
}

const styles = {
  requestMainCollapsed: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-out',
    width: '0px',
  },

  requestMainExpanded: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-in',
  },
}

const headerHeight = 100

export default function MediaContainer({ show, showOverflow, isColab }: MediaContainerProps): ReactElement {
  const {
    extractedPreviewIndex,
    extractedFilesPollingFailed,
    retryExtractingPages,
    selectedFile,
    setExtractedPreviewIndex,
    zipFile,
    showPromptModal,
    setShowPromptModal,
    fileExportModalMode,
    setFileExportModalMode,
    currentTicketFile,
    genAiResultType,
  } = useMediaContext()
  const { isCollaboratorView } = useAnnotationsContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const wipPreviewActionsEnabled = isFeatureFlagEnabled('wip_preview_actions')
  const bannerHeight = selectedFile?.preview ? 80 : 0

  return (
    <>
      {selectedFile &&
        !isCollaboratorView &&
        (!selectedFile.preview || (selectedFile.preview && wipPreviewActionsEnabled)) && (
          <FileDownloadModal
            fileExportModalMode={fileExportModalMode}
            setFileExportModalMode={setFileExportModalMode}
            currentTicketFile={selectedFile}
          />
        )}
      <aside
        className={`${classNames.mediaView.aside} ${showOverflow ? '' : 'tw-overflow-hidden'}`}
        style={show && !!selectedFile ? styles.requestMainExpanded : styles.requestMainCollapsed}
      >
        {selectedFile && (
          <div className={`${classNames.mediaView.container} ${showOverflow ? '' : 'tw-overflow-y-auto'}`}>
            {genAiResultType === GenAiResultTypes.CreateAssist && (
              <CreateAssistOriginalPromptModal
                open={showPromptModal}
                setOpen={() => setShowPromptModal(false)}
                requestId={selectedFile.imageableId}
              />
            )}
            {genAiResultType === GenAiResultTypes.EditAssist && (
              <EditAssistOriginalPromptModal
                open={showPromptModal}
                setOpen={() => setShowPromptModal(false)}
                requestId={selectedFile.imageableId}
              />
            )}
            <header className="tw-flex tw-items-center tw-justify-between tw-break-all tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-bg-white tw-px-4 tw-py-6">
              <div className="tw-flex tw-items-center tw-overflow-hidden">
                <FileType className="tw-mr-2" fileName={selectedFile.name} />
                <strong className="tw-truncate">
                  {selectedFile.name}
                  {zipFile && (
                    <>
                      {' '}
                      <span className="tw-ml-2 tw-text-xs tw-font-normal tw-text-neutral-600">
                        <FolderArchiveIcon className="tw-mr-1 tw-h-4 tw-w-4" /> via {zipFile.name}
                      </span>
                    </>
                  )}
                </strong>
                {selectedFile?.exported && (
                  <Badge color="light" className="tw-ml-2">
                    Stockpress
                  </Badge>
                )}
                <ShareAction />
                <StateButton />
              </div>

              {selectedFile.isExtractable && selectedFile.extractedPages.length !== 0 && (
                <MultipageNavigation
                  index={extractedPreviewIndex}
                  count={selectedFile.extractedPages.length}
                  setIndex={setExtractedPreviewIndex}
                />
              )}
              <div className="tw-flex tw-items-center">
                <MediaActions />
                {currentTicketFile && !isColab && <MediaDrawer />}
              </div>
            </header>
            {selectedFile?.preview && (
              <Banner
                type="warning"
                heading={
                  wipPreviewActionsEnabled
                    ? 'This is a preview file. Your designer will resume work on it soon. You can add feedback and annotations to guide them.'
                    : 'This is a preview. Your designer will continue working on this soon.'
                }
              />
            )}
            {!currentTicketFile && !extractedFilesPollingFailed && (
              <div className="tw-text-center">
                <LoadingScreen />
              </div>
            )}
            {!currentTicketFile && extractedFilesPollingFailed && (
              <div className="tw-mx-10 tw-my-4 tw-text-center">
                <img alt="Pickle Error Hole" src="/images/states/error-hole.png" height={200} width={200} />
                <h5>Uh oh! We couldn’t load all your pages</h5>
                <p>
                  It seems there was an issue processing your attachment. Please try again. If the issue persists, feel
                  free to reach out to our Customer Support team at{' '}
                  <a href="mailto:help@designpickle.com">help@designpickle.com</a> for assistance.
                </p>
                <Button color="purple" onClick={() => retryExtractingPages()}>
                  <RefreshCw className="lu-md" /> Retry
                </Button>
              </div>
            )}
            {currentTicketFile && (
              <figure
                className={classNames.mediaView.figure}
                style={{ height: `calc(100% - ${headerHeight + bannerHeight}px)` }}
              >
                <MediaViewer />
              </figure>
            )}
          </div>
        )}
      </aside>
    </>
  )
}
