import { baseRequest, BaseResponse } from '../api'
import { postApi, bindJWT } from '../api'

interface FileUrlResponse extends BaseResponse {
  data: {
    url: string
  }
}

export async function getPresignedUrl(filename: string, expires_in?: number, token?: string): Promise<string> {
  const postWithJWT = bindJWT(postApi, token ?? '')
  const response = (await baseRequest('/api/internal/file_urls', postWithJWT, {
    filename: filename,
    ...(expires_in && { expires_in }),
  })) as FileUrlResponse

  return response.data.url
}
