import { useEffect, useState, useCallback } from 'react'
import TextInput from 'lib/components/text-input/text-input'
import { Check, Copy, LucideLoaderCircle, Pencil, RefreshCw, SquareArrowOutUpRight, TriangleAlert } from 'lucide-react'
import { ChangeEvent } from 'react'
import { FormState, UpdateFormState } from './custom-branding'
import { CustomBrandingSettings } from './custom-branding-page'
import { fetchBrand, refreshBrandDns, regenerateCname } from 'lib/api/custom-branding/custom-branding'
import Button from 'components/core/button'
import { toast } from 'lib/components/toast/toast'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { getDomain } from 'tldts'
import { cn } from 'lib/util/cn'

export const normalizeUrl = (url: string): string => {
  // Remove protocol
  let normalized = url.replace(/^(https?:\/\/)/i, '')

  // Remove trailing slash
  normalized = normalized.replace(/\/$/, '')

  return normalized.toLowerCase()
}

export const isValidDomain = (url: string): boolean => {
  const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.){1,2}[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i
  return domainRegex.test(url)
}

export function CustomBrandingUrlSettings({
  formState,
  updateFormState,
  customBranding,
  customBrandingStatus,
}: {
  formState: FormState
  updateFormState: UpdateFormState
  customBranding: CustomBrandingSettings
  customBrandingStatus: boolean
}) {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  // Heroku
  const [mappedTo, setMappedTo] = useState(customBranding?.mappedTo || '')
  const [isGeneratingCname, setIsGeneratingCname] = useState(!customBranding?.mappedTo && !!customBranding?.url)

  // AWS
  const [cnameConfig, setCnameConfig] = useState(customBranding?.cnameConfig)
  const [isGeneratingCnameAcm, setIsGeneratingCnameAcm] = useState(
    !customBranding?.cnameConfig?.acm && !!customBranding?.url,
  )
  const [isGeneratingCnameCloudFront, setIsGeneratingCnameCloudFront] = useState(
    !customBranding?.cnameConfig?.cloudfront && !!customBranding?.url,
  )
  const [isInputDisabled, setIsInputDisabled] = useState(!!customBranding?.url)
  const [isEditing, setIsEditing] = useState(false)

  const classNames = {
    tableHeader: 'tw-bg-gray-100 tw-text-gray-700',
    tableCell: 'tw-p-3 tw-border-r tw-border-gray-200 tw-border-solid',
  }

  const pollBrand = useCallback(async () => {
    if (!customBranding?.id) return

    try {
      const response = await fetchBrand(customBranding.id)
      // Check if CNAME is available (Heroku)
      if (response?.data?.mappedTo) {
        setMappedTo(response?.data?.mappedTo)
        setIsGeneratingCname(false)
      }
      // Check if CNAME is available (AWS)
      if (response?.data?.cnameConfig) {
        setCnameConfig(response?.data?.cnameConfig)
      }
      if (response?.data?.cnameConfig?.acm) {
        setIsGeneratingCnameAcm(false)
      }
      if (response?.data?.cnameConfig?.cloudfront) {
        setIsGeneratingCnameCloudFront(false)
      }
    } catch (error) {
      console.error('Error fetching brand:', error)
    }
  }, [customBranding?.id])

  useEffect(() => {
    if (isGeneratingCname) {
      const pollInterval = setInterval(pollBrand, 5000) // Poll every 5 seconds
      const timeout = setTimeout(() => {
        clearInterval(pollInterval)
        setIsGeneratingCname(false)
      }, 60 * 1000) // Stop polling after 1 minute

      return () => {
        clearInterval(pollInterval)
        clearTimeout(timeout)
      }
    }
  }, [isGeneratingCname, pollBrand])

  useEffect(() => {
    if (isGeneratingCnameAcm) {
      const pollInterval = setInterval(pollBrand, 5000) // Poll every 5 seconds
      const timeout = setTimeout(() => {
        clearInterval(pollInterval)
        setIsGeneratingCnameAcm(false)
      }, 60 * 1000) // Stop polling after 1 minute

      return () => {
        clearInterval(pollInterval)
        clearTimeout(timeout)
      }
    }
  }, [isGeneratingCnameAcm, pollBrand])

  useEffect(() => {
    if (isGeneratingCnameCloudFront) {
      const pollInterval = setInterval(pollBrand, 5000) // Poll every 5 seconds
      const timeout = setTimeout(() => {
        clearInterval(pollInterval)
        setIsGeneratingCnameCloudFront(false)
      }, 60 * 1000) // Stop polling after 1 minute

      return () => {
        clearInterval(pollInterval)
        clearTimeout(timeout)
      }
    }
  }, [isGeneratingCnameCloudFront, pollBrand])

  useEffect(() => {
    if ((customBranding?.url || formState.url.value) && !isEditing) {
      setIsInputDisabled(true)
    }
  }, [customBranding?.url, formState.url.value, isEditing])

  useEffect(() => {
    setMappedTo(customBranding?.mappedTo || '')
  }, [customBranding?.mappedTo])

  useEffect(() => {
    setCnameConfig(customBranding?.cnameConfig)
  }, [customBranding?.cnameConfig])

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    const normalizedUrl = normalizeUrl(event.target.value)
    updateFormState('url', normalizedUrl)
    setIsEditing(true)
  }

  const handleUrlBlur = () => {
    const normalizedUrl = formState.url.value
    if (normalizedUrl && !isValidDomain(normalizedUrl)) {
      updateFormState('url', normalizedUrl, 'Please enter a valid domain')
    } else {
      updateFormState('url', normalizedUrl, null) // Clear any previous error
      setIsEditing(false)
    }
  }

  const removeRootDomain = (hostname) => {
    // Remove trailing dot if present
    hostname = hostname.replace(/\.$/, '')
    // Get root domain
    const rootDomain = getDomain(hostname)
    // Return original if parsing fails
    if (!rootDomain) return hostname
    // Extract subdomain
    const subdomain = hostname.replace(`.${rootDomain}`, '')
    // Return subdomain if exists, else root domain
    return subdomain || rootDomain
  }

  const showRefreshButton =
    customBranding?.url &&
    !customBrandingStatus &&
    Date.now() - new Date(customBranding.createdAt).getTime() > 60 * 60 * 1000 // only show one hour after creation to prevent users spamming the button

  const hasCnameConfig =
    customBranding?.cnameConfig && (customBranding?.cnameConfig?.acm || customBranding?.cnameConfig?.cloudfront)
  const showRegenerateCnameButton =
    isFeatureFlagEnabled('aws_white_label') && customBranding?.url && !hasCnameConfig && !isGeneratingCname
  const [isDnsRefreshing, setIsDnsRefreshing] = useState(false)
  const [isRegeneratingCname, setIsRegeneratingCname] = useState(false)

  const handleRefresh = async () => {
    setIsDnsRefreshing(true)
    try {
      await refreshBrandDns(customBranding.id)
      toast.success('DNS Status Successfully Refreshed - Please Refresh Your Page')
    } catch (e) {
      console.error(e)
      toast.error('DNS Status Refresh Failed')
    }
    setIsDnsRefreshing(false)
  }

  const handleCnameRegeneration = async () => {
    setIsRegeneratingCname(true)
    try {
      await regenerateCname(customBranding.id)
      toast.success('CNAME Records Successfully Regenerated - Please Refresh Your Page')
    } catch (e) {
      console.error(e)
      toast.error('CNAME Records Regeneration Failed')
    }
    setIsRegeneratingCname(false)
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(mappedTo)
    toast.success('Value Copied to Clipboard')
  }

  const onCopy = async (value: string) => {
    await navigator.clipboard.writeText(value)
    toast.success('Value Copied to Clipboard')
  }

  return (
    <div className="tw-max-w-300 tw-flex tw-flex-col tw-gap-4">
      <h4>Add your domain</h4>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4">
        <div className="tw-relative tw-max-w-sm tw-flex-1">
          <TextInput
            type="text"
            id="url"
            name="url"
            placeholder="review.yourdomain.com"
            value={formState.url.value}
            onChange={handleUrlChange}
            onBlur={handleUrlBlur}
            error={!!formState.url.error}
            className="tw-mb-0 tw-mt-0 tw-w-96 tw-pl-14 tw-pr-6"
            disabled={isInputDisabled}
          />
          <div className="tw-pointer-events-none tw-absolute tw-top-0 tw-flex tw-h-full tw-w-14 tw-items-center tw-justify-end tw-text-peppercorn-300">
            https://
          </div>
          {isInputDisabled && (
            <div
              className="tw-absolute tw-right-1 tw-top-1/2 tw-flex tw-h-8 tw-w-8 tw--translate-y-1/2 tw-transform tw-cursor-pointer tw-items-center tw-rounded-md tw-p-2 tw-text-cornflower-500 hover:tw-bg-peppercorn-100 hover:tw-text-cornflower-800"
              onClick={() => setIsInputDisabled(false)}
            >
              <Pencil className="tw-w-4" />
            </div>
          )}
        </div>
        <div className="tw-text-sm">
          {customBranding?.url && (
            <>
              {customBrandingStatus && (customBranding?.mappedTo || customBranding?.cnameConfig) ? (
                <div className="tw-flex tw-items-center tw-gap-1 tw-text-gherkin">
                  <Check className="lu-md" />
                  Connected
                </div>
              ) : (
                <div className="tw-flex tw-items-center tw-gap-1 tw-text-sunnyyellow-600">
                  <TriangleAlert className="lu-md" />
                  Pending Connection (
                  <a
                    href="https://help.designpickle.com/en/articles/9487693-share-review-how-to-update-your-domain-s-dns-settings"
                    target="_blank"
                    rel="noreferrer"
                    className="tw-flex tw-items-center tw-gap-1 tw-font-bold"
                  >
                    How to connect your domain
                    <SquareArrowOutUpRight className="lu-sm" />
                  </a>
                  )
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {formState.url.error && <div className="tw-text-flushpink-500">{formState.url.error}</div>}
      {showRegenerateCnameButton && (
        <Button
          color={'red'}
          onClick={handleCnameRegeneration}
          className="tw-flex tw-items-center tw-gap-2 tw-self-start"
        >
          <RefreshCw className={cn('lu-sm', isRegeneratingCname && 'tw-animate-spin')} />
          Re-generate CNAME records
        </Button>
      )}
      {showRefreshButton && (
        <Button color={'lightGray'} onClick={handleRefresh} className="tw-flex tw-items-center tw-gap-2 tw-self-start">
          <RefreshCw className={cn('lu-sm', isDnsRefreshing && 'tw-animate-spin')} />
          Refresh DNS Status
        </Button>
      )}
      <p className="tw-mb-0">
        You can use a subdomain. E.g. subdomain.yourdomain.com. You will need to point your domain to our CNAME via your
        custom DNS settings.
      </p>
      <table
        className={cn('tw-rounded-md tw-border-solid tw-border-gray-200', !customBranding?.url && 'tw-opacity-30')}
      >
        <tbody>
          <tr className={classNames.tableHeader}>
            <th className={classNames.tableCell}>Type</th>
            <th className={classNames.tableCell}>Host Name</th>
            <th className={classNames.tableCell}>Value/Points To</th>
          </tr>
          {/* AWS */}
          {isFeatureFlagEnabled('aws_white_label') && (
            <>
              <tr>
                <td className={classNames.tableCell}>CNAME</td>
                <td className={classNames.tableCell}>
                  {customBranding?.url && (
                    <>
                      {cnameConfig?.acm ? (
                        <div
                          className="tw-cursor-pointer hover:tw-opacity-70"
                          onClick={() => onCopy(removeRootDomain(cnameConfig?.acm?.name))}
                        >
                          <Copy className="lu-sm tw-mr-2" />
                          <span data-testid="mappedToUrl">{removeRootDomain(cnameConfig?.acm?.name)}</span>
                        </div>
                      ) : !hasCnameConfig ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          Please regenerate CNAME records
                        </span>
                      ) : isGeneratingCnameAcm ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          <LucideLoaderCircle className="lu-sm tw-mr-1 tw-animate-spin" />
                          Generating - this may take a few minutes
                        </span>
                      ) : (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrlFallback">
                          Refresh the page to check for updates.
                        </span>
                      )}
                    </>
                  )}
                </td>
                <td className={classNames.tableCell}>
                  {customBranding?.url && (
                    <>
                      {cnameConfig?.acm ? (
                        <div
                          className="tw-cursor-pointer hover:tw-opacity-70"
                          onClick={() => onCopy(cnameConfig?.acm?.value)}
                        >
                          <Copy className="lu-sm tw-mr-2" />
                          <span data-testid="mappedToUrl">{cnameConfig?.acm?.value}</span>
                        </div>
                      ) : !hasCnameConfig ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          Please regenerate CNAME records
                        </span>
                      ) : isGeneratingCnameAcm ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          <LucideLoaderCircle className="lu-sm tw-mr-1 tw-animate-spin" />
                          Generating - this may take a few minutes
                        </span>
                      ) : (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrlFallback">
                          Refresh the page to check for updates.
                        </span>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td className={classNames.tableCell}>CNAME</td>
                <td className={classNames.tableCell}>
                  {customBranding?.url && (
                    <>
                      {cnameConfig?.cloudfront ? (
                        <div
                          className="tw-cursor-pointer hover:tw-opacity-70"
                          onClick={() => onCopy(removeRootDomain(cnameConfig?.cloudfront?.name))}
                        >
                          <Copy className="lu-sm tw-mr-2" />
                          <span data-testid="mappedToUrl">{removeRootDomain(cnameConfig?.cloudfront?.name)}</span>
                        </div>
                      ) : !hasCnameConfig ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          Please regenerate CNAME records
                        </span>
                      ) : isGeneratingCnameCloudFront ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          <LucideLoaderCircle className="lu-sm tw-mr-1 tw-animate-spin" />
                          Generating - this may take a few minutes
                        </span>
                      ) : (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrlFallback">
                          Refresh the page to check for updates.
                        </span>
                      )}
                    </>
                  )}
                </td>
                <td className={classNames.tableCell}>
                  {customBranding?.url && (
                    <>
                      {cnameConfig?.cloudfront ? (
                        <div
                          className="tw-cursor-pointer hover:tw-opacity-70"
                          onClick={() => onCopy(cnameConfig?.cloudfront?.value)}
                        >
                          <Copy className="lu-sm tw-mr-2" />
                          <span data-testid="mappedToUrl">{cnameConfig?.cloudfront?.value}</span>
                        </div>
                      ) : !hasCnameConfig ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          Please regenerate CNAME records
                        </span>
                      ) : isGeneratingCnameCloudFront ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          <LucideLoaderCircle className="lu-sm tw-mr-1 tw-animate-spin" />
                          Generating - this may take a few minutes
                        </span>
                      ) : (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrlFallback">
                          Refresh the page to check for updates.
                        </span>
                      )}
                    </>
                  )}
                </td>
              </tr>
            </>
          )}

          {/* Heroku (LEGACY) */}
          {!isFeatureFlagEnabled('aws_white_label') && (
            <>
              <tr>
                <td className={classNames.tableCell}>CNAME</td>
                <td className={classNames.tableCell}>{customBranding?.url ?? '(Your custom domain)'}</td>
                <td className={classNames.tableCell}>
                  {customBranding?.url && (
                    <>
                      {mappedTo ? (
                        <div className="tw-cursor-pointer hover:tw-opacity-70" onClick={handleCopy}>
                          <Copy className="lu-sm tw-mr-2" />
                          <span data-testid="mappedToUrl">{mappedTo}</span>
                        </div>
                      ) : isGeneratingCname ? (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                          <LucideLoaderCircle className="lu-sm tw-mr-1 tw-animate-spin" />
                          Generating - please wait
                        </span>
                      ) : (
                        <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrlFallback">
                          Refresh the page to check for updates.
                        </span>
                      )}
                    </>
                  )}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <p className="tw-mb-0">
        CNAME information might take up to a few minutes to generate. You can refresh the page to check for updates.
      </p>
    </div>
  )
}
