import { ReactElement, useState } from 'react'
import { ClipboardList, Copy, Pen, Quote, Sparkles, Trash } from 'lucide-react'

import Avatar from 'components/core/avatar'
import { DetailTaskTimelineItem, TimelineItemTypes } from 'lib/api/timeline/timeline'
import Badge from 'lib/components/badge/badge'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'

import RequestAssistReadOnlyModal from '../ai/modals/request-assist-read-only-modal'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import { displayDate } from 'lib/util/date'
import { useRequestContext } from 'components/pages/request/providers/request-provider'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import parseDirectionHTML from 'lib/util/parse-direction-html'

interface TimelineDirectionDetailsProps {
  direction: DetailTaskTimelineItem
  enableEditMode: () => void
  onDelete: (direction: DetailTaskTimelineItem) => void
}

const classNames = {
  article: 'tw-mb-4 tw-bg-neutral-50 tw-text-neutral-800 tw-rounded-lg tw-p-4',
  header: {
    avatar: 'tw-shrink',
    byline: 'tw-flex-grow tw-text-left tw-flex',
    container: 'tw-flex tw-direction-row tw-justify-between tw-items-center tw-p-1 tw-gap-2',
  },
  itemContainer: 'tw-flex tw-divide-solid tw-divide-y-0 tw-divide-x tw-divide-peppercorn-100 tw-min-w-0',
  itemDescriptionContainer: 'tw-pt-2 tw-px-2 tw-text-neutral-800 tw-flex-grow tw-truncate',
  itemTypeTitle: 'tw-mb-2',
  tooltip:
    'tw--top-16 tw-absolute tw-bg-neutral-500 tw-px-3 tw-py-1.5 tw-right-2 tw-rounded tw-text-left tw-text-sm tw-text-white tw-whitespace-nowrap tw-z-50',
}

const ldquo = <>&ldquo;</>
const rdquo = <>&rdquo;</>

export default function TimelineDirectionDetails({
  direction,
  enableEditMode,
  onDelete,
}: TimelineDirectionDetailsProps): ReactElement {
  const [showModal, setShowModal] = useState(false)
  const [showCopyTooltip, setShowCopyTooltip] = useState(false)
  const { canEditDirection } = useDirectionsContext()
  const { ticket } = useRequestContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const isSubmittedByCustomer = ticket?.submittedBy?.id === direction?.user?.id

  function handleDelete() {
    onDelete(direction)
  }

  function viewOriginalPrompt() {
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  async function handleCopy() {
    await navigator.clipboard.writeText(direction.description)
    showTooltip()
  }

  function showTooltip() {
    setShowCopyTooltip(true)
    const timeout = setTimeout(() => {
      setShowCopyTooltip(false)

      clearTimeout(timeout)
    }, 3000)
  }

  const containerClassNames = isSubmittedByCustomer
    ? 'tw-bg-neutral-50 tw-justify-end'
    : 'tw-bg-neutral-100 tw-justify-start'

  return (
    <article className={`${classNames.article} ${containerClassNames}`}>
      <header className={classNames.header.container}>
        <div className={classNames.header.avatar}>
          <Avatar src={direction.user.avatar} name={direction.userFullName} />
        </div>
        <div className={classNames.header.byline}>
          <div>{direction.user.fullName}</div>
          <Badge style="pill" color="light">
            {displayDate(direction.createdAt)}
          </Badge>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <IconFlyoutMenu
            color={showCopyTooltip ? 'success' : 'transparent'}
            icon={showCopyTooltip ? ['far', 'check'] : ['far', 'ellipsis-v']}
          >
            {canEditDirection(direction) && (
              <>
                <IconFlyoutMenu.Button onClick={enableEditMode}>
                  <Pen className="lu-light lu-sm tw-mr-1" /> Edit
                </IconFlyoutMenu.Button>
                <IconFlyoutMenu.Button onClick={handleDelete}>
                  <Trash className="lu-light lu-sm tw-mr-1" /> Delete
                </IconFlyoutMenu.Button>
                {direction.taskType === TimelineItemTypes.GEN_AI_REQUEST && (
                  <IconFlyoutMenu.Button onClick={viewOriginalPrompt}>
                    <Sparkles className="lu-light lu-sm tw-mr-1" />
                    View Original Prompt
                  </IconFlyoutMenu.Button>
                )}
              </>
            )}
            <IconFlyoutMenu.Button onClick={handleCopy}>
              <Copy className="lu-light lu-sm tw-mr-1" /> Copy
            </IconFlyoutMenu.Button>
          </IconFlyoutMenu>
          <div className="tw-relative">
            {showCopyTooltip && <div className={classNames.tooltip}>Direction Copied!</div>}
          </div>

          {direction.taskType === TimelineItemTypes.GEN_AI_REQUEST && (
            <RequestAssistReadOnlyModal
              open={showModal}
              promptText={direction.genAiRequest?.message}
              setOpen={closeModal}
            />
          )}
        </div>
      </header>
      <div className={classNames.itemContainer}>
        <div className={classNames.itemDescriptionContainer}>
          <div className={classNames.itemTypeTitle}>
            <TypeTitle type={direction.taskType} />
          </div>
          {direction.taskType !== TimelineItemTypes.COPY && (
            <div onClick={enableEditMode} style={{ textWrap: 'wrap' }}>
              {isFeatureFlagEnabled('request_wysiwyg') ? (
                <div
                  className="tw-text-wrap tw-break-words"
                  dangerouslySetInnerHTML={{
                    __html: parseDirectionHTML(direction.description),
                  }}
                />
              ) : (
                direction.description
              )}
            </div>
          )}
          {direction.taskType === TimelineItemTypes.COPY && (
            <div onClick={enableEditMode} style={{ textWrap: 'wrap' }}>
              {ldquo}
              {direction.description}
              {rdquo}
            </div>
          )}
        </div>
      </div>
    </article>
  )
}

function TypeTitle({ type }: { type: TimelineItemTypes }): ReactElement {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const detailTypeIconMap = {
    ...(!isFeatureFlagEnabled('request_wysiwyg') && {
      [TimelineItemTypes.COPY]: (
        <>
          <Quote className="lu-light lu-md" /> EXACT COPY
        </>
      ),
      [TimelineItemTypes.DIRECTION]: (
        <>
          <ClipboardList className="lu-light lu-md" /> GENERAL DIRECTION
        </>
      ),
    }),
    [TimelineItemTypes.GEN_AI_REQUEST]: (
      <>
        <Sparkles className="lu-light lu-md" /> GEN AI REQUEST
      </>
    ),
  }
  return detailTypeIconMap[type] || null
}
