import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { featureFlags, features, getFeatureFlags } from 'lib/api/feature-flags/feature-flags'
import { useUserContext } from 'providers/user-provider'
import { toast } from 'lib/components/toast/toast'

interface FeatureFlagsContextProps {
  children: ReactNode
}

export type FeatureFlagsContextValue = {
  isFeatureFlagEnabled: (key: features) => boolean
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue | null>(null)

export function useFeatureFlagsContext() {
  if (!FeatureFlagsContext) {
    throw new Error('useFeatureFlagsContext must be used within a FeatureFlagsProvider')
  }
  return useContext(FeatureFlagsContext)
}

export default function FeatureFlagsProvider({ children }: FeatureFlagsContextProps) {
  const [featureFlags, setFeatureFlags] = useState<featureFlags>()

  const { token } = useUserContext()

  useEffect(() => {
    async function fetchFeatureFlags() {
      try {
        const features = await getFeatureFlags(token)
        setFeatureFlags(features)
      } catch (error) {
        console.error('Error fetching feature flags', error)
        toast.error('Oops, something went wrong, try again later')
      }
    }
    fetchFeatureFlags()
  }, [token])

  function isFeatureFlagEnabled(key: string | features): boolean {
    if (!featureFlags) {
      return false
    }

    return Boolean(featureFlags[key])
  }

  const contextValue: FeatureFlagsContextValue = {
    isFeatureFlagEnabled,
  }

  return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>
}
