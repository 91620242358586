import { Drawer } from 'lib/components/drawer/drawer'
import IconButton from 'lib/components/buttons/icon-button'
import { ArrowRightToLine, FileIcon, FolderArchiveIcon, PaletteIcon, RulerIcon, ViewIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { displayDate } from 'lib/util/date'
import CompanyTaggingProvider, { ChangeTagAction } from 'providers/company-tagging-provider'
import CompanyTaggingPopover from 'components/elements/company-tagging/company-tagging-popover'
import { CompanyTag } from 'lib/api/company-tags/company-tags'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'
import { useMediaContext } from './media-provider'
import { displayFilesize, getShareLinkUrl } from 'lib/util/file/file'
import FileType from 'lib/components/file-type/file-type'
import UserAvatar from 'components/elements/user-avatar'
import { MediaActions, ShareAction } from './media-actions'
import StateButton from './state-button'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import Badge from 'lib/components/badge/badge'

export default function MediaDrawer() {
  const { companyTags } = useAllCompanyTagsContext()
  const {
    zipFile,
    currentTicketFile: file,
    isTaggingDrawerOpen: isOpen,
    setIsTaggingDrawerOpen: setIsOpen,
    useToken,
    token,
  } = useMediaContext()

  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const [selectedTags, setSelectedTags] = useState(file.tags || [])
  const [shareLinkUrl, setShareLinkUrl] = useState<string | null>(null)

  useEffect(() => {
    const fetchShareLinkUrl = async () => {
      const url = await getShareLinkUrl(file, undefined, useToken ? token : undefined)
      setShareLinkUrl(url)
    }
    fetchShareLinkUrl()
  }, [file, useToken, token])

  useEffect(() => {
    setSelectedTags(file.tags || [])
  }, [file.id, file.tags])

  useEffect(() => {
    setSelectedTags((prevSelectedTags) => {
      return prevSelectedTags
        .filter((selectedTag) => companyTags.some((companyTag) => companyTag.id === selectedTag.id))
        .map((selectedTag) => companyTags.find((tag) => tag.id === selectedTag.id))
    })
  }, [companyTags])

  function handleUpdateTaggableState(tag: CompanyTag, action: ChangeTagAction) {
    switch (action) {
      case ChangeTagAction.Select:
        setSelectedTags([...selectedTags, tag])
        break
      case ChangeTagAction.Unselect:
      case ChangeTagAction.Delete:
        setSelectedTags(selectedTags.filter((selectedTag) => selectedTag.id !== tag.id))
        break
      case ChangeTagAction.Update:
        setSelectedTags(selectedTags.map((selectedTag) => (selectedTag.id === tag.id ? tag : selectedTag)))
        break
      default:
        break
    }
  }

  const fileHasMetaData = file?.colorspace || file?.dpi || file?.fileSize || file?.height || file?.width

  return (
    <Drawer isOpen={isOpen} setOpen={setIsOpen} size="md">
      <Drawer.Header setOpen={setIsOpen} showCloseButton={false}>
        <div className="tw-flex tw-w-full tw-items-center">
          <IconButton color="secondary" onClick={() => setIsOpen(false)}>
            <ArrowRightToLine />
          </IconButton>
          <FileType size={40} fileName={file.name} className="tw-ml-4 tw-mr-2" />
          <div className="tw-flex tw-min-w-0 tw-flex-1 tw-flex-col tw-gap-1">
            <div className="tw-flex tw-justify-between">
              <h5 className="tw-my-1 tw-truncate tw-text-xl tw-font-bold">{file.name}</h5>
              {file?.exported && (
                <Badge color="light" className="tw-ml-2 tw-mr-auto tw-h-min tw-self-center">
                  Stockpress
                </Badge>
              )}
              {isFeatureFlagEnabled('stockpress') && (
                <div className="tw-flex tw-h-min tw-flex-row">
                  <ShareAction direction="down" />
                  <StateButton />
                </div>
              )}
            </div>
            {file?.user && file.user?.name && (
              <div className="tw-flex tw-items-center tw-gap-2">
                Created on {displayDate(file.createdAt)} <UserAvatar name={file.user.name} />
              </div>
            )}
          </div>
          <MediaActions showViewDetials={false} />
        </div>
      </Drawer.Header>
      <Drawer.Body>
        <div className="tw-relative tw-my-4">
          <img src={file.url} alt={file.name} className="tw-max-h-96 tw-w-full tw-object-contain" />
          <div className="tw-absolute tw-bottom-2 tw-left-0 tw-right-0 tw-flex tw-items-center tw-justify-center">
            {fileHasMetaData && (
              <div className="tw-mx-auto tw-flex tw-min-w-fit tw-flex-row tw-gap-2 tw-rounded-lg tw-bg-black tw-p-2 tw-text-white tw-opacity-60">
                {file.colorspace && (
                  <>
                    <PaletteIcon />
                    {file.colorspace}
                  </>
                )}
                {file.dpi && (
                  <>
                    <ViewIcon />
                    {file.dpi} DPI
                  </>
                )}
                {file.fileSize && (
                  <>
                    <FileIcon />
                    {displayFilesize(file.fileSize)}
                  </>
                )}
                {file.height && file.width && (
                  <>
                    <RulerIcon />
                    {file.width} x {file.height} px
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <h5>Tags</h5>
          <CompanyTaggingProvider
            selectedTags={selectedTags}
            updateTaggableState={handleUpdateTaggableState}
            taggableId={file.id}
            taggableType="TicketFile"
            warningMessage="Adding and removing asset tags does not add nor remove tags from the associated requests"
          >
            <CompanyTaggingPopover size="full" />
          </CompanyTaggingProvider>
        </div>
        {zipFile && (
          <div>
            <h5>Source</h5>
            <a href={shareLinkUrl} target="_blank" rel="noreferrer" className="tw-text-cornflower-500">
              <FolderArchiveIcon className="tw-mr-1 tw-h-4 tw-w-4" />
              via {zipFile.name}
            </a>
          </div>
        )}
      </Drawer.Body>
    </Drawer>
  )
}
