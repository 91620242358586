import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'

interface ExternalActionLinkItemProps {
  label: string
  iconClass: string
  route: string
  method?: 'GET' | 'POST' | 'DELETE'
}

export default function ExternalActionLinkItem({
  label,
  iconClass,
  route,
  method = 'GET',
}: ExternalActionLinkItemProps): JSX.Element {
  return (
    <IconFlyoutMenu.Link url={route} data-method={method}>
      <i className={iconClass}></i>
      &nbsp;
      {label}
    </IconFlyoutMenu.Link>
  )
}
