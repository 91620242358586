import { ReactElement } from 'react'

import AdminHeaderDetails from './admin-header-details'
import RequestsProjectsBreadcrumb from 'lib/components/breadcrumb/requests-projects-breadcrumb'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AllCompanyTagsProvider from 'providers/all-company-tags-provider'

export default function AdminHeader(): ReactElement {
  const { ticket } = useAdminTicketContext()

  return (
    <AllCompanyTagsProvider>
      <header className="tw-px-2 tw-py-4">
        <RequestsProjectsBreadcrumb className="tw-ml-6 tw-mt-3" ticket={ticket} isAdmin />
        <AdminHeaderDetails />
      </header>
    </AllCompanyTagsProvider>
  )
}
