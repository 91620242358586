import { ConversationTimelineItem } from 'lib/api/timeline/timeline'
import { Ticket } from './ticket'
import { User } from './user'
import { PendingLeaveDay } from './pending-leave-day'
import { Company } from './company'
import { Designer } from './designer'

export enum ActivityNotificationType {
  ClientReply = 'client_reply',
  CollaboratorAnnotation = 'collaborator_annotation',
  CompanyMissingDesigner = 'company_missing_designer',
  CompanyAutoAssignFail = 'company_auto_assign_fail',
  ConversationMention = 'conversation_mention',
  DesignerOverweight = 'designer_overweight',
  DesignerUnderweight = 'designer_underweight',
  GenericTicketReply = 'generic_ticket_reply',
  LeaveApproved = 'leave_approved',
  LeaveDenied = 'leave_denied',
  LeaveDestroy = 'leave_destroy',
  LeaveReadyForApproval = 'leave_ready_for_approval',
  StatusPending = 'status_pending',
  TicketAssigned = 'ticket_assigned',
  TicketFeedbackSubmitted = 'ticket_feedback_submitted',
}

export interface BaseActivityNotification {
  id: number
  createdAt: string
  read: boolean
  type: ActivityNotificationType
  target: User
  metadata?: {
    collaborator_name?: string
    collaborator_id?: number
    title?: string
    content?: string
    [key: string]: string | number | boolean | null | undefined
  }
}

export type NotificationTypes =
  | {
      object: Ticket
      type: ActivityNotificationType.TicketAssigned
    }
  | {
      type: ActivityNotificationType.ClientReply
      object: ConversationTimelineItem
    }
  | {
      type: ActivityNotificationType.StatusPending
      object: Ticket
    }
  | {
      type: ActivityNotificationType.LeaveReadyForApproval
      object: PendingLeaveDay
    }
  | {
      type: ActivityNotificationType.LeaveDenied
      object: PendingLeaveDay
    }
  | {
      type: ActivityNotificationType.LeaveApproved
      object: PendingLeaveDay
    }
  | {
      type: ActivityNotificationType.CompanyMissingDesigner
      object: Company
    }
  | {
      type: ActivityNotificationType.ConversationMention
      object: ConversationTimelineItem
    }
  | {
      type: ActivityNotificationType.CompanyAutoAssignFail
      object: Company
    }
  | {
      type: ActivityNotificationType.DesignerOverweight
      object: Designer
    }
  | {
      type: ActivityNotificationType.DesignerUnderweight
      object: Designer
    }
  | {
      type: ActivityNotificationType.GenericTicketReply
      object: Ticket
    }
  | {
      type: ActivityNotificationType.LeaveDestroy
      object: PendingLeaveDay
    }
  | {
      type: ActivityNotificationType.TicketFeedbackSubmitted
      object: Ticket
    }
  | {
      type: ActivityNotificationType.CollaboratorAnnotation
      object: Ticket
    }

export type ActivityNotification = BaseActivityNotification & NotificationTypes
