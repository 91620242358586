import { deleteApi, patchApi, postApi } from '../api'
import DetailTask, { DetailTaskType } from 'components/pages/request/types/detail-task'
import { AnnotationStatus } from '../annotations/annotations'

function baseUrl(ticketId: number) {
  return `/api/internal/tickets/${ticketId}/detail_tasks`
}

interface CreateDetailTaskParams {
  description: string
  type: DetailTaskType
  taskId?: number
  ticketVersion?: number
}

function createDetailTaskCamelCaseToSnakeCase(param: CreateDetailTaskParams) {
  return {
    description: param.description,
    task_type: param.type,
    task_id: param.taskId,
    ticket_version: param.ticketVersion,
  }
}

export async function createDetailTask(
  ticketId: number,
  createDetailTaskParam: CreateDetailTaskParams,
): Promise<DetailTask> {
  const url = baseUrl(ticketId)
  const { data } = await postApi(url, createDetailTaskCamelCaseToSnakeCase(createDetailTaskParam))
  return data.detailTask
}

export async function createBulkDetailTasks(
  ticketId: number,
  detailTasks: CreateDetailTaskParams[],
): Promise<DetailTask[]> {
  const url = `${baseUrl(ticketId)}/bulk_create`
  const snakeCaseDetailTasks = detailTasks.map(createDetailTaskCamelCaseToSnakeCase)
  const { data } = await postApi(url, { data: snakeCaseDetailTasks })
  return data.detailTasks
}

export async function updateDetailTask(detailTaskId: number, description: string): Promise<DetailTask> {
  const url = `/api/internal/detail_tasks/${detailTaskId}`
  const { data } = await patchApi(url, { description })
  return data
}

export async function updateDetailTaskStatus(detailTaskId: number, status: AnnotationStatus): Promise<DetailTask> {
  const url = `/api/internal/detail_tasks/${detailTaskId}`
  const { data } = await patchApi(url, { status })
  return data
}

export async function deleteDetailTask(detailTaskId: number) {
  const url = `/api/internal/detail_tasks/${detailTaskId}`
  await deleteApi(url)
}
