import { useQuery } from '@tanstack/react-query'
import { ProjectTemplate } from 'interfaces/project-template'
import { requestQuery } from 'lib/api/fetch-api'
import { useEffect } from 'react'
import ProjectTemplatesSkeleton from './project-templates-skeleton'
import ProjectCard from './project-card'
import { toast } from 'sonner'

interface MostUsedProjectTemplatesProps {
  selectProjectTemplate: (projectTemplate: ProjectTemplate) => void
}

export default function MostUsedProjectTemplates({ selectProjectTemplate }: MostUsedProjectTemplatesProps) {
  const {
    data: projectTemplates,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['mostUsedProjectTemplates'],
    queryFn: requestQuery({ endpoint: 'getMostUsedProjectTemplates' }),
    retry: 3,
  })

  useEffect(() => {
    if (error) {
      toast.error('Failed to fetch project templates')
      console.error('Failed to fetch project templates', error)
    }
  }, [error])

  return (
    <>
      {projectTemplates?.data.length > 0 || !isLoading ? (
        <>
          <div className="tw-grid tw-max-w-[1440px] tw-grid-cols-4 tw-gap-4">
            {projectTemplates?.data.map((projectTemplate) => (
              <ProjectCard
                key={projectTemplate.id}
                imgSrc={projectTemplate.imageUrl}
                title={projectTemplate.name}
                subtitle={projectTemplate.description}
                color={projectTemplate?.partner && '#FFFFFF'}
                onClick={() => selectProjectTemplate(projectTemplate)}
              />
            ))}
          </div>
        </>
      ) : (
        <ProjectTemplatesSkeleton />
      )}
    </>
  )
}
