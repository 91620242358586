import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'

interface ModalData {
  title: string
  message: string
}

// Either overwrite the onClick using clickAction or fill out the remaining properties.
interface ActionLinkItemProps {
  label: string
  iconClass: string
  route?: string
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE'
  data?: Record<string, unknown>
  menuActionBtn?: (route: string, data: Record<string, unknown>, method: string, modalData: ModalData) => void
  modalData?: ModalData
  onClick?: () => void
}

export default function ActionLinkItem({
  label,
  iconClass,
  route,
  method,
  data,
  menuActionBtn,
  modalData,
  onClick,
}: ActionLinkItemProps): JSX.Element {
  function onClickAction() {
    if (onClick instanceof Function) {
      onClick()
    } else {
      menuActionBtn(route, data, method, modalData)
    }
  }

  return (
    <IconFlyoutMenu.Button onClick={onClickAction}>
      <i className={iconClass}></i>
      &nbsp;
      {label}
    </IconFlyoutMenu.Button>
  )
}
