import { ReactElement, useState } from 'react'

import { AppSettings, CurrentUser } from 'interfaces/app'

import UserProvider from 'providers/user-provider'
import PageHeader from 'components/elements/page-header'
import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'
import TicketList from './ticket-list'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import TicketRatingReminder from './ticket-rating-reminder'
import SingleQueueProvider, { useSingleQueueContext } from 'providers/single-queue-provider'
import TicketFeedbackProvider from 'providers/ticket-feedback-provider'
import { Ticket, GripHorizontal, FolderOpenIcon } from 'lucide-react'
import ConfirmationModalProvider from 'providers/confirmation-modal-provider'
import AllCompanyTagsProvider from 'providers/all-company-tags-provider'
import SingleQSelectedTagsProvider from 'components/pages/requests/single-q-selected-tags-provider'
import { Toaster } from 'lib/components/toast/toast'
import ProjectList from 'components/elements/projects/project-list'
import QueryClientProvider from 'providers/query-client-provider'
import { useQuery } from '@tanstack/react-query'
import { requestQuery } from 'lib/api/fetch-api'
import { LoadingScreen } from './empty-screens'
import { LinkLikeButton } from 'components/core/button'
const REQUESTS_CSV_URL = '/requests.csv'

interface SingleQueueProps {
  settings: AppSettings
  user: CurrentUser
}

function SingleQueue({ user }: SingleQueueProps): ReactElement {
  const { priorityMode, tickets, fetchTickets, oops } = useSingleQueueContext()
  const [showAllProjects, setShowAllProjects] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: requestQuery({
      endpoint: 'getProjects',
    }),
  })

  const projects = data?.data

  const displayedProjects = showAllProjects ? projects : projects?.slice(0, 4)

  return (
    <TicketFeedbackProvider>
      {user.ticketFeedback && !priorityMode && <TicketRatingReminder />}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between tw-gap-2 tw-pt-4">
            <h4 className="tw-mb-2 tw-flex tw-items-center tw-gap-1">
              <FolderOpenIcon />
              Projects
            </h4>

            {projects?.length > 4 && (
              <LinkLikeButton color="primary" onClick={() => setShowAllProjects((prev) => !prev)}>
                See {showAllProjects ? 'less' : 'all'}
              </LinkLikeButton>
            )}
          </div>
          <ProjectList projects={displayedProjects} onUpdate={() => fetchTickets().catch(oops)} />
        </>
      )}
      <PageHeader
        title={priorityMode ? ' Re-prioritize Requests ' : ' Requests '}
        icon={priorityMode ? <GripHorizontal /> : <Ticket />}
      >
        <ExternalActionLinkItem
          label="Export as CSV"
          iconClass="far fa-regular fa-file-export"
          route={REQUESTS_CSV_URL}
        />
      </PageHeader>
      <SingleQSelectedTagsProvider tickets={tickets}>
        <TicketList />
      </SingleQSelectedTagsProvider>
    </TicketFeedbackProvider>
  )
}

export default function SingleQueuePage({ settings, user }: SingleQueueProps): ReactElement {
  return (
    <>
      <Toaster />
      <UserProvider settings={settings} user={user}>
        <FeatureFlagsProvider>
          <SingleQueueProvider userId={user.id}>
            <ConfirmationModalProvider>
              <AllCompanyTagsProvider>
                <QueryClientProvider>
                  <SingleQueue settings={settings} user={user} />
                </QueryClientProvider>
              </AllCompanyTagsProvider>
            </ConfirmationModalProvider>
          </SingleQueueProvider>
        </FeatureFlagsProvider>
      </UserProvider>
    </>
  )
}
