import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import AssetVideo from '../asset-video'

export interface VideoOptions {
  src: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    video: {
      /**
       * Set a video node
       */
      setVideo: (options: VideoOptions) => ReturnType
    }
  }
}

export default Node.create({
  name: 'video',
  draggable: true,

  inline() {
    return this.options.inline
  },

  group() {
    return this.options.inline ? 'inline' : 'block'
  },

  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      'data-asset-id': {
        default: null,
        parseHTML: (element) => element.getAttribute('data-asset-id'),
        renderHTML: (attributes) => {
          if (attributes['data-asset-id']) {
            return { 'data-asset-id': attributes['data-asset-id'] }
          }
          return {}
        },
      },
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute('src'),
        renderHTML: (attributes) => {
          if (attributes.src) {
            return { src: attributes.src }
          }
          return {}
        },
      },
      HTMLAttributes: {
        default: null,
        renderHTML: (attributes) => {
          return attributes?.HTMLAttributes || {}
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'video[data-asset-id]',
      },
      {
        tag: this.options.allowBase64 ? 'video[src]' : 'video[src]:not([src^="data:"])',
        getAttrs: (element) => {
          // Only match videos that don't have data-asset-id
          if (typeof element === 'string' || element.hasAttribute('data-asset-id')) {
            return false
          }
          return {}
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['video', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(AssetVideo)
  },

  addCommands() {
    return {
      setVideo:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options.src.includes('/')
              ? { src: options.src } // Direct URL case (Filestack)
              : { 'data-asset-id': options.src }, // Asset ID case (Bytescale)
          })
        },
    }
  },
})
