import Modal from 'lib/components/modals/modal'
import Button from 'components/core/button'
import { CustomerState, TicketFile } from 'lib/api/ticket-files/ticket-files'
import { cn } from 'lib/util/cn'
import { DownloadIcon, ExternalLinkIcon, InfoIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { request, requestQuery } from 'lib/api/fetch-api'
import { useUserContext } from 'providers/user-provider'

import { useCreateFileExport } from 'lib/hooks/useCreateFileExport'
import { downloadFile, getFileExtension } from 'lib/util/file/file'
import Tooltip from 'lib/components/tooltip/tooltip'
import { PATHS } from 'lib/constants/paths'
import { fileExportSuccessToast } from 'lib/hooks/useFileExports'

import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { MenuItem } from 'lib/components/menu-item/menu-item'
import { useMediaContext } from './media-provider'

import Link from 'lib/components/link/link'
import Checkbox from 'lib/components/checkbox/checkbox'
import { AUTO_SHOW_FILE_EXPORT_MODAL_KEY } from './state-button'
import FileThumbnail from 'lib/components/file-thumbnail'
import { toast } from 'lib/components/toast/toast'

type FileDownloadModalProps = {
  fileExportModalMode: FileExportModalMode
  setFileExportModalMode: (open: FileExportModalMode) => void
  currentTicketFile: TicketFile
}

export enum FileExportModalMode {
  DOWNLOAD = 'download',
  EXPORT = 'export',
  NONE = null,
}

interface FileFormatProps {
  formats: string[]
  selectedFormats: string[]
  handleFormatClick: (format: string) => void
}

const AdditionalFormats = ({ formats, selectedFormats, handleFormatClick }: FileFormatProps) => {
  return (
    <div className="tw-mt-4 tw-flex tw-flex-row tw-gap-2 tw-text-xs">
      {formats?.map((format) => (
        <FileFormat
          extension={format}
          onClick={() => handleFormatClick(format)}
          selected={selectedFormats.includes(format)}
          key={format}
        />
      ))}
    </div>
  )
}

const FileFormat = ({
  extension,
  selected,
  onClick,
}: {
  extension: string
  selected: boolean
  onClick?: () => void
}) => {
  return (
    <div
      className={cn(
        'transition-all duration-300 tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-gray-200 tw-px-3 tw-py-2 hover:tw-border-cornflower-200 hover:tw-bg-cornflower-50',
        selected && 'tw-border-cornflower-300 tw-bg-cornflower-100',
      )}
      onClick={onClick}
    >
      <img src={`/images/file_placeholders/${extension}.png`} width={24} height={24} /> .{extension}
    </div>
  )
}

const CurrentAsset = () => {
  const { previewUrl, currentTicketFile, isPlaceholder } = useMediaContext()

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      <div className="tw-mb-1 tw-text-xs tw-text-neutral-500">Current asset</div>
      <div className="tw-flex tw-flex-row tw-rounded-lg tw-border tw-border-solid tw-border-neutral-200 tw-bg-neutral-50 tw-p-2">
        <FileThumbnail previewUrl={previewUrl} isPlaceholder={isPlaceholder} currentTicketFile={currentTicketFile} />
      </div>
    </div>
  )
}

const DownloadScreen = ({
  currentTicketFile,
  setFileExportModalMode,
}: {
  currentTicketFile: TicketFile
  setFileExportModalMode: (open: FileExportModalMode) => void
}) => {
  const { user } = useUserContext()

  const { data: fileExportFormats, isLoading } = useQuery({
    queryKey: ['file-export-formats', currentTicketFile?.id],
    queryFn: requestQuery({ endpoint: 'getFileExportsFormats', query: { ticket_file_id: currentTicketFile?.id } }),
  })

  const currentFormat = getFileExtension(currentTicketFile?.name)
  const preSelectedFormats =
    fileExportFormats?.data.filter((format) => user?.preferredFormats?.includes(format) && format !== currentFormat) ||
    []
  preSelectedFormats.push(currentFormat)

  const additionalFormats = fileExportFormats?.data?.filter((format) => format !== currentFormat)

  const [selectedFormats, setSelectedFormats] = useState<string[]>(preSelectedFormats || [])

  const { createFileExport } = useCreateFileExport({
    onSuccess: () => {
      setFileExportModalMode(undefined)
      setSelectedFormats(preSelectedFormats || [])
    },
  })

  useEffect(() => {
    setSelectedFormats(preSelectedFormats || [])
  }, [isLoading])

  const handleFormatClick = (format: string) => {
    if (selectedFormats.includes(format)) {
      setSelectedFormats((prev) => prev.filter((f) => f !== format))
    } else {
      setSelectedFormats((prev) => [...prev, format])
    }
  }

  const handleDownload = () => {
    if (selectedFormats.includes(currentFormat)) {
      downloadFile(currentTicketFile?.name, currentTicketFile?.downloadUrl)
      fileExportSuccessToast({
        name: currentTicketFile?.name,
        link: currentTicketFile?.downloadUrl,
      })
    }

    const formatsToExport = selectedFormats.filter((format) => format !== currentFormat)

    if (formatsToExport.length > 0) {
      createFileExport({
        ticketFileId: currentTicketFile.id,
        selectedFormats: formatsToExport,
      })
    } else {
      setFileExportModalMode(undefined)
    }
  }

  return (
    <>
      <Modal.Header className="tw-font-bold">Download</Modal.Header>
      <Modal.Body className="tw-mt-4 tw-text-neutral-800" setOpen={() => setFileExportModalMode(undefined)} closeButton>
        <CurrentAsset />
        <hr className="tw-mb-2 tw-mt-4" />
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-base tw-font-bold">
            Need a different format?
            <Tooltip
              content={
                <>
                  Available formats depend on the file you&apos;ve selected. Preferred formats are automatically chosen,
                  but you can update these anytime in your{' '}
                  <a
                    className="tw-text-inherit tw-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={PATHS.SETTINGS}
                  >
                    profile settings
                  </a>
                  .
                </>
              }
            >
              <InfoIcon className="tw-h-4 tw-w-4" />
            </Tooltip>
          </div>
          <div className="tw-text-xs tw-text-neutral-500">Choose your format(s) to convert and download.</div>
          {additionalFormats?.length > 0 ? (
            <>
              <AdditionalFormats
                formats={additionalFormats}
                selectedFormats={selectedFormats}
                handleFormatClick={handleFormatClick}
              />
            </>
          ) : (
            <div className="tw-mt-4 tw-text-sm">
              No additional formats are available for conversion, but you can still download the file as is.
            </div>
          )}
          <div className="tw-mt-6 tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-text-sm tw-font-semibold tw-text-neutral-800">
              {selectedFormats?.length} formats selected
            </div>
            <Button disabled={selectedFormats?.length === 0} color="purple" onClick={handleDownload}>
              <DownloadIcon className="tw-mr-2 tw-h-4 tw-w-4" />
              Download
            </Button>
          </div>
        </div>
      </Modal.Body>
    </>
  )
}

// To be used later if/when we have more vendors
// enum ExportVendors {
//   stockpress = 'stockpress',
// }

// const ExportVendor = ({ vendor }: { vendor: ExportVendors }) => {
//   return (
//     <div className="tw-flex tw-w-min tw-cursor-pointer tw-flex-col tw-items-center tw-gap-1">
//       <IconButton color="secondary" size="sm">
//         <img src={`/images/vendors/${vendor}.png`} width={24} height={24} />
//       </IconButton>
//       <div className="tw-text-xs">Stockpress</div>
//     </div>
//   )
// }

const ExportScreen = () => {
  const { setFileExportModalMode, selectedFile, refreshTicketFile } = useMediaContext()

  const [autoShow, setAutoShow] = useState(localStorage.getItem(AUTO_SHOW_FILE_EXPORT_MODAL_KEY) !== 'true')

  const approved = selectedFile?.customerState === CustomerState.Approved

  const { mutate: exportToStockPress } = useMutation({
    mutationFn: () => request({ endpoint: 'exportAssets', body: { ticket_file_ids: [selectedFile.id] } }),
    onSuccess: () => {
      setFileExportModalMode(null)
      refreshTicketFile(selectedFile.id)
      toast.success(
        'Your file has been exported to Stockpress. It may take a few minutes to appear in your Stockpress account.',
      )
    },
    onError: (err) => {
      toast.error('There was an error exporting to Stockpress, please try again')
      console.error(err)
    },
  })

  return (
    <>
      <Modal.Header className="tw-font-bold">Export</Modal.Header>
      <Modal.Body
        className="tw-mt-4 tw-flex tw-flex-col tw-gap-4 tw-text-neutral-800"
        setOpen={() => setFileExportModalMode(null)}
        closeButton
      >
        {!approved && (
          <div className="tw-flex tw-flex-row tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-bg-skyblue-50 tw-p-4 tw-text-sky-700">
            <InfoIcon className="tw-mt-1 tw-h-4 tw-w-4 tw-flex-shrink-0" />
            Only assets marked as approved in the Design Pickle platform are eligible to be sent to Stockpress. To
            export directly to Stockpress, mark your asset as approved.
          </div>
        )}
        <CurrentAsset />
        <div className="tw-flex tw-flex-row tw-gap-2 tw-rounded tw-p-3">
          <div className="tw-rounded-lg tw-bg-neutral-100 tw-p-2">
            <img src={`/images/vendors/stockpress.png`} width={50} height={50} />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <div className="tw-text-md tw-font-semibold">Stockpress</div>
            <div>Simple Digital Asset Management for teams of all sizes</div>
            <Link target="_blank" rel="noopener noreferrer" href="https://stockpress.co/" externalIcon>
              See how our partners at Stockpress help manage assets
            </Link>
          </div>
        </div>

        <Checkbox
          label="Automatically show this modal when a file is approved"
          onClick={(value) => {
            setAutoShow(value.target.checked)
            localStorage.setItem(AUTO_SHOW_FILE_EXPORT_MODAL_KEY, value.target.checked ? 'false' : 'true')
          }}
          isChecked={autoShow}
          disabled={!approved}
        />

        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-text-sm tw-font-semibold">1 asset selected</div>
          <Button color="purple" onClick={() => exportToStockPress()} disabled={!approved}>
            Export to Stockpress
          </Button>
        </div>
      </Modal.Body>
    </>
  )
}

const FileDownloadModal = ({
  fileExportModalMode,
  setFileExportModalMode,
  currentTicketFile,
}: FileDownloadModalProps) => {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  return (
    <Modal
      open={!!fileExportModalMode}
      size="md"
      setOpen={() => setFileExportModalMode(FileExportModalMode.NONE)}
      className={cn('tw-p-4', isFeatureFlagEnabled('stockpress') && 'tw-max-w-2xl !tw-p-0')}
    >
      {isFeatureFlagEnabled('stockpress') ? (
        <div className="tw-flex">
          <div className="tw-flex tw-flex-col tw-gap-2 tw-border tw-border-b-0 tw-border-l-0 tw-border-t-0 tw-border-solid tw-border-gray-200 tw-px-2 tw-py-6">
            <MenuItem
              item={{ label: 'Download', Icon: DownloadIcon }}
              selected={fileExportModalMode === FileExportModalMode.DOWNLOAD}
              onClick={() => setFileExportModalMode(FileExportModalMode.DOWNLOAD)}
              cmp="div"
            />
            <MenuItem
              intent="rowMobileCol"
              item={{
                label: 'Export',
                Icon: ExternalLinkIcon,
                RightIcon: () => {
                  return (
                    <div
                      className={cn(
                        'tw-ml-auto tw-rounded tw-bg-cornflower-100 tw-font-semibold tw-text-cornflower-800',
                        'tw-absolute tw-right-0 tw-top-0 tw-flex tw-min-h-3 tw-min-w-3 tw-rounded-full tw-text-[0px] tw-leading-[0px] md:tw-static md:tw-rounded md:tw-bg-cornflower-100 md:tw-px-2 md:tw-py-1 md:tw-text-xs md:tw-text-cornflower-800',
                      )}
                    >
                      New
                    </div>
                  )
                },
              }}
              onClick={() => setFileExportModalMode(FileExportModalMode.EXPORT)}
              selected={fileExportModalMode === FileExportModalMode.EXPORT}
            />
          </div>
          <div className="tw-w-full tw-p-4">
            {fileExportModalMode === FileExportModalMode.DOWNLOAD ? (
              <DownloadScreen currentTicketFile={currentTicketFile} setFileExportModalMode={setFileExportModalMode} />
            ) : (
              <ExportScreen />
            )}
          </div>
        </div>
      ) : (
        <DownloadScreen currentTicketFile={currentTicketFile} setFileExportModalMode={setFileExportModalMode} />
      )}
    </Modal>
  )
}

export default FileDownloadModal
