import { startCase } from 'lodash'

export function snakeCaseKeys(obj, prefix = '', suffix = '') {
  const entries = Object.entries(obj)

  return entries.reduce((accumulator, [key, value]) => {
    const k = camelToSnakeCase(key)
    accumulator[`${prefix}${k}${suffix}`] = value
    return accumulator
  }, {})
}

export function camelToSnakeCase(key: string): string {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase()
}

export function camelCaseKeys(obj, prefix = '', suffix = '') {
  const entries = Object.entries(obj)

  return entries.reduce((accumulator, [key, value]) => {
    if (value !== null) {
      const k = snakeToCamelCase(key)
      accumulator[`${prefix}${k}${suffix}`] = value
    }
    return accumulator
  }, {})
}

export function camelCaseKeysDeep(obj, prefix = '', suffix = '') {
  const entries = Object.entries(obj)

  return entries.reduce((accumulator, [key, value]) => {
    if (value !== null) {
      const k = snakeToCamelCase(key)
      if (value instanceof Array) {
        accumulator[`${prefix}${k}${suffix}`] = value.map((v) => camelCaseKeysDeep(v))
      } else if (typeof value === 'object') {
        accumulator[`${prefix}${k}${suffix}`] = camelCaseKeysDeep(value)
      } else {
        accumulator[`${prefix}${k}${suffix}`] = value
      }
    }
    return accumulator
  }, {})
}

export function snakeToCamelCase(key: string): string {
  return key.replace(/(_\w)/g, (m) => m[1].toUpperCase())
}

export function snakeToTitleCase(key: string): string {
  return startCase(key.replaceAll('_', ' '))
}

export function hasOnlyFalsyValues(obj: object | []): boolean {
  return Object.values(obj).every((value) => !value)
}

export function hasOnlyEmptyValues(obj, includeZero = true): boolean {
  if (!includeZero && Object.values(obj).filter((value) => value === 0).length > 0) return false
  return Object.values(obj).every((value) => !value || (value instanceof Array && !value.length))
}
