export default function ProjectTemplatesSkeleton({ count = 4 }: { count?: number }) {
  return (
    <div className={`tw-grid tw-gap-4 tw-grid-cols-${count}`}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="tw-aspect-square tw-w-full tw-animate-pulse tw-overflow-hidden tw-rounded-lg tw-bg-neutral-200"
        />
      ))}
    </div>
  )
}
