import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { useMediaContext } from './media-provider'
import { CustomerState } from 'lib/api/ticket-files/ticket-files'
import Button from 'components/core/button'
import { request } from 'lib/api/fetch-api'
import { toast } from 'lib/components/toast/toast'
import { CheckCircle2Icon } from 'lucide-react'
import { useMutation } from '@tanstack/react-query'
import { FileExportModalMode } from './file-download-modal'

export const AUTO_SHOW_FILE_EXPORT_MODAL_KEY = 'disableAutoShowFileExportModal'

const StateButton = () => {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { selectedFile, refreshTicketFile, setFileExportModalMode } = useMediaContext()

  const { mutate: updateTicketFile } = useMutation({
    mutationFn: ({
      ticketId,
      ticketFileId,
      customerState,
    }: {
      ticketId: number
      ticketFileId: number
      customerState: CustomerState
    }) =>
      request({
        endpoint: 'updateTicketFile',
        query: { ticket_id: ticketId, ticket_file_id: ticketFileId, customer_state: customerState },
      }),
    onSuccess: () => {
      refreshTicketFile(selectedFile.id)
    },
    onError: () => {
      toast.error('There was an error marking this file as approved, please try again')
    },
  })

  return (
    isFeatureFlagEnabled('stockpress') && (
      <Button
        color={selectedFile.customerState === CustomerState.Approved ? 'softGreen' : 'neutral'}
        className="tw-truncate"
        onClick={() => {
          const newState =
            selectedFile.customerState === CustomerState.Approved ? CustomerState.Concept : CustomerState.Approved

          updateTicketFile({
            ticketId: selectedFile.ticketId,
            ticketFileId: selectedFile.id,
            customerState: newState,
          })

          const disableAutoShowFileExportModal = localStorage.getItem(AUTO_SHOW_FILE_EXPORT_MODAL_KEY)

          if (disableAutoShowFileExportModal !== 'true' && newState === CustomerState.Approved) {
            setFileExportModalMode(FileExportModalMode.EXPORT)
          }
        }}
      >
        <CheckCircle2Icon className="tw-mr-1 tw-h-4 tw-w-4" />
        {selectedFile.customerState === CustomerState.Approved ? 'Approved' : 'Mark approved'}
      </Button>
    )
  )
}

export default StateButton
