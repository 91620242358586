import { Partner } from 'interfaces/partner'
import Link from 'lib/components/link/link'

interface ProjectsPartnerPreviewProps {
  partner: Partner
}

export default function ProjectsPartnerPreview({ partner }: ProjectsPartnerPreviewProps) {
  return (
    <div className="tw-border-1 tw-rounded-lg tw-border tw-border-solid tw-border-neutral-200 tw-p-4">
      <div className="tw-flex tw-items-center tw-gap-4">
        <img src={partner.logo} alt={partner.name} className="tw-h-20 tw-w-20 tw-rounded-md" />
        <div className="tw-flex tw-flex-col tw-gap-1">
          <div className="tw-text-sm tw-text-neutral-500">
            {partner.label}{' '}
            <Link href={partner.link} className="tw-text-neutral-500 tw-underline" target="_blank">
              {partner.name}
            </Link>
          </div>
          <h5 className="tw-m-0 tw-font-bold tw-text-neutral-800">{partner.header}</h5>
          <div className="tw-m-0 tw-text-neutral-800">{partner.body}</div>
          <Link href={partner.link} target="_blank" externalIcon>
            {partner.linkText}
          </Link>
        </div>
      </div>
    </div>
  )
}
