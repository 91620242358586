import BulkActionsBar from 'components/elements/bulk-actions-bar'
import BulkActionsButtons from '../requests/bulk-actions-buttons'
import { flexRender } from '@tanstack/react-table'
import Pagination from 'components/elements/pagination'
import { useTable } from './use-table'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { ArrowDownIcon, ArrowDownUpIcon, ArrowUpIcon } from 'lucide-react'
import { cn } from 'lib/util/cn'

const sortIcons = {
  asc: <ArrowUpIcon className="lu-sm tw-cursor-pointer tw-text-neutral-500" />,
  desc: <ArrowDownIcon className="lu-sm tw-cursor-pointer tw-text-neutral-500" />,
  false: <ArrowDownUpIcon className="lu-sm tw-cursor-pointer tw-text-neutral-500" />,
}

const classNames = {
  tr: 'tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-t-2 tw-border-b-2 tw-border-neutral-200',
  td: 'tw-p-4',
}

const SkeletonLoader = ({ cols }: { cols: number }) => (
  <tr className={classNames.tr} data-testid="skeleton-loader">
    <td className={cn(classNames.td)}>
      <Skeleton className="tw-mx-auto tw-h-4 tw-w-4 tw-bg-neutral-200" />
    </td>
    <td className={cn(classNames.td, 'tw-flex tw-w-full tw-items-center tw-gap-4')}>
      <Skeleton className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-neutral-200" />
      <div className="tw-flex tw-w-2/3 tw-flex-col tw-gap-2">
        <Skeleton className="tw-mt-2 tw-h-4 tw-rounded tw-bg-neutral-200" />
        <Skeleton className="tw-h-4 tw-rounded tw-bg-neutral-200" />
      </div>
    </td>
    {Array.from({ length: cols - 2 }).map((_, index) => (
      <td className={classNames.td} key={index}>
        <Skeleton className="tw-h-4 tw-rounded tw-bg-neutral-200" />
      </td>
    ))}
    <td className={classNames.td}>
      <Skeleton className="tw-h-3 tw-w-1 tw-bg-neutral-200" />
    </td>
  </tr>
)

export default function SearchRequestsTable() {
  const { pagination, query, count, table } = useTable()
  const { menuButtonActionWithCallbacks } = useConfirmationModalContext()

  return (
    <>
      <table className="tw-my-2 tw-w-full tw-bg-white">
        <>
          <thead className="tw-border-b-2 tw-border-l-0 tw-border-r-0 tw-border-t tw-border-solid tw-border-neutral-200 tw-bg-neutral-50">
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr className="" key={headerGroup.id}>
                  {table.getSelectedRowModel().rows.length > 0 ? (
                    <th scope="col" colSpan={12} className="tw-pl-4 tw-text-left tw-text-xs tw-tracking-wider">
                      <BulkActionsBar
                        totalSelected={table.getSelectedRowModel().rows.length}
                        bulkAllToggle={table.getToggleAllRowsSelectedHandler()}
                      >
                        <BulkActionsButtons
                          status={table.getState().globalFilter}
                          bulkActionIds={table
                            .getSelectedRowModel()
                            .rows.map((row) => ({ ticketId: row.original.ticket_id }))}
                          menuActionBtn={menuButtonActionWithCallbacks(query.refetch, () => {
                            table.resetRowSelection(null)
                            table.setGlobalFilter('')
                          })}
                          onTagUpdate={() => {
                            table.resetRowSelection(null)
                            query.refetch()
                          }}
                          editTags={false}
                        />
                      </BulkActionsBar>
                    </th>
                  ) : (
                    headerGroup.headers.map((header) => {
                      return (
                        <th
                          className="tw-p-2 tw-text-xs tw-font-medium tw-uppercase tw-text-black"
                          key={header.id}
                          id={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <div className="tw-flex tw-items-center tw-gap-2">
                            {header.column.getCanSort() && sortIcons[(header.column.getIsSorted() as string) ?? null]}
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        </th>
                      )
                    })
                  )}
                </tr>
              )
            })}
          </thead>
          <tbody>
            {query.isFetching
              ? Array.from({ length: count < 20 ? count : 20 }).map((_, index) => (
                  <SkeletonLoader key={index} cols={table.getVisibleFlatColumns().length - 1} />
                ))
              : table.getRowModel().rows.map((row) => {
                  return (
                    <tr className={classNames.tr} key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            className={cn(
                              classNames.td,
                              (cell.column.columnDef.meta as { tdClassName?: string })?.tdClassName,
                            )}
                            key={cell.id}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
          </tbody>
        </>
      </table>
      <Pagination
        pageCount={query.data?.meta?.total / pagination.pageSize}
        onPageChange={({ selected }) => {
          table.setPageIndex(selected + 1)
        }}
        forcePage={pagination.pageIndex - 1}
      />
    </>
  )
}
