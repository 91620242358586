import { useMemo } from 'react'
import { DesignRequestStatus, EditMode } from 'lib/api/tickets/tickets'
import MediaList from './media-list'
import MediaUploaders from './media-uploaders'
import { useMediaContext } from './media-provider'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import { generateRangeTo } from 'lib/util/range'
import { DownloadAllAssetsButton } from 'lib/components/download-assets-button/download-assets-button'
import IconButton from 'lib/components/buttons/icon-button'
import { FileImageIcon } from 'lucide-react'
import { cn } from 'lib/util/cn'
import Tooltip from 'lib/components/tooltip/tooltip'
import { useThreeResizableContext } from 'lib/components/resizable/resizable'

const classNames = {
  aside: 'tw-w-full tw-relative tw-flex tw-h-full tw-flex-col tw-bg-white',
  header: 'tw-flex-none tw-h-min tw-m-4 tw-mt-6',
  h2: 'tw-text-base tw-mb-1 -tw-mt-1',
  footer: 'tw-flex-none tw-mx-auto tw-h-min tw-p-5 tw-sticky tw-bottom-0 tw-bg-white tw-max-w-60',
}

export default function MediaLeftSidebar() {
  const { editMode, files, selectedVersion, setSelectedVersion, showRevisions, ticket } = useMediaContext()

  const { leftCollapsed, toggleLeftCollapsed } = useThreeResizableContext()

  const showDownloadButton = useMemo(() => {
    const visibleFiles = selectedVersion ? files.filter((file) => file.ticketVersion === selectedVersion) : files
    return visibleFiles.findIndex((ticket) => !ticket.preview) !== -1
  }, [files, selectedVersion])

  const versions = useMemo(() => {
    return generateRangeTo(ticket?.currentVersion)
  }, [ticket?.currentVersion])

  function handleVersionChange(version: number) {
    setSelectedVersion(version)
  }

  function showMediaUploaders() {
    const isSelectedVersionDelivered = selectedVersion === ticket?.currentVersion
    const isEditModeProcessing = editMode === EditMode.processing
    const isEditModeComplete = editMode === EditMode.complete
    const isEditModeDraft = editMode === EditMode.draft
    const needsAttention = ticket.status === DesignRequestStatus.attention

    return (
      (isSelectedVersionDelivered && !isEditModeProcessing && !isEditModeComplete) || isEditModeDraft || needsAttention
    )
  }

  return (
    <aside className={cn('tw-relative tw-flex tw-h-full tw-flex-row tw-border-0 tw-bg-white')}>
      <div className="tw-sticky tw-left-0 tw-top-0 tw-z-10 tw-flex tw-h-full tw-flex-col tw-gap-2 tw-border-0 tw-border-r tw-border-solid tw-border-gray-200 tw-p-3">
        <Tooltip direction="up" content="Assets">
          <IconButton
            color={leftCollapsed ? 'secondary' : 'primary'}
            invert={!leftCollapsed}
            selected={!leftCollapsed}
            onClick={toggleLeftCollapsed}
          >
            <FileImageIcon className="lu-light lu-md" />
          </IconButton>
        </Tooltip>
      </div>
      <div className={cn(classNames.aside)}>
        <header className={classNames.header}>
          <h2 className={classNames.h2}>{showRevisions ? 'Delivered Assets' : 'Media'}</h2>
          {showRevisions && !!files.length ? (
            <>
              <TicketVersionSelectBox
                versions={versions}
                selectedVersion={selectedVersion}
                setSelectedVersion={handleVersionChange}
              />
              <hr className="tw-bg-neutral-400" />
              {showDownloadButton && <DownloadAllAssetsButton />}
            </>
          ) : (
            <br />
          )}
          {!showRevisions && (
            <>
              <p>
                Upload images and/or files and use our annotation tool to provide contextual directions for your
                designer.
              </p>
              <hr className="tw-bg-neutral-400" />
            </>
          )}
        </header>
        <div className="tw-mb-4 tw-flex-auto tw-overflow-y-auto tw-bg-white">
          <div className="tw-mx-2 tw-h-full tw-min-h-min tw-rounded-lg tw-bg-gray-100 tw-py-3">
            <MediaList />
          </div>
        </div>
        {showMediaUploaders() && (
          <footer className={classNames.footer}>
            <p className="tw-mb-2 tw-text-sm tw-font-semibold tw-text-neutral-800">Need to add additional media?</p>
            <MediaUploaders />
          </footer>
        )}
      </div>
    </aside>
  )
}
