import { ChangeEvent, Dispatch, SetStateAction, useCallback, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GenAiRequestTypes } from 'lib/api/gen-ai/gen-ai-requests'
import Button from 'components/core/button'
import { BetaBadge } from 'lib/components/badge/badge'
import IconButton from 'lib/components/buttons/icon-button'
import ExternalLink from 'lib/components/links/external-link'
import Modal from 'lib/components/modals/modal'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'

import { useGenAiContext } from '../../providers/gen-ai-provider'
import ThumbnailWithSpinner from '../../thumbnail-with-spinner/thumbnail-with-spinner'
import { FileUploaderResult, InlineFileUploader } from 'lib/components/file-uploader/file-uploader'

const classNames = {
  buttons: {
    cancel: `
        tw-bg-peppercorn-50
        hover:tw-bg-peppercorn-100
        tw-mt-3
        tw-inline-flex
        tw-w-full
        tw-justify-center
        tw-px-4
        tw-py-2
        tw-border-0
        sm:tw-mt-0
        sm:tw-w-auto s
        m:tw-text-sm"
      `,
  },
}
interface File {
  url: string
  filename: string
}

interface DirectionTxt2ImgModalProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function CreateAssistGenerateModal({ open, setOpen }: DirectionTxt2ImgModalProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [inputValue, setInputValue] = useState<string>('')
  const [inputType, setInputType] = useState<GenAiRequestTypes>(GenAiRequestTypes.TXT2IMG)

  const { createCreateAssist } = useGenAiContext()

  const generateDisabled = inputValue.length < 3 || (inputType === GenAiRequestTypes.IMG2IMG && !uploadedFile)

  const onBytescaleUpload = useCallback(async (files: FileUploaderResult[]) => {
    const genaiReadyUrl = `https://upcdn.io/FW25cKr/genai-1024w${files[0].filePath}`
    if (files.length) {
      setUploadedFile({ filename: files[0].originalFile.originalFileName, url: genaiReadyUrl })
    }
  }, [])

  function resetModalToDefault() {
    setOpen(false)
    setInputValue('')
    setUploadedFile(null)
    setInputType(GenAiRequestTypes.TXT2IMG)
  }

  async function generate() {
    try {
      await createCreateAssist(
        inputValue,
        inputType,
        inputType === GenAiRequestTypes.IMG2IMG ? uploadedFile?.url : undefined,
      )
      resetModalToDefault()
    } catch (error) {
      console.error('Creating Create Assist Request Error', error)
      alert('There was an error creating your request. Please try again.')
    }
  }

  function handleCancel() {
    resetModalToDefault()
  }

  function handleInputChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(event.target.value)
  }

  function handleAfterEnter() {
    textareaRef.current.focus()
  }

  return (
    <Modal open={open} setOpen={setOpen} size="lg" afterEnter={handleAfterEnter}>
      <Modal.Header>
        <span className="tw-flex tw-items-center tw-gap-1 tw-text-lg tw-font-bold">
          <FontAwesomeIcon icon={['far', 'magic']} />
          <span>
            Create Assist <BetaBadge />
          </span>
        </span>
      </Modal.Header>

      <Modal.Body setOpen={setOpen} closeButton>
        <p>
          <>
            Immediately generate images to visualize your ideas for your designer. You can even ask Create Assist to
            modify an image you upload!
          </>
          <ExternalLink className="tw-ml-2" href="https://help.designpickle.com/en/articles/8304523">
            Learn More
          </ExternalLink>
        </p>
        <p className="tw-font-bold">Give us some details about what you want to create</p>

        <div className="tw-flex tw-flex-wrap 2xl:tw-flex-nowrap">
          <div className="tw-w-1/3">
            <h5>Select an option to get started:</h5>
            <div>
              <input
                type="radio"
                name="text2image"
                value="text2image"
                id="text2image"
                checked={inputType === GenAiRequestTypes.TXT2IMG}
                onChange={() => setInputType(GenAiRequestTypes.TXT2IMG)}
              />
              <label htmlFor="text2image" className="tw-pl-2">
                Generate an image using text
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="image2image"
                value="image2image"
                id="image2image"
                checked={inputType === GenAiRequestTypes.IMG2IMG}
                onChange={() => setInputType(GenAiRequestTypes.IMG2IMG)}
              />
              <label htmlFor="image2image" className="tw-pl-2">
                Generate using text and an image
              </label>
            </div>
          </div>
          <div className="tw-w-full">
            <Textarea
              ref={textareaRef}
              name="text-input"
              className="tw-w-full"
              placeholder="e.g. pickle robot"
              value={inputValue}
              onChange={handleInputChange}
            />
            <TextCounter className="tw-text-right" value={inputValue} />
          </div>
        </div>

        {inputType === GenAiRequestTypes.TXT2IMG && (
          <div className="tw-my-20 tw-text-center">
            <img
              alt="Pickle Terminator Robot"
              className="tw-mx-auto tw-mb-6"
              height="333"
              src="/images/pickle_terminator_robot.svg"
            />
          </div>
        )}

        <div className="tw-my-6">
          {inputType === GenAiRequestTypes.IMG2IMG && !uploadedFile && (
            <InlineFileUploader
              options={{ maxFileCount: 1 }}
              onComplete={onBytescaleUpload}
              className="!tw-max-w-full"
            />
          )}
          {inputType === GenAiRequestTypes.IMG2IMG && uploadedFile && (
            <div className="tw-h-full tw-w-full tw-rounded-md tw-bg-neutral-100 tw-p-4">
              <h5 className="tw-mt-0">Uploaded source file</h5>
              <div className="tw-flex tw-items-center tw-gap-6 tw-border-2 tw-border-dashed tw-border-neutral-200 tw-p-2">
                <ThumbnailWithSpinner className="tw-h-24 tw-w-auto" alt="Uploaded Image" src={uploadedFile.url} />
                <p className="tw-m-0 tw-max-w-2xl tw-break-all">{uploadedFile.filename}</p>
                <IconButton
                  icon={['far', 'times']}
                  dataTestid="remove-uploaded-file-button"
                  color="danger"
                  invert
                  onClick={() => setUploadedFile(null)}
                  size="xs"
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="tw-mt-4 tw-flex tw-justify-end tw-gap-2">
          <Button type="button" color="neutralGray" className={classNames.buttons.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="purple" type="button" onClick={generate} disabled={generateDisabled}>
            Generate
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
