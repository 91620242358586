import SortableTableHeader from 'components/core/sortable-table-header'
import TableHead from 'components/core/table-head'
import { DroppableTableRow } from 'components/core/table-row'
import BulkActionsBar from 'components/elements/bulk-actions-bar'
import BulkActionsButtons from 'components/pages/requests/bulk-actions-buttons'
import { useSingleQSelectedTagsContext } from 'components/pages/requests/single-q-selected-tags-provider'
import { Ticket } from 'interfaces/ticket'
import Tooltip from 'lib/components/tooltip/tooltip'
import { Info } from 'lucide-react'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import { BulkActionId, useSingleQueueContext } from 'providers/single-queue-provider'
import { ReactElement, ReactNode } from 'react'
import { DroppableProvided } from 'react-beautiful-dnd'

interface HeaderProps {
  colSpan?: number
  column: string
  isSortable?: boolean
  name: ReactNode
}

interface TableHeaderProps {
  droppableProvided: DroppableProvided
}

function TableHeader({ droppableProvided }: TableHeaderProps): ReactElement {
  const { activeTab, priorityModeEnabled, sortBy, sortColumn, sortDirection } = useSingleQueueContext()

  const lastUpdatedColumn = activeTab === 'draft' ? 'updated_at' : 'last_updated_for_user'

  const DynamicTableHeader = ({ colSpan = 1, column, name, isSortable = true }: HeaderProps): ReactElement => (
    <SortableTableHeader
      colSpan={colSpan}
      column={column}
      className="tw-whitespace-nowrap tw-px-6 tw-py-3 tw-pl-4 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tw-tracking-wider tw-text-gray-500 hover:tw-bg-peppercorn-50 hover:tw-text-peppercorn-900"
      sortedClassName="tw-bg-peppercorn-50  tw-text-peppercorn-900"
      onClick={sortBy}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      isSortable={isSortable}
    >
      {name}
    </SortableTableHeader>
  )

  const NonSortableColumn = () => (
    <th
      scope="col"
      className="tw-px-6 tw-py-3 tw-pl-4 tw-pr-0.5 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tw-tracking-wider tw-text-gray-500"
    />
  )

  return (
    <TableHead>
      <DroppableTableRow innerRef={droppableProvided.innerRef} droppableProvided={droppableProvided}>
        {activeTab === 'active' && !priorityModeEnabled && (
          <>
            <NonSortableColumn />
            <NonSortableColumn />
          </>
        )}
        {activeTab === 'draft' && (
          <>
            <NonSortableColumn />
            <NonSortableColumn />
          </>
        )}

        {activeTab === 'archived' && (
          <>
            <NonSortableColumn />
            <NonSortableColumn />
            {/*<NonSortableColumn />*/}
          </>
        )}

        {activeTab === 'active' && (
          <DynamicTableHeader
            column="position"
            name={priorityModeEnabled ? <PriorityTableHeader /> : '#'}
            colSpan={priorityModeEnabled ? 3 : 1}
          />
        )}
        <DynamicTableHeader column="subject" name="Title" />
        <DynamicTableHeader column="friendly_status_name" name="Status" />
        <DynamicTableHeader column="project" name="Project" />
        <DynamicTableHeader column="queue_name" name="Request Type" />
        {activeTab === 'active' && <DynamicTableHeader column="designer_id" name="Designer" />}
        {activeTab === 'archived' && <DynamicTableHeader column="overall_rating" name="Rating" />}
        <DynamicTableHeader column="user_id" name="Submitted by" />
        {activeTab !== 'draft' && <DynamicTableHeader column={lastUpdatedColumn} name="Last Updated" />}

        <th scope="col" className="tw-relative tw-w-12 tw-px-6 tw-py-3 tw-pl-4 tw-pr-0.5"></th>
      </DroppableTableRow>
    </TableHead>
  )
}
function getBulkId(ticket: Ticket, activeTab: string): BulkActionId {
  if (activeTab === 'draft') {
    return { ticketId: ticket.ticketId, requestId: ticket.id }
  }

  return { ticketId: ticket.id, requestId: ticket.requestId }
}

function TableHeaderActions({ droppableProvided }: TableHeaderProps) {
  const { bulkActionIds, bulkStateSelected, setBulkActionIds, setBulkStateSelected, tickets, fetchTickets, activeTab } =
    useSingleQueueContext()
  const { selectedTags } = useSingleQSelectedTagsContext()

  const { menuButtonActionWithCallbacks } = useConfirmationModalContext()

  const bulkAllToggle = () => {
    if (bulkActionIds.length === 1) {
      const ticketIdsToAdd: BulkActionId[] = tickets
        .filter(({ friendlyStatusName }) => friendlyStatusName === bulkStateSelected)
        .map((ticket) => getBulkId(ticket, activeTab))
      setBulkActionIds([...ticketIdsToAdd])
    } else {
      setBulkActionIds([])
      setBulkStateSelected(null)
    }
  }

  return (
    <TableHead>
      <DroppableTableRow innerRef={droppableProvided.innerRef} droppableProvided={droppableProvided}>
        <th scope="col" className="tw-px-6 tw-pr-0.5 tw-text-left tw-text-xs"></th>
        <th scope="col" colSpan={9} className="tw-pr-0.5 tw-text-left tw-text-xs tw-tracking-wider">
          <BulkActionsBar totalSelected={bulkActionIds.length} bulkAllToggle={bulkAllToggle}>
            <BulkActionsButtons
              status={bulkStateSelected}
              bulkActionIds={bulkActionIds}
              menuActionBtn={menuButtonActionWithCallbacks(fetchTickets, () => {
                setBulkActionIds([])
                setBulkStateSelected(null)
              })}
              selectedTags={selectedTags}
              onTagUpdate={async () => await fetchTickets()}
            />
          </BulkActionsBar>
        </th>
      </DroppableTableRow>
    </TableHead>
  )
}

function PriorityTableHeader(): ReactElement {
  return (
    <>
      Priority
      <span className="tw-ml-1">
        <Tooltip content="Shifting the priority of the requests will change the order they get worked on.">
          <Info className="lu-sm" />
        </Tooltip>
      </span>
    </>
  )
}

export default function TableHeaderSection({ droppableProvided }: TableHeaderProps): ReactElement {
  const { bulkActionIds } = useSingleQueueContext()

  return bulkActionIds.length > 0 ? (
    <TableHeaderActions droppableProvided={droppableProvided} />
  ) : (
    <TableHeader droppableProvided={droppableProvided} />
  )
}
