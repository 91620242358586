import { ShareIcon } from 'lucide-react'
import { useUserContext, AuthRoute } from 'providers/user-provider'
import { ReactNode } from 'react'

export default function AuthBranding({ children }: { children?: ReactNode }) {
  const { settings, auth } = useUserContext()
  const customBranding = settings?.customBranding

  const showLogo = (customBranding && customBranding?.logo) || !customBranding

  const defaultLogoSrc = '/images/states/not-authenticated.svg'
  const routeLogoMap = {
    [AuthRoute.FORGOTPASSWORD]: '/images/states/reset-password.svg',
    [AuthRoute.RESETPASSWORD]: '/images/states/reset-password.svg',
  }

  const logoSrc = customBranding?.logo || routeLogoMap[auth.authRoute] || defaultLogoSrc

  const showPreviewColumn =
    settings?.previewDetails?.imageUrl &&
    settings?.previewDetails?.invitedByName &&
    settings?.previewDetails?.requestName

  return (
    <div className="tw-mx-auto tw-grid tw-max-w-screen-lg tw-auto-cols-fr tw-justify-center tw-gap-6 tw-p-4 lg:tw-min-h-screen lg:tw-grid-flow-col lg:tw-items-center lg:tw-gap-16">
      {!customBranding && (
        <div className="tw-absolute tw-right-4 tw-top-4 tw-inline-block">
          Existing Design Pickle customer? <a href="/users/sign_in">Log in here</a>
        </div>
      )}
      {showPreviewColumn && (
        <div className="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-gap-4 lg:tw-max-w-lg lg:tw-gap-6">
          <span className="tw-flex tw-items-center tw-gap-4 tw-text-pretty tw-rounded-lg tw-border tw-border-solid tw-bg-neutral-100 tw-px-6 tw-py-4 tw-text-base tw-leading-snug tw-text-neutral-700">
            <ShareIcon />
            <span>
              {settings?.previewDetails?.invitedByName} shared &quot;{settings?.previewDetails?.requestName}&quot; with
              you. Log in or sign up to start Collaborating.
            </span>
          </span>
          <div className="tw-relative tw-flex tw-aspect-square tw-w-full tw-max-w-40 tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl tw-border tw-border-solid tw-border-gray-200 tw-shadow-xl lg:tw-max-w-lg">
            <img
              src={settings?.previewDetails?.imageUrl}
              alt="Collaborator Preview"
              className="tw-absolute tw-left-0 tw-top-0 tw-block tw-h-full tw-w-full tw-object-cover"
            />
          </div>
        </div>
      )}
      <div className="tw-mx-auto">
        <div className={'tw-mx-auto tw-mb-6 tw-block tw-h-auto tw-w-full tw-max-w-48'}>
          {showLogo && <img src={logoSrc} alt="Authentication Logo" className="tw-block tw-h-auto tw-w-full" />}
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}
