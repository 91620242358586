import Button from 'components/core/button'
import { submitTicket } from 'lib/api/tickets/tickets'
import Modal from 'lib/components/modals/modal'
import { CircleHelpIcon, LoaderCircleIcon } from 'lucide-react'
import { Dispatch, SetStateAction, useState } from 'react'
import { toast } from 'sonner'

type ConfirmNavigatePendingUpdatesModal = {
  ticketId: number
  isOpen: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
}

const ConfirmNavigatePendingUpdatesModal = ({ ticketId, isOpen, setOpen }: ConfirmNavigatePendingUpdatesModal) => {
  const [loading, setLoading] = useState(false)

  const onConfirm = async () => {
    setLoading(true)
    try {
      await submitTicket(ticketId)

      window.location.assign('/requests')
    } catch (e) {
      console.error('Error submitting ticket', e)
      toast.error('Oops! Something went wrong sending updates. Please try again')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal open={isOpen} size="md" clickOutsideToClose>
      <Modal.Body closeButton setOpen={setOpen}>
        <div className="tw-flex tw-items-start tw-justify-start">
          <div className="tw-mr-4">
            <div className="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-cornflower-100 tw-text-cornflower-400">
              <CircleHelpIcon className="lu-md" />
            </div>
          </div>
          <div>
            <h3>You have pending updates</h3>
            <p>
              You have pending updates that will not be sent to your designer at this time. Navigating away from this
              page will delay sharing your directions, annotations, files, conversations, or other changes.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-mt-4 tw-flex tw-justify-end tw-gap-2">
          <Button color="lightGray" onClick={() => window.location.assign('/requests')}>
            Navigate without sending updates
          </Button>
          <Button color="purple" onClick={onConfirm}>
            {loading ? <LoaderCircleIcon className="tw-animate-spin" /> : 'Send updates to my designer'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmNavigatePendingUpdatesModal
