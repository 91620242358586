import { SubscriptionTypeIcon } from 'components/elements/deliverable-type'
import Badge from 'lib/components/badge/badge'
import Checkbox from 'lib/components/checkbox/checkbox'
import { cn } from 'lib/util/cn'
import { LockIcon } from 'lucide-react'
import { Dispatch, SetStateAction, useState } from 'react'
import { useSkillsContext } from '../request/skills/skills-provider'
import { SkillWithCategoryFields } from 'interfaces/project-template'
import SearchInput from 'lib/components/search-input/search-input'

const classNames = {
  img: 'tw-h-20 tw-w-20 tw-rounded',
  tr: 'tw-border-solid tw-border-t-2 tw-border-b-2 tw-border-neutral-200',
  td: 'tw-px-4 tw-py-2',
  th: 'tw-sticky tw-top-[110px] tw-p-4 tw-bg-neutral-100 tw-text-xs tw-font-medium tw-text-black tw-items-center tw-gap-2',
  icon: 'tw-neutral-800 tw-h-4 tw-w-4',
}

interface TicketTemplatesTableProps {
  selectedTicketTemplates: SkillWithCategoryFields[]
  setSelectedTicketTemplates: Dispatch<SetStateAction<SkillWithCategoryFields[]>>
}

export default function TicketTemplatesTable({
  selectedTicketTemplates,
  setSelectedTicketTemplates,
}: TicketTemplatesTableProps) {
  const { availableSkills } = useSkillsContext()
  const [searchValue, setSearchValue] = useState('')

  function isTicketTemplateSelected(skill: SkillWithCategoryFields) {
    return selectedTicketTemplates.some((t) => t.id === skill.id)
  }

  function toggleTicketTemplateSelection(skill: SkillWithCategoryFields) {
    setSelectedTicketTemplates((prev) =>
      prev.some((t) => t.id === skill.id) ? prev.filter((t) => t.id !== skill.id) : [...prev, skill],
    )
  }

  return (
    <>
      <SearchInput value={searchValue} onChange={setSearchValue} />
      <table className="tw-my-2 tw-w-full tw-border tw-border-neutral-200 tw-bg-white">
        <thead className="tw-border-b-2 tw-border-t tw-border-solid tw-border-neutral-200 tw-bg-neutral-50">
          <tr className={classNames.tr}>
            <th className={classNames.th}>
              <div className="tw-flex tw-items-center tw-gap-2">TITLE</div>
            </th>
            <th className={classNames.th}>
              <div className="tw-flex tw-items-center tw-gap-2">REQUEST TYPE</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {availableSkills
            ?.filter(({ name, skillCategoryName }) =>
              `${name} ${skillCategoryName}`.toLowerCase().includes(searchValue.toLowerCase()),
            )
            ?.map((skill) => (
              <TicketTemplateRow
                key={skill.id}
                skill={skill as SkillWithCategoryFields}
                isSelected={isTicketTemplateSelected(skill)}
                onToggleSelection={toggleTicketTemplateSelection}
              />
            ))}
        </tbody>
      </table>
    </>
  )
}

interface TicketTemplateRowProps {
  isSelected: boolean
  skill: SkillWithCategoryFields
  onToggleSelection: (skill: SkillWithCategoryFields) => void
}

function TicketTemplateRow({ skill, isSelected, onToggleSelection }: TicketTemplateRowProps) {
  const { availableSkills } = useSkillsContext()
  const isSkillAvailable = availableSkills.some(({ id }) => id === skill.id)

  return (
    <tr
      key={skill.id}
      className={cn(classNames.tr, {
        'tw-bg-cornflower-100': isSelected,
        'tw-bg-neutral-100': !isSkillAvailable,
      })}
    >
      <td className={cn(classNames.td, 'tw-flex tw-items-center tw-gap-4')}>
        <Checkbox isChecked={isSelected} onClick={() => onToggleSelection(skill)} disabled={!isSkillAvailable} />
        <img src={skill.imageUrl} alt={skill.name} className={classNames.img} />
        <div className="tw-flex tw-flex-col">
          <h5 className="tw-text-medium tw-m-0 tw-font-semibold tw-text-neutral-800">{skill.name}</h5>
          <Badge color="light" className="tw-mt-1 tw-text-xs tw-font-normal">
            {skill.skillCategoryName}
          </Badge>
        </div>
      </td>
      <td className={classNames.td}>
        <div className="tw-flex tw-items-center tw-gap-2 tw-text-xs tw-text-neutral-800">
          <Badge color="light" className="tw-flex tw-items-center tw-gap-1 tw-font-normal">
            <SubscriptionTypeIcon type={skill.subscriptionType} />
          </Badge>
          {!isSkillAvailable && (
            <div className="tw-ml-2 tw-flex tw-items-center tw-gap-1">
              <LockIcon className={classNames.icon} />
              <span>Not available with your current subscription</span>
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}
