import { CheckIcon, CircleX } from 'lucide-react'
import { Toaster as SonnerToaster, toast as SonnerToast } from 'sonner'

export const toast = {
  success: SonnerToast.success,
  error: SonnerToast.error,
}

export const Toaster = () => (
  <SonnerToaster
    visibleToasts={4}
    toastOptions={{
      unstyled: true,
      classNames: {
        toast:
          'tw-py-2 tw-px-6 tw-min-w-80 tw-w-auto tw-min-h-12 tw-box-border tw-rounded-lg tw-border-2 tw-text-neutral-900 tw-flex tw-gap-2 tw-items-center tw-drop-shadow-md tw-text-xs',
        success: 'tw-bg-picklegreen-200 tw-border-picklegreen-100 tw-text-picklegreen-800',
        closeButton:
          'tw-static tw-bg-transparent tw-border-none tw-p-0 tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-drop-shadow-sm [&>svg]:tw-h-4 [&>svg]:tw-w-4 hover:!tw-bg-transparent tw-h-full tw-transform-none tw-text-inherit tw-justify-self-end tw-order-1 tw-ml-auto',
        error: 'tw-bg-red-100 tw-border-red-200 tw-text-red-900',
        actionButton: 'tw-ml-auto',
        description: 'tw-m-0 tw-text-xs tw-font-normal',
        loading: 'tw-bg-neutral-100',
      },
    }}
    position="bottom-right"
    icons={{
      success: <CheckIcon />,
      error: <CircleX />,
    }}
    className="tw-flex tw-w-full tw-max-w-[min(calc(100vw-2rem),800px)] tw-justify-end"
    expand
    closeButton
  />
)
