import { haveFiles } from 'lib/ticket/utils'
import ActionLinkItem from '../items/action-link-item'
import { DownloadFilesButton } from 'lib/components/download-assets-button/download-assets-button'
import { DownloadCurrentVersionResponse, fetchDownloadableFiles } from 'lib/api/tickets/tickets'
import { useEffect, useState } from 'react'
import { Loader2 } from 'lucide-react'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { toast } from 'sonner'
import { duplicateTicket } from 'lib/api/tickets/tickets'

interface ArchivedTabMenuProps {
  id: bigint | number
  subject: string
  currentVersion: number
  filesDownloadUrl: string
  status: string
  ticketFilesCount: bigint | number
  userId: number
  menuActionBtn: (route: string, data: Record<string, unknown>, method: string) => void
  showDelete?: boolean
}

export default function ArchivedTabMenu({
  id,
  currentVersion,
  menuActionBtn,
  subject,
  ticketFilesCount,
  userId,
  showDelete = true,
}: ArchivedTabMenuProps): JSX.Element {
  const deleteTicketUrl = window.Routes.apiInternalTicketUrl(id)
  const [downloadableFiles, setDownloadableFiles] = useState<DownloadCurrentVersionResponse['data'] | null>(null)

  const deleteButtonData = { id: id, user: userId }

  const deleteModalData = {
    title: 'Delete Request?',
    message: `Are you sure you want to delete ${subject}?`,
    confirmBtnText: 'Yes, Delete',
    confirmAction: () => {
      menuActionBtn(deleteTicketUrl, deleteButtonData, 'DELETE')
    },
  }

  const downloadDate = new Date().toLocaleDateString().replaceAll('/', '')
  const version = currentVersion === 0 ? '' : `_V${currentVersion}`
  const downloadZipName = `${id}_${subject}_${downloadDate}${version}`

  useEffect(() => {
    const fetchTicketDownloadableFiles = async () => {
      const files = await fetchDownloadableFiles(Number(id))
      setDownloadableFiles(files)
    }
    fetchTicketDownloadableFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDuplicateClick = async () => {
    try {
      const newTicket = await duplicateTicket(id.toString())
      window.location.assign('/tickets/' + newTicket.id)
    } catch (error) {
      console.error('Error duplicating ticket:', error)
      toast.error('Error duplicating ticket')
    }
  }

  if (downloadableFiles == null) {
    return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-py-4 tw-opacity-50">
        <Loader2 className="tw-animate-spin" />
      </div>
    )
  }

  return (
    <>
      {haveFiles(ticketFilesCount) && downloadableFiles?.length > 0 && (
        <DownloadFilesButton
          files={downloadableFiles}
          buttonColor="transparent"
          buttonText="Download Assets"
          className="tw-flex tw-w-full tw-items-center tw-gap-0.5 tw-rounded-none tw-border-0 !tw-px-4 !tw-py-2 tw-text-sm tw-text-peppercorn-700 hover:tw-bg-peppercorn-100 hover:tw-text-peppercorn-900"
          zipName={downloadZipName}
        />
      )}
      <IconFlyoutMenu.Button onClick={onDuplicateClick}>
        <i className="far fa-copy"></i>
        &nbsp; Duplicate
      </IconFlyoutMenu.Button>
      {showDelete && (
        <ActionLinkItem
          label="Delete"
          iconClass="far fa-trash"
          route={deleteTicketUrl}
          method="DELETE"
          data={deleteButtonData}
          menuActionBtn={menuActionBtn}
          modalData={deleteModalData}
        />
      )}
    </>
  )
}
