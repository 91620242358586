import { Card } from 'lib/components/card/card'
import { cn } from 'lib/util/cn'
import { getDominantSwatchFromImage } from 'lib/util/color'
import { useCallback, useEffect, useState } from 'react'

export interface ProjectCardProps {
  imgSrc: React.ImgHTMLAttributes<HTMLImageElement>['src']
  title: string
  subtitle: string
  color?: string
  onClick: () => void
}

const classNames = {
  footerText: 'tw-w-full tw-truncate tw-text-nowrap tw-text-inherit',
}

export default function ProjectCard({ imgSrc, title, subtitle, color, onClick }: ProjectCardProps) {
  const [footerColor, setFooterColor] = useState(color ?? '#FFFFFF')
  const [textColor, setTextColor] = useState('#000000')

  const setColor = useCallback(async () => {
    try {
      if (!color) {
        const swatch = await getDominantSwatchFromImage(imgSrc)
        setFooterColor(swatch?.hex ?? '#FFFFFF')
        setTextColor(swatch?.bodyTextColor ?? '#000000')
      }
    } catch (e) {
      console.error('Error getting colors', e)
    }
  }, [color, imgSrc])

  useEffect(() => {
    setColor()
  }, [imgSrc, setColor])

  return (
    <Card disableZoom size="custom" onClick={onClick} className="tw-aspect-square tw-overflow-hidden tw-ring-0">
      <Card.Body className="tw-h-0">
        <img className="tw-w-full tw-object-cover" src={imgSrc} />
      </Card.Body>
      <Card.Footer
        className="tw-flex tw-flex-col tw-items-start tw-p-4"
        style={{ background: footerColor, color: textColor }}
      >
        <div className={cn(classNames.footerText, 'tw-text-base tw-font-bold')}>{title}</div>
        <div className={cn(classNames.footerText, 'tw-text-sm tw-font-normal')}>{subtitle}</div>
      </Card.Footer>
    </Card>
  )
}
