import { ReactElement, ReactNode, useState } from 'react'

import { useAdminTicketContext } from '../providers/admin-ticket-provider'

import {
  TicketCoveredBadge,
  TicketDesignerErrorBadge,
  TicketProductionStateBadge,
  TicketReviewStateBadge,
  TicketTrainingBadge,
} from 'lib/components/badge/badge'
import { allSizesAsSkillSizes, skillSizeDetails } from 'lib/util/skill-sizes/skill-sizes'
import { useAdminUserContext } from 'providers/admin-user-provider'
import { AdminTicket, AdminTicketState, routes } from 'lib/api/admin/tickets/admin-tickets'
import { LinkLikeButton } from 'components/core/button'
import AdminHeaderButtons from './admin-header-buttons'
import { truncateText } from 'lib/string/utils'
import { getHoursFromMinutes } from 'lib/util/date'
import CopyTextButton from 'lib/components/buttons/copy-text-button'
import ProjectDrawer from 'components/elements/projects/project-drawer'

function Line() {
  return <div className="tw-ml-4 tw-mt-2 tw-h-8 tw-bg-neutral-200" style={{ width: '2px' }} />
}

interface ItemProps {
  title?: string
  children: ReactNode
  line?: boolean
}

function Item({ title, children, line = true }: ItemProps) {
  return (
    <>
      <div className="tw-ml-4">
        {!!title && <div className="tw-font-sm tw-pb-1 tw-text-neutral-600">{title}</div>}
        <div className="tw-text-neutral-800">{children}</div>
      </div>
      {line && <Line />}
    </>
  )
}

const SizeContent = ({ ticket, onMoreClick }: { ticket: AdminTicket; onMoreClick: () => void }) => {
  const sizes = allSizesAsSkillSizes(ticket.selectedSkillSizes, ticket.size)

  if (!sizes || sizes.length === 0) {
    return <div>N/A</div>
  } else if (sizes.length <= 2) {
    return <div>{truncateText(sizes.map(skillSizeDetails).join(', '))}</div>
  }

  return (
    <div>
      {truncateText(sizes.slice(0, 2).map(skillSizeDetails).join(', '))}
      <a className="tw-cursor-pointer hover:tw-text-berry-800" onClick={onMoreClick}>
        &nbsp;+ {sizes.length - 2} more
      </a>
    </div>
  )
}

export default function AdminHeaderDetails(): ReactElement {
  const [isProjectsDrawerOpen, setIsProjectsDrawerOpen] = useState(false)

  const { ticket, setRightSidebar } = useAdminTicketContext()
  const { user } = useAdminUserContext()

  function openClientProfileTab() {
    setRightSidebar('clientProfile')
  }

  function openTicketDetailsTab() {
    setRightSidebar('ticketDetails')
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <div className="tw-mx-3 tw-flex tw-flex-row tw-items-center tw-gap-1">
            <h3 className="tw-m-0">
              #{ticket?.id} - {ticket?.subject ? ticket?.subject : `Untitled ${ticket?.skill?.name}`}
            </h3>
            <CopyTextButton textToCopy={`#${ticket?.id} - ${ticket?.subject}`} defaultButtonColor="transparent" />
          </div>
          <div className="tw-flex tw-gap-2">
            <TicketProductionStateBadge state={ticket?.state} />
            {ticket.state === AdminTicketState.qualityReview && (
              <TicketReviewStateBadge state={ticket?.qaReviews?.[0]?.state} />
            )}
            {ticket?.training && <TicketTrainingBadge />}
            {ticket?.covered && <TicketCoveredBadge />}
            {ticket?.designerError && <TicketDesignerErrorBadge />}
          </div>
        </div>
        <AdminHeaderButtons />
      </div>
      <div className="tw-flex tw-items-center">
        <Item title="Client">
          <LinkLikeButton onClick={openClientProfileTab}>{ticket?.company.name}</LinkLikeButton>
        </Item>
        {!user.isCreative && (
          <Item title="Creative Name">
            {ticket?.designer?.name ? (
              <a
                href={`/admin/designers?designer_id=${ticket?.designer?.id}`}
                target="_blank"
                rel="noreferrer"
                className="tw-font-semibold"
              >
                {ticket?.designer?.name}
              </a>
            ) : (
              <span>None</span>
            )}
          </Item>
        )}
        <Item title="Project">
          {ticket?.project ? (
            <>
              <LinkLikeButton
                onClick={() => setIsProjectsDrawerOpen(true)}
                className="tw-flex tw-items-center tw-gap-2 tw-font-semibold tw-text-cornflower-500"
              >
                <span className="tw-max-w-64 tw-truncate">{ticket.project.name}</span>
              </LinkLikeButton>
              <ProjectDrawer
                isOpen={isProjectsDrawerOpen}
                setOpen={setIsProjectsDrawerOpen}
                project={ticket.project}
                readOnly
              />
            </>
          ) : (
            <span>None</span>
          )}
        </Item>
        {ticket?.duplicatedFrom && (
          <>
            <Item title="Duplicate of">
              <a href={`${routes.show}/${ticket.duplicatedFrom}`}>#{ticket.duplicatedFrom}</a>
            </Item>
          </>
        )}
        {ticket?.parentId && (
          <>
            <Item title="Parent Ticket">
              <a href={`${routes.show}/${ticket.parentId}`}>#{ticket.parentId}</a>
            </Item>
          </>
        )}
        <Item title="Request Type">
          {ticket?.skill.name} {ticket?.skill.forWeb && '[for Web]'} {ticket?.skill.forPrint && '[for Print]'}
        </Item>
        <Item title="Size(s)">
          <SizeContent ticket={ticket} onMoreClick={openTicketDetailsTab} />
        </Item>
        <Item title="Format(s)">{ticket?.selectedFormats.join(', ')}</Item>
        <Item title="Design Time">
          <>
            <span className="tw-text-black">{getHoursFromMinutes(ticket?.designTime)}</span>
            <span className="tw-text-neutral-600"> / {ticket?.companyDailyAvailableTime / 60} hr</span>
          </>
        </Item>
        <Item title="Brand Profile">
          {!!ticket?.selectedBrand && (
            <LinkLikeButton onClick={openClientProfileTab}>{ticket?.selectedBrand.name}</LinkLikeButton>
          )}
          {!ticket?.selectedBrand && 'None'}
        </Item>
        <Item line={false}>
          <LinkLikeButton onClick={openTicketDetailsTab}>View More</LinkLikeButton>
        </Item>
      </div>
    </div>
  )
}
