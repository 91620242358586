import { useCallback, useState } from 'react'
import { ChevronLeftIcon, Edit2Icon, FolderOpenIcon, PlusIcon, SlashIcon, TicketIcon } from 'lucide-react'
import ProjectsDrawer from 'components/elements/projects/project-drawer'
import { DesignRequest, updateTicket } from 'lib/api/tickets/tickets'
import { cn } from 'lib/util/cn'
import { LinkLikeButton } from 'components/core/button'
import { AdminTicket, routes } from 'lib/api/admin/tickets/admin-tickets'
import { Project } from 'interfaces/project'
import { toast } from '../toast/toast'
import ProjectsPopover from 'components/elements/projects/projects-popover'
import { useRequestContext } from 'components/pages/request/providers/request-provider'
import { revisionsCanBeSentToDesigner } from 'lib/ticket/utils'
import ConfirmNavigatePendingUpdatesModal from 'components/pages/request/request-header/confirm-navigate-pending-updates-modal'
import { TicketStatusBadge } from '../badge/badge'

interface RequestsProjectsBreadcrumbProps {
  className?: string
  ticket: DesignRequest | AdminTicket
  onProjectUpdate?: () => void
  isAdmin?: boolean
}

export default function RequestsProjectsBreadcrumb({
  className,
  ticket,
  onProjectUpdate,
  isAdmin = false,
}: RequestsProjectsBreadcrumbProps) {
  const { editMode } = useRequestContext()
  const [isOpen, setIsOpen] = useState(false)
  const [confirmationVisible, setConfirmationVisible] = useState(false)

  const hasEdits = revisionsCanBeSentToDesigner(ticket as DesignRequest, editMode)

  const [isProjectsPopoverOpen, setIsProjectsPopoverOpen] = useState(false)

  const updateTicketProject = useCallback(
    async (project: Project | null) => {
      try {
        await updateTicket(Number(ticket?.id), { project_id: project?.id || null })
        onProjectUpdate?.()
        setIsProjectsPopoverOpen(false)
        toast.success('Ticket project updated')
      } catch (error) {
        toast.error('Failed to update ticket project')
        console.error('Failed to update ticket project', error)
      }
    },
    [onProjectUpdate, ticket?.id],
  )

  const link = isAdmin
    ? {
        href: routes.assignedToMe,
        text: 'Back to all tickets',
      }
    : {
        href: '/requests',
        text: 'Back to all requests',
      }

  return (
    <div className={cn(className, 'tw-flex tw-items-center tw-gap-2')}>
      <LinkLikeButton
        onClick={() => (hasEdits ? setConfirmationVisible(true) : window.location.assign(link.href))}
        className="tw-flex tw-items-center tw-gap-1 tw-text-neutral-400 hover:tw-text-cornflower-500"
      >
        <ChevronLeftIcon className="tw-h-4 tw-w-4" />
        {link.text}
      </LinkLikeButton>
      {!isAdmin ? (
        <>
          <SlashIcon className="tw-h-4 tw-w-4" />
          <ProjectsPopover
            isOpen={isProjectsPopoverOpen}
            onSelectProject={updateTicketProject}
            project={ticket?.project}
            setIsOpen={setIsProjectsPopoverOpen}
          >
            <LinkLikeButton
              onClick={(e) => {
                if (ticket.project) {
                  e.stopPropagation()
                  setIsOpen(true)
                }
              }}
              className="tw-flex tw-items-center tw-gap-2 tw-font-normal tw-text-neutral-400 hover:tw-text-cornflower-500"
            >
              {ticket?.project ? (
                <span className="tw-group">
                  <FolderOpenIcon data-testid="folder-open-icon" className="tw-h-4 tw-w-4 group-hover:tw-hidden" />
                  <Edit2Icon
                    data-testid="edit-icon"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsProjectsPopoverOpen(true)
                    }}
                    className="tw-hidden tw-h-4 tw-w-4 group-hover:tw-block"
                  />
                </span>
              ) : (
                <PlusIcon data-testid="plus-icon" className="tw-h-4 tw-w-4" />
              )}
              <span className="tw-max-w-64 tw-truncate">{ticket?.project?.name || 'Add project'}</span>
            </LinkLikeButton>
          </ProjectsPopover>
        </>
      ) : (
        ticket?.project && (
          <>
            <SlashIcon className="tw-h-4 tw-w-4" />
            <LinkLikeButton
              onClick={() => setIsOpen(true)}
              className="tw-flex tw-items-center tw-gap-2 tw-font-normal tw-text-neutral-400 hover:tw-text-cornflower-500"
            >
              <FolderOpenIcon data-testid="folder-open-icon" className="tw-h-4 tw-w-4" />
              <span className="tw-max-w-64 tw-truncate">{ticket?.project?.name}</span>
            </LinkLikeButton>
          </>
        )
      )}

      <ProjectsDrawer
        isOpen={isOpen}
        setOpen={setIsOpen}
        projectId={ticket?.project?.id}
        project={ticket?.project}
        onUpdate={onProjectUpdate}
        readOnly={isAdmin}
      />

      <SlashIcon className="tw-h-4 tw-w-4" />
      <div className="tw-flex tw-items-center tw-gap-2 tw-font-normal tw-text-neutral-400">
        <TicketIcon className="tw-h-4 tw-w-4" />
        <span className="tw-max-w-64 tw-truncate">{ticket?.subject || `Untitled ${ticket?.skill?.name}`}</span>
      </div>
      <ConfirmNavigatePendingUpdatesModal
        ticketId={ticket?.id}
        isOpen={confirmationVisible}
        setOpen={setConfirmationVisible}
      />

      {!isAdmin && <TicketStatusBadge status={ticket?.status} className="tw-mx-0" />}
    </div>
  )
}
