import { AppSettings } from 'interfaces/app'
import { Company } from 'interfaces/company'
import CustomBranding from './custom-branding'
import { Toaster } from 'lib/components/toast/toast'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'

export interface CustomBrandingSettings {
  companyId: string
  createdAt: string
  id: string
  logo?: string
  s3LogoFilename?: string
  mappedTo?: string
  primaryColor?: string
  updatedAt?: string
  url?: string
  userId?: string
  cnameConfig?: {
    acm: {
      name: string
      value: string
    }
    cloudfront: {
      name: string
      value: string
    }
  }
}

export interface CustomBrandingPageProps {
  settings: AppSettings
  company: Company
  customBranding?: CustomBrandingSettings
  customBrandingStatus?: boolean
  customBrandingPlanId?: number
  hasWhiteLabelSubscription?: boolean
  canSubscribeToWhiteLabel?: boolean
  isPowerCompany?: boolean
}

export default function CustomBrandingPage(props: CustomBrandingPageProps) {
  return (
    <>
      <Toaster />
      <FeatureFlagsProvider>
        <CustomBranding {...props} />
      </FeatureFlagsProvider>
    </>
  )
}
