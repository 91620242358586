import { SearchTicketCompanyTag, Ticket } from 'interfaces/ticket'
import QueueTypePlaceholder, { Placeholder } from 'components/core/queue-type-placeholder'
import CompanyTaggingProvider, { ChangeTagAction } from 'providers/company-tagging-provider'
import { PencilCompanyTaggingPopover } from 'components/elements/company-tagging/company-tagging-popover'

import styles from './table-content.module.scss'
import { CompanyTag, TaggableTag } from 'lib/api/company-tags/company-tags'

interface TicketLinkElementProps {
  ticket: Pick<Ticket, 'id' | 'ticketId' | 'subject' | 'skillName' | 'thumbnail' | 'status' | 'url'>
  showTags: boolean
  updateSelectedTags?: (updatedTag: CompanyTag, action: ChangeTagAction, taggableId: number) => void
  selectedTags?: TaggableTag[] | CompanyTag[] | SearchTicketCompanyTag[]
  editTags?: boolean
}

export default function TicketLinkElement({
  ticket,
  showTags,
  selectedTags,
  updateSelectedTags,
  editTags = true,
}: TicketLinkElementProps): JSX.Element {
  const ticketId = ticket.ticketId || ticket.id

  const { subject, skillName, url, thumbnail } = ticket

  return (
    <div className="tw-flex tw-items-center">
      <a href={url} className="tw-text-eggplant hover:tw-text-eggplant-600">
        <div className={styles.thumbnailContainer}>
          {thumbnail?.placeholder ? (
            <QueueTypePlaceholder
              fileName={thumbnail?.url as Placeholder}
              className={styles.placeholderImage}
              color={'#ADA6AD'}
            />
          ) : (
            <img className={styles.thumbnailImage} src={thumbnail?.url} alt="thumbnail" />
          )}
        </div>
      </a>
      <div>
        <a href={url} className="tw-text-eggplant hover:tw-text-eggplant-600">
          <div className="tw-align-middle tw-font-semibold">{subject || `Untitled ${skillName}`}</div>
        </a>
        {showTags && (
          <CompanyTaggingProvider
            taggableId={Number(ticketId)}
            taggableType="Ticket"
            selectedTags={selectedTags}
            updateTaggableState={updateSelectedTags}
          >
            <div className="tw-pt-1">
              <PencilCompanyTaggingPopover editTags={editTags} />
            </div>
          </CompanyTaggingProvider>
        )}
      </div>
    </div>
  )
}
