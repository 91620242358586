export function getParamAsNumber(params: URLSearchParams, key: string): number {
  const value = params.get(key)
  return value ? parseInt(value) : null
}

export function getParamAsNumberArray(params: URLSearchParams, key: string): number[] | null {
  const value = params.get(key)
  return value ? value.split(',').map((v) => parseInt(v)) : null
}

export function getParamAsString(params: URLSearchParams, key: string, defaultValue = ''): string {
  return params.get(key) || defaultValue
}
