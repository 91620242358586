import QueueTypePlaceholder from 'components/core/queue-type-placeholder'
import { TicketTemplate } from 'interfaces/ticket_template'
import { Skill } from 'interfaces/skill'
import { Ticket } from 'interfaces/ticket'
import { SkillWithCategoryFields } from 'interfaces/project-template'

interface DeliverablesTableTitleCellProps {
  ticket: Ticket | TicketTemplate | Skill | SkillWithCategoryFields
  isAdmin?: boolean
}

const DeliverablesTableTitleCell = ({ ticket, isAdmin }: DeliverablesTableTitleCellProps) => {
  const url = isAdmin ? `/admin/tickets/${ticket.id}` : `/tickets/${ticket.id}`

  if ('skill' in ticket || 'skillCategoryName' in ticket) {
    const skill = 'skill' in ticket ? ticket.skill : ticket

    return (
      <div className="tw-flex tw-items-center tw-gap-2">
        <div>
          <img src={skill.imageUrl} className="tw-h-16 tw-w-16 tw-rounded-t-lg tw-object-cover" />
        </div>
        <div>
          <div className="tw-max-w-48 tw-truncate tw-font-bold">{skill.name}</div>
          <div className="tw-text-xs">{skill.skillCategoryName}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      {ticket?.thumbnail?.placeholder ? (
        <a
          className="tw-flex tw-aspect-square tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-lg tw-bg-peppercorn-50 hover:tw-cursor-pointer"
          href={url}
        >
          <QueueTypePlaceholder fileName={ticket.thumbnail.url} className="tw-h-8 tw-w-8 tw-text-neutral-500" />
        </a>
      ) : (
        <a href={url}>
          <img
            src={ticket?.thumbnail?.url}
            alt={ticket.subject}
            className="tw-h-16 tw-w-16 tw-rounded-t-lg tw-object-cover hover:tw-cursor-pointer"
          />
        </a>
      )}
      <a className="tw-max-w-96 tw-truncate tw-font-bold tw-text-cornflower-500 hover:tw-cursor-pointer" href={url}>
        {ticket.subject || `Untitled ${ticket.skillName}`}
      </a>
    </div>
  )
}

export default DeliverablesTableTitleCell
