import { forwardRef, MutableRefObject, ReactElement, ReactNode, useEffect, useImperativeHandle, useRef } from 'react'
import { EditorContent, EditorContextValue, Extensions, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Underline } from '@tiptap/extension-underline'
import { TextAlign } from '@tiptap/extension-text-align'
import { Placeholder } from '@tiptap/extension-placeholder'
import debounce from 'lib/util/debounce'
import Video from './extensions/tiptap-video'
import { AssetImage } from './extensions/tiptap-asset-image'
import { AssetLink } from './extensions/tiptap-asset-link'
import WYSIWYGToolbar, { WysiwygFileUpload } from './wysiwyg-toolbar'
import { ImageResize } from 'tiptap-extension-resize-image'
import { EditorProps } from '@tiptap/pm/view'
import WYSIWYGFileModal from './wysiwyg-file-modal'
import './wysiwyg.scss'

export const footerClassNames = 'tw-m-4 tw-flex tw-items-center tw-justify-end tw-gap-2'

export const baseExtensions = [
  StarterKit.configure({
    blockquote: {
      HTMLAttributes: {
        class: 'tw-border-l-2 tw-border-solid tw-border-0 tw-pl-1 tw-border-neutral-200',
      },
    },
  }),
  AssetLink.configure({
    autolink: true,
    openOnClick: true,
  }),
  Underline,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  AssetImage.configure({
    HTMLAttributes: {
      class: 'tw-mx-auto tw-my-4 tw-block tw-max-w-full',
    },
  }),
  ImageResize.configure({
    inline: true,
    HTMLAttributes: {
      class: 'tw-mx-auto tw-my-4 tw-block tw-max-w-full',
    },
  }),
  Video.configure({ HTMLAttributes: { class: 'tw-h-64 tw-w-96', controls: true, 'data-testid': 'wysiwyg-video' } }),
]

const editorProps: EditorProps = {
  attributes: {
    class: 'tw-p-4 focus:tw-outline-none',
  },
}

interface ExtendedLinkOptions {
  href: string
  target?: string
  rel?: string
  class?: string
  'data-asset-id'?: string
}

interface WYSIWYGTextareaProps {
  defaultValue?: string
  onChange: (value: string) => void
  placeholder?: string
  extensions?: Extensions
  prependToolbar?: ReactNode
  appendToolbar?: ReactNode
  disabled?: boolean
  showCannedResponseButton?: boolean
  ref?: MutableRefObject<EditorContextValue>
  footer?: ReactNode
  allowFileDrop?: boolean
}

export default forwardRef(function WYSIWYGTextarea(props: WYSIWYGTextareaProps, ref): ReactElement {
  const {
    defaultValue,
    onChange,
    placeholder,
    extensions = [],
    prependToolbar,
    appendToolbar,
    disabled,
    showCannedResponseButton,
    footer,
    allowFileDrop,
  } = props
  const content = useRef(defaultValue)

  if (placeholder) {
    baseExtensions.push(Placeholder.configure({ placeholder }) as never)
  }

  const editor = useEditor({
    extensions: [...baseExtensions, ...extensions],
    content: content.current,
    editorProps,
  })

  const onFilesUpload = (files: WysiwygFileUpload[]) => {
    for (const file of files) {
      const isMedia = file.mimetype.startsWith('video/') || file.mimetype.startsWith('image/')
      if (isMedia) {
        const command = file.mimetype.startsWith('video/') ? 'setVideo' : 'setAssetImage'

        if (file.isFilestack) {
          editor.chain().focus()[command]({ src: file.url }).run()
        } else {
          const assetId = file.url.split('/').pop()
          if (!assetId) {
            console.error('Failed to extract asset ID from URL:', file.url)
            return
          }
          editor.chain().focus()[command]({ src: assetId }).run()
        }
      } else {
        if (file.isFilestack) {
          editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: file.url, target: '_blank' })
            .insertContent(file.filename)
            .run()
        } else {
          const assetId = file.url.split('/').pop()
          if (!assetId) {
            console.error('Failed to extract asset ID from URL:', file.url)
            return
          }
          editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: '#', target: '_blank', 'data-asset-id': assetId } as ExtendedLinkOptions)
            .insertContent(file.filename)
            .run()
        }
      }
    }
  }

  useEffect(() => {
    if (editor) {
      const debouncedOnChange = debounce(() => onChange(editor.getHTML()), 300)
      editor.on('update', debouncedOnChange)
      return () => {
        editor.off('update', debouncedOnChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  useImperativeHandle(ref, () => {
    return {
      editor,
    }
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <div className="tw-w-full tw-flex-1 tw-rounded-md tw-border tw-border-solid tw-border-neutral-300 tw-bg-white">
      <WYSIWYGToolbar
        editor={editor}
        onFilesUploaded={onFilesUpload}
        prependChildren={prependToolbar}
        appendChildren={appendToolbar}
        showCannedResponseButton={showCannedResponseButton}
      />
      <EditorContent
        disabled={disabled}
        editor={editor}
        className="tw-max-h-60 tw-min-h-16 tw-resize-y tw-overflow-scroll"
      />
      {footer}
      <WYSIWYGFileModal editor={editor} onFilesUploaded={onFilesUpload} allowFileDrop={allowFileDrop} />
    </div>
  )
})
