import { CustomerState, TicketFile } from 'lib/api/ticket-files/ticket-files'
import FileType from 'lib/components/file-type/file-type'
import { cn } from 'lib/util/cn'
import ApprovedBadge from '../approved-badge'

interface FileThumbnailProps {
  previewUrl: string
  isPlaceholder: boolean
  currentTicketFile: TicketFile
  className?: string
}

const FileThumbnail = ({ previewUrl, isPlaceholder, currentTicketFile, className }: FileThumbnailProps) => {
  return (
    <div className={cn('tw-relative tw-flex tw-h-20 tw-w-20 tw-overflow-hidden tw-rounded-lg', className)}>
      <img className="tw-relative tw-object-cover tw-object-center" src={previewUrl} width={80} height={80} />
      {!isPlaceholder && (
        <FileType className="tw-absolute tw-bottom-1 tw-left-1 tw-h-4 tw-w-4" fileName={currentTicketFile?.name} />
      )}
      {currentTicketFile?.customerState === CustomerState.Approved && (
        <ApprovedBadge className="tw-absolute tw-right-1 tw-top-1" />
      )}
    </div>
  )
}

export default FileThumbnail
