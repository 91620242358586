import { ChevronRightIcon, TrashIcon } from 'lucide-react'

import { ChevronLeftIcon } from 'lucide-react'
import IconButton from '../buttons/icon-button'

export default function QuantitySelector({
  quantity,
  handleDecrement,
  handleIncrement,
  handleDelete,
  disabled,
}: {
  quantity: number
  handleDecrement: () => void
  handleIncrement: () => void
  handleDelete: () => void
  disabled?: boolean
}) {
  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      {!disabled && (
        <IconButton
          color="secondary"
          className={quantity > 0 ? 'tw-visible' : 'tw-invisible'}
          size="sm"
          onClick={handleDecrement}
          dataTestid="decrement-button"
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <span className="tw-w-4 tw-text-center tw-text-sm">{quantity}</span>
      {!disabled && (
        <>
          <IconButton color="secondary" size="sm" onClick={handleIncrement} dataTestid="increment-button">
            <ChevronRightIcon />
          </IconButton>
          <IconButton
            className={quantity > 0 ? 'tw-visible' : 'tw-invisible'}
            color="danger"
            invert
            size="sm"
            onClick={handleDelete}
            dataTestid="delete-button"
          >
            <TrashIcon />
          </IconButton>
        </>
      )}
    </div>
  )
}
