import DeliverablesTableTitleCell from './deliverables-table-title-cell'
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { PlusIcon, TicketIcon } from 'lucide-react'
import { cn } from 'lib/util/cn'
import { ProjectTemplate, SkillWithCategoryFields } from 'interfaces/project-template'
import { SubscriptionTypeIcon } from '../deliverable-type'
import { LinkLikeButton } from 'components/core/button'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import Badge from 'lib/components/badge/badge'
import SearchInput from 'lib/components/search-input/search-input'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { Skill } from 'interfaces/skill'
import { toast } from 'sonner'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { useInView } from 'react-intersection-observer'
import { debounce, sortBy, uniqBy } from 'lodash'
import QuantitySelector from 'lib/components/quantity-selector/quantity-selector'

interface TemplateRequestsTableProps {
  template?: ProjectTemplate
  selectedRows?: (Skill | SkillWithCategoryFields)[]
  setSelectedRows?: Dispatch<SetStateAction<(Skill | SkillWithCategoryFields)[]>>
  onAddRequestsClick?: () => void
  isCustomer?: boolean
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  isBlankTemplate: boolean
}

const TemplateRequestsTable = ({
  template,
  selectedRows = [],
  setSelectedRows,
  onAddRequestsClick,
  isCustomer,
  searchValue,
  setSearchValue,
  isBlankTemplate,
}: TemplateRequestsTableProps) => {
  const { ref, inView } = useInView()

  const selectedTicketMatcher = useCallback(
    (selectedTicketOrSkill: Skill | SkillWithCategoryFields, rowTicketOrSkill: Skill | SkillWithCategoryFields) => {
      return selectedTicketOrSkill.id === rowTicketOrSkill?.id
    },
    [],
  )

  const handleDecrement = useCallback(
    (rowOriginal: SkillWithCategoryFields | Skill) => {
      if (!setSelectedRows) return

      setSelectedRows((prev: Skill[]) => {
        const index = prev.findIndex((skill) => selectedTicketMatcher(skill, rowOriginal))
        if (index !== -1) {
          return [...prev.slice(0, index), ...prev.slice(index + 1)]
        }
        return prev
      })
    },
    [setSelectedRows, selectedTicketMatcher],
  )

  const handleIncrement = useCallback(
    (rowOriginal: SkillWithCategoryFields | Skill) => {
      if (!setSelectedRows) return

      setSelectedRows((prev: Skill[] | SkillWithCategoryFields[]) => [...prev, rowOriginal])
    },
    [setSelectedRows],
  )

  const handleDelete = useCallback(
    (rowOriginal: SkillWithCategoryFields | Skill) => {
      if (!setSelectedRows) return

      setSelectedRows((prev: Skill[]) => prev.filter((skill) => !selectedTicketMatcher(skill, rowOriginal)))
    },
    [setSelectedRows, selectedTicketMatcher],
  )

  const columns: ColumnDef<SkillWithCategoryFields | Skill>[] = useMemo(() => {
    return [
      {
        id: 'title',
        header: 'Title',
        cell: (props) => <DeliverablesTableTitleCell isAdmin ticket={props.row.original} />,
      },
      {
        id: 'scope',
        header: 'Scope of Service',
        cell: (props) => {
          return (
            <Badge color="light">
              <SubscriptionTypeIcon
                type={
                  'subscriptionType' in props.row.original
                    ? props.row.original.subscriptionType
                    : (props.row.original?.scopeOfService ?? '')
                }
              />
            </Badge>
          )
        },
      },
      {
        id: 'quantity',
        header: 'Quantity',
        cell: ({ row }) => {
          const quantity = selectedRows?.filter((skill) => selectedTicketMatcher(skill, row.original)).length

          return (
            <QuantitySelector
              disabled={!!template?.partner && isCustomer}
              quantity={quantity || 0}
              handleDecrement={() => handleDecrement(row.original)}
              handleIncrement={() => handleIncrement(row.original)}
              handleDelete={() => handleDelete(row.original)}
            />
          )
        },
      },
    ]
  }, [
    selectedRows,
    template?.partner,
    isCustomer,
    selectedTicketMatcher,
    handleDecrement,
    handleIncrement,
    handleDelete,
  ])

  const { data, error, isFetching, fetchNextPage, isFetchingNextPage, refetch, hasNextPage } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['adminSkills'],
    queryFn: ({ pageParam = 1 }) =>
      request({
        endpoint: 'getAdminSkills',
        query: { page: pageParam, q: { name_cont: searchValue } },
      }),
    enabled: !template,
    getNextPageParam: (lastPage, pages) => (lastPage.meta?.nextPage ? pages.length + 1 : undefined),
  })

  const tableData = useMemo(() => {
    const filterSearch = (rows: (Skill | SkillWithCategoryFields)[] = []) =>
      rows.filter(({ name, skillCategoryName }) =>
        `${name} ${skillCategoryName}`.toLowerCase().includes(searchValue.toLowerCase()),
      )

    if (template && !template.blankTemplate) {
      return filterSearch(sortBy(uniqBy(selectedRows, 'id'), 'id'))
    }
    return filterSearch(data?.pages.flatMap((page) => page.data)) ?? []
  }, [data?.pages, searchValue, selectedRows, template])

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  })

  const numRequests = useMemo(() => {
    if (template) {
      return (
        uniqBy(
          template?.ticketTemplates.map((ticket) => ticket.skill),
          'id',
        ).length || 0
      )
    }

    return data?.pages[0].meta.total || 0
  }, [template, data])

  useEffect(() => {
    if (error) {
      console.error('Failed to fetch requests', error)
      toast.error('Failed to fetch requests')
    }
  }, [error])

  useEffect(() => {
    if (!template && inView && !isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetchingNextPage, hasNextPage, template])

  useEffect(() => {
    debounce(() => refetch(), 500)()
  }, [searchValue, refetch])

  return (
    <div>
      <div className="tw-w-full">
        {!template && <h3 className="tw-flex tw-items-center tw-gap-2">Select request(s) to add to this template*</h3>}
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
          {!isBlankTemplate && (
            <SearchInput
              value={searchValue}
              onChange={setSearchValue}
              placeholder="Search requests"
              className="tw-flex-1"
            />
          )}
          {!template && <h5>Requests ({data?.pages[0].meta.total ?? 0})</h5>}
        </div>
      </div>
      <div className="tw-my-4 tw-flex tw-items-center tw-justify-between tw-gap-2">
        {template && (
          <>
            <h5 className="tw-flex tw-items-center tw-gap-2">
              <TicketIcon className="lu-md" /> {numRequests} default requests
            </h5>
            {!template?.blankTemplate && ((isCustomer && !template?.partner) || !isCustomer) && (
              <LinkLikeButton
                onClick={onAddRequestsClick}
                className="tw-flex tw-items-center tw-justify-center tw-gap-2"
              >
                <PlusIcon className="lu-md" /> <span>Add requests</span>
              </LinkLikeButton>
            )}
          </>
        )}
      </div>
      <div>
        <table className="tw-w-full">
          <thead className="tw-sticky tw--top-4 tw-pl-1">
            <tr className="tw-h-12 tw-bg-peppercorn-50">
              {table.getFlatHeaders().map((header) => (
                <th
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="tw-pl-4 tw-font-medium tw-uppercase tw-text-black"
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={cn('tw-h-24 tw-border-s-2 tw-border-solid tw-border-peppercorn-100 tw-p-2', {
                  'tw-bg-cornflower-50': row.getIsSelected(),
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={{ width: `${cell.column.getSize()}px` }} className="tw-pl-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            <tr ref={ref} className="tw-h-4" />
          </tbody>
        </table>
        {!template && isFetching && <Skeleton className="tw-h-24 tw-w-full tw-bg-neutral-200" data-testid="skeleton" />}
      </div>
    </div>
  )
}

export default TemplateRequestsTable
