import { Designer } from 'interfaces/designer'
import { bindJWT, getApi } from 'lib/api/api'

export async function getDesigners(jwt: string = null): Promise<Designer[]> {
  const getWithJWT = bindJWT(getApi, jwt)
  const response = await getWithJWT('/api/internal/designers')

  return response.data
}

export async function getCompanyDesigners(company_id: number, jwt: string = null): Promise<Designer[]> {
  const getWithJWT = bindJWT(getApi, jwt)
  const response = await getWithJWT(`/api/internal/companies/${company_id}/designers`)

  return response.data
}
