import React, { ReactNode, useState } from 'react'
import { Editor } from '@tiptap/react'
import { Level } from '@tiptap/extension-heading'
import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Bold,
  ImageIcon,
  Italic,
  LinkIcon,
  List,
  ListOrdered,
  Plus,
  QuoteIcon,
  Redo,
  Strikethrough,
  UnderlineIcon,
  Undo,
} from 'lucide-react'

import { useAdminUserContext } from 'providers/admin-user-provider'
import AdminCannedResponseModal from 'components/pages/admin/admin-ticket/canned-response-modal/admin-canned-response-modal'

import SelectBox, { SelectBoxOption } from '../dropdown/select-box'
import LinkModal from './link-modal'
import { FileUploaderResult, ModalFileUploader } from '../file-uploader/file-uploader'

export interface WysiwygFileUpload {
  url: string
  filename: string
  mimetype: string
  isFilestack?: boolean
}

interface MenuBarButtonProps {
  children: React.ReactNode
  disabled?: boolean
  isActive?: boolean
  name: string
  onClick: () => void
}

function TextStyleOption({ children }: { children: ReactNode }) {
  return <div className="tw-flex tw-w-48 tw-items-center tw-truncate tw-pl-2">{children}</div>
}

const textStyleOptions = [
  { displayElement: <TextStyleOption>Paragraph</TextStyleOption>, value: 'paragraph' },
  {
    displayElement: (
      <TextStyleOption>
        <h1 className="tw-m-0 tw-p-0">Heading 1</h1>
      </TextStyleOption>
    ),
    value: 'h1',
  },
  {
    displayElement: (
      <TextStyleOption>
        <h2 className="tw-m-0 tw-p-0">Heading 2</h2>
      </TextStyleOption>
    ),
    value: 'h2',
  },
  {
    displayElement: (
      <TextStyleOption>
        <h3 className="tw-m-0 tw-p-0">Heading 3</h3>
      </TextStyleOption>
    ),
    value: 'h3',
  },
  {
    displayElement: (
      <TextStyleOption>
        <h4 className="tw-m-0 tw-p-0">Heading 4</h4>
      </TextStyleOption>
    ),
    value: 'h4',
  },
  {
    displayElement: (
      <TextStyleOption>
        <h5 className="tw-m-0 tw-p-0">Heading 5</h5>
      </TextStyleOption>
    ),
    value: 'h5',
  },
  {
    displayElement: (
      <TextStyleOption>
        <h6 className="tw-m-0 tw-p-0">Heading 6</h6>
      </TextStyleOption>
    ),
    value: 'h6',
  },
] as unknown as SelectBoxOption[]

function ToolbarButton({ onClick, name, isActive = false, disabled = false, children }: MenuBarButtonProps) {
  const classNames = `
    tw-border
    tw-border-neutral-900
    tw-border-solid
    tw-rounded-md
    tw-flex
    tw-items-center
    tw-justify-center
    tw-p-1
    tw-cursor-pointer
    ${isActive ? 'tw-bg-neutral-300' : 'tw-bg-transparent'}
  `
  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        disabled={disabled}
        className={classNames}
        name={name}
        data-testid={name}
        type="button"
      >
        {children}
      </button>
    </>
  )
}

function ToolbarButtonGroup({ children }: { children: ReactNode }) {
  return (
    <div className="tw-flex tw-items-center" style={{ gap: '2px' }}>
      {children}
    </div>
  )
}

interface ToolbarProps {
  editor: Editor
  onFilesUploaded: (files: WysiwygFileUpload[]) => void
  prependChildren?: ReactNode
  appendChildren?: ReactNode
  showCannedResponseButton?: boolean
}

export default function WYSIWYGToolbar({
  editor,
  onFilesUploaded,
  prependChildren,
  appendChildren,
  showCannedResponseButton,
}: ToolbarProps) {
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [cannedResponseModalOpen, setCannedResponseModalOpen] = useState(false)
  const [isFileUploaderOpen, setFileUploaderOpen] = useState(false)
  const { settings } = useAdminUserContext()

  if (!editor) {
    return null
  }

  function handleLinkClick() {
    if (editor.isActive('link')) {
      editor.chain().focus().unsetLink().run()
    } else {
      setLinkModalOpen(true)
    }
  }

  function handleLinkSubmit(url: string) {
    const hasHttp = url.startsWith('http://') || url.startsWith('https://')

    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: hasHttp ? url : `//${url}`, target: '_blank' })
        .run()
    }
    setLinkModalOpen(false)
  }

  async function onBytescaleUpload(files: FileUploaderResult[]) {
    const normalizedFiles: WysiwygFileUpload[] = files.map((file) => ({
      mimetype: file.originalFile.mime,
      url: file.filePath,
      filename: file.originalFile.originalFileName,
      isFilestack: false,
    }))

    onFilesUploaded(normalizedFiles)
    setFileUploaderOpen(false)
  }

  function handleTextStyleChange(value: string) {
    if (value === 'paragraph') {
      editor.chain().focus().setParagraph().run()
    } else {
      editor
        .chain()
        .focus()
        .setHeading({ level: parseInt(value.slice(-1)) as Level })
        .run()
    }
  }

  function handleCannedResponseSelect(selectedResponse: string) {
    editor.chain().focus().insertContent(selectedResponse).run()
    setCannedResponseModalOpen(false)
  }

  const selectedTextStyle = editor.isActive('heading') ? `h${editor.getAttributes('heading').level}` : 'paragraph'

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-3 tw-rounded-t-md tw-bg-neutral-100 tw-p-2">
      {prependChildren}
      <ToolbarButtonGroup>
        <SelectBox options={textStyleOptions} handleChange={handleTextStyleChange} selectedValue={selectedTextStyle} />
      </ToolbarButtonGroup>
      <ToolbarButtonGroup>
        <ToolbarButton
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          isActive={editor.isActive('undo')}
          name="undo"
        >
          <Undo className="lu-md" />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          isActive={editor.isActive('redo')}
          name="redo"
        >
          <Redo className="lu-md" />
        </ToolbarButton>
      </ToolbarButtonGroup>
      {showCannedResponseButton && (
        <ToolbarButtonGroup>
          <AdminCannedResponseModal
            open={cannedResponseModalOpen}
            setOpen={setCannedResponseModalOpen}
            onSelect={handleCannedResponseSelect}
          />
          <ToolbarButton name="canned-response" onClick={() => setCannedResponseModalOpen(true)}>
            <Plus className="lu-md" />
          </ToolbarButton>
        </ToolbarButtonGroup>
      )}
      <ToolbarButtonGroup>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          isActive={editor.isActive('bold')}
          name="bold"
        >
          <Bold className="lu-md" />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          isActive={editor.isActive('italic')}
          name="italic"
        >
          <Italic className="lu-md" />
        </ToolbarButton>
        <ToolbarButton
          name="strike"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          isActive={editor.isActive('strike')}
        >
          <Strikethrough className="lu-md" />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          isActive={editor.isActive('underline')}
          name="underline"
        >
          <UnderlineIcon className="lu-md" />
        </ToolbarButton>
      </ToolbarButtonGroup>
      <ToolbarButton
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        disabled={!editor.can().chain().focus().toggleBlockquote().run()}
        isActive={editor.isActive('blockquote')}
        name="blockquote"
      >
        <QuoteIcon className="lu-md" />
        &nbsp;Exact Copy
      </ToolbarButton>
      <ToolbarButtonGroup>
        <ToolbarButton
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          isActive={editor.isActive({ textAlign: 'left' })}
          name="align-left"
        >
          <AlignLeft className="lu-md" />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          isActive={editor.isActive({ textAlign: 'center' })}
          name="align-center"
        >
          <AlignCenter className="lu-md" />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          isActive={editor.isActive({ textAlign: 'right' })}
          name="align-right"
        >
          <AlignRight className="lu-md" />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().setTextAlign('justify').run()}
          isActive={editor.isActive({ textAlign: 'justify' })}
          name="align-justify"
        >
          <AlignJustify className="lu-md" />
        </ToolbarButton>
      </ToolbarButtonGroup>
      <ToolbarButtonGroup>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive('bulletList')}
          name="bullet-list"
        >
          <List className="lu-md" />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive('orderedList')}
          name="ordered-list"
        >
          <ListOrdered className="lu-md" />
        </ToolbarButton>
      </ToolbarButtonGroup>

      {<ModalFileUploader isOpen={isFileUploaderOpen} setIsOpen={setFileUploaderOpen} onComplete={onBytescaleUpload} />}
      {settings?.filestackApiKey && (
        <ToolbarButton onClick={() => setFileUploaderOpen(true)} name="image">
          <ImageIcon className="lu-md" />
        </ToolbarButton>
      )}
      <LinkModal open={linkModalOpen} setOpen={setLinkModalOpen} onSubmit={handleLinkSubmit} />
      <ToolbarButton onClick={handleLinkClick} isActive={editor.isActive('link')} name="link">
        <LinkIcon className="lu-md" />
      </ToolbarButton>
      {appendChildren}
    </div>
  )
}
