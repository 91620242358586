import { ReactNode, JSX } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton, { getClassNames } from 'lib/components/buttons/icon-button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { PopoverContent, Popover, PopoverTrigger } from '../popover/popover'
import { cn } from 'lib/util/cn'

interface IconFlyoutProps {
  children: ReactNode
  color: 'primary' | 'secondary' | 'transparent' | 'danger' | 'success'
  icon: IconProp
  size?: 'xs' | 'sm' | 'lg' | 'xl' | '2xl'
  collisionPadding?: number
  forceRenderOnTop?: boolean
  invert?: boolean
  active?: boolean
}

const classNames = {
  menuItem: `tw-border-none
    hover:tw-bg-neutral-100
    tw-bg-white
    tw-w-full
    tw-px-5
    tw-py-2
    tw-text-left
    tw-text-neutral-800
    tw-cursor-pointer
    tw-block
  `,
}

interface ItemProps {
  children: ReactNode
  onClick: () => void
  className?: string
}

function Link({
  children,
  url,
  target = '_blank',
  ...otherProps
}: {
  children: ReactNode
  url: string
  target?: string
}): JSX.Element {
  return (
    <a className={classNames.menuItem} href={url} target={target} rel="noreferrer" {...otherProps}>
      {children}
    </a>
  )
}

function Button({ children, onClick, className, ...otherProps }: ItemProps): JSX.Element {
  return (
    <button className={cn(classNames.menuItem, className)} onClick={onClick} {...otherProps}>
      {children}
    </button>
  )
}

function ContainerIconFlyoutMenu({
  invert,
  active,
  children,
  color,
  icon,
  size = 'sm',
  collisionPadding = 20,
  forceRenderOnTop = false,
}: IconFlyoutProps): JSX.Element {
  return (
    <Popover>
      <PopoverTrigger className={getClassNames({ size, color, active, invert })} asChild>
        <IconButton color="transparent" dataTestid={`icon-menu-button-${icon[1]}`}>
          <FontAwesomeIcon icon={icon as IconProp} size={size} />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent
        className="tw-flex tw-w-max tw-min-w-48 tw-flex-col tw-items-start tw-rounded tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-p-0 tw-py-1 tw-text-neutral-800 tw-shadow-lg"
        intent="flyout"
        side={forceRenderOnTop ? 'top' : 'bottom'}
        align="start"
        collisionPadding={collisionPadding}
      >
        {children}
      </PopoverContent>
    </Popover>
  )
}

export const IconFlyoutMenu = Object.assign(ContainerIconFlyoutMenu, { Button, Link })
