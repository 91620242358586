import { useEffect, useState } from 'react'
import { getPresignedUrl } from 'lib/api/file-urls/file-urls'
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import { FileWarning, LoaderCircle } from 'lucide-react'

export default function AssetVideo(props: NodeViewProps) {
  const [resolvedSrc, setResolvedSrc] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchPresignedUrl() {
      const assetId = props.node.attrs['data-asset-id']
      const directSrc = props.node.attrs.src

      if (directSrc) {
        setResolvedSrc(directSrc)
        return
      }

      if (!assetId) return
      setIsLoading(true)
      try {
        const presignedUrl = await getPresignedUrl(assetId)
        setResolvedSrc(presignedUrl)
      } catch (error) {
        console.error('Failed to get presigned URL:', error)
        setResolvedSrc(undefined)
      } finally {
        setIsLoading(false)
      }
    }

    fetchPresignedUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.node.attrs['data-asset-id'], props.node.attrs.src])

  const { class: classNames, ...otherHTMLAttributes } = props.extension.options.HTMLAttributes

  return (
    <NodeViewWrapper>
      {isLoading ? (
        <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-neutral-100 tw-p-4 tw-text-sm tw-italic tw-text-neutral-500">
          <LoaderCircle className="lu-sm tw-mr-2 tw-animate-spin" />
          Loading...
        </div>
      ) : resolvedSrc ? (
        <video
          {...otherHTMLAttributes}
          className={classNames}
          src={resolvedSrc}
          data-asset-id={props.node.attrs['data-asset-id']}
          controls
        />
      ) : (
        <div className="tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-neutral-100 tw-p-4 tw-text-sm tw-italic tw-text-neutral-500">
          <FileWarning className="lu-sm tw-mr-2" />
          Failed to load video
        </div>
      )}
    </NodeViewWrapper>
  )
}
