import Button from 'components/core/button'
import { getUsersForCompany, searchCompanies } from 'lib/api/admin/companies/admin-companies'
import SelectBox, { SelectBoxOption } from 'lib/components/dropdown/select-box'
import Modal from 'lib/components/modals/modal'
import { toast } from 'lib/components/toast/toast'
import { LoaderCircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useAdminTicketContext } from '../../providers/admin-ticket-provider'
import { sortBy } from 'lodash'

interface TransferRequestModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const TransferRequestModal = ({ open, setOpen }: TransferRequestModalProps) => {
  const { transferAndUpdateTicket } = useAdminTicketContext()
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [companySelectOptions, setCompanySelectOptions] = useState<SelectBoxOption[]>([])
  const [companyOptionsLoading, setCompanyOptionsLoading] = useState(true)
  const [userOptionsLoading, setUserOptionsLoading] = useState(false)
  const [userSelectOptions, setUserSelectOptions] = useState<SelectBoxOption[]>([])
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    setSelectedUser(null)
    setCompany(null)
    setOpen(false)
  }

  const setCompany = async (companyId: number) => {
    setSelectedCompany(companyId)
    setUserSelectOptions([])

    setUserOptionsLoading(true)
    try {
      const users = await getUsersForCompany(companyId)
      setUserSelectOptions(
        sortBy(users, 'fullName').map(({ id, fullName }) => ({
          value: id,
          label: fullName,
          displayElement: <>{fullName}</>,
        })),
      )
    } catch (error) {
      console.error('Error fetching users', error)
      toast.error('Error fetching users for selected company')
    } finally {
      setUserOptionsLoading(false)
    }
  }

  const transferTicket = async () => {
    setLoading(true)
    try {
      await transferAndUpdateTicket(selectedCompany, selectedUser)
      toast.success('Successfully transfered ticket')
      closeModal()
    } catch (error) {
      console.error('Error transferring ticket', error)
      toast.error('Error transferring ticket')
    }
    setLoading(false)
  }

  const loadCompaniesList = async () => {
    try {
      const companies = await searchCompanies('')
      const sortedCompanyOptions = sortBy(companies, 'name').map(({ id, name }) => ({
        value: id,
        label: name,
        displayElement: <>{name}</>,
      }))
      setCompanySelectOptions(sortedCompanyOptions)
    } catch (error) {
      console.error('Error fetching companies', error)
      toast.error('Error fetching companies')
    } finally {
      setCompanyOptionsLoading(false)
    }
  }

  useEffect(() => {
    if (open && companySelectOptions.length === 0) {
      loadCompaniesList()
    }
  }, [companySelectOptions.length, open])

  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <Modal.Body setOpen={setOpen} closeButton>
        <h4>Transfer Request</h4>
        <h5>Are you sure you want to transfer this request?</h5>
        <p>This will transfer the request and ticket to the user in the specified company.</p>
        <div className="tw-flex tw-flex-row tw-gap-1">
          <SelectBox
            searchable
            className="tw-flex-1"
            disabled={companySelectOptions.length === 0}
            options={companySelectOptions}
            handleChange={setCompany}
            selectedValue={selectedCompany}
            label="Company"
            labelClassName="tw-text-neutral-800"
          />
          {companyOptionsLoading && (
            <LoaderCircleIcon className="tw-mt-6 tw-animate-spin" data-testid="company-loader" />
          )}
        </div>
        <br />
        <div className="tw-flex tw-flex-row tw-gap-1">
          <SelectBox
            searchable
            className="tw-flex-1"
            disabled={userSelectOptions.length === 0}
            options={userSelectOptions}
            handleChange={(user) => setSelectedUser(user)}
            selectedValue={selectedUser}
            label="User"
            labelClassName="tw-text-neutral-800"
          />
          {userOptionsLoading && <LoaderCircleIcon className="tw-mt-6 tw-animate-spin" data-testid="user-loader" />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-mt-8 tw-flex tw-justify-end tw-gap-2">
          <Button type="button" color="lightGray" onClick={closeModal}>
            Close
          </Button>
          <Button type="button" color="red" disabled={selectedUser === null} onClick={transferTicket}>
            {loading ? (
              <LoaderCircleIcon className="tw-animate-spin" data-testid="transfer-loader" />
            ) : (
              'Transfer request'
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default TransferRequestModal
