/**
 * Hook to access environment variables that are injected into window.DPP_ENV
 * Source: app/views/layouts/_client_environment_variables.html.haml
 */
export const useEnvVars = () => {
  const env = window.DPP_ENV

  if (!env) {
    throw new Error(
      'Environment variables not found. Make sure _environment_variables.html.haml is included in your layout.',
    )
  }

  return {
    bytescalePublicApiKey: env?.bytescalePublicApiKey,
    bytescaleS3AliasBucket: env?.bytescaleS3AliasBucket,
  } as const
}
