import { useMutation } from '@tanstack/react-query'
import Button from 'components/core/button'
import { ProjectTemplate, SkillWithCategoryFields } from 'interfaces/project-template'
import { TicketTemplate } from 'interfaces/ticket_template'
import { request } from 'lib/api/fetch-api'
import CopyTextButton from 'lib/components/buttons/copy-text-button'
import IconButton from 'lib/components/buttons/icon-button'
import { Drawer } from 'lib/components/drawer/drawer'
import { toast } from 'lib/components/toast/toast'
import { PATHS } from 'lib/constants/paths'
import { ArrowRightToLine, LinkIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSkillsContext } from '../request/skills/skills-provider'
import TicketTemplatesTable from './ticket-templates-table'
import Search from 'components/core/search'
import ProjectsPartnerPreview from 'components/elements/projects/project-partner-preview'
import Link from 'lib/components/link/link'
import TemplateRequestsTable from 'components/elements/projects/template-requests-table'
import Tooltip from 'lib/components/tooltip/tooltip'

export interface CreateProjectDrawerProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  projectTemplate: ProjectTemplate
}

export default function CreateProjectDrawer({ isOpen, setOpen, projectTemplate }: CreateProjectDrawerProps) {
  const [selectedTicketTemplates, setSelectedTicketTemplates] = useState<SkillWithCategoryFields[]>([])
  const [selectedSkills, setSelectedSkills] = useState<SkillWithCategoryFields[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [showAllTemplates, setShowAllTemplates] = useState(false)
  const isBlankTemplate = projectTemplate?.blankTemplate
  const { availableSkills } = useSkillsContext()
  const [isLoading, setIsLoading] = useState(false)

  const filteredTicketTemplates = searchValue
    ? projectTemplate?.ticketTemplates?.filter((template) =>
        template.skill.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : projectTemplate?.ticketTemplates

  const createProjectMutation = useMutation({
    mutationFn: () => {
      return request({
        endpoint: 'createProject',
        body: {
          name: projectTemplate?.name,
          description: projectTemplate?.description,
          project_template_id: projectTemplate?.id || null,
          tickets: {
            skill_ids: selectedTicketTemplates.map((skill) => skill.id),
          },
        },
      })
    },
    onSuccess: (response) => {
      toast.success('Project created')
      window.location.href = `${PATHS.REQUESTS}?tab=draft&projectId=${response.data.id}`
    },
    onError: () => {
      toast.error('Failed to create project')
    },
  })

  function handleCreateProject() {
    setIsLoading(true)
    createProjectMutation.mutate()
  }

  const addRequests = () => {
    setSelectedTicketTemplates(selectedSkills)
    setShowAllTemplates(false)
  }

  const navToShowAllTemplates = () => {
    setShowAllTemplates(true)
    setSelectedSkills(selectedTicketTemplates)
  }

  const cancelAddingRequests = () => {
    setShowAllTemplates(false)
    setSelectedSkills(selectedTicketTemplates)
  }

  useEffect(() => {
    if (isBlankTemplate) {
      setSelectedTicketTemplates([])
    } else {
      setSelectedTicketTemplates(
        projectTemplate?.ticketTemplates
          ?.filter((template) => availableSkills.some((skill) => skill.id === template.skill.id))
          .map((ticketTemplate) => ticketTemplate.skill) || [],
      )
    }
  }, [availableSkills, isBlankTemplate, projectTemplate?.ticketTemplates])

  return (
    <Drawer isOpen={isOpen} setOpen={setOpen} size="md">
      <Drawer.Header setOpen={setOpen} showCloseButton={false}>
        <div className="tw-flex tw-items-center tw-gap-4">
          <IconButton size="xs" color="transparent" onClick={() => setOpen(false)} className="tw-mr-2">
            <ArrowRightToLine className="tw-text-neutral-700" />
          </IconButton>
          <img src={projectTemplate?.imageUrl} alt={projectTemplate?.name} className="tw-h-20 tw-w-20 tw-rounded" />
          <div className="tw-w-full tw-overflow-hidden">
            <Tooltip content={projectTemplate?.name}>
              <h4 className="tw-truncate">{projectTemplate?.name}</h4>
            </Tooltip>
            <div>
              {projectTemplate?.description}
              {projectTemplate?.partner && (
                <div className="tw-flex tw-items-center tw-gap-2">
                  <Link href={projectTemplate.partner.blogPostLink} target="_blank">
                    View the full guided recipe here
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="tw-ml-auto">
            <CopyTextButton textToCopy={window.location.href} defaultIcon={<LinkIcon />} />
          </div>
        </div>
      </Drawer.Header>
      <Drawer.Body>
        {isLoading ? (
          <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center">
            <img
              src="/images/progress/pickle-rocket-2.gif"
              alt="Pickle Rocket"
              className="tw-h-auto tw-w-1/2"
              data-testid="pickle-rocket"
            />
            <h5>Creating your project</h5>
            <p className="tw-text-sm tw-text-neutral-500">One moment please...</p>
          </div>
        ) : (
          <div>
            {projectTemplate?.partner && <ProjectsPartnerPreview partner={projectTemplate?.partner} />}
            {isBlankTemplate && (
              <BlankTemplateHeader
                filteredTicketTemplates={filteredTicketTemplates}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
            {showAllTemplates ? (
              <TicketTemplatesTable
                setSelectedTicketTemplates={setSelectedSkills}
                selectedTicketTemplates={selectedSkills}
              />
            ) : (
              <TemplateRequestsTable
                isCustomer
                template={projectTemplate}
                selectedRows={selectedTicketTemplates}
                setSelectedRows={setSelectedTicketTemplates}
                onAddRequestsClick={navToShowAllTemplates}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                isBlankTemplate={isBlankTemplate}
              />
            )}
            <div className="tw-sticky tw-bottom-0 tw-flex tw-w-full tw-justify-between tw-bg-white tw-py-4">
              <h5>{selectedTicketTemplates.length} requests selected</h5>
              <div className="tw-flex tw-gap-2">
                <Button color="lightGray" onClick={showAllTemplates ? cancelAddingRequests : () => setOpen(false)}>
                  Cancel
                </Button>
                <Button
                  color="purple"
                  disabled={
                    isLoading || showAllTemplates ? selectedSkills.length === 0 : selectedTicketTemplates.length === 0
                  }
                  onClick={showAllTemplates ? addRequests : handleCreateProject}
                >
                  {showAllTemplates ? 'Add requests to project' : 'Create Project'}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Drawer.Body>
    </Drawer>
  )
}

interface BlankTemplateHeaderProps {
  filteredTicketTemplates: TicketTemplate[]
  searchValue: string
  setSearchValue: (value: string) => void
}

const BlankTemplateHeader = ({ filteredTicketTemplates, searchValue, setSearchValue }: BlankTemplateHeaderProps) => {
  return (
    <>
      <h4 className="tw-m-0 tw-mb-4 tw-mt-2 tw-text-xl">Select request(s) to add to this project</h4>
      <div className="tw-mb-4 tw-flex tw-items-center tw-gap-2">
        <Search
          name="create-search"
          value={searchValue}
          onChange={setSearchValue}
          placeholder="Search requests"
          className="tw-w-full"
        />
        <h5 className="tw-my-2 tw-min-w-fit">{filteredTicketTemplates?.length} requests found</h5>
      </div>
    </>
  )
}
