import { cn } from 'lib/util/cn'
import { forwardRef, RefObject, useRef, useState } from 'react'
import Badge from '../badge/badge'

type Size = 'xl' | 'lg' | 'md' | 'sm' | 'custom'

interface CardProps {
  size: Size
  children: JSX.Element | JSX.Element[]
  onClick: () => void
  className?: string
  isSelected?: boolean
  isSquare?: boolean
  disabled?: boolean
  disableZoom?: boolean
}

const classNames = {
  card: {
    default: `tw-rounded-lg
              tw-ring-2
              tw-ring-neutral-200
              tw-bg-neutral-50
              tw-flex
              tw-flex-col
              tw-justify-between
              tw-cursor-pointer
              tw-shadow-md
              tw-transition-all
              hover:tw-shadow-xl`,
    selected: `tw-bg-cornflower-100 tw-ring-cornflower-500`,
    sm: `tw-w-32`,
    md: `tw-w-40`,
    lg: `tw-w-64`,
    xl: `tw-w-80`,
    custom: '',
  },
  footer: {
    default: `tw-p-2 tw-m-0 tw-text-neutral-800 tw-rounded-b-lg tw-flex tw-items-center`,
    selected: '',
  },
  body: {
    default: `tw-rounded-t-lg`,
    selected: '',
  },
}

type ClassNameKeys = keyof typeof classNames

function generatedClassNames(
  name: ClassNameKeys,
  size: Size = null,
  isSelected = false,
  square = false,
  disabled = false,
): string {
  return `
    ${classNames[name].default}
    ${size ? classNames[name][size] : ''}
    ${isSelected ? classNames[name].selected : ''}
    ${square ? classNames[name][size].replace('-w-', '-h-') : ''}
    ${disabled ? 'tw-opacity-50 tw-cursor-not-allowed hover:tw-shadow-md' : ''}
  `
}

function BaseCard(
  {
    size,
    children,
    onClick,
    className = '',
    isSelected = false,
    isSquare = false,
    disabled = false,
    disableZoom = false,
  }: CardProps,
  ref?: RefObject<HTMLDivElement>,
): JSX.Element {
  const [hovering, setHovering] = useState(false)
  const style = hovering && !disableZoom && !disabled ? { transform: 'scale(1.02)' } : {}
  const localRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className={cn(generatedClassNames('card', size, isSelected, isSquare, disabled), className)}
      style={style}
      onClick={() => !disabled && onClick()}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      data-testid="card"
      ref={ref || localRef}
    >
      {children}
    </div>
  )
}

interface BodyProps {
  children: JSX.Element | JSX.Element[]
  centered?: boolean
  className?: string
}

function Body({ children, className = '', centered = false }: BodyProps) {
  const centeredClassNames = 'tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full'

  return (
    <div className={cn(generatedClassNames('body'), className, centered ? centeredClassNames : '')}>{children}</div>
  )
}

interface FooterProps {
  children: JSX.Element | JSX.Element[] | string
  className?: string
  style?: React.CSSProperties
}

function Footer({ children, className = '', style }: FooterProps): JSX.Element {
  return (
    <h5 className={cn(generatedClassNames('footer'), className)} style={style}>
      {children}
    </h5>
  )
}

export const Card = Object.assign(forwardRef(BaseCard), { Footer, Body })

interface HorizontalCardProps {
  image: string
  title: string
  description: string
  onClick?: () => void
}

export function HorizontalCard({ image, title, description, onClick }: HorizontalCardProps) {
  return (
    <div
      className="tw-flex tw-h-16 tw-w-64 tw-gap-2 tw-overflow-hidden tw-rounded-lg tw-border tw-bg-white tw-shadow-sm tw-transition-all tw-duration-300 hover:tw-cursor-pointer hover:tw-shadow-xl"
      onClick={onClick}
    >
      <img src={image} alt={title} className="tw-h-full tw-w-16 tw-rounded-l-lg" />
      <div className="tw-w-full tw-overflow-hidden tw-p-2">
        <h4 className="tw-m-0 tw-truncate tw-text-sm tw-font-semibold">{title}</h4>
        <Badge color="light" className="tw-mt-2 tw-max-w-full tw-text-xs tw-font-normal">
          <div className="tw-truncate">{description}</div>
        </Badge>
      </div>
    </div>
  )
}
