import { ChangeEvent, ReactElement, useState } from 'react'
import Textarea from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { toast } from 'sonner'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'

interface TimelineCommentFormProps {
  onSend: (body: string) => void | Promise<void>
}

const classNames = {
  container: 'tw-flex tw-items-center tw-gap-2 tw-pb-4',
  textarea: 'tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-p-2',
}

export default function TimelineCommentForm({ onSend }: TimelineCommentFormProps): ReactElement {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const [renderKey, setRenderKey] = useState(1)
  const [disabled, setDisabled] = useState(false)
  const [value, setValue] = useState('')

  const onTextAreaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    onChange(newValue)
  }

  const onChange = (newValue: string) => {
    setValue(newValue)
  }

  const clear = () => {
    setValue('')
    setRenderKey((prev) => prev + 1)
  }

  async function handleAddClick() {
    if (disabled) {
      return
    }

    const response = onSend(value)
    if (response instanceof Promise) {
      try {
        setDisabled(true)
        await response
        clear()
      } catch (error) {
        console.error('Error adding comment', error)
        toast.error('Error posting comment.')
      }
      setDisabled(false)
    } else {
      clear()
    }
  }

  return (
    <div className={classNames.container}>
      {isFeatureFlagEnabled('request_wysiwyg') ? (
        <WYSIWYGTextarea
          disabled={disabled}
          key={renderKey}
          placeholder="Start typing your reply."
          onChange={onChange}
        />
      ) : (
        <Textarea
          value={value}
          disabled={disabled}
          onChange={onTextAreaChange}
          className={classNames.textarea}
          maxLength={-1}
          placeholder="Start typing your reply."
          rows="2"
          resize={{ vertical: true }}
        />
      )}
      <IconButton onClick={handleAddClick} icon={['far', 'paper-plane']} color="primary" />
    </div>
  )
}
