import { Annotation, AnnotationRecord, VideoAnnotationData } from 'lib/api/annotations/annotations'
import {
  bulkCreateTicketFiles,
  createTicketFile,
  deleteTicketFile,
  getTicketFile,
  getTicketFiles,
  retryExtractPages,
  TicketFile,
  TicketFilePayload,
  VideoJSAnnotation,
} from 'lib/api/ticket-files/ticket-files'
import { DesignRequest, EditMode } from 'lib/api/tickets/tickets'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { FileUploaderResult, ModalFileUploader } from 'lib/components/file-uploader/file-uploader'
import { toast } from 'lib/components/toast/toast'
import { getFileExtension, getPreviewUrl, getShareLinkUrl, isPlaceholder } from 'lib/util/file/file'
import { useUserContext } from 'providers/user-provider'
import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FileExportModalMode } from './file-download-modal'
import { useS3PreviewImage } from 'lib/hooks/useS3PreviewImage'
import { GenAiResultTypes, getGenAiResultType } from 'lib/api/gen-ai/gen-ai-requests'

interface ContextProps {
  editMode: EditMode
  children: ReactNode
  fetchAndSetTicket?: () => void
  isCollaboratorView?: boolean
  showRevisions?: boolean
  useToken?: boolean
  ticket: DesignRequest
  fromSources?: string[]
}

export interface MediaContextValue {
  addFiles: (imageableId: number, files: TicketFile[]) => void
  addStockAssets: (newFiles: TicketFile[]) => void
  deleteFile: (file: TicketFile) => void
  editMode: EditMode
  extractedFilesPollingFailed: boolean
  extractedPreviewIndex: number
  fetchAndSetTicket: () => void
  files: TicketFile[]
  filters: MediaFilters
  getFileById: (id: number, parentId?: number) => TicketFile
  getFilePageNumber: (parentId: number, childId: number) => number
  getParentFileName: (parentId: number) => string
  isCollaboratorView?: boolean
  isMediaLoaded: boolean
  open: () => void
  retryExtractingPages: () => Promise<void>
  selectedFile: TicketFile
  selectedVersion: number
  selectFileById: (fileId: number, parentId?: number) => void
  setExtractedPreviewIndex: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<MediaFilters>>
  setSelectedVersion: Dispatch<SetStateAction<number>>
  showRevisions: boolean
  ticket: DesignRequest
  updateFileAnnotations: (file: TicketFile, newAnnotations: Annotation[]) => void
  updateTicketFileVideoAnnotations: (ticketFileId: number, annotations: VideoAnnotationData[]) => void
  zipFile: TicketFile
  isZip: boolean
  zipIsExtracting: boolean
  refreshTicketFiles: () => void
  showPromptModal: boolean
  setShowPromptModal: (open: boolean) => void
  isTaggingDrawerOpen: boolean
  setIsTaggingDrawerOpen: (open: boolean) => void
  fileExportModalMode: FileExportModalMode
  setFileExportModalMode: (mode: FileExportModalMode) => void
  currentTicketFile: TicketFile
  shareLinkUrl: string
  genAiResultType: GenAiResultTypes
  previewUrl: string
  isPlaceholder: boolean
  refreshTicketFile: (ticketFileId: number) => void
  useToken: boolean
  token: string
}

interface MediaFilters {
  version: number
}

const ALL_VERSIONS = 0

const MediaContext = createContext({})

function determineFilesInVersion(files: TicketFile[], version: number): number {
  if (!version) {
    return files.length
  }
  const userFilesInVersion = files.filter((file) => {
    return !file.uploadedByCreative && file.ticketVersion === version
  })
  return userFilesInVersion.length
}

function findNextImageFile(currentFiles: TicketFile[], fromIndex: number) {
  const nextFiles = currentFiles.slice(fromIndex)
  return nextFiles[0]
}

function findPreviousImageFile(currentFiles: TicketFile[], endIndex: number) {
  const previousFiles = currentFiles.slice(0, endIndex).reverse()
  return previousFiles[0]
}

function getNextFile(currentFiles: TicketFile[], fromIndex = 0, reverse = false) {
  if (reverse) {
    const previousImageFile = findPreviousImageFile(currentFiles, fromIndex)
    if (previousImageFile) {
      return previousImageFile
    }
    return findNextImageFile(currentFiles, fromIndex)
  } else {
    const nextImageFile = findNextImageFile(currentFiles, fromIndex)
    if (nextImageFile) {
      return nextImageFile
    }
    return findPreviousImageFile(currentFiles, fromIndex)
  }
}

function sortFiles(ticket: DesignRequest, files: TicketFile[]): TicketFile[] {
  const featuredFile = ticket.featuredFileId ? files.find((file) => file.id === ticket.featuredFileId) : null
  const chronSorter = (a: TicketFile, b: TicketFile) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()

  if (featuredFile) {
    const nonFeaturedFiles = files.filter((file) => file.id !== ticket.featuredFileId).sort(chronSorter)
    const insertIndex = nonFeaturedFiles.findIndex((file) => file.ticketVersion === featuredFile.ticketVersion)

    nonFeaturedFiles.splice(insertIndex, 0, featuredFile)

    return nonFeaturedFiles
  }

  return files.sort(chronSorter)
}

async function sendBytescaleMetadata(ticketId: number, file: FileUploaderResult, token?: string): Promise<TicketFile> {
  const s3_filename = file.filePath.split('/').pop()
  const mimetype = file.originalFile.mime
  const filename = file.originalFile.originalFileName

  return await createTicketFile(
    ticketId,
    {
      filename,
      s3_filename,
      mimetype,
    } as TicketFilePayload,
    token ?? '',
  )
}

function updateExtractableFile(oldFile: TicketFile, newFile: TicketFile): TicketFile {
  const { extractedPages, previewUrl } = newFile
  return {
    ...oldFile,
    previewUrl,
    extractedPages,
  }
}

export function useMediaContext(): MediaContextValue {
  return useContext(MediaContext) as MediaContextValue
}

export default function MediaProvider({
  children,
  editMode,
  fetchAndSetTicket = () => null,
  isCollaboratorView = false,
  showRevisions = false,
  useToken = false,
  ticket,
}: ContextProps): ReactElement {
  const { token } = useUserContext()

  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const [files, setFiles] = useState<TicketFile[]>([])
  const [filters, setFilters] = useState<MediaFilters>({ version: null })
  const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<TicketFile>(null)
  const [selectedVersion, setSelectedVersion] = useState<number>(ticket?.currentVersion || 1)
  const [extractedPreviewIndex, setExtractedPreviewIndex] = useState<number>(0)
  const [extractedFilesPollingFailed, setExtractedFilesPollingFailed] = useState<boolean>(false)
  const [isExtractedPollingAbandoned, setIsExtractedPollingAbandoned] = useState<boolean>(false)
  const [isExtractingPages, setIsExtractingPages] = useState<boolean>(false)
  const [timeoutUntilNextPoll, setTimeoutUntilNextPoll] = useState<number>(null)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [showPromptModal, setShowPromptModal] = useState<boolean>(false)
  const [isTaggingDrawerOpen, setIsTaggingDrawerOpen] = useState<boolean>(false)
  const [fileExportModalMode, setFileExportModalMode] = useState<FileExportModalMode>(null)
  const isZip = getFileExtension(selectedFile?.name) === 'zip'

  const recursivelyPollUntilPagesExtracted = useCallback(
    (pollCount = 1, timeoutUntilNextPoll = 30000) => {
      getTicketFile(ticket.id, selectedFile.id).then((latestFile) => {
        if (isExtractedPollingAbandoned || !(latestFile.isExtractable || isZip)) {
          return
        }

        if (latestFile.extractedPages.length > 0) {
          setFiles((currentFiles) => {
            return currentFiles.map((file) => {
              if (file.id === latestFile.id) {
                return updateExtractableFile(file, latestFile)
              }
              return file
            })
          })

          setSelectedFile((current) => {
            return updateExtractableFile(current, latestFile)
          })
          setIsExtractedPollingAbandoned(false)
          setIsExtractingPages(false)
        } else if (isZip && latestFile.zipFinishedExtracting) {
          setSelectedFile(latestFile)
          setRefresh(!refresh)
          setIsExtractingPages(false)
          setIsExtractedPollingAbandoned(false)
        } else if (pollCount < 5) {
          setTimeoutUntilNextPoll(
            window.setTimeout(
              () => recursivelyPollUntilPagesExtracted(pollCount + 1, timeoutUntilNextPoll),
              timeoutUntilNextPoll * pollCount,
            ),
          )
        } else {
          setExtractedFilesPollingFailed(true)
          setIsExtractedPollingAbandoned(false)
          setIsExtractingPages(false)
          window.clearTimeout(timeoutUntilNextPoll)
          toast.error('There was an error extracting the pages from this file. Click the retry button to try again.')
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExtractedPollingAbandoned, selectedFile?.id, ticket?.id, timeoutUntilNextPoll, isZip],
  )

  const retryExtractingPages = useCallback(async () => {
    setExtractedFilesPollingFailed(false)

    try {
      await retryExtractPages(ticket.id, selectedFile.id)
      setIsExtractedPollingAbandoned(false)
      setIsExtractingPages(true)
      recursivelyPollUntilPagesExtracted()
    } catch (e) {
      toast.error('There was an error retrying the extraction. Please try again.')
      console.error('Error retrying extraction', e)
    }
  }, [recursivelyPollUntilPagesExtracted, selectedFile?.id, ticket?.id])

  const numberOfFilesInCurrentVersion = useMemo(
    () => determineFilesInVersion(files, ticket?.currentVersion),
    [files, ticket?.currentVersion],
  )

  async function addFiles(genAiRequestID: number, newFiles: TicketFile[]) {
    const filesFromResponse = await bulkCreateTicketFiles(ticket.id, genAiRequestID, newFiles)
    setFiles((current) => [...filesFromResponse, ...current])
    const file = getNextFile(filesFromResponse)
    setSelectedFile(file)
    fetchAndSetTicket()
  }

  function addFile(response: PromiseSettledResult<TicketFile>) {
    if (response.status === 'fulfilled') {
      setFiles((current) => [response.value, ...current])
    } else {
      toast.error('Oops! Something went wrong while uploading. Please try again')
    }
  }

  function updateFileAnnotations(file: TicketFile, newAnnotations: Annotation[]) {
    const updateFileFromCurrent = (currentFiles: TicketFile[]) => {
      return currentFiles.map((currentFile) => {
        if (currentFile.isExtractable) {
          const extractedPages = currentFile.extractedPages.map((pdf) => {
            if (pdf.id === file.id) {
              pdf.annotations = newAnnotations
              return pdf
            }
            return pdf
          })

          return {
            ...currentFile,
            extractedPages,
          }
        }

        if (currentFile.id === file.id) {
          file.annotations = newAnnotations
          return file
        }
        return currentFile
      })
    }

    setFiles(updateFileFromCurrent)

    if (selectedFile.id === file.id) {
      setSelectedFile((previous: TicketFile) => {
        return {
          ...previous,
          annotations: newAnnotations,
        }
      })
    } else if (selectedFile.isExtractable) {
      setSelectedFile((previous: TicketFile) => {
        return {
          ...previous,
          extractedPages: previous.extractedPages.map((pdf) => {
            if (pdf.id === file.id) {
              pdf.annotations = newAnnotations
              return pdf
            }
            return pdf
          }),
        }
      })
    }
  }

  function getFileById(id: number, parentId?: number) {
    if (parentId) {
      const parent = files.find((f) => f.id === parentId)
      return parent.extractedPages.find((f) => f.id === id)
    }
    return files.find((f) => f.id === id)
  }

  function updateTicketFileVideoAnnotations(ticketFileId: number, annotations: VideoAnnotationData[]) {
    setFiles((previous: TicketFile[]) => {
      return previous.map((currentFile) => {
        if (currentFile.id === ticketFileId) {
          currentFile.annotations = annotations as AnnotationRecord<VideoJSAnnotation>[]
        }
        return currentFile
      })
    })

    if (selectedFile.id === ticketFileId) {
      setSelectedFile((previous: TicketFile) => {
        return {
          ...previous,
          annotations: annotations as AnnotationRecord<VideoJSAnnotation>[],
        }
      })
    }
  }

  function addStockAssets(newFiles: TicketFile[]) {
    const noErrorAssets = newFiles.filter((newFile) => !newFile.errors)

    setFiles((current) => {
      return [...noErrorAssets, ...current]
    })

    fetchAndSetTicket()

    if (newFiles.some((newFile) => !!newFile.errors)) {
      toast.error('Oops! There was an error choosing images. Please try again')
    }

    if (noErrorAssets) {
      const file = getNextFile(noErrorAssets)
      setSelectedFile(file)
    }
  }

  function getFilePageNumber(parentId: number, childId: number) {
    const parentFile = files.find((file) => file.id === parentId)
    if (parentFile?.isExtractable) {
      const index = parentFile.extractedPages.findIndex((page) => page.id === childId)
      return index === -1 ? null : index + 1
    }
    return null
  }

  function getParentFileName(parentId: number) {
    const parentFile = files.find((file) => file.id === parentId)
    return parentFile?.name || null
  }

  const refreshTicketFiles = () => {
    setRefresh(!refresh)
  }

  async function onBytescaleUpload(files: FileUploaderResult[]) {
    setIsOpen(false)

    const promises = files.map((file) => sendBytescaleMetadata(ticket.id, file, useToken ? token : undefined))
    const results = (await Promise.allSettled(promises)) as PromiseFulfilledResult<TicketFile>[]
    results.forEach(addFile)
    if (results.length) {
      setSelectedFile(results[0].value)
    }

    fetchAndSetTicket()
  }

  function open() {
    if (numberOfFilesInCurrentVersion >= 30) {
      toast.error('You can only upload 30 files per version. Please try again')
    } else {
      setIsOpen(true)
    }
  }

  function selectFileById(fileId: number, parentId?: number) {
    const thisFileId = parentId || fileId
    const thisChildFileId = parentId ? fileId : null
    const thisFile = selectedFile?.id === thisFileId ? selectedFile : files.find((f) => f.id === thisFileId)
    if (thisFile) {
      setSelectedFile(thisFile)

      if (selectedVersion !== ALL_VERSIONS && thisFile.ticketVersion !== selectedVersion) {
        setSelectedVersion(thisFile.ticketVersion)
      }

      if (thisChildFileId) {
        const index = thisFile.extractedPages.findIndex((page) => page.id === thisChildFileId)
        setExtractedPreviewIndex(index)
      } else {
        setExtractedPreviewIndex(0)
      }
    }
  }

  const deleteFileAndSelectImage = useCallback(
    (currentFiles: TicketFile[], fileToBeDeleted: TicketFile) => {
      const newFiles = currentFiles.filter((f) => f.id !== fileToBeDeleted.id)

      if (selectedFile?.id !== fileToBeDeleted?.id) {
        return newFiles
      }

      if (!newFiles.length) {
        setSelectedFile(null)
      } else {
        const deletedFileIndex = currentFiles.indexOf(fileToBeDeleted)

        // If the deleted file was the last one, find the next image in reverse order
        const reverse = deletedFileIndex === currentFiles.length - 1
        const file = getNextFile(newFiles, deletedFileIndex, reverse)
        setSelectedFile(file)
      }
      return newFiles
    },
    [selectedFile],
  )

  const deleteFile = useCallback(
    async (fileToBeDeleted: TicketFile) => {
      try {
        await deleteTicketFile(ticket.id, fileToBeDeleted.id)

        setIsOpen(false)
        setFiles((currentFiles) => deleteFileAndSelectImage(currentFiles, fileToBeDeleted))
        fetchAndSetTicket()
      } catch (e) {
        setIsOpen(false)
        toast.error('There was an error deleting this file, please try again')
        console.error('Error deleting file', e)
      }
    },
    [ticket.id, fetchAndSetTicket, deleteFileAndSelectImage],
  )

  const refreshTicketFile = (ticketFileId: number) => {
    getTicketFile(ticket.id, ticketFileId).then((ticketFile) => {
      setFiles((currentFiles) => {
        return currentFiles.map((file) => {
          if (file.id === ticketFile.id) {
            return ticketFile
          }
          return file
        })
      })
      setSelectedFile((currentFile) => {
        if (currentFile.id === ticketFile.id) {
          return ticketFile
        }
        return currentFile
      })
    })
  }

  useEffect(() => {
    if (ticket?.id) {
      getTicketFiles(ticket.id, token).then((responseFiles) => {
        const sortedFiles = sortFiles(ticket, responseFiles)

        setFiles(sortedFiles)
        setIsMediaLoaded(true)

        if (sortedFiles.length > 0) {
          let file: TicketFile
          if (showRevisions) {
            setFilters({ version: ticket.currentVersion })

            const currentVersionFiles = sortedFiles.filter((file) => file.ticketVersion === ticket.currentVersion)
            file = getNextFile(currentVersionFiles)
          } else {
            file = getNextFile(responseFiles)
          }
          setSelectedFile(file)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  // Checking the date based on the prod flipper flag. We need to not check unzip for files created before the flipper was enabled.
  useEffect(() => {
    if (
      !isExtractingPages &&
      !extractedFilesPollingFailed &&
      selectedFile &&
      ((selectedFile.isExtractable && selectedFile.extractedPages.length === 0) ||
        (isZip && !selectedFile.zipFinishedExtracting && new Date(selectedFile.createdAt) > new Date('2025-01-14')))
    ) {
      setIsExtractingPages(true)
      recursivelyPollUntilPagesExtracted(1, 8000)
    }
  }, [
    extractedFilesPollingFailed,
    isExtractingPages,
    recursivelyPollUntilPagesExtracted,
    selectedFile,
    isFeatureFlagEnabled,
    isZip,
  ])

  useEffect(() => {
    return () => {
      setIsExtractedPollingAbandoned(true)
      window.clearTimeout(timeoutUntilNextPoll)
    }
  }, [timeoutUntilNextPoll])

  useEffect(
    () => {
      if (showRevisions) {
        let version: number = null
        let file: TicketFile

        if (selectedVersion === ALL_VERSIONS) {
          version = null
          file = getNextFile(files)
        } else {
          version = Number(selectedVersion)

          const currentVersionFiles = files.filter((file) => file.ticketVersion === selectedVersion)

          if (currentVersionFiles.length) {
            const creativeFiles = currentVersionFiles.filter((file) => file.uploadedByCreative)

            if (creativeFiles.length) {
              file = creativeFiles[0]
            } else {
              file = getNextFile(files)
            }
          } else {
            file = null
          }
        }

        setFilters((previous) => ({ ...previous, version }))
        setSelectedFile(file)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedVersion, showRevisions],
  )

  const currentTicketFile = useMemo(() => {
    if (selectedFile?.isExtractable) {
      if (extractedPreviewIndex === -1 || !selectedFile.extractedPages?.length) {
        return null
      }
      const index = Math.min(extractedPreviewIndex, selectedFile.extractedPages.length)
      return selectedFile.extractedPages[index]
    }
    return selectedFile
  }, [selectedFile, extractedPreviewIndex])

  const previewUrlWithS3 = useS3PreviewImage(selectedFile)
  const previewUrl = getPreviewUrl(previewUrlWithS3)

  const [shareLinkUrl, setShareLinkUrl] = useState<string | null>(null)

  useEffect(() => {
    const fetchShareLinkUrl = async () => {
      const url = await getShareLinkUrl(selectedFile, previewUrlWithS3, useToken ? token : undefined)
      setShareLinkUrl(url)
    }
    fetchShareLinkUrl()
  }, [selectedFile, previewUrlWithS3, token, useToken])

  const genAiResultType: GenAiResultTypes = useMemo(() => getGenAiResultType(selectedFile), [selectedFile])

  const context: MediaContextValue = {
    addFiles,
    addStockAssets,
    deleteFile,
    editMode,
    extractedFilesPollingFailed,
    extractedPreviewIndex,
    fetchAndSetTicket,
    files,
    filters,
    getFileById,
    getFilePageNumber,
    getParentFileName,
    isCollaboratorView,
    isMediaLoaded,
    open,
    retryExtractingPages,
    selectedFile,
    selectedVersion,
    selectFileById,
    setExtractedPreviewIndex,
    setFilters,
    setSelectedVersion,
    showRevisions,
    ticket,
    zipFile: files?.find((f) => f.id === selectedFile?.extractedFromZip),
    zipIsExtracting: isExtractingPages && isZip && !selectedFile.zipFinishedExtracting,
    isZip,
    updateFileAnnotations,
    updateTicketFileVideoAnnotations,
    refreshTicketFiles,
    showPromptModal,
    setShowPromptModal,
    isTaggingDrawerOpen,
    setIsTaggingDrawerOpen,
    fileExportModalMode,
    setFileExportModalMode,
    currentTicketFile,
    shareLinkUrl,
    genAiResultType,
    previewUrl,
    isPlaceholder: isPlaceholder(previewUrlWithS3),
    refreshTicketFile,
    useToken,
    token,
  }

  return (
    <MediaContext.Provider value={context}>
      {<ModalFileUploader isOpen={isOpen} setIsOpen={setIsOpen} onComplete={onBytescaleUpload} />}
      {children}
    </MediaContext.Provider>
  )
}
