import ReactDOMServer from 'react-dom/server'
import { ClipboardListIcon, QuoteIcon } from 'lucide-react'

const parseDirectionHTML = (html: string) => {
  const parser = new DOMParser()
  const document = parser.parseFromString(html, 'text/html')
  const quotes = document.querySelectorAll('body > *')

  const copyHeaderString = ReactDOMServer.renderToString(
    <div>
      <QuoteIcon className="lu-light lu-md" /> EXACT COPY
    </div>,
  )
  const directionHeaderString = ReactDOMServer.renderToString(
    <div>
      <ClipboardListIcon className="lu-light lu-md" /> GENERAL DIRECTION
    </div>,
  )

  quotes.forEach((quote) => {
    if (quote.localName === 'blockquote') {
      quote.insertAdjacentHTML('beforebegin', copyHeaderString)
    } else if (!quote.previousElementSibling || quote.previousElementSibling.localName === 'blockquote') {
      quote.insertAdjacentHTML('beforebegin', directionHeaderString)
    }
  })

  return document.documentElement.innerHTML
}

export default parseDirectionHTML
