import { useMode, modeOklch, modeRgb, formatHex, parse } from 'culori/fn'
import { Vibrant } from 'node-vibrant/browser'
import type { Palette } from '@vibrant/color'

// `useMode` is not a hook.
// eslint-disable-next-line react-hooks/rules-of-hooks
const oklch = useMode(modeOklch)
// eslint-disable-next-line react-hooks/rules-of-hooks
const rgb = useMode(modeRgb)

type TailwindPalette = {
  [key: string]: string
}

interface OklchColor {
  mode: 'oklch'
  l: number
  c: number
  h: number
}

function hexToOklch(hexColor: string): OklchColor {
  return oklch(parse(hexColor)) as OklchColor
}

function oklchToHex(oklchColor: OklchColor): string {
  return formatHex(rgb(oklchColor))
}

function adjustChroma(
  baseChroma: number,
  baseLightness: number,
  targetLightness: number,
  closestShade: number,
  currentShade: number,
): number {
  const lightnessDiff = Math.abs(baseLightness - targetLightness)
  let factor
  if (targetLightness > baseLightness) {
    // For lighter shades, use a more gradual reduction
    factor = 1 - lightnessDiff * 0.7
  } else {
    // For darker shades, use a steeper reduction
    factor = 1 - lightnessDiff * 1.1
  }
  // Ensure factor is between 0 and 1
  factor = Math.max(0, Math.min(1, factor))

  // Preserve chroma for the closest shade and adjust nearby shades
  const shadeDistance = Math.abs(closestShade - currentShade)
  if (shadeDistance <= 200) {
    return baseChroma * (1 - (shadeDistance / 200) * 0.3)
  }

  return baseChroma * factor
}

function adjustHue(baseHue: number, baseLightness: number, targetLightness: number): number {
  const lightnessDiff = targetLightness - baseLightness
  // Shift hue slightly towards blue for darker shades and towards yellow for lighter shades to align with perception of color
  const hueShift = lightnessDiff * -2
  return (baseHue + hueShift + 360) % 360
}

export function generateTailwindPalette(inputHex: string): TailwindPalette {
  const baseColor = hexToOklch(inputHex)
  const palette: TailwindPalette = {}

  const lightnessRange: [string, number][] = [
    ['50', 0.985],
    ['100', 0.96],
    ['200', 0.92],
    ['300', 0.87],
    ['400', 0.81],
    ['500', 0.71],
    ['600', 0.62],
    ['700', 0.52],
    ['800', 0.43],
    ['900', 0.34],
    ['950', 0.26],
  ]

  const closestShade = lightnessRange.reduce((closest, current) =>
    Math.abs(current[1] - baseColor.l) < Math.abs(closest[1] - baseColor.l) ? current : closest,
  )

  const baseLightness = closestShade[1]
  const closestShadeNumber = parseInt(closestShade[0])

  lightnessRange.forEach(([shade, lightness]) => {
    const shadeNumber = parseInt(shade)
    let chromaAdjustment = adjustChroma(baseColor.c, baseLightness, lightness, closestShadeNumber, shadeNumber)

    // Fine-tune chroma for the lightest shades
    if (shade === '50') chromaAdjustment *= 0.2
    if (shade === '100') chromaAdjustment *= 0.5
    if (shade === '200') chromaAdjustment *= 0.8
    if (shade === '300') chromaAdjustment *= 0.9

    const oklchColor: OklchColor = {
      mode: 'oklch',
      l: lightness,
      c: chromaAdjustment,
      h: adjustHue(baseColor.h, baseLightness, lightness),
    }
    palette[shade] = oklchToHex(oklchColor).toUpperCase()
  })

  // Ensure the closest shade exactly matches the input color
  palette[closestShade[0]] = inputHex.toUpperCase()

  return palette
}

export function setColorVars(colorName: string, palette: TailwindPalette) {
  const shades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950]

  shades.forEach((shade) => {
    document.documentElement.style.setProperty(`--color-${colorName}-${shade}`, palette[shade as keyof TailwindPalette])
  })
}

export function isValidHexColor(color: string): boolean {
  const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return colorRegex.test(color)
}

export const generatePaletteFromImage = async (img: HTMLImageElement['src']) =>
  await new Promise<Palette>((resolve, reject) => {
    const imgSrc = img.includes('cloudfront') ? `https:${img}` : img
    Vibrant.from(imgSrc).getPalette().then(resolve).catch(reject)
  })

export const getDominantSwatchFromImage = async (img: HTMLImageElement['src']) =>
  (await generatePaletteFromImage(img)).Muted
