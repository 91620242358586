import { ChevronLeft, ChevronRight } from 'lucide-react'
import { cn } from 'lib/util/cn'
import { forwardRef, HTMLAttributes, useState } from 'react'
import { LucideIcon } from 'lucide-react'
import { cva, VariantProps } from 'class-variance-authority'
import { Popover, PopoverContent, PopoverTrigger } from '../popover/popover'
import { pathMatch } from 'lib/util/path'
import IconButton from '../buttons/icon-button'

function isPathInSubmenu(submenu: MenuItem[], path: string): boolean {
  return submenu?.some((subItem) => {
    if (pathMatch(subItem.path, path)) {
      return true
    }
    return subItem?.submenu ? isPathInSubmenu(subItem?.submenu, path) : false
  })
}

export const menuItemVariants = cva(
  'tw-flex tw-items-center tw-py-2 tw-px-2 tw-text-neutral-800 tw-rounded tw-cursor-pointer tw-transition-colors tw-duration-300 tw-overflow-hidden tw-border tw-border-transparent tw-border-solid tw-relative',
  {
    variants: {
      intent: {
        row: 'tw-gap-2 tw-text-base tw-w-full',
        rowMobileCol:
          'tw-flex-col tw-rounded-lg tw-py-3 tw-gap-0 tw-text-xs tw-justify-center tw-w-24 md:tw-gap-2 md:tw-text-base md:tw-w-full md:tw-flex-row md:tw-justify-start',
        col: 'tw-flex-col tw-rounded-lg tw-py-3 tw-gap-0 tw-text-xs tw-justify-center tw-w-20',
      },
      hover: {
        true: 'hover:tw-bg-cornflower-100 hover:tw-border hover:tw-border-white hover:tw-border-solid hover:tw-text-neutral-800',
      },
      selected: {
        true: 'tw-bg-cornflower-100 tw-border tw-border-white tw-border-solid tw-font-semibold tw-ring-2 tw-ring-offset-1 tw-text-cornflower-800 tw-ring-cornflower-100 tw-text-nowrap',
      },
    },
    defaultVariants: {
      intent: 'row',
      hover: true,
    },
  },
)

const iconMenuVariants = cva('tw-min-w-4 tw-min-h-4 tw-ml-auto', {
  variants: {
    intent: {
      row: 'tw-ml-auto',
      rowMobileCol: 'tw-hidden md:tw-flex tw-ml-auto',
      col: 'tw-hidden',
    },
  },
})

export type IconMenuVariant = VariantProps<typeof iconMenuVariants>

export type MenuItemVariant = VariantProps<typeof menuItemVariants>

export interface MenuItem {
  label: string
  path?: string
  Icon?: LucideIcon | React.FC
  RightIcon?: LucideIcon | React.FC<IconMenuVariant>
  submenu?: MenuItem[]
  newTab?: boolean
  permission?: boolean
}

interface MenuItemProps<T> extends MenuItemVariant, HTMLAttributes<T> {
  item: MenuItem
  selected?: boolean
  className?: HTMLAttributes<HTMLDivElement>['className']
  popoverClassName?: HTMLAttributes<HTMLDivElement>['className']
  cmp?: React.ElementType<T>
}

export const MenuItem = forwardRef(
  <T,>({ intent = 'row', item, selected, hover, className, cmp, ...props }: MenuItemProps<T>, ref) => {
    const Cmp = cmp || ((item.path ? 'a' : 'div') as React.ElementType)

    return (
      <Cmp
        ref={ref}
        href={item?.path}
        target={item.newTab ? '_blank' : undefined}
        className={cn(menuItemVariants({ intent, hover, selected }), className)}
        {...props}
      >
        {item.Icon && <item.Icon className="tw-min-h-6 tw-min-w-6 tw-shrink-0" />}
        {item.label}
        {item.submenu && <ChevronRight className={iconMenuVariants({ intent })} />}
        {item.RightIcon && <item.RightIcon intent={intent} className={iconMenuVariants({ intent })} />}
      </Cmp>
    )
  },
)

MenuItem.displayName = 'MenuItem'

interface SubMenuProps {
  item: MenuItem
  toggleFlyout: () => void
}

const SubMenu = ({ item, toggleFlyout }: SubMenuProps) => {
  return (
    <>
      {item.Icon && (
        <div className="tw-mb-1 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-base tw-font-semibold tw-text-neutral-800">
          <IconButton size="xs" color="secondary" onClick={toggleFlyout}>
            <ChevronLeft className="tw-shrink-0" />
          </IconButton>
          {item.Icon && <item.Icon className="tw-min-h-12 tw-min-w-6" />}
          {item.label}
        </div>
      )}
      {item.submenu?.map((subItem) => (
        <PopoverMenuItem key={subItem.label} intent="row" item={subItem} popoverClassName="tw-ml-2" />
      ))}
    </>
  )
}

interface PopoverMenuItemProps {
  item: MenuItem
  intent: MenuItemVariant['intent']
  popoverClassName?: HTMLAttributes<HTMLDivElement>['className']
}

export const PopoverMenuItem = ({ item, popoverClassName, intent }: PopoverMenuItemProps) => {
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const toggleFlyout = () => {
    setFlyoutOpen(!flyoutOpen)
  }

  const selected =
    pathMatch(item.path, window.location.pathname) || isPathInSubmenu(item.submenu, window.location.pathname)

  const Component = <MenuItem item={item} hover={true} intent={intent} selected={flyoutOpen || selected} />

  if (item.path) {
    return Component
  }

  return (
    <Popover open={flyoutOpen} onOpenChange={setFlyoutOpen}>
      <PopoverTrigger asChild>
        <MenuItem item={item} hover={true} intent={intent} selected={flyoutOpen || selected} />
      </PopoverTrigger>
      {item.submenu && (
        <PopoverContent className={popoverClassName} intent="flyout" side="right" align="start" avoidCollisions={false}>
          <SubMenu item={item} toggleFlyout={toggleFlyout} />
        </PopoverContent>
      )}
    </Popover>
  )
}
