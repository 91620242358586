import { baseRequest, bindJWT, getApi } from '../api'

// This list is not a full list of all features
// Feel free to add more as needed
export type features =
  | 'collaborative_review'
  | 'gen_ai_inpainting'
  | 'request_wysiwyg'
  | 'white_label_ga'
  | 'stockpress'
  | 'wip_preview_actions'
  | 'aws_white_label'
  | 'search_v2'

export type featureFlags = Record<features, boolean>[]

export async function isEnabled(key: string): Promise<boolean> {
  const { data } = await baseRequest('/api/internal/features', getApi)
  return data.features[key]
}

export async function getFeatureFlags(token?: string): Promise<featureFlags> {
  const getApiWithToken = bindJWT(getApi, token)
  const { data } = await baseRequest('/api/internal/features', getApiWithToken)
  return data.features as featureFlags
}
