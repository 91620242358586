import { useInfiniteQuery } from '@tanstack/react-query'
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import Avatar from 'components/core/avatar'
import { LinkLikeButton } from 'components/core/button'
import { SubscriptionTypeIcon } from 'components/elements/deliverable-type'
import AdminBadges from 'components/elements/projects/admin-badges'
import ProjectTemplatesFlyoutMenu from 'components/elements/projects/project-templates-flyout-menu'
import { AdminProjectTemplate } from 'interfaces/project-template'
import { request } from 'lib/api/fetch-api'
import Badge from 'lib/components/badge/badge'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { truncateText } from 'lib/string/utils'
import { displayDate } from 'lib/util/date'
import { uniq } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { toast } from 'sonner'

export default function ProjectTemplatesTable({
  onTemplateClick,
}: {
  onTemplateClick: (projectTemplate: AdminProjectTemplate) => void
}) {
  const { ref, inView } = useInView()

  const { data, error, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['projectTemplates'],
    queryFn: ({ pageParam = 1 }) => request({ endpoint: 'getAllAdminProjectTemplates', query: { page: pageParam } }),
    getNextPageParam: (lastPage, pages) => (lastPage.meta?.nextPage ? pages.length + 1 : undefined),
    retry: 3,
  })

  const columns: ColumnDef<AdminProjectTemplate>[] = useMemo(
    () => [
      {
        id: 'name',
        header: 'Title',
        cell: (props) => {
          return (
            <div className="tw-flex tw-items-center tw-gap-2">
              <img
                src={props.row.original.imageUrl}
                alt={props.row.original.name}
                className="tw-h-20 tw-w-20 tw-rounded"
              />
              <div>
                <LinkLikeButton onClick={() => onTemplateClick(props.row.original)}>
                  <div className="tw-m-0 tw-text-sm tw-font-medium">{truncateText(props.row.original.name)}</div>
                </LinkLikeButton>
                <p className="tw-m-0 tw-truncate tw-text-xs tw-text-neutral-500">
                  {truncateText(props.row.original.description)}
                </p>
              </div>
              <AdminBadges shared={props.row.original.shared} isPartnership={!!props.row.original.partner} />
            </div>
          )
        },
        size: 240,
      },
      {
        id: 'scopes-of-service',
        header: 'Scopes of Service',
        accessorKey: 'scopesOfService',
        cell: (props) => {
          const subscriptionTypes = uniq(
            props.row.original.ticketTemplates.map((ticketTemplate) => ticketTemplate.skill.subscriptionType),
          )

          return (
            <div className="tw-flex tw-flex-wrap tw-gap-2">
              {subscriptionTypes.map((subscriptionType) => (
                <Badge key={subscriptionType} color="light">
                  <SubscriptionTypeIcon type={subscriptionType} />
                </Badge>
              ))}
            </div>
          )
        },
      },
      {
        id: 'requests',
        header: 'Request(s)',
        accessorKey: 'requests',
        cell: (props) => {
          const requestTypes = uniq(
            props.row.original.ticketTemplates.map((ticketTemplate) => ticketTemplate.skill.name),
          )

          if (requestTypes.length > 4) {
            return (
              <div className="tw-flex tw-flex-wrap tw-gap-1">
                {requestTypes.slice(0, 4).map((requestType) => (
                  <Badge key={requestType} color="light">
                    {requestType}
                  </Badge>
                ))}
                <span className="tw-text-xs tw-font-semibold tw-text-neutral-800">
                  + {requestTypes.length - 4} more
                </span>
              </div>
            )
          }

          return (
            <div className="tw-flex tw-flex-wrap tw-gap-1">
              {requestTypes.map((requestType) => (
                <Badge key={requestType} color="light">
                  {requestType}
                </Badge>
              ))}
            </div>
          )
        },
      },
      {
        id: 'request-categories',
        header: 'Request Category(ies)',
        accessorKey: 'requestCategories',
        cell: (props) => {
          const requestCategories = uniq(
            props.row.original.ticketTemplates.map((ticketTemplate) => ticketTemplate.skill.skillCategoryName),
          )

          if (requestCategories.length > 4) {
            return (
              <div className="tw-flex tw-flex-wrap tw-gap-1">
                {requestCategories.slice(0, 4).map((requestCategory) => (
                  <Badge key={requestCategory} color="light">
                    {requestCategory}
                  </Badge>
                ))}
                <span className="tw-text-xs tw-font-semibold tw-text-neutral-800">
                  + {requestCategories.length - 4} more
                </span>
              </div>
            )
          }

          return (
            <div className="tw-flex tw-flex-wrap tw-gap-1">
              {requestCategories.map((requestCategory) => (
                <Badge key={requestCategory} color="light">
                  {requestCategory}
                </Badge>
              ))}
            </div>
          )
        },
      },
      {
        id: 'created-by',
        header: 'Created By',
        accessorKey: 'createdBy',
        cell: (props) => {
          return (
            <div className="tw-flex tw-items-center tw-gap-2" style={{ minWidth: '148px' }}>
              <Avatar src={props.row.original.createdBy?.avatar} name={props.row.original.createdBy?.fullName} />
              <span>{props.row.original.createdBy?.fullName}</span>
            </div>
          )
        },
      },
      {
        id: 'last-updated',
        header: 'Last Updated',
        accessorKey: 'lastUpdated',
        cell: (props) => <div style={{ minWidth: '88px' }}>{displayDate(props.row.original.updatedAt)}</div>,
      },
      {
        id: 'actions',
        enableSorting: false,
        cell: (props) => <ProjectTemplatesFlyoutMenu projectTemplate={props.row.original} />,
        size: 8,
      },
    ],
    [onTemplateClick],
  )

  const tableData = useMemo(() => data?.pages.flatMap((page) => page.data), [data?.pages]) ?? []
  const table = useReactTable<AdminProjectTemplate>({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (error) {
      toast.error('Failed to fetch projects')
      console.error('Failed to fetch projects', error)
    }
  }, [error])

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetching])

  return (
    <div>
      <table className="tw-w-full tw-max-w-full tw-border-collapse tw-text-neutral-500">
        <thead className="tw-sticky tw--top-4 tw-z-10">
          <tr className="tw-h-12 tw-w-full tw-overflow-x-scroll tw-border-0 tw-border-x-2 tw-border-t-2 tw-border-solid tw-border-neutral-200 tw-bg-neutral-200">
            {table.getFlatHeaders().map((header) => (
              <th key={header.id} className="tw-black tw-pl-2 tw-font-medium tw-uppercase">
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="tw-h-24 tw-border-s-2 tw-border-solid tw-border-neutral-200 tw-bg-white">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="tw-overflow-hidden tw-p-2" style={{ width: cell.column.getSize() }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {hasNextPage && <tr ref={ref} className="tw-h-4" />}
        </tbody>
      </table>
      {isFetching && <Skeleton className="tw-h-24 tw-w-full tw-bg-neutral-200" data-testid="skeleton" />}
    </div>
  )
}
