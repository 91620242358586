import { ReactElement, useEffect, useMemo, useRef, useState, MouseEvent } from 'react'

import { PenLine, TrashIcon, UserPlus } from 'lucide-react'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { GenAiRequestTypes } from 'lib/api/gen-ai/gen-ai-requests'
import { CustomerState, TicketFile } from 'lib/api/ticket-files/ticket-files'
import { EditMode } from 'lib/api/tickets/tickets'
import IconButton from 'lib/components/buttons/icon-button'
import { Card } from 'lib/components/card/card'
import { useMediaContext } from './media-provider'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { toast } from 'lib/components/toast/toast'
import { useS3PreviewImage } from 'lib/hooks/useS3PreviewImage'
import { cn } from 'lib/util/cn'
import { useThreeResizableContext } from 'lib/components/resizable/resizable'
import { isPlaceholder } from 'lib/util/file/file'
import ApprovedBadge from 'lib/components/approved-badge'

interface MediaItemPlaceholderProps {
  file: TicketFile
}

interface MediaItemProps {
  file: TicketFile
  openMiddlePanel?: () => void
}

const classNames = {
  previewOverlayContainer: `tw-absolute
                            tw-flex
                            tw-justify-center
                            tw-items-center
                            tw-w-full
                            tw-h-full
                            tw-bg-opacity-40
                            tw-bg-black
                            tw-z-10`,

  img: 'tw-w-full tw-h-full tw-object-cover tw-aspect-square',
  imgName: 'tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-neutral-600',

  mediaItemContainer: 'tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-px-4 tw-items-start tw-py-6',
  removeButtonAbsoluteContainer: 'tw-absolute tw--top-4 tw--right-4 tw-z-10',
  badge: 'tw-bg-cornflower-500 tw-text-white tw-rounded-md tw-px-2 tw-flex tw-items-center tw-gap-2 ',
}

function PreviewOverlay() {
  return (
    <div className={classNames.previewOverlayContainer} data-testid="preview-overlay">
      <h4 className="tw-m-0 tw-text-white">PREVIEW</h4>
    </div>
  )
}

function MediaItem({ file, openMiddlePanel }: MediaItemProps): ReactElement {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const containerRef = useRef(null)
  const { ticket, editMode, deleteFile, isCollaboratorView, selectedFile, selectFileById } = useMediaContext()
  const { cancelAnnotation } = useAnnotationsContext()

  const isSelected = useMemo(() => {
    return file.id === selectedFile?.id
  }, [selectedFile?.id, file.id])

  const removeModalMessage = useMemo(() => {
    if (file.stockAsset?.requestType === GenAiRequestTypes.Inpainting) {
      return 'Are you sure you want to delete this generated image?'
    }
    return `Are you sure you want to delete ${file.name}?`
  }, [file.name, file.stockAsset?.requestType])

  const isUploadedByCollaborator = file?.user?.collaborator === true

  useEffect(() => {
    if (isSelected) {
      containerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isSelected])

  function handleRemoveClick(e: MouseEvent) {
    e.stopPropagation()
    setIsModalVisible(true)
  }

  function handleCancelClick(e: MouseEvent) {
    e.stopPropagation()
    setIsModalVisible(false)
  }

  async function handleDelete(e: MouseEvent) {
    e.stopPropagation()
    deleteFile(file)
    setIsModalVisible(false)
    toast.success('The file has been deleted')
  }

  function isMediaDeletable() {
    const isCurrentVersion = file.ticketVersion === ticket.currentVersion
    const isNotUploadedByCreative = !file.uploadedByCreative
    const isNotInProgress = editMode !== EditMode.processing
    const isNotComplete = editMode !== EditMode.complete

    return !isCollaboratorView && isCurrentVersion && isNotUploadedByCreative && isNotInProgress && isNotComplete
  }

  const annotationsCount = useMemo(() => {
    if (file.isExtractable) {
      return file.extractedPages.reduce((acc, pdf) => acc + pdf.annotations.length, 0)
    } else {
      return file.annotations?.length || 0
    }
  }, [file.isExtractable, file.extractedPages, file.annotations?.length])

  function handleSelectFile() {
    cancelAnnotation()

    selectFileById(file.id, null)
    openMiddlePanel?.()
  }

  const previewUrl = useS3PreviewImage(file)

  const isApproved = file.customerState === CustomerState.Approved

  return (
    <Card
      size="custom"
      className="tw-group tw-w-auto"
      onClick={handleSelectFile}
      isSelected={isSelected}
      ref={containerRef}
    >
      <Card.Body className="tw-relative">
        <div
          data-testid="badges-wrapper"
          className="tw-absolute tw--left-2 tw--top-2 tw-z-20 tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-1"
        >
          {annotationsCount > 0 && (
            <div className={classNames.badge} data-testid="annotation-count-badge">
              <PenLine className="lu-light lu-sm" />
              <span>{annotationsCount}</span>
            </div>
          )}
          {isUploadedByCollaborator && (
            <div className={classNames.badge} data-testid="uploaded-by-collaborator-badge">
              <UserPlus className="lu-light lu-sm" />
            </div>
          )}
        </div>
        {isApproved && <ApprovedBadge className="tw-absolute tw-bottom-1 tw-left-1 tw-z-10" />}
        {isMediaDeletable() && (
          <div className={cn('tw-hidden group-hover:tw-block')}>
            <div className={classNames.removeButtonAbsoluteContainer}>
              <IconButton color="danger" invert onClick={handleRemoveClick} size="xs">
                <TrashIcon className="lu-light lu-sm" />
              </IconButton>
            </div>
          </div>
        )}
        <ConfirmationModal
          title="Delete Media"
          message={<span className="tw-break-all">{removeModalMessage}</span>}
          confirmBtnText="Yes, Delete"
          confirmAction={handleDelete}
          cancelAction={handleCancelClick}
          visible={isModalVisible}
        />
        <div className="tw-relative tw-flex tw-aspect-square tw-w-44 tw-min-w-20 tw-max-w-44 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-lg tw-text-center">
          {file?.preview && <PreviewOverlay />}
          {isPlaceholder(previewUrl) ? (
            <MediaItemPlaceholder file={file} />
          ) : (
            <img key={file.id} src={previewUrl} alt={file.name} className={classNames.img} />
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

function MediaItemPlaceholder({ file }: MediaItemPlaceholderProps): ReactElement {
  const previewUrl = useS3PreviewImage(file)
  const src = `/images/${previewUrl}`
  return <img key={file.id} src={src} alt={file.name} className={cn(classNames.img, 'tw-max-w-25 tw-object-none')} />
}

export default function MediaList(): ReactElement {
  const { files, filters, showRevisions } = useMediaContext()

  const { middleRef } = useThreeResizableContext()

  function openMiddlePanel() {
    middleRef.current?.expand()
  }

  const visibleFiles = useMemo(() => {
    if (filters.version) {
      return files
        .filter((file) => file.ticketVersion === filters.version)
        .sort((a, b) => parseInt(b.createdAt) - parseInt(a.createdAt))
    }
    return files
  }, [files, filters.version])

  const creativeFiles = visibleFiles.filter((file) => file.uploadedByCreative && !file.preview) || []
  const previewFiles = visibleFiles.filter((file) => file.preview) || []
  const clientFiles = visibleFiles.filter((file) => !file.uploadedByCreative) || []

  const showDivider = showRevisions && (creativeFiles.length > 0 || previewFiles.length > 0)

  return (
    <>
      {showDivider ? (
        <>
          {creativeFiles.length > 0 && (
            <div>
              <h5 className="tw-mx-4 tw-my-0 tw-w-10/12 tw-border-0 tw-border-b tw-border-solid tw-pb-2">
                Delivered Assets
              </h5>

              <div className={classNames.mediaItemContainer}>
                {creativeFiles.map((file) => (
                  <MediaItem file={file} key={file.id} openMiddlePanel={openMiddlePanel} />
                ))}
              </div>
            </div>
          )}

          {previewFiles.length > 0 && (
            <div>
              <h5 className="tw-mx-4 tw-my-0 tw-w-10/12 tw-border-0 tw-border-b tw-border-solid tw-pb-2">
                Preview Assets
              </h5>
              <div className={classNames.mediaItemContainer}>
                {previewFiles.map((file) => (
                  <MediaItem file={file} key={file.id} openMiddlePanel={openMiddlePanel} />
                ))}
              </div>
            </div>
          )}

          {clientFiles.length > 0 && (
            <div>
              <h5 className="tw-mx-4 tw-my-0 tw-w-10/12 tw-border-0 tw-border-b tw-border-solid tw-pb-2">
                Added Assets
              </h5>
              <div className={classNames.mediaItemContainer}>
                {clientFiles.map((file) => (
                  <MediaItem file={file} key={file.id} openMiddlePanel={openMiddlePanel} />
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={classNames.mediaItemContainer}>
          {visibleFiles.map((file) => (
            <MediaItem file={file} key={file.id} openMiddlePanel={openMiddlePanel} />
          ))}
        </div>
      )}
    </>
  )
}
