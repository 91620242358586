import { LinkLikeButton } from 'components/core/button'
import { useState } from 'react'

export default function ExpandableText({ text }: { text: string }) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div>
      <p className="tw-m-0 tw-p-0">{text.length > 200 && !isExpanded ? `${text.substring(0, 200)}...` : text}</p>
      {text.length > 200 && (
        <span className="tw-flex tw-items-center tw-justify-end">
          <LinkLikeButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Show less' : 'Show more'}
          </LinkLikeButton>
        </span>
      )}
    </div>
  )
}
