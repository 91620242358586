import { ActivityNotification, ActivityNotificationType } from 'interfaces/notifications'
import { PATHS } from 'lib/constants/paths'
import { cn } from 'lib/util/cn'
import { HTMLAttributes } from 'react'
import { format } from 'timeago.js'

export const Notification = ({
  read = false,
  children,
  createdAt,
  className,
}: {
  read?: boolean
  children: React.ReactNode
  createdAt?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
}) => {
  return (
    <div className={cn('tw-flex tw-gap-1 tw-text-neutral-800', className)}>
      {!read && <UnreadNotification />}
      <div className="tw-flex tw-flex-col tw-gap-1">
        {children}
        {createdAt && <div className="tw-text-xs tw-text-neutral-500">{format(createdAt)}</div>}
      </div>
    </div>
  )
}

export const UnreadNotification = ({ read = false }: { read?: boolean }) => {
  return (
    <div className={cn('tw-mt-1 tw-h-3 tw-min-w-3 tw-rounded-full tw-bg-flushpink-500', read && 'tw-bg-transparent')} />
  )
}

const linkClassName = 'tw-text-cornflower-500 hover:tw-underline'

export const NotificationContent = ({ notification }: { notification: ActivityNotification }) => {
  const href =
    notification.type === ActivityNotificationType.CollaboratorAnnotation && notification.object?.id
      ? `/tickets/${notification.object.id}`
      : `${PATHS.ADMIN_NOTIFICATIONS}/${notification.id}`

  switch (notification.type) {
    case ActivityNotificationType.TicketAssigned:
      return (
        <div>
          You were assigned to{' '}
          <a className={linkClassName} href={href}>
            {notification.object.subject}
          </a>
        </div>
      )
    case ActivityNotificationType.ClientReply:
      return (
        <div>
          {notification.object.user.fullName}({notification.object.user.companyName}) replied to{' '}
          <a className={linkClassName} href={href}>
            {notification.object.ticketSubject}
          </a>
        </div>
      )
    case ActivityNotificationType.StatusPending:
      return (
        <div>
          The status of{' '}
          <a className={linkClassName} href={href}>
            {notification.object.subject}
          </a>{' '}
          was changed to Customer Clarification
        </div>
      )
    case ActivityNotificationType.LeaveDenied:
      return (
        <div>
          Your{' '}
          <a className={linkClassName} href={href}>
            leave request
          </a>{' '}
          from {notification.object.startDate} to {notification.object.endDate} was denied
        </div>
      )
    case ActivityNotificationType.LeaveReadyForApproval:
      return (
        <div>
          <a className={linkClassName} href={href}>
            {notification.object.designer.name}
          </a>{' '}
          requested days off
        </div>
      )
    case ActivityNotificationType.LeaveApproved:
      return (
        <div>
          Your{' '}
          <a className={linkClassName} href={href}>
            leave request
          </a>{' '}
          from {notification.object.startDate} to {notification.object.endDate} was approved
        </div>
      )
    case ActivityNotificationType.CompanyMissingDesigner:
      return (
        <div>
          All designers for{' '}
          <a className={linkClassName} href={href}>
            {notification.object.name}
          </a>{' '}
          are on leave today, so no tickets will be worked on
        </div>
      )
    case ActivityNotificationType.ConversationMention:
      return (
        <div>
          {notification.object.user.fullName}({notification.object.user.companyName}) mentioned you on{' '}
          <a className={linkClassName} href={href}>
            {notification.object.ticketSubject}
          </a>
        </div>
      )
    case ActivityNotificationType.CompanyAutoAssignFail:
      return (
        <div>
          <a className={linkClassName} href={href}>
            {notification.object.name}
          </a>{' '}
          failed in auto assignment and therefore did not get tickets assigned for today
        </div>
      )
    case ActivityNotificationType.DesignerOverweight:
      return (
        <div>
          <a className={linkClassName} href={href}>
            {notification.object.name}
          </a>{' '}
          is OVER loaded today
        </div>
      )
    case ActivityNotificationType.DesignerUnderweight:
      return (
        <div>
          <a className={linkClassName} href={href}>
            {notification.object.name}
          </a>{' '}
          is UNDER loaded today
        </div>
      )
    case ActivityNotificationType.GenericTicketReply:
      return (
        <div>
          A client left directions/annotations on{' '}
          <a className={linkClassName} href={href}>
            {notification.object.subject}
          </a>
        </div>
      )
    case ActivityNotificationType.LeaveDestroy:
      return (
        <div>
          Leave day/s for{' '}
          <a className={linkClassName} href={href}>
            {notification.object.designer.name}
          </a>{' '}
          were deleted from the calendar
        </div>
      )
    case ActivityNotificationType.TicketFeedbackSubmitted:
      return (
        <div>
          A client left feedback on{' '}
          <a className={linkClassName} href={href}>
            {notification?.object?.subject}
          </a>
        </div>
      )
    case ActivityNotificationType.CollaboratorAnnotation:
      return (
        <div>
          {notification.metadata?.collaborator_name || 'A collaborator'} added feedback to{' '}
          <a className={linkClassName} href={href}>
            {notification?.object?.subject}
          </a>
        </div>
      )
    default:
      return null
  }
}
