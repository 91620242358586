import { useEffect } from 'react'
import { FileExport } from 'interfaces/file-exports'
import { FileExportStatus } from 'interfaces/file-exports'
import { useState } from 'react'
import { useCreateFileExport } from './useCreateFileExport'
import { useMutation, useQuery } from '@tanstack/react-query'
import { downloadFile, getFileNameWithoutExtension } from 'lib/util/file/file'
import { toast } from 'sonner'
import { request, requestQuery } from 'lib/api/fetch-api'
import { LinkLikeButton } from 'components/core/button'
import { queryClient } from 'providers/query-client-provider'
import { useDownloadQueue } from './useDownloadQueue'
import { MinusIcon } from 'lucide-react'

interface FileExportSuccessToastProps {
  name: string
  link?: string
  id?: number
  onRetry?: () => void
}

export const fileExportSuccessToast = ({ name, link, id, onRetry }: FileExportSuccessToastProps) => {
  toast.success(`"${name}" downloaded successfully`, {
    id,
    description: 'Please check your downloads folder.',
    classNames: {
      toast: '!tw-w-[650px]',
      closeButton: '!tw-ml-0',
    },
    action: (
      <LinkLikeButton
        className="tw-ml-auto tw-text-nowrap tw-text-inherit"
        onClick={() => {
          if (link) {
            downloadFile(name, link)
          }
          onRetry?.()
        }}
      >
        Download again
      </LinkLikeButton>
    ),
    cancel: null,
    closeButton: true,
    duration: Infinity,
  })
}

export const useFileExports = () => {
  const [downloadedIds, setDownloadedIds] = useState<number[]>([])
  const { addToQueue } = useDownloadQueue()

  const { data: fileExports } = useQuery({
    queryKey: ['file-exports'],
    queryFn: requestQuery({ endpoint: 'getFileExports' }),
    refetchInterval: ({ state }: { state: { data: { data: FileExport[] } } }) => {
      const fileExports = state?.data?.data as FileExport[]
      if (
        fileExports?.some(
          (fileExport) =>
            fileExport.display ||
            fileExport.status === FileExportStatus.processing ||
            fileExport.status === FileExportStatus.waiting,
        )
      ) {
        return 1000
      }
      return false
    },
  })

  const { mutate: updateFileExport } = useMutation({
    mutationFn: (id: number) => request({ endpoint: 'updateFileExport', query: { id }, body: { display: false } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['file-exports'] })
    },
  })
  const { createFileExport } = useCreateFileExport()

  useEffect(() => {
    fileExports?.data?.forEach((arrayFormat) => {
      if (!arrayFormat.display && downloadedIds.includes(arrayFormat.id)) {
        setDownloadedIds((prev) => prev.filter((id) => id !== arrayFormat.id))
      }

      if (arrayFormat.display) {
        switch (arrayFormat.status) {
          case FileExportStatus.finished:
            if (!downloadedIds.includes(arrayFormat.id) && arrayFormat.display) {
              arrayFormat?.temporaryLinks?.forEach((link) => {
                addToQueue(`${getFileNameWithoutExtension(arrayFormat.fileName)}.${arrayFormat.outputFormat}`, link)
              })
              setDownloadedIds((prev) => [...prev, arrayFormat.id])
              updateFileExport(arrayFormat.id)
            }

            fileExportSuccessToast({
              name: `${getFileNameWithoutExtension(arrayFormat.fileName)}.${arrayFormat.outputFormat}`,
              onRetry: () => {
                createFileExport({
                  ticketFileId: arrayFormat.ticketFileId,
                  selectedFormats: [arrayFormat.outputFormat],
                })
              },
              id: arrayFormat.id,
            })
            break
          case FileExportStatus.error:
            toast.error(
              `"${getFileNameWithoutExtension(arrayFormat.fileName)}.${arrayFormat.outputFormat}" failed to download`,
              {
                id: arrayFormat.id,
                description: 'Please try again.',
                classNames: {
                  toast: '!tw-w-[650px]',
                  closeButton: '!tw-ml-0',
                },
                cancel: null,
                action: (
                  <LinkLikeButton
                    className="tw-ml-auto tw-text-nowrap tw-text-inherit"
                    onClick={() =>
                      createFileExport({
                        ticketFileId: arrayFormat.ticketFileId,
                        selectedFormats: [arrayFormat.outputFormat],
                      })
                    }
                  >
                    Retry
                  </LinkLikeButton>
                ),
                onDismiss: () => {
                  updateFileExport(arrayFormat.id)
                },
                closeButton: true,
                duration: Infinity,
              },
            )
            break
          case FileExportStatus.processing:
          case FileExportStatus.waiting:
            toast.loading(`Downloading "${arrayFormat.fileName}" as .${arrayFormat.outputFormat}`, {
              id: arrayFormat.id,
              classNames: {
                toast: '!tw-w-[650px]',
              },
              description:
                'Please wait while your files are being prepared—they will download automatically once ready.',
              closeButton: false,
              cancel: (
                <MinusIcon
                  className="tw-ml-auto tw-h-4 tw-w-4 tw-cursor-pointer"
                  onClick={() => {
                    updateFileExport(arrayFormat.id)
                    toast.dismiss(arrayFormat.id)
                  }}
                />
              ),
            })
            break
        }
      }
    })
  }, [fileExports, createFileExport, setDownloadedIds, downloadedIds, updateFileExport, addToQueue])
}

// Used in the header to show the file exports toast because it needs to be below the QueryClientProvider
export const FileExports = () => {
  useFileExports()

  return null
}
