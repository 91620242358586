import CompanyDesignerAllocationsFilters from './company-designer-allocations-filters'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import {
  getCompanyDesignerAllocations,
  routes,
} from 'lib/api/company-designer-allocations/company-designer-allocations'
import { useCallback, useEffect, useState } from 'react'
import Pagination from 'components/elements/pagination'
import { Pencil } from 'lucide-react'
import { snakeCaseKeys } from 'lib/object/utils'
import { Toaster, toast } from 'lib/components/toast/toast'

const classNames = {
  container: 'tw-container tw-px-3',
  header: 'tw-flex tw-justify-between',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white',
  wrapper: 'tw-flex tw-w-full tw-flex-col',
}

type checkbox = '0' | '1'

export interface Filters {
  powerPlan: checkbox
  proPremiumPlan: checkbox
  companyNameOrCompanyEmailCont: string
  unassigned: checkbox
}

export const emptyFilters: Filters = {
  powerPlan: '0',
  proPremiumPlan: '0',
  companyNameOrCompanyEmailCont: '',
  unassigned: '0',
}

function CompanyDesignerAllocations() {
  const [companyDesignerAllocations, setCompanyDesignerAllocations] = useState([])
  const [count, setCount] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(24)
  const [filters, setFilters] = useState<Filters>(emptyFilters)
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    fetchCompanyDesignerAllocations(currentPage, filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const fetchCompanyDesignerAllocations = useCallback(async (nextPage: number, filters: Filters) => {
    setIsLoading(true)

    try {
      const { companyDesignerAllocations, meta } = await getCompanyDesignerAllocations({
        q: snakeCaseKeys(filters),
        page: nextPage + 1,
      })
      setCompanyDesignerAllocations(companyDesignerAllocations)
      setCount(meta.total)
      setPerPage(meta.pageSize)
    } catch (error) {
      console.error('Company Designer Allocations could not be fetched.', error)
      toast.error('Company Designer Allocations could not be fetched.')
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onPageClick = ({ selected }) => {
    setIsLoading(true)
    setCurrentPage(selected)
    window.scrollTo(0, 0)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    fetchCompanyDesignerAllocations(currentPage, filters)
  }

  const handleClear = (e) => {
    e.preventDefault()
    setFilters(emptyFilters)
    fetchCompanyDesignerAllocations(0, emptyFilters)
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <h4>Company Designer Allocations</h4>
        {!isLoading && `Showing ${companyDesignerAllocations.length} out of ${count} companies`}
      </div>
      <div className={classNames.container}>
        <CompanyDesignerAllocationsFilters
          filters={filters}
          handleClear={handleClear}
          onSubmit={handleSubmit}
          setFilters={setFilters}
        />
        <div className={classNames.tableWrapper}>
          <table className={classNames.table}>
            <thead className={classNames.tableHead}>
              <tr>
                <th className={classNames.tableHeadCell}>Company</th>
                <th className={classNames.tableHeadCell}>Email</th>
                <th className={classNames.tableHeadCell}>Designers</th>
                <th className={classNames.tableHeadCell}>Graphics</th>
                <th className={classNames.tableHeadCell}>Pro</th>
                <th className={classNames.tableHeadCell}>Premium</th>
                <th className={classNames.tableHeadCell}>Power</th>
                <th className={classNames.tableHeadCell}>EST</th>
                <th className={classNames.tableHeadCell}>PST</th>
                <th className={classNames.tableHeadCell}>UK</th>
                <th className={classNames.tableHeadCell}>Australia</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <LoadingScreenTableRow />
              ) : (
                companyDesignerAllocations.map(({ id, company, designerAllocationsCount, timezoneTeamBackup }) => (
                  <tr key={id} className={classNames.tableRow}>
                    <td className={classNames.tableCell}>
                      <a href={routes.show(id)} className="tw-mr-3" title="Edit company designer allocation">
                        <Pencil className="lu-light lu-md" />
                      </a>
                      <a href={routes.company(company.id)} target="_blank" rel="noreferrer">
                        {company.name}
                      </a>
                    </td>
                    <td className={classNames.tableCell}>{company.email}</td>
                    <td className={classNames.tableCell}>{designerAllocationsCount}</td>
                    <td className={classNames.tableCell}>{company.subscriptionsCount.graphics || 0}</td>
                    <td className={classNames.tableCell}>{company.subscriptionsCount.graphicsPro || 0}</td>
                    <td className={classNames.tableCell}>{company.subscriptionsCount.graphicsPremium || 0}</td>
                    <td className={classNames.tableCell}>{company.subscriptionsCount.power || 0}</td>
                    <td className={classNames.tableCell}>{timezoneTeamBackup?.est ? 'ON' : 'OFF'}</td>
                    <td className={classNames.tableCell}>{timezoneTeamBackup?.pst ? 'ON' : 'OFF'}</td>
                    <td className={classNames.tableCell}>{timezoneTeamBackup?.uk ? 'ON' : 'OFF'}</td>
                    <td className={classNames.tableCell}>{timezoneTeamBackup?.aus ? 'ON' : 'OFF'}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination pageCount={Math.ceil(count / perPage)} onPageChange={onPageClick} forcePage={currentPage} />
      </div>
    </div>
  )
}

export default function CompanyDesignerAllocationsPage() {
  return (
    <>
      <Toaster />
      <CompanyDesignerAllocations />
    </>
  )
}
