import { GripVertical } from 'lucide-react'
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels'
import { cn } from 'lib/util/cn'

const ResizablePanelGroup = ({ className, ...props }: React.ComponentProps<typeof PanelGroup>) => (
  <PanelGroup
    className={cn('tw-flex tw-h-full tw-w-full data-[panel-group-direction=vertical]:tw-flex-col', className)}
    {...props}
  />
)

const ResizablePanel = Panel

const ResizableHandle = ({
  withHandle,
  className,
  children,
  ...props
}: React.ComponentProps<typeof PanelResizeHandle> & {
  withHandle?: boolean
}) => (
  <PanelResizeHandle
    className={cn(
      'focus-visible:tw-ring-ring tw-relative tw-flex tw-w-px tw-items-center tw-justify-center tw-bg-neutral-200 after:tw-absolute after:tw-inset-y-0 after:tw-left-1/2 after:tw-w-1 after:-tw-translate-x-1/2 focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-offset-1 data-[panel-group-direction=vertical]:tw-h-px data-[panel-group-direction=vertical]:tw-w-full data-[panel-group-direction=vertical]:after:tw-left-0 data-[panel-group-direction=vertical]:after:tw-h-1 data-[panel-group-direction=vertical]:after:tw-w-full data-[panel-group-direction=vertical]:after:-tw-translate-y-1/2 data-[panel-group-direction=vertical]:after:tw-translate-x-0 [&[data-panel-group-direction=vertical]>div]:tw-rotate-90',
      className,
    )}
    {...props}
  >
    {withHandle && (
      <div className="tw-bg-border tw-z-10 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-sm tw-border">
        <GripVertical className="tw-h-4 tw-w-4" />
      </div>
    )}
    {children}
  </PanelResizeHandle>
)

export { ResizablePanelGroup, ResizablePanel, ResizableHandle }

import { createContext, useContext, useRef, useState } from 'react'
import type { ImperativePanelHandle } from 'react-resizable-panels'
import { XButton } from 'components/core/button'

interface ThreeResizableContextType {
  leftRef: React.RefObject<ImperativePanelHandle>
  middleRef: React.RefObject<ImperativePanelHandle>
  rightRef: React.RefObject<ImperativePanelHandle>
  leftCollapsed: boolean
  middleCollapsed: boolean
  rightCollapsed: boolean
  setLeftCollapsed: (collapsed: boolean) => void
  setMiddleCollapsed: (collapsed: boolean) => void
  setRightCollapsed: (collapsed: boolean) => void
  toggleLeftCollapsed: () => void
  toggleMiddleCollapsed: () => void
  toggleRightCollapsed: () => void
}

const ThreeResizableContext = createContext<ThreeResizableContextType | undefined>(undefined)

export function ThreeResizableProvider({ children }: { children: React.ReactNode }) {
  const [leftCollapsed, setLeftCollapsed] = useState(false)
  const [middleCollapsed, setMiddleCollapsed] = useState(false)
  const [rightCollapsed, setRightCollapsed] = useState(false)

  const leftRef = useRef<ImperativePanelHandle>(null)
  const middleRef = useRef<ImperativePanelHandle>(null)
  const rightRef = useRef<ImperativePanelHandle>(null)

  function toggleLeftCollapsed() {
    if (leftRef.current?.isCollapsed()) {
      leftRef.current?.expand()
    } else {
      leftRef.current?.collapse()
    }
  }

  function toggleMiddleCollapsed() {
    if (middleRef.current?.isCollapsed()) {
      middleRef.current?.expand()
    } else {
      middleRef.current?.collapse()
    }
  }

  function toggleRightCollapsed() {
    if (rightRef.current?.isCollapsed()) {
      rightRef.current?.expand()
    } else {
      rightRef.current?.collapse()
    }
  }

  return (
    <ThreeResizableContext.Provider
      value={{
        leftRef,
        middleRef,
        rightRef,
        leftCollapsed,
        middleCollapsed,
        rightCollapsed,
        setLeftCollapsed,
        setMiddleCollapsed,
        setRightCollapsed,
        toggleLeftCollapsed,
        toggleMiddleCollapsed,
        toggleRightCollapsed,
      }}
    >
      {children}
    </ThreeResizableContext.Provider>
  )
}

export function useThreeResizableContext() {
  const context = useContext(ThreeResizableContext)
  if (!context) {
    throw new Error('useThreeColumnResizableContext must be used within a ThreeResizableProvider')
  }
  return context
}

type PanelPosition = 'left' | 'middle' | 'right'

interface ThreeResizablePanelProps extends React.ComponentProps<typeof ResizablePanel> {
  position: PanelPosition
  xButton?: boolean
  children: React.ReactNode
  conditionalRender?: boolean
  buttonClassName?: HTMLButtonElement['className']
}

export function ThreeResizablePanel({
  position,
  children,
  xButton = true,
  conditionalRender = false,
  buttonClassName,
  ...props
}: ThreeResizablePanelProps) {
  const {
    leftRef,
    middleRef,
    rightRef,
    setLeftCollapsed,
    setMiddleCollapsed,
    setRightCollapsed,
    toggleLeftCollapsed,
    toggleMiddleCollapsed,
    toggleRightCollapsed,
    rightCollapsed,
    leftCollapsed,
    middleCollapsed,
  } = useThreeResizableContext()

  const refs = {
    left: leftRef,
    middle: middleRef,
    right: rightRef,
  }

  const setCollapsed = {
    left: setLeftCollapsed,
    middle: setMiddleCollapsed,
    right: setRightCollapsed,
  }

  const toggleCollapsed = {
    left: toggleLeftCollapsed,
    middle: toggleMiddleCollapsed,
    right: toggleRightCollapsed,
  }

  const isCollapsed = {
    left: leftCollapsed,
    middle: middleCollapsed,
    right: rightCollapsed,
  }

  return (
    <ResizablePanel
      {...props}
      className={cn('tw-relative', props.className)}
      ref={refs[position]}
      onCollapse={() => setCollapsed[position](true)}
      onExpand={() => setCollapsed[position](false)}
    >
      {conditionalRender && isCollapsed[position] ? null : children}
      {xButton && !isCollapsed[position] && (
        <XButton
          className={cn('tw-absolute tw-right-5 tw-top-4 tw-z-10', buttonClassName)}
          onClick={toggleCollapsed[position]}
        />
      )}
    </ResizablePanel>
  )
}
