import { cva, VariantProps } from 'class-variance-authority'
import { ExternalLinkIcon } from 'lucide-react'
import { AnchorHTMLAttributes, ReactNode } from 'react'

const linkClasses = cva(['tw-text-cornflower-500'], {
  variants: {
    underline: {
      true: 'tw-underline',
    },
  },
})

export type LinkVariant = VariantProps<typeof linkClasses>

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode
  variant?: LinkVariant
  externalIcon?: boolean
}

export default function Link({ children, externalIcon, ...rest }: LinkProps) {
  return (
    <a className={linkClasses({ ...rest.variant, className: rest.className })} {...rest}>
      {children}
      {externalIcon && <ExternalLinkIcon className="tw-ml-1 tw-h-4 tw-w-4" />}
    </a>
  )
}
