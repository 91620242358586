import { useState } from 'react'
import { useRequestContext } from '../providers/request-provider'
import HeaderActions from './header-actions'
import RequestName from './request-name'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import HeaderDropdowns from './header-dropdowns'
import HeaderRequestDetails from 'components/pages/request/request-header/header-request-details'
import CloseButton from '../media/close-button'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'
import RequestsProjectsBreadcrumb from 'lib/components/breadcrumb/requests-projects-breadcrumb'
import Banner from 'lib/components/banner/banner'
import { isStatusInProgress, isStatusInReview } from 'lib/ticket/utils'
import ConfirmNavigatePendingUpdatesModal from './confirm-navigate-pending-updates-modal'

export default function RequestHeader(): JSX.Element {
  const [isOpen, setIsOpen] = useState(true)
  const { ticket, showRevisions, fetchAndSetTicket, invalidFields, showValidationErrors } = useRequestContext()
  const [confirmationVisible, setConfirmationVisible] = useState(false)

  function toggleOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <header className="tw-relative">
      <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 tw-px-3 tw-py-1">
        <RequestsProjectsBreadcrumb className="tw-mt-3" ticket={ticket} onProjectUpdate={() => fetchAndSetTicket()} />
        <div className="tw-flex tw-items-center tw-gap-2">
          <HeaderActions />
        </div>
      </div>
      {isOpen && (
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-3">
          <RequestName
            showValidationError={invalidFields?.includes('subject') && showValidationErrors}
            wrapperClassName="tw-min-w-12 tw-max-w-full tw-w-fit tw-flex-grow"
            className="tw-w-full"
            revisionClassName="tw-truncate"
          />

          <TicketStatusBadge status={ticket?.status} className="tw-mr-2 tw-flex-shrink-0" />
          {!showRevisions && <HeaderDropdowns />}
          {showRevisions && <HeaderRequestDetails />}
        </div>
      )}
      <CloseButton Icon={isOpen ? ChevronUpIcon : ChevronDownIcon} onClick={toggleOpen} position="bottom" />

      {(isStatusInProgress(ticket.status) || isStatusInReview(ticket.status)) && (
        <Banner type="info">
          Please note that your request is in progress. Replies to your designer that contain directions for revision
          may not be accommodated today.
        </Banner>
      )}
      <ConfirmNavigatePendingUpdatesModal
        ticketId={ticket?.id}
        isOpen={confirmationVisible}
        setOpen={setConfirmationVisible}
      />
    </header>
  )
}
