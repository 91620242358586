import { useEffect } from 'react'

import { ReactNode } from 'react'

import { getTicketRequest } from 'lib/api/tickets/tickets'
import { toast } from 'lib/components/toast/toast'
import { cn } from 'lib/util/cn'
import { useState } from 'react'
import { ChevronLeftIcon } from 'lucide-react'

interface ReferredBreadcrumbProps {
  currentPageText: string
  className?: string
}

export default function ReferredBreadcrumb({ currentPageText, className }: ReferredBreadcrumbProps) {
  const [referredText, setReferredText] = useState<ReactNode>()
  const referrer = document.referrer

  useEffect(() => {
    getReferrerText(referrer).then((text) => setReferredText(text))
  }, [referrer])

  if (!referredText) return null
  return (
    <div
      className={cn(
        className,
        'tw-flex tw-max-w-[300px] tw-items-center tw-gap-2 tw-overflow-hidden tw-whitespace-nowrap tw-text-neutral-400',
      )}
      data-testid="referred-breadcrumb"
    >
      <a href={referrer} className="tw-align-middle tw-text-neutral-400 hover:tw-text-cornflower-500">
        <ChevronLeftIcon className="lu-md tw-mr-1 tw-h-5 tw-w-5" />
        <span>{referredText}</span>
      </a>
      <span>/</span>
      <span className="tw-font-semibold">{currentPageText}</span>
    </div>
  )
}

async function getReferrerText(referrer: string): Promise<ReactNode> {
  const supportedPagesMap = [
    { path: 'tickets', text: 'Back to' },
    { path: 'requests', text: 'Back to requests' },
    { path: 'brands', text: 'Back to brands' },
    // Order matters here otherwise anything that includes 'settings' will be matched
    { path: 'companies/settings', text: 'Back to company settings' },
    { path: 'settings/notifications', text: 'Back to notification settings' },
    { path: 'settings', text: 'Back to my profile' },
    { path: 'white_label', text: 'Back to White Label' },
    { path: 'companies/team', text: 'Back to my team' },
    { path: 'search', text: 'Back to search' },
    { path: 'recent_updates', text: 'Back to product feedback' },
  ]

  const supportedPage = supportedPagesMap.find((page) => referrer.includes(page.path))

  if (supportedPage?.path === 'tickets') {
    try {
      const requestId = referrer.match(/\/tickets\/(\d+)/)?.[1]
      const { ticket } = await getTicketRequest(Number(requestId))
      const maxSubjectLength = 25
      return (
        <span>
          {supportedPage.text}{' '}
          <span>
            {ticket.subject.length > maxSubjectLength
              ? `${ticket.subject.slice(0, maxSubjectLength)}...`
              : ticket.subject}
          </span>
        </span>
      )
    } catch (error) {
      console.error('Error getting ticket', error)
      toast.error('Error getting ticket')
      return `${supportedPage.text} ticket`
    }
  } else if (supportedPage) {
    return `${supportedPage.text}`
  }

  return `Back to requests`
}
