import Banner from 'lib/components/banner/banner'
import { FolderOpenIcon, PlusIcon } from 'lucide-react'
import { ReactElement, useEffect, useState } from 'react'
import ProjectTemplatesTable from './project-templates-table'
import QueryClientProvider from 'providers/query-client-provider'
import ProjectTemplateDrawer from 'components/elements/projects/project-template-drawer'
import { AdminProjectTemplate } from 'interfaces/project-template'
import { Toaster } from 'lib/components/toast/toast'
import Button from 'components/core/button'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'

function ProjectTemplates(): ReactElement {
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [showTemplateDrawer, setShowTemplateDrawer] = useState(false)

  const handleSetIsProjectDrawerOpen = (isOpen: boolean) => {
    if (!isOpen) {
      setShowTemplateDrawer(false)
    }
  }

  useEffect(() => {
    if (!showTemplateDrawer) {
      setSelectedTemplate(null)
    }
  }, [showTemplateDrawer])

  return (
    <QueryClientProvider>
      <FeatureFlagsProvider>
        <div className="tw-mt-[var(--header-height,0px)] tw-h-[calc(100vh-var(--header-height,0px))] tw-w-full tw-p-5">
          <h2 className="tw-m-0 tw-flex tw-items-center tw-gap-1 tw-p-0 tw-text-xl">
            <FolderOpenIcon className="tw-size-6" />
            <span>Projects</span>
          </h2>
          <div className="tw-my-2 tw-flex tw-flex-col tw-gap-2">
            <Banner type="warning">
              Projects is currently in development, some features may not work as expected or be available.
            </Banner>
          </div>
          <Button
            color="purple"
            className="tw-flex tw-items-center tw-gap-2"
            onClick={() => {
              setSelectedTemplate(null)
              setShowTemplateDrawer(true)
            }}
          >
            <PlusIcon className="lu-md" />
            Create Project Template
          </Button>
          <div className="tw-my-4">
            <ProjectTemplatesTable
              onTemplateClick={(projectTemplate: AdminProjectTemplate) => {
                setSelectedTemplate(projectTemplate)
                setShowTemplateDrawer(true)
              }}
            />
          </div>
          <ProjectTemplateDrawer
            isOpen={showTemplateDrawer}
            setOpen={handleSetIsProjectDrawerOpen}
            template={selectedTemplate}
          />
          <Toaster />
        </div>
      </FeatureFlagsProvider>
    </QueryClientProvider>
  )
}

export default ProjectTemplates
