import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useRequestContext } from './request-provider'
import DetailTask, { DetailTaskType } from '../types/detail-task'
import {
  createBulkDetailTasks,
  createDetailTask,
  deleteDetailTask,
  updateDetailTask,
} from 'lib/api/detail-tasks/detail-tasks-api'
import { EditMode } from 'lib/api/tickets/tickets'

interface ContextProps {
  children: ReactNode
}

export interface DirectionsContextValue {
  addAiTasks: (tasks: string[], genAiRequestId: number) => void
  addCopy: (description: string) => void
  addDirectionToInEditMode: (detailTaskId: number) => void
  addTask: (description: string) => void
  areDirectionsValid: boolean
  canCreateDirections: boolean
  canEditDirection: (direction: { ticketVersion: number }) => boolean
  deleteDirection: (detailTask: DetailTask) => Promise<void>
  directions: DetailTask[] | null
  filters: DirectionsFilters
  removeDirectionFromInEditMode: (detailTask: DetailTask) => void
  setFilters: Dispatch<SetStateAction<DirectionsFilters>>
  setNewDirectionHasValue: (hasValue: boolean) => void
  setShowDirectionErrors: Dispatch<SetStateAction<boolean>>
  showDirectionErrors: boolean
  subscribeToDirections: (event: string, callback: SubscriptionCallback) => void
  unsubscribeToDirections: (event: string, callback: SubscriptionCallback) => void
  update: (direction: DetailTask, description: string) => Promise<void>
}

type SubscriptionCallback = (direction: DetailTask | number) => void

interface DirectionsFilters {
  version: number
}

const defaultFilters: DirectionsFilters = Object.freeze({
  version: null,
})

const DirectionsContext = createContext({})

export function useDirectionsContext(): DirectionsContextValue {
  return useContext(DirectionsContext) as DirectionsContextValue
}

export default function DirectionsProvider({ children }: ContextProps): ReactElement {
  const [directions, setDirections] = useState<DetailTask[]>([])
  const [filters, setFilters] = useState<DirectionsFilters>(defaultFilters)
  const [newDirectionHasValue, setNewDirectionHasValue] = useState(false)
  const [directionsInEditMode, setDirectionsInEditMode] = useState([])
  const [showDirectionErrors, setShowDirectionErrors] = useState(false)

  const { ticket, showValidationErrors, editMode, fetchAndSetTicket } = useRequestContext()

  const mergedSubscriptions = useRef({
    add: [],
    remove: [],
    update: [],
  })

  const areDirectionsValid = useMemo(() => {
    return !newDirectionHasValue && directionsInEditMode.length === 0
  }, [newDirectionHasValue, directionsInEditMode.length])

  async function add(description: string, type: DetailTaskType) {
    try {
      const direction = await createDetailTask(ticket.id, {
        description,
        type,
        ticketVersion: ticket.currentVersion,
      })

      setDirections((directions: DetailTask[]) => {
        const newDirections = [...(directions || []), direction]
        setNewDirectionHasValue(false)

        return newDirections
      })
      mergedSubscriptions.current.add.forEach((cb) => cb(direction))

      fetchAndSetTicket()
    } catch (e) {
      window.alert('There was an error creating the direction, please try again')
      console.error('Error creating direction', e)
    }
  }

  function addCopy(description: string) {
    return add(description, DetailTaskType.COPY)
  }

  function addTask(description: string) {
    return add(description, DetailTaskType.TASK)
  }

  async function addAiTasks(tasks: string[], genAiRequestId: number) {
    try {
      const directions = tasks.map((task) => {
        return {
          description: task,
          type: DetailTaskType.GEN_AI_REQUEST,
          taskId: genAiRequestId,
          ticketVersion: ticket.currentVersion,
        }
      })

      const directionsResponse = await createBulkDetailTasks(ticket.id, directions)

      setDirections((directions: DetailTask[] | null) => {
        const newDirections = [...(directions || []), ...directionsResponse]
        setNewDirectionHasValue(false)

        return newDirections
      })

      mergedSubscriptions.current.add.forEach((cb) => {
        directionsResponse.forEach((direction) => cb(direction))
      })

      fetchAndSetTicket()
    } catch (e) {
      window.alert('There was an error creating the directions, please try again')
      console.error('Error creating directions', e)
    }
  }

  async function update(directionToUpdate: DetailTask, description: string) {
    if (directionToUpdate.description === description) return

    try {
      const updatedDirection = await updateDetailTask(directionToUpdate.id, description)

      setDirections(
        directions.map((direction) => {
          if (direction.id === updatedDirection.id) {
            return updatedDirection
          } else {
            return direction
          }
        }),
      )
      mergedSubscriptions.current.update.forEach((cb) => cb(updatedDirection))

      fetchAndSetTicket()
    } catch (e) {
      window.alert('There was an error updating the direction, please try again')
      console.error('Error updating direction', e)
    }
  }

  async function deleteDirection(direction: DetailTask): Promise<void> {
    try {
      await deleteDetailTask(direction.id)
      setDirections((currentDirections) => currentDirections.filter((d) => d.id !== direction.id))
      mergedSubscriptions.current.remove.forEach((cb) => cb(direction))

      fetchAndSetTicket()
    } catch (e) {
      window.alert('There was an error deleting the direction, please try again')
      console.error('Error deleting direction', e)
      throw new Error(e)
    }
  }

  const canCreateDirections = useMemo(() => {
    if (editMode === EditMode.processing || editMode === EditMode.complete) return false
    if (editMode === EditMode.draft) return true

    return ticket?.currentVersion === filters.version
  }, [editMode, filters.version, ticket?.currentVersion])

  const canEditDirection = useCallback(
    (direction: { ticketVersion: number }) => {
      const isCurrentVersion = direction.ticketVersion === ticket.currentVersion
      const isNotInProgress = editMode !== EditMode.processing
      const isNotComplete = editMode !== EditMode.complete
      return isCurrentVersion && isNotInProgress && isNotComplete
    },
    [editMode, ticket.currentVersion],
  )

  const removeDirectionFromInEditMode = useCallback(
    (detailTask: DetailTask) => {
      setDirectionsInEditMode(directionsInEditMode.filter((id) => id !== detailTask.id))
    },
    [directionsInEditMode],
  )

  const addDirectionToInEditMode = useCallback((detailTaskId: number) => {
    setDirectionsInEditMode((current) => [...current, detailTaskId])
  }, [])

  function subscribeToDirections(event: string, callback: SubscriptionCallback) {
    mergedSubscriptions.current[event].push(callback)
  }

  function unsubscribeToDirections(event: string, callback: SubscriptionCallback) {
    function removeCallbackIterator(cb: SubscriptionCallback) {
      return cb !== callback
    }

    mergedSubscriptions.current[event] = mergedSubscriptions.current[event].filter(removeCallbackIterator)
  }

  useEffect(() => {
    if (showValidationErrors && !areDirectionsValid) {
      setShowDirectionErrors(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showValidationErrors])

  useEffect(() => {
    if (areDirectionsValid) {
      setShowDirectionErrors(false)
    }
  }, [areDirectionsValid])

  const context: DirectionsContextValue = {
    addAiTasks,
    addCopy,
    addDirectionToInEditMode,
    addTask,
    areDirectionsValid,
    canCreateDirections,
    canEditDirection,
    deleteDirection,
    directions,
    filters,
    removeDirectionFromInEditMode,
    setFilters,
    setNewDirectionHasValue,
    setShowDirectionErrors,
    showDirectionErrors,
    subscribeToDirections,
    unsubscribeToDirections,
    update,
  }
  return <DirectionsContext.Provider value={context}>{children}</DirectionsContext.Provider>
}
