import { Ticket } from 'interfaces/ticket'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import ActiveTabMenu from '../dropdown/menus/active-tab-menu'
import DraftTabMenu from '../dropdown/menus/draft-tab-menu'
import ArchivedTabMenu from '../dropdown/menus/archived-tab-menu'
import { isStatusDraft, isStatusArchived, isStatusCanceled } from 'lib/ticket/utils'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import { useCallback } from 'react'

const ProjectDeliverablesFlyoutMenu = ({ ticket, onChange }: { ticket: Ticket; onChange: () => void }) => {
  const {
    id,
    subject,
    filesDownloadUrl,
    friendlyStatusName,
    user,
    ticketFilesCount,
    url,
    skillName,
    requestId,
    currentVersion,
  } = ticket

  const { menuButtonActionWithCallbacks } = useConfirmationModalContext()

  const menuButtonAction = menuButtonActionWithCallbacks(onChange)

  const ticketName = subject || `Untitled ${skillName}`

  const getActiveTab = useCallback(() => {
    if (isStatusDraft(friendlyStatusName)) {
      return (
        <DraftTabMenu
          requestId={requestId}
          url={url}
          subject={ticketName}
          userId={user.id}
          menuActionBtn={menuButtonAction}
        />
      )
    }
    if (isStatusArchived(friendlyStatusName) || isStatusCanceled(friendlyStatusName)) {
      return (
        <ArchivedTabMenu
          id={id}
          currentVersion={currentVersion}
          filesDownloadUrl={filesDownloadUrl}
          menuActionBtn={menuButtonAction}
          status={friendlyStatusName}
          subject={ticketName}
          ticketFilesCount={ticketFilesCount}
          userId={user?.id}
        />
      )
    }
    return (
      <ActiveTabMenu
        id={id}
        currentVersion={currentVersion}
        subject={ticketName}
        filesDownloadUrl={filesDownloadUrl}
        status={friendlyStatusName}
        userId={user?.id}
        menuActionBtn={menuButtonAction}
        ticketFilesCount={ticketFilesCount}
        hideMoveToTop
      />
    )
  }, [
    friendlyStatusName,
    id,
    currentVersion,
    ticketName,
    filesDownloadUrl,
    user.id,
    menuButtonAction,
    ticketFilesCount,
    requestId,
    url,
  ])

  return (
    <IconFlyoutMenu icon={['far', 'ellipsis-v']} color="transparent">
      {getActiveTab()}
    </IconFlyoutMenu>
  )
}

export default ProjectDeliverablesFlyoutMenu
