import {
  addTicketFile,
  AddTicketFileParams,
  AdminTicketFile,
  deleteTicketFile,
  getTicketFile,
  getTicketFilesForTicket,
} from 'lib/api/admin/ticket-files/admin-ticket-files'
import { AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'
import { FileUploaderResult, ModalFileUploader } from 'lib/components/file-uploader/file-uploader'
import { generateRangeTo } from 'lib/util/range'

import { AnimatedLoadingScreen } from 'components/pages/requests/empty-screens'
import { useAdminUserContext } from 'providers/admin-user-provider'
import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAdminTicketContext } from './admin-ticket-provider'
import { Annotation } from 'lib/api/annotations/annotations'
import { toast } from 'lib/components/toast/toast'

interface AdminMediaContextProps {
  children: ReactNode
}

interface AdminMediaContextValue {
  addFile: (params: AddTicketFileParams) => Promise<AdminTicketFile>
  currentVersion: number
  currentSelectedPage: AdminTicketFile
  deleteFile: (file: AdminTicketFile) => void
  extractedPreviewIndex: number
  files: AdminTicketFile[]
  filters: AdminMediaFilters
  getFilePageNumber: (parentId: number, childId: number) => number
  getParentFileName: (parentId: number) => string
  findAndSelectFileFromAnnotation: (annotation: Annotation, ticketVersion: number) => Promise<void>
  getLatestFiles: (uploadedByCreative: boolean) => Promise<AdminTicketFile[]>
  selectedFile: AdminTicketFile
  selectedVersion: number
  setExtractedPreviewIndex: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<AdminMediaFilters>>
  setSelectedFile: Dispatch<SetStateAction<AdminTicketFile>>
  versions: number[]
  visibleFiles: AdminTicketFile[]
  setIsFileUploaderOpen: Dispatch<SetStateAction<boolean>>
}

interface AdminMediaFilters {
  isUploadedByCreative: boolean
  version: number
}

const AdminMediaContext = createContext({})

export default function AdminMediaProvider({ children }: AdminMediaContextProps): ReactElement {
  const [isFileUploaderOpen, setIsFileUploaderOpen] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [files, setFiles] = useState<AdminTicketFile[]>([])
  const [selectedFile, setSelectedFile] = useState<AdminTicketFile>(null)
  const [extractedPreviewIndex, setExtractedPreviewIndex] = useState<number>(-1)

  const { ticket } = useAdminTicketContext()
  const { user } = useAdminUserContext()
  const [filters, setFilters] = useState<AdminMediaFilters>({
    isUploadedByCreative: ticket.state === AdminTicketState.incompleteReviewFailed || user.isDPManager,
    version: ticket.currentVersion,
  })

  const versions = useMemo(() => {
    return generateRangeTo(ticket.currentVersion)
  }, [ticket.currentVersion])

  const visibleFiles = useMemo(() => {
    const filter = filters.version
      ? (file) => file.ticketVersion === filters.version && file.uploadedByCreative === filters.isUploadedByCreative
      : (file) => file.uploadedByCreative === filters.isUploadedByCreative
    return files.filter(filter).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  }, [files, filters])

  const addFile = useCallback(
    async (params: AddTicketFileParams): Promise<AdminTicketFile> => {
      const newFile = await addTicketFile(ticket.id, params)
      setFiles((previous) => [...previous, newFile])

      return newFile
    },
    [ticket.id],
  )

  const addFileFromResponse = useCallback((response: PromiseSettledResult<AdminTicketFile>) => {
    if (response.status === 'fulfilled') {
      setFiles((current) => [...current, response.value])
    } else {
      toast.error('Oops! Something went wrong while uploading. Please try again')
    }
  }, [])

  async function onBytescaleUpload(files: FileUploaderResult[]) {
    setIsFileUploaderOpen(false)

    const promises = files.map(async (file) => {
      const s3_filename = file.filePath.split('/').pop()
      const mimetype = file.originalFile.mime
      const filename = file.originalFile.originalFileName

      return await addTicketFile(ticket.id, {
        filename,
        s3_filename,
        mimetype,
      })
    })

    const results = (await Promise.allSettled(promises)) as PromiseFulfilledResult<AdminTicketFile>[]
    results.forEach(addFileFromResponse)
  }

  async function deleteFile(file: AdminTicketFile) {
    await deleteTicketFile(ticket.id, file.id)
    if (file === selectedFile) {
      setSelectedFile(null)
    }
    setFiles((previous) => previous.filter((f) => f.id !== file.id))
  }

  async function getLatestFiles(isUploadedByCreative?: boolean) {
    return await getFilteredFiles(ticket.id, {
      isUploadedByCreative: isUploadedByCreative,
      version: ticket.currentVersion,
    })
  }

  function selectFileAndFilters(file: AdminTicketFile, ticketFiles: AdminTicketFile[], annotation: Annotation) {
    if (filters.isUploadedByCreative !== file.uploadedByCreative || filters.version !== file.ticketVersion) {
      setFilters({ isUploadedByCreative: file.uploadedByCreative, version: file.ticketVersion })

      if (files !== ticketFiles) {
        setFiles(ticketFiles)
      }
    }

    if (file.isExtractable) {
      const pageIndex = file.extractedPages.findIndex((page) => page.id === annotation.assetId)
      setExtractedPreviewIndex(pageIndex)
    }
    setSelectedFile(file)
  }

  function getFilePageNumber(parentId: number, childId: number) {
    const parentFile = files.find((file) => file.id === parentId)
    if (parentFile?.isExtractable) {
      const index = parentFile.extractedPages.findIndex((page) => page.id === childId)
      return index === -1 ? null : index + 1
    }
    return null
  }

  function getParentFileName(parentId: number) {
    const parentFile = files.find((file) => file.id === parentId)
    return parentFile?.name || null
  }

  async function findAndSelectFileFromAnnotation(annotation: Annotation, ticketVersion: number) {
    const filtersMatchesCurrentVersion = ticketVersion === filters.version || filters.version === 0
    const ticketFiles = filtersMatchesCurrentVersion ? files : await getTicketFilesForTicket(ticket.id, ticketVersion)

    const file: AdminTicketFile = findFileFromAnnotation(annotation, ticketFiles)

    if (file) {
      selectFileAndFilters(file, ticketFiles, annotation)
    }
  }

  useEffect(() => {
    let isAbandoned = false

    // This could be optimized to avoid calling this when findAndSelectFileFromAnnotation updates the filters
    getTicketFilesForTicket(ticket.id, filters.version).then((response) => {
      if (!isAbandoned) {
        setFiles(response)
        setIsLoaded(true)
        if (
          (ticket.state === AdminTicketState.incompleteReviewFailed || user.isDPManager) &&
          filters.isUploadedByCreative &&
          filters.version === ticket.currentVersion
        ) {
          setSelectedFile(response.find((file) => file.uploadedByCreative === filters.isUploadedByCreative))
        }
      }
    })
    return () => {
      isAbandoned = true
    }
  }, [
    ticket.currentVersion,
    ticket.id,
    filters.isUploadedByCreative,
    filters.version,
    user.isDPManager,
    filters,
    ticket.state,
  ])

  useEffect(() => {
    let isAbandoned: boolean, timeoutUntilNextPoll: number

    function recursivelyPollUntilPagesExtracted(delay = 500) {
      getTicketFile(ticket.id, selectedFile.id).then((latestFile) => {
        if (!isAbandoned && latestFile.isExtractable) {
          if (latestFile.extractedPages.length > 0) {
            setFiles((currentFiles) => {
              return currentFiles.map((file) => {
                if (file.id === latestFile.id) {
                  return updateExtractableFile(file, latestFile)
                }
                return file
              })
            })

            setExtractedPreviewIndex(0)
            setSelectedFile((current) => {
              return updateExtractableFile(current, latestFile)
            })
          } else {
            timeoutUntilNextPoll = window.setTimeout(() => recursivelyPollUntilPagesExtracted(delay * 2), delay)
          }
        }
      })
    }

    if (selectedFile && selectedFile.isExtractable && selectedFile.extractedPages.length === 0) {
      recursivelyPollUntilPagesExtracted()
    }

    return () => {
      isAbandoned = true
      window.clearTimeout(timeoutUntilNextPoll)
    }
  }, [selectedFile, ticket?.id])

  useEffect(() => {
    if (
      selectedFile?.uploadedByCreative !== filters.isUploadedByCreative ||
      (selectedFile?.ticketVersion !== filters.version && filters.version !== 0)
    ) {
      setSelectedFile(null)
    }
  }, [filters.isUploadedByCreative, filters.version, selectedFile?.ticketVersion, selectedFile?.uploadedByCreative])

  const currentSelectedPage = useMemo(() => {
    if (selectedFile?.isExtractable) {
      if (extractedPreviewIndex === -1 && selectedFile.extractedPages?.length > 0) {
        return selectedFile.extractedPages[0]
      }

      const index = Math.min(extractedPreviewIndex, selectedFile.extractedPages.length)
      if (index === -1) {
        return null
      }
      return selectedFile.extractedPages[index]
    }
    return selectedFile
  }, [selectedFile, extractedPreviewIndex])

  const context: AdminMediaContextValue = {
    addFile,
    currentSelectedPage,
    currentVersion: ticket.currentVersion,
    deleteFile,
    extractedPreviewIndex,
    files,
    filters,
    getFilePageNumber,
    getParentFileName,
    findAndSelectFileFromAnnotation,
    getLatestFiles,
    selectedFile,
    selectedVersion: filters.version,
    setExtractedPreviewIndex,
    setFilters,
    setIsFileUploaderOpen,
    setSelectedFile,
    versions,
    visibleFiles,
  }

  if (!isLoaded) {
    return (
      <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
        <AnimatedLoadingScreen />
      </div>
    )
  }

  return (
    <AdminMediaContext.Provider value={context}>
      {children}
      <ModalFileUploader
        isOpen={isFileUploaderOpen}
        setIsOpen={setIsFileUploaderOpen}
        onComplete={onBytescaleUpload}
        options={{
          maxFileSizeBytes: 1024 * 1024 * 1024 * 16,
        }}
      />
    </AdminMediaContext.Provider>
  )
}

export function useAdminMediaContext(): AdminMediaContextValue {
  return useContext(AdminMediaContext) as AdminMediaContextValue
}

async function getFilteredFiles(ticketId: number, filters: AdminMediaFilters): Promise<AdminTicketFile[]> {
  const response = await getTicketFilesForTicket(ticketId, filters.version)
  return response.filter((file) => file.uploadedByCreative === filters.isUploadedByCreative)
}

function updateExtractableFile(oldFile: AdminTicketFile, newFile: AdminTicketFile): AdminTicketFile {
  const { extractedPages, previewUrl } = newFile
  return {
    ...oldFile,
    previewUrl,
    extractedPages,
  }
}

function findAnnotationFromExtractablePages(
  file: AdminTicketFile,
  annotation: Annotation,
): AdminTicketFile | undefined {
  return file.extractedPages.find((page) =>
    page.annotations.some((pageAnnotation) => annotation.id === pageAnnotation.id),
  )
}

function findFileFromAnnotation(annotation: Annotation, ticketFiles: AdminTicketFile[]): AdminTicketFile {
  if (!ticketFiles) {
    return null
  }

  return ticketFiles.find((file) => {
    if (file.isExtractable) {
      return findAnnotationFromExtractablePages(file, annotation)
    } else if (file.annotations.some((fileAnnotation) => annotation.id === fileAnnotation.id)) {
      return file
    }
  })
}
