import IconButton from 'lib/components/buttons/icon-button'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import Tooltip, { Direction } from 'lib/components/tooltip/tooltip'
import { DownloadIcon, LinkIcon } from 'lucide-react'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { useMediaContext } from './media-provider'
import { toast } from 'lib/components/toast/toast'
import { FileExportModalMode } from './file-download-modal'
interface MediaActionsProps {
  showViewDetials?: boolean
}

const MediaActions = ({ showViewDetials = true }: MediaActionsProps) => {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { isCollaboratorView } = useAnnotationsContext()
  const { setFileExportModalMode, currentTicketFile, setShowPromptModal, setIsTaggingDrawerOpen, genAiResultType } =
    useMediaContext()

  const stockpressEnabled = isFeatureFlagEnabled('stockpress')
  const wipPreviewActionsEnabled = isFeatureFlagEnabled('wip_preview_actions')

  return (
    <>
      {!isCollaboratorView &&
        (!currentTicketFile?.preview || (currentTicketFile?.preview && wipPreviewActionsEnabled)) &&
        currentTicketFile?.downloadUrl && (
          <Tooltip content="Download" direction="up">
            <IconButton
              color="transparent"
              onClick={() => {
                setIsTaggingDrawerOpen(false)
                setFileExportModalMode(FileExportModalMode.DOWNLOAD)
              }}
              className="tw-mx-1 tw-p-3"
              dataTestid="download-file"
            >
              <DownloadIcon className="lu-light lu-sm" />
            </IconButton>
          </Tooltip>
        )}
      {!isCollaboratorView && (stockpressEnabled || showViewDetials || genAiResultType != null) && (
        <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']}>
          {stockpressEnabled && (
            <IconFlyoutMenu.Button
              onClick={() => {
                setIsTaggingDrawerOpen(false)
                setFileExportModalMode(FileExportModalMode.EXPORT)
              }}
            >
              Export
            </IconFlyoutMenu.Button>
          )}
          {genAiResultType !== null && (
            <IconFlyoutMenu.Button
              onClick={() => {
                setIsTaggingDrawerOpen(false)
                setShowPromptModal(true)
              }}
            >
              View Original Prompt
            </IconFlyoutMenu.Button>
          )}
          {showViewDetials && (
            <IconFlyoutMenu.Button onClick={() => setIsTaggingDrawerOpen(true)}>View details</IconFlyoutMenu.Button>
          )}
        </IconFlyoutMenu>
      )}
    </>
  )
}

interface ShareActionProps {
  direction?: Direction
}

const ShareAction = ({ direction = 'up' }: ShareActionProps) => {
  const { isCollaboratorView } = useAnnotationsContext()
  const { selectedFile, shareLinkUrl } = useMediaContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const wipPreviewActionsEnabled = isFeatureFlagEnabled('wip_preview_actions')

  return (
    !isCollaboratorView &&
    (!selectedFile.preview || (selectedFile.preview && wipPreviewActionsEnabled)) &&
    shareLinkUrl && (
      <Tooltip content="Copy Share Link" direction={direction}>
        <IconButton
          color="transparent"
          onClick={() => {
            navigator.clipboard.writeText(shareLinkUrl)
            toast.success('File URL copied to clipboard! This link will expire in 7 days.')
          }}
          className="tw-mx-1"
          dataTestid="copy-share-link"
        >
          <LinkIcon className="lu-light lu-sm" />
        </IconButton>
      </Tooltip>
    )
  )
}

export { MediaActions, ShareAction }
