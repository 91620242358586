import { AdminProjectTemplate } from 'interfaces/project-template'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { CopyIcon, LoaderCircle, TrashIcon } from 'lucide-react'
import ConfirmationModal from '../confirmation-modal'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { toast } from 'sonner'

const ProjectTemplatesFlyoutMenu = ({ projectTemplate }: { projectTemplate: AdminProjectTemplate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  const deleteTemplateMutation = useMutation({
    mutationFn: () => {
      setLoading(true)
      return request({
        endpoint: 'deleteProjectTemplate',
        query: { id: projectTemplate.id },
      })
    },
    onSuccess: () => {
      toast.success('Project template deleted successfully')
      queryClient.invalidateQueries({ queryKey: ['projectTemplates'] })
      setIsModalVisible(false)
    },
    onError: (error) => {
      toast.error('Error deleting project template')
      console.error(error)
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  return (
    <>
      <IconFlyoutMenu icon={['far', 'ellipsis-v']} color="transparent">
        <IconFlyoutMenu.Button onClick={() => {}}>
          <CopyIcon className="lu-md" />
          &nbsp;Duplicate
        </IconFlyoutMenu.Button>
        {!projectTemplate?.blankTemplate && (
          <IconFlyoutMenu.Button onClick={() => setIsModalVisible(true)}>
            <TrashIcon className="lu-md" />
            &nbsp;Delete
          </IconFlyoutMenu.Button>
        )}
      </IconFlyoutMenu>
      <ConfirmationModal
        title="Delete Project Template"
        message={`Are you sure you want to delete the template "${projectTemplate.name}"?`}
        confirmBtnText={loading ? <LoaderCircle className="tw-animate-spin" /> : 'Delete template'}
        confirmAction={() => deleteTemplateMutation.mutate()}
        cancelAction={() => setIsModalVisible(false)}
        visible={isModalVisible}
      />
    </>
  )
}

export default ProjectTemplatesFlyoutMenu
