import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { PenLine } from 'lucide-react'
import OpenSeadragon, { CanvasClickEvent, ZoomEvent } from 'openseadragon'
import Annotorious from '@recogito/annotorious-openseadragon'
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AnnotationRecord } from 'lib/api/annotations/annotations'
import { GenAiResultTypes } from 'lib/api/gen-ai/gen-ai-requests'
import IconButton from 'lib/components/buttons/icon-button'
import InpaintingCanvas from 'lib/components/canvas/inpainting-canvas'
import Tooltip from 'lib/components/tooltip/tooltip'
import debounce from 'lib/util/debounce'
import EditAssistPromptModal from 'components/pages/request/ai/modals/edit-assist-prompt-modal'
import { useAnnotationsContext } from 'components/pages/request/providers/annotations-provider'
import { AnnotoriousAnnotation, AnnotoriousAnnotationTarget } from './annotorious-openseadragon-types'
import { toast } from '../toast/toast'

interface AnnotationUser {
  companyId: number
  fullName: string
  genAiInpainting: boolean
  id: number
  isDPAdmin: boolean
}

interface AnnotationViewerProps {
  annotations: AnnotationRecord<AnnotoriousAnnotation>[]
  genAiResultType: GenAiResultTypes
  isExtractable: boolean
  onCreate: (annotation: AnnotoriousAnnotation, callback: SimpleCallback) => void
  onCreateInpaintingRequest: (mask: Blob, original: Blob, prompt: string) => Promise<void>
  onUpdate: (annotation: AnnotoriousAnnotation) => void
  readOnly?: boolean
  url: string
  user: AnnotationUser
}

interface AnnotoriousInstance {
  cancelSelected: () => void
  headless: boolean
  readOnly: boolean
  setAnnotations: (annotations: AnnotoriousAnnotation[]) => void
  setAuthInfo: (authInfo: { displayName: string; id: number }) => void
  setDrawingEnabled: (enabled: boolean) => void
  setDrawingTool: (tool: string) => void
}

type SimpleCallback = () => void

interface UserData {
  callback: (zoom: number) => void
}

enum Tools {
  Move,
  Annotate,
  Paint,
}

const classNames = {
  buttons: {
    zoomIn: `tw-relative tw-inline-flex tw-items-center tw-rounded-l-md tw-bg-white tw-px-2 tw-py-2 tw-text-neutral-800
      tw-border tw-border-solid tw-border-neutral-300 hover:tw-bg-neutral-50 focus:tw-z-10
      disabled:tw-text-neutral-400 disabled:tw-border-neutral-200 disabled:tw-bg-white`,
    zoomOut: `tw-relative tw--ml-px tw-inline-flex tw-items-center tw-rounded-r-md tw-bg-white tw-px-2 tw-py-2 tw-text-neutral-800
      tw-border tw-border-solid tw-border-neutral-300 hover:tw-bg-neutral-50 focus:tw-z-10
      disabled:tw-text-neutral-400 disabled:tw-border-neutral-200 disabled:tw-bg-white`,
  },
  select: 'tw-rounded-md tw-border-0 tw-py-2 tw-px-3 tw-text-neutral-800',
  container: 'tw-relative tw-w-full tw-h-full tw-z-0',
  tools: {
    bottom: 'tw-absolute tw-bottom-12 tw-left-0 tw-w-full tw-text-center tw-z-10 tw-h-0',
    top: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-text-center tw-z-10 tw-h-0 tw-flex tw-justify-center',
  },
  viewer: {
    [Tools.Move]: 'tw-cursor-move',
    [Tools.Annotate]: 'tw-cursor-crosshair',
    [Tools.Paint]: 'tw-cursor-pointer',
  },
}

const zoomPresets = [0, 50, 75, 100, 125, 150, 200]

export default function AnnotationViewer({
  annotations,
  genAiResultType,
  isExtractable,
  onCreate,
  onCreateInpaintingRequest,
  onUpdate,
  readOnly = false,
  url,
  user,
}: AnnotationViewerProps) {
  const [currentZoom, setCurrentZoom] = useState<[number, number]>([0, 0])
  const [selectedTool, setSelectedTool] = useState<Tools>(Tools.Move)
  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [updatedSelectedAnnotation, setUpdatedSelectedAnnotation] = useState<AnnotoriousAnnotation>(null)

  const { setAnnotorious, highlightAnnotation, setShowImageAnnotationCreator } = useAnnotationsContext()

  const annotoriousAnnotations: AnnotoriousAnnotation[] = useMemo(() => {
    return annotations.map((annotation) => annotation.data)
  }, [annotations])

  const annotoriousInstance = useRef(null)
  const canvasInstance = useRef(null)
  const openSeadragonInstance = useRef(null)

  const debouncedUpdateInpaintingCanvas = debounce(updateInpaintingCanvas, 100)
  const debouncedZoomToShowAll = debounce(zoomToShowAll, 200)

  if (annotoriousInstance.current) {
    fixRandomToolChange()
  }

  // Sometimes annotorious changes the selected tool
  // This ensures the tool stays the same unless we explicitly change it
  function fixRandomToolChange() {
    if (selectedTool === Tools.Annotate) {
      annotoriousInstance.current.setDrawingEnabled(true)
      annotoriousInstance.current.setDrawingTool('rect')
    } else {
      annotoriousInstance.current.setDrawingEnabled(false)
    }
  }

  function handleButtonClick(event: MouseEvent, newTool: Tools) {
    event.stopPropagation()

    switchTool(selectedTool, newTool)
  }

  function handleMouseUp(event) {
    // classes prefixed with "a9s-" are Annotorious/OpenSeadragon
    event.stopPropagation()
    if (event.target.classList.toString().includes('a9s') && updatedSelectedAnnotation) {
      if (updatedSelectedAnnotation.id) {
        annotoriousInstance.current.updateSelected(updatedSelectedAnnotation, true)
        onUpdate(updatedSelectedAnnotation)
        setUpdatedSelectedAnnotation(null)
      }
    }
  }

  async function handlePromptModalClose(prompt: string) {
    if (prompt) {
      try {
        switchTool(Tools.Paint, Tools.Move, false)
        const { mask, original } = await canvasInstance.current.getDrawing()
        await onCreateInpaintingRequest(mask, original, prompt)
        canvasInstance.current.clear()
      } catch (e) {
        console.error('handlePromptModalClose error:', e)
        toast.error(
          'There was a problem with generating the images for Edit Assist. Please reload your browser and try again.',
        )
        throw e
      }
    } else {
      canvasInstance.current.clear()
    }
    setShowPrompt(false)
  }

  function resetTool(cancelSelected = true) {
    switchTool(Tools.Annotate, Tools.Move, cancelSelected)
  }

  function switchTool(previous: Tools, next: Tools, cancelSelected = true) {
    if (cancelSelected) {
      annotoriousInstance.current.cancelSelected()
    }

    // TODO: logic around whether Annotorious has drawing enabled is subject to change in Merging

    if (next !== previous) {
      if (previous === Tools.Paint) {
        canvasInstance.current.disable()
        togglePanAndZoom(true)
      } else if (previous === Tools.Annotate) {
        setShowImageAnnotationCreator(false)
        setUpdatedSelectedAnnotation(null)
        annotoriousInstance.current.setDrawingEnabled(false)
      }

      if (next === Tools.Annotate) {
        annotoriousInstance.current.setDrawingEnabled(true)
        annotoriousInstance.current.setDrawingTool('rect')
      } else if (next === Tools.Paint && user.genAiInpainting) {
        togglePanAndZoom(false)
        canvasInstance.current.enable()
      }
      setSelectedTool(next)
    } else {
      if (next === Tools.Annotate) {
        switchTool(previous, Tools.Move)
      }
    }
  }

  function togglePanAndZoom(isPannable: boolean) {
    openSeadragonInstance.current.panHorizontal = isPannable
    openSeadragonInstance.current.panVertical = isPannable
    if (isPannable) {
      if (user.genAiInpainting) {
        openSeadragonInstance.current.removeHandler('after-resize', debouncedZoomToShowAll)
      }
      openSeadragonInstance.current.removeHandler('canvas-click', openSeaDragonDisableAction)
      openSeadragonInstance.current.removeHandler('canvas-pinch', openSeaDragonDisableAction)
      openSeadragonInstance.current.removeHandler('canvas-scroll', openSeaDragonDisableAction)
    } else {
      zoomTo('0')
      if (user.genAiInpainting) {
        canvasInstance.current.update(currentZoom[0])
        openSeadragonInstance.current.addHandler('after-resize', debouncedZoomToShowAll)
      }
      openSeadragonInstance.current.addHandler('canvas-click', openSeaDragonDisableAction)
      openSeadragonInstance.current.addHandler('canvas-pinch', openSeaDragonDisableAction)
      openSeadragonInstance.current.addHandler('canvas-scroll', openSeaDragonDisableAction)
    }
  }

  function unPropagatedEvent(event) {
    event.stopPropagation()
  }

  function updateInpaintingCanvas(preciseCurrentZoom: number) {
    if (canvasInstance.current.update) {
      canvasInstance.current.update(preciseCurrentZoom)
    }
  }

  function updateSelectedAnnotation(target: AnnotoriousAnnotationTarget) {
    if (updatedSelectedAnnotation) {
      setUpdatedSelectedAnnotation({ ...updatedSelectedAnnotation, target })
    } else {
      const selected = annotoriousInstance.current.getSelected()
      const updatedAnnotation = { ...selected, target }
      setUpdatedSelectedAnnotation(updatedAnnotation)
    }
  }

  function zoomIn() {
    openSeadragonInstance.current.viewport.zoomBy(2)
  }

  function zoomOut() {
    openSeadragonInstance.current.viewport.zoomBy(0.5)
  }

  function zoomTo(zoom: string) {
    if (zoom === null || selectedTool === Tools.Paint) {
      return
    }
    const zoomValue = parseInt(zoom)
    if (!zoomValue) {
      openSeadragonInstance.current.viewport.goHome({ immediately: true })
      return
    }
    openSeadragonInstance.current.viewport.zoomTo(zoomValue / 100)
  }

  function zoomToShowAll() {
    openSeadragonInstance.current.viewport.goHome({ immediately: true })
  }

  function zoomEventCallback(preciseCurrentZoom: number) {
    setCurrentZoom([preciseCurrentZoom, Math.floor(preciseCurrentZoom)])
    if (!openSeadragonInstance.current.panHorizontal) {
      if (user.genAiInpainting) {
        debouncedUpdateInpaintingCanvas(preciseCurrentZoom)
      }
    }
  }

  // Initialize OpenSeadragon and Annotorious instances only when necessary
  useEffect(() => {
    function onDraw() {
      setShowPrompt(true)
    }

    openSeadragonInstance.current = createOpenSeadragon(url)
    if (user.genAiInpainting) {
      canvasInstance.current = new InpaintingCanvas(openSeadragonInstance.current, url, onDraw)
    }

    annotoriousInstance.current = createAnnotorious(openSeadragonInstance.current, readOnly, user)

    openSeadragonInstance.current.addHandler(
      'zoom',
      (zoomEvent: ZoomEvent) => {
        const preciseCurrentZoom = zoomEvent.zoom * 100
        const { callback } = zoomEvent.userData as UserData
        callback(preciseCurrentZoom)
      },
      { callback: zoomEventCallback },
    )

    setAnnotorious(annotoriousInstance.current)
    annotoriousInstance.current.on('cancelSelected', () => {
      setShowImageAnnotationCreator(false)
      setUpdatedSelectedAnnotation(null)
      resetTool(false)
    })
    annotoriousInstance.current.on('createSelection', (annotation) => {
      setUpdatedSelectedAnnotation(annotation)
      onCreate(annotation, null)
    })
    annotoriousInstance.current.on('createAnnotation', resetTool)
    annotoriousInstance.current.on('changeSelectionTarget', updateSelectedAnnotation)
    annotoriousInstance.current.on('selectAnnotation', (annotoriousAnnotation: AnnotoriousAnnotation) => {
      const annotation = findAnnotationRecord(annotations, annotoriousAnnotation)
      setUpdatedSelectedAnnotation(annotoriousAnnotation)
      highlightAnnotation(annotation)
    })

    return () => {
      try {
        annotoriousInstance.current.destroy()
        openSeadragonInstance.current.destroy()
        if (user.genAiInpainting) {
          canvasInstance.current.destroy()
        }
        setSelectedTool(Tools.Move)
      } catch {
        // do nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, url, user])

  useEffect(() => {
    if (annotoriousInstance.current) {
      annotoriousInstance.current.setAnnotations(annotoriousAnnotations)
    }
  }, [annotoriousAnnotations])

  useEffect(() => {
    if (annotoriousInstance.current) {
      // Remove previous handlers to avoid duplicates
      annotoriousInstance.current.off('createSelection')
      annotoriousInstance.current.off('createAnnotation')
      annotoriousInstance.current.off('changeSelectionTarget')

      // Re-attach with updated handlers
      annotoriousInstance.current.on('createSelection', (annotation) => {
        setUpdatedSelectedAnnotation(annotation)
        onCreate(annotation, null)
      })
      annotoriousInstance.current.on('createAnnotation', resetTool)
      annotoriousInstance.current.on('changeSelectionTarget', updateSelectedAnnotation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCreate, onUpdate])

  return (
    <div
      className={classNames.container}
      onClick={unPropagatedEvent}
      onMouseDown={unPropagatedEvent}
      onMouseUp={handleMouseUp}
    >
      {user.genAiInpainting && <EditAssistPromptModal open={showPrompt} onClose={handlePromptModalClose} />}
      <div className={classNames.tools.top}>
        <IconButton
          className="tw-m-2"
          color={selectedTool === Tools.Move ? 'primary' : 'secondary'}
          invert={selectedTool === Tools.Move}
          selected={selectedTool === Tools.Move}
          icon={['fal', 'hand-paper']}
          onClick={(e: MouseEvent) => handleButtonClick(e, Tools.Move)}
          size="lg"
        />

        {!readOnly && (
          <Tooltip content="Create an annotation" direction="up">
            <IconButton
              className="tw-m-2"
              color={selectedTool === Tools.Annotate ? 'primary' : 'secondary'}
              invert={selectedTool === Tools.Annotate}
              selected={selectedTool === Tools.Annotate}
              onClick={(e: MouseEvent) => handleButtonClick(e, Tools.Annotate)}
              size="lg"
              dataTestid="pen-line-button"
            >
              <PenLine className="lu-light lu-md" />
            </IconButton>
          </Tooltip>
        )}

        {user.genAiInpainting && !readOnly && !isExtractable && genAiResultType === null && (
          <IconButton
            className="tw-m-2"
            color={selectedTool === Tools.Paint ? 'primary' : 'secondary'}
            invert={selectedTool === Tools.Paint}
            selected={selectedTool === Tools.Paint}
            icon={['fal', 'paint-brush']}
            onClick={(e: MouseEvent) => handleButtonClick(e, Tools.Paint)}
            size="lg"
          />
        )}
      </div>
      <div className={classNames.tools.bottom}>
        <span>
          <button
            className={classNames.buttons.zoomIn}
            onClick={zoomIn}
            disabled={selectedTool === Tools.Paint}
            data-testid="search-plus-button"
          >
            <FontAwesomeIcon icon={['fal', 'search-plus']} size="lg" />
          </button>
          <button
            className={classNames.buttons.zoomOut}
            onClick={zoomOut}
            disabled={selectedTool === Tools.Paint}
            data-testid="search-minus-button"
          >
            <FontAwesomeIcon icon={['fal', 'search-minus']} size="lg" />
          </button>
        </span>
        <span className="tw-mx-2 tw-align-bottom">
          <select
            className={classNames.select}
            value={currentZoom[1]}
            onChange={(event) => zoomTo(event.target.value)}
            disabled={selectedTool === Tools.Paint}
          >
            {isZoomPreset(currentZoom[1]) ? null : <option value={null}>{currentZoom[1]}%</option>}
            {zoomPresets.map((zoom) => (
              <option key={zoom} value={zoom}>
                {zoom ? `${zoom}%` : 'Fit'}
              </option>
            ))}
          </select>
        </span>
      </div>
      <div id="openSeadragonViewer" className={`tw-h-full ${classNames.viewer[selectedTool]}`} />
    </div>
  )
}

function createAnnotorious(
  openSeadragonInstance: OpenSeadragon.Viewer,
  readOnly: boolean,
  user: AnnotationUser,
): AnnotoriousInstance {
  const annotoriousConfig = {
    fragmentUnit: 'percent',
    widgets: [],
    disableEditor: true,
  }

  const anno = Annotorious(openSeadragonInstance, annotoriousConfig)
  const displayName = user.isDPAdmin ? `${user.fullName} (DP)` : user.fullName
  anno.readOnly = readOnly
  anno.setAuthInfo({
    displayName,
    id: user.id,
  })
  return anno
}

function createOpenSeadragon(url: string): OpenSeadragon.Viewer {
  return OpenSeadragon({
    id: 'openSeadragonViewer',
    showFullPageControl: false,
    showHomeControl: false,
    showNavigationControl: false,
    tileSources: {
      type: 'image',
      url,
    },
  })
}

function findAnnotationRecord(
  annotationRecords: AnnotationRecord<AnnotoriousAnnotation>[],
  annotoriousAnnotation: AnnotoriousAnnotation,
): AnnotationRecord<AnnotoriousAnnotation> {
  return annotationRecords.find((annotationRecord) => annotationRecord.data.id === annotoriousAnnotation.id)
}

function isZoomPreset(zoom: number) {
  return zoomPresets.includes(zoom)
}

function openSeaDragonDisableAction(options: CanvasClickEvent) {
  options.preventDefaultAction = true
}
