import { bindJWT, getApi, patchApi } from 'lib/api/api'
import { Skill, SkillSize } from '../../skills/skills'
import { DesignRequestStatus } from 'lib/api/tickets/tickets'
import { snakeCaseKeys } from 'lib/object/utils'
import { Tag } from 'lib/components/tag-input/tag-input'
import { TicketFeedback } from 'interfaces/ticket_feedback'
import { Project } from 'interfaces/project'

export interface AdminTicketPermissions {
  read: boolean
  start: boolean
  customerClarification: boolean
  pause: boolean
  resume: boolean
  stop: boolean
  split: boolean
  merge: boolean
  transfer: boolean
  selfQualityReview: boolean
  new_version: boolean
  update: {
    covered: boolean
    designerError: boolean
    designerId: boolean
    featuredFileId: boolean
    formats: boolean
    skillId: boolean
    skillSizeIds: boolean
    state: boolean
    subject: boolean
    todaysWeight: boolean
    training: boolean
  }
}

export interface AdminTicket {
  id: number
  availableFormats: string[]
  category: string
  company: AdminTicketCompany
  companyDailyAvailableTime: number
  covered: boolean
  currentVersion: number
  designer: AdminTicketDesigner
  designerError: boolean
  designTime: number
  disableManualChanges: string[]
  duplicatedFrom: number | null
  featuredFileId: number | null
  feedbackReasons: string[]
  inProgressTickets: number[]
  lastDeliveredVersion: number
  meta: {
    permissions: AdminTicketPermissions
  }
  origin: string
  parentId: number | null
  position: number
  project: Project | null
  qaReviews: QAReview[]
  revisions: number
  selectedBrand: AdminTicketBrand | null
  selectedFormats: string[]
  selectedSkillSizes: SkillSize[]
  size: string
  skill: Skill
  source: string
  state: AdminTicketState
  status: DesignRequestStatus
  subject: string
  submittedAt: string | null
  submittedBy: AdminTicketSubmittedBy
  thumbnail: {
    url: string
    placeholder: boolean
  }
  ticketFeedback: TicketFeedback
  ticketTrackings: TicketTracking[]
  training: boolean
  updatedAt: string
  tags: Tag[]
}

interface AdminTicketBrand {
  id: number
  assets: AdminTicketBrandAsset[]
  colors: AdminTicketBrandColor[]
  description: string
  inspirations: AdminTicketBrandInspiration[]
  name: string
  notes: string
  target: string
}

export interface AdminTicketBrandAsset {
  assetFileName: string
  description: string
  expiringUrl: string
  expiringThumbnailUrl?: string
  id: number
  isFont: boolean
  isImage: boolean
  isInspiration: boolean
  name: string
}

export interface AdminTicketImageAsset {
  thumbnailUrl: AdminTicketBrandAsset['expiringThumbnailUrl']
  url: AdminTicketBrandAsset['expiringUrl']
  name: AdminTicketBrandAsset['name']
}

interface AdminTicketBrandColor {
  id: number
  type: string
  value: string
}

interface AdminTicketBrandInspiration {
  id: number
  name: string
  photoId: number
  previewUrl: string
  thumbnailUrl: string
  url: string
}

interface AdminTicketCompany {
  activeTickets: [number, string][]
  apms: AdminCompanySupportUser[]
  qrTeams: AdminCompanySupportUser[]
  companyDailyAvailableTime: {
    total_available_time: number
    total_daily_time: number
  }
  completedTickets: [number, string][]
  designers: AdminCompanyDesigners[]
  backupDesigners: AdminCompanyDesigners[]
  id: number
  createdAt: string
  email: string
  name: string
  notes: string
  status: string
  stripeCustomerId: string
}

interface AdminCompanySupportUser {
  id: number
  name: string
}

interface AdminCompanyDesigners {
  apmId: number
  avatar: string
  id: number
  name: string
}

export interface AdminTicketListItem {
  id: number
  category: string
  company: AdminTicketCompany
  currentVersion: number
  designTime: number
  featuredFileId: number | null
  feedbackReasons: string[]
  lastDeliveredVersion: number
  position?: number
  requestType: string
  state: AdminTicketState
  stateChangedAt: string
  status: string
  subject: string
  submittedAt: string | null
  submittedBy: AdminTicketSubmittedBy
  thumbnail: AdminTicketListItemThumbnail
  updatedAt: string
}

interface AdminTicketListItemThumbnail {
  url: string
  placeholder: boolean
}

interface AdminTicketDesigner {
  id: number
  avatar: string | null
  apmid: string
  name: string
}

interface AdminTicketSubmittedBy {
  id: number
  avatar: string | null
  email: string
  fullName: string
}

export enum AdminQaReviewStates {
  assigned = 'assigned',
  canceled = 'canceled',
  failed = 'failed',
  inProgress = 'in_progress',
  passed = 'passed',
  pending = 'pending',
}

interface QAReviewPermissions {
  start: boolean
  fail: boolean
  pass: boolean
  update: boolean
}

interface QASpecialist {
  id: number
  name: string
}

interface QAReview {
  id: number
  companyId: number
  companyName: string
  createdAt: string
  designerId: number
  designerName: string
  finishedAt: string
  startedAt: string
  state: AdminQaReviewStates
  ticketId: number
  ticketStateChangedAt: string
  ticketSubject: string
  meta: {
    permissions: QAReviewPermissions
  }
  qaSpecialist: QASpecialist
}

export enum TicketTrackingType {
  Design = 'design',
  Pause = 'pause',
}

export interface TicketTracking {
  id: number
  endTime: string | null
  hoursWorked: number
  minutesPaused: number
  startTime: Date
  trackingType: TicketTrackingType
}

export enum AdminTicketState {
  archived = 'Archived',
  assigned = 'Assigned',
  canceled = 'Canceled',
  closed = 'Closed',
  customerClarification = 'Customer Clarification',
  customerReview = 'Customer Review',
  draft = 'Draft',
  inactive = 'Inactive',
  incompleteCustomerClarificationReceived = 'Incomplete (Clarification)',
  incompleteCustomerReviewFailed = 'Incomplete (Revision)',
  incompleteNeedsMoreTime = 'Incomplete (WIP)',
  incompleteReviewFailed = 'Incomplete (QR Fail)',
  inProgress = 'In Progress',
  open = 'Open',
  paused = 'In Progress (paused)',
  qualityReview = 'Quality Review',
}

export enum StopAction {
  DELIVER_FINAL = 'deliver_final',
  WORK_NEXT_DAY = 'work_next_day',
}

export const routes = {
  show: '/admin/tickets',
  assignedToMe: '/admin/tickets/me',
}

const baseUrl = '/api/internal/admin/tickets'

export async function getTicket(id: number, jwt: string = null): Promise<AdminTicket> {
  const getWithJWT = bindJWT(getApi, jwt)
  const response = await getWithJWT(`${baseUrl}/${id}`)

  return response.data.data
}

export async function getTicketsForCurrentUser(jwt: string = null): Promise<AdminTicketListItem[]> {
  const getWithJWT = bindJWT(getApi, jwt)
  const response = await getWithJWT(baseUrl)
  return response.data.data
}

export interface AdminUpdateTicketParams {
  covered?: boolean
  designerError?: boolean
  id: number
  subject?: string
  training?: boolean
  skillId?: number
  skillSizeIds?: number[]
  size?: string
  state?: string
  todaysWeight?: number
  designerId?: number
}

export async function updateTicket(ticketParams: AdminUpdateTicketParams, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)

  const ticketId = ticketParams.id
  delete ticketParams.id

  const ticketParamsSnakeCase = snakeCaseKeys(ticketParams)

  const response = await patchWithJWT(
    `${baseUrl}/${ticketId}`,
    ticketParamsSnakeCase as unknown as Record<string, unknown>,
  )
  return response.data.data
}

export async function startTicket(id: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const response = await patchWithJWT(`${baseUrl}/${id}/start`)
  return response.data.data
}

export async function pauseTicket(id: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const response = await patchWithJWT(`${baseUrl}/${id}/pause`)
  return response.data.data
}

export async function resumeTicket(id: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const response = await patchWithJWT(`${baseUrl}/${id}/resume`)
  return response.data.data
}

export async function stopTicket(id: number, action: string, time: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithWT = bindJWT(patchApi, jwt)
  const payload = {
    next_shift_weight: time,
    stop_action: action,
  }
  const response = await patchWithWT(`${baseUrl}/${id}/stop`, payload)
  return response.data.data
}

export async function updateQS(ticketId: number, qaId: number, qsId: number, jwt: string = null): Promise<QAReview> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const payload = {
    qa_specialist_id: qsId,
  }
  const response = await patchWithJWT(`${baseUrl}/${ticketId}/qa_reviews/${qaId}`, payload)
  return response.data.data
}

export async function startTicketQr(id: number, qaId: number, jwt: string = null): Promise<QAReview> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const response = await patchWithJWT(`${baseUrl}/${id}/qa_reviews/${qaId}/start`)
  return response.data.data
}

export async function failTicketQr({
  ticketId,
  qaId,
  jwt = null,
}: {
  ticketId: number
  qaId?: number
  selfQr?: boolean
  jwt?: string
}): Promise<QAReview> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  return (await patchWithJWT(`${baseUrl}/${ticketId}/qa_reviews/${qaId}/fail`)).data.data
}

export async function failSelfQr(ticketId: number, jwt = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  return (await patchWithJWT(`${baseUrl}/${ticketId}/self_quality_review_fail`)).data.data
}

export async function passTicketQr(
  ticketId: number,
  qa_review_id: number,
  messageBody: string,
  jwt: string = null,
): Promise<QAReview> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const response = await patchWithJWT(`${baseUrl}/${ticketId}/qa_reviews/${qa_review_id}/pass`, {
    message_body: messageBody,
  })
  return response.data.data
}

export async function passSelfQr(ticketId: number, messageBody: string, jwt = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  return (await patchWithJWT(`${baseUrl}/${ticketId}/self_quality_review_pass`, { message_body: messageBody })).data
    .data
}

export async function patchCustomerClarification(ticketId: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const response = await patchWithJWT(`${baseUrl}/${ticketId}/customer_clarification`)
  return response.data.data
}

export async function updateFeaturedFile(ticketId: number, fileId: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const payload = {
    featured_file_id: fileId,
  }
  const response = await patchWithJWT(`${baseUrl}/${ticketId}`, payload)
  return response.data.data
}

export async function createNewVersion(ticketId: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const response = await patchWithJWT(`${baseUrl}/${ticketId}/new_version`)
  return response.data.data
}

export async function splitTicket(ticketId: number, numTickets: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const payload = {
    quantity: numTickets,
  }
  const response = await patchWithJWT(`${baseUrl}/${ticketId}/split`, payload)
  return response.data.data
}

export async function mergeTicket(ticketId: number, mergeTicketId: number, jwt: string = null): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const payload = {
    merge_ticket_id: mergeTicketId,
  }
  const response = await patchWithJWT(`${baseUrl}/${ticketId}/merge`, payload)
  return response.data.data
}

export async function transferTicket(
  ticketId: number,
  companyId: number,
  userId: number,
  jwt: string = null,
): Promise<AdminTicket> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const payload = {
    transfer_company_id: companyId,
    transfer_user_id: userId,
  }
  const response = await patchWithJWT(`${baseUrl}/${ticketId}/transfer`, payload)
  return response.data.data
}
