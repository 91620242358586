import { MultiBrands } from 'components/pages/request/brands/brands'
import BrandsProvider from 'components/pages/request/brands/brands-provider'
import { Brand } from 'interfaces/brand'
import { useMemo } from 'react'
import { FilterDropdown } from './filter-dropdown'

interface BrandsFilterProps {
  companyId: number
  onSelectBrand: (brands: Brand[]) => void
  selectedBrands: Brand[]
}

export default function BrandsFilter({ companyId, onSelectBrand, selectedBrands }: BrandsFilterProps) {
  const selectedBrandsNames = selectedBrands.map((brand) => brand.name)
  const title = useMemo(() => {
    if (!selectedBrandsNames || selectedBrandsNames?.length === 0) return 'Brand Profile(s)'
    if (selectedBrandsNames.length <= 1) return selectedBrandsNames.join(', ')
    return `${selectedBrandsNames.slice(0, 1).join(', ')} + ${selectedBrandsNames.length - 1} more`
  }, [selectedBrandsNames])

  return (
    <BrandsProvider>
      <FilterDropdown title={title}>
        <MultiBrands onSelect={onSelectBrand} companyId={companyId} brands={selectedBrands} />
      </FilterDropdown>
    </BrandsProvider>
  )
}
