import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ProjectTemplates from './project-templates'
import QueryClientProvider from 'providers/query-client-provider'
import MostUsedProjectTemplates from './most-used-project-templates'
import { ProjectTemplate } from 'interfaces/project-template'
import { requestQuery } from 'lib/api/fetch-api'
import CreateProjectDrawer from './create-project-drawer'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'
interface CreateProjectsProps {
  setTab: Dispatch<SetStateAction<string>>
}

export default function CreateProjects({ setTab }: CreateProjectsProps) {
  return (
    <QueryClientProvider>
      <CreateProjectsContent setTab={setTab} />
    </QueryClientProvider>
  )
}

function CreateProjectsContent({ setTab }: CreateProjectsProps) {
  const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<ProjectTemplate>()
  const [isCreateProjectDrawerOpen, setIsCreateProjectDrawerOpen] = useState(false)

  const projectTemplateId = parseInt(new URLSearchParams(window.location.search).get('projectTemplateId'))

  const { data: projectTemplate, error: projectTemplateError } = useQuery({
    queryKey: ['projectTemplate', projectTemplateId],
    queryFn: requestQuery({ endpoint: 'getProjectTemplate', query: { id: projectTemplateId } }),
    enabled: !!projectTemplateId && !selectedProjectTemplate,
  })

  function selectProjectTemplate(projectTemplate: ProjectTemplate) {
    setSelectedProjectTemplate(projectTemplate)
    setIsCreateProjectDrawerOpen(true)
    history.replaceState(null, '', `/create?tab=projects&projectTemplateId=${projectTemplate.id}`)
  }

  function handleCloseCreateProjectDrawer() {
    setSelectedProjectTemplate(undefined)
    setIsCreateProjectDrawerOpen(false)
    history.replaceState(null, '', '/create?tab=projects')
  }

  useEffect(() => {
    if (projectTemplateError) {
      toast.error('Failed to fetch project template')
      console.error('Failed to fetch project template', projectTemplateError)
    } else if (!selectedProjectTemplate && projectTemplate) {
      selectProjectTemplate(projectTemplate)
    }
  }, [projectTemplateError, selectedProjectTemplate, projectTemplate])

  useEffect(() => {
    document.title = 'Create Projects'
    if (!window.location.search.includes('tab=projects')) {
      history.replaceState(null, '', '/create?tab=projects')
    }
    setTab('projects')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* TODO: Search has been moved to V2*/}
      {/* <CreateSearch searchValue={searchValue} handleSearchChange={handleSearchChange} /> */}
      <h2 className="tw-m-0 tw-mt-2 tw-text-2xl">Top projects for you</h2>
      <h4 className="tw-text-md tw-m-0 tw-pb-3 tw-text-base tw-font-normal tw-text-neutral-800">Made for you</h4>
      <MostUsedProjectTemplates selectProjectTemplate={selectProjectTemplate} />

      <>
        <h2 className="tw-m-0 tw-mt-8 tw-text-2xl">Trending Partners</h2>
        <h4 className="tw-text-md tw-m-0 tw-pb-3 tw-text-base tw-font-normal tw-text-neutral-800">
          Curated recipes with Design Pickle
        </h4>
        <ProjectTemplates partner selectProjectTemplate={selectProjectTemplate} />
      </>

      <h2 className="tw-m-0 tw-mt-8 tw-text-2xl">All projects</h2>
      <h4 className="tw-text-md tw-m-0 tw-pb-3 tw-text-base tw-font-normal tw-text-neutral-800">
        Endless ways to create
      </h4>
      <ProjectTemplates selectProjectTemplate={selectProjectTemplate} />

      <CreateProjectDrawer
        isOpen={isCreateProjectDrawerOpen}
        setOpen={handleCloseCreateProjectDrawer}
        projectTemplate={selectedProjectTemplate}
      />
    </>
  )
}
