import CreateDeliverables from 'components/pages/create/create-deliverables'
import CreateProjects from 'components/pages/create/create-projects'
import { AppSettings, CurrentUser } from 'interfaces/app'
import Badge from 'lib/components/badge/badge'
import ReferredBreadcrumb from 'lib/components/breadcrumb/referred-breadcrumb'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'lib/components/tabs'
import { Toaster } from 'lib/components/toast/toast'
import UserProvider from 'providers/user-provider'
import { useEffect, useState } from 'react'
import SkillsProvider, { useSkillsContext } from '../request/skills/skills-provider'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import CreateSearch from './create-search'

interface CreateProps {
  settings: AppSettings
  user: CurrentUser
}

interface TabContentsProps {
  setTab: (tab: string) => void
  tab: string
}

function TabContents({ setTab, tab }: TabContentsProps) {
  const [searchValue, setSearchValue] = useState('')

  const { filterCategoriesBySkillName, getSkillsByCategory } = useSkillsContext()

  function handleSearchChange(value: string) {
    setSearchValue(value)
    filterCategoriesBySkillName(value)
  }

  useEffect(() => {
    getSkillsByCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="tw-mx-auto tw-w-full tw-max-w-[1440px]">
      <Toaster />
      <header className="tw-sticky tw-top-0 tw-z-10 tw-bg-neutral-100 tw-px-5 tw-py-5">
        <div className="tw-flex tw-justify-between">
          <ReferredBreadcrumb currentPageText="Create" />
          <TabsList className="tw-justify-self-end" intent="background">
            <TabsTrigger value="requests" intent="background">
              Requests
            </TabsTrigger>
            <TabsTrigger value="projects" intent="background">
              <div className="tw-mr-2">Projects</div>
              <Badge color={tab === 'projects' ? 'light' : 'primary'}>New</Badge>
            </TabsTrigger>
          </TabsList>
        </div>
        <div className="tw-relative tw-z-10 tw-flex tw-items-center tw-justify-between">
          {tab === 'requests' && (
            <h2 className="tw-m-0 tw-mt-2 tw-justify-self-center tw-text-2xl">What are you looking to create?</h2>
          )}
        </div>
        {tab === 'requests' && <CreateSearch searchValue={searchValue} handleSearchChange={handleSearchChange} />}
      </header>
      {/* This is for Pendo to tap into the Create banner */}
      <div id={`pendo-create-banner-${tab}`} />
      <div className="tw-px-5 tw-pb-5">
        <TabsContent value="requests">
          <CreateDeliverables setTab={setTab} />
        </TabsContent>
        <TabsContent value="projects">
          <CreateProjects setTab={setTab} />
        </TabsContent>
      </div>
    </div>
  )
}

export default function Create({ user, settings }: CreateProps) {
  const [tab, setTab] = useState<string>(new URLSearchParams(window.location.search).get('tab') || 'requests')

  return (
    <FeatureFlagsProvider>
      <SkillsProvider>
        <UserProvider settings={settings} user={user}>
          <Tabs
            className="tw-mt-[var(--header-height,0px)] tw-h-[calc(100vh-var(--header-height,0px))] tw-w-screen tw-overflow-auto tw-scrollbar-gutter-stable"
            value={tab}
            onValueChange={setTab}
          >
            <TabContents setTab={setTab} tab={tab} />
          </Tabs>
        </UserProvider>
      </SkillsProvider>
    </FeatureFlagsProvider>
  )
}
