import { ReactElement, useCallback, useEffect, useState } from 'react'
import Pagination from 'components/elements/pagination'
import { EmptyTableScreen, LoadingScreen } from 'components/pages/requests/empty-screens'
import { QaReview, getQaReviews } from 'lib/api/qa_reviews/qa_reviews'
import { hasOnlyEmptyValues, snakeCaseKeys } from 'lib/object/utils'
import QaReviewsTable from './qa-reviews-table'
import QaReviewsFilters from './qa-reviews-filters'
import { Toaster, toast } from 'lib/components/toast/toast'

export interface QaReviewFiltersParams {
  qaSpecialistIdEq?: string | number
  s?: string
  stateIn?: Array<string | number>
  ticketIdEq?: string
}

export const emptyQaReviewFiltersParams: QaReviewFiltersParams = {
  qaSpecialistIdEq: '',
  s: '',
  stateIn: [],
  ticketIdEq: '',
}

const classNames = {
  container: 'tw-container tw-px-3',
  emptyScreen: 'tw-flex tw-items-center tw-justify-center',
  filtersWrapper: 'tw-flex tw-justify-between tw-py-2',
  header: 'tw-flex tw-justify-between',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white',
  wrapper: 'tw-flex tw-w-full tw-flex-col',
}

function QaReviews() {
  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(15)
  const [count, setCount] = useState<number>(0)
  const [qaReviews, setQaReviews] = useState<QaReview[]>([])
  const [filters, setFilters] = useState<QaReviewFiltersParams>(emptyQaReviewFiltersParams)
  const { s, ...queryFilters } = filters
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const tableHeight = (perPage + 1) * 54

  const oops = useCallback(() => toast.error('Oops, something went wrong.'), [])
  const fetchQaReviews = useCallback(
    async (nextPage = 0) => {
      setIsLoading(true)
      setCurrentPage(nextPage)

      try {
        const response = await getQaReviews({ page: nextPage + 1, q: snakeCaseKeys(filters) })
        const { count, perPage, reviews } = response
        setQaReviews(reviews)
        setCount(count)
        setPerPage(perPage)
      } catch (error) {
        console.error('Error fetching QA reviews', error)
        oops()
      } finally {
        setIsLoading(false)
      }
    },
    [filters, oops],
  )

  useEffect(() => {
    if (hasOnlyEmptyValues(queryFilters, false)) fetchQaReviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (s) fetchQaReviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s])

  const handleSubmit = (e) => {
    e.preventDefault()
    fetchQaReviews()
  }

  const handleClear = (e) => {
    e.preventDefault()
    setFilters((prev) => ({ ...prev, ...emptyQaReviewFiltersParams }))
  }

  const onPageClick = ({ selected }) => {
    fetchQaReviews(selected)
    window.scrollTo(0, 0)
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <h4>Quality Reviews</h4>
        {!isLoading && `Showing ${qaReviews.length} out of ${count} reviews`}
      </div>
      <div className={classNames.container}>
        <QaReviewsFilters
          filters={filters}
          handleClear={handleClear}
          handleSubmit={handleSubmit}
          setFilters={setFilters}
        />
        <div className={classNames.tableWrapper}>
          {isLoading ? (
            <div className={classNames.emptyScreen} style={{ height: tableHeight }}>
              <LoadingScreen />
            </div>
          ) : qaReviews.length > 0 ? (
            <QaReviewsTable filters={filters} qaReviews={qaReviews} setFilters={setFilters} />
          ) : (
            <div className={classNames.emptyScreen} style={{ height: tableHeight }}>
              <EmptyTableScreen />
            </div>
          )}
        </div>
        <Pagination pageCount={Math.ceil(count / perPage)} onPageChange={onPageClick} forcePage={currentPage} />
      </div>
    </div>
  )
}

export default function QaReviewsPage(): ReactElement {
  return (
    <>
      <Toaster />
      <QaReviews />
    </>
  )
}
