import { HTMLAttributes, ReactElement, ReactNode } from 'react'
import { AdminQaReviewStates, AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'
import { cn } from 'lib/util/cn'

export type BadgeColor = 'primary' | 'light' | 'dark' | 'white' | 'info' | 'success' | 'warning' | 'danger'

// Temporary fix for the DesignRequestStatus enum import not working
// Will clean up the 'lib/api/tickets/tickets' file and remove this once the import is working
export enum DesignRequestStatus {
  archived = 'Archived',
  attention = 'Attention',
  canceled = 'Canceled',
  completed = 'Completed',
  draft = 'Draft',
  inProgress = 'In Progress',
  onDeck = 'On Deck',
  qualityReview = 'Quality Review',
  review = 'For Review',
  wipOnDeck = 'WIP - On Deck',
  preview = 'Preview',
}

interface BadgeProps {
  children: ReactNode | string
  color: BadgeColor
  style?: 'pill' | 'rounded'
  fontWeight?: 'normal' | 'bold'
  className?: string
}

const classNames = {
  base: `tw-w-max
    tw-px-2
    tw-inline-flex
    tw-items-center
    tw-justify-center
    tw-text-xs
    tw-leading-5`,
  pill: 'tw-rounded-full',
  rounded: 'tw-rounded',
  primary: 'tw-bg-cornflower-100 tw-text-cornflower-800',
  light: 'tw-bg-neutral-100 tw-text-neutral-800',
  dark: 'tw-bg-neutral-800 tw-text-white',
  white: 'tw-bg-white tw-text-neutral-800 tw-border tw-border-neutral-200',
  info: 'tw-bg-skyblue-100 tw-text-skyblue-800',
  success: 'tw-bg-picklegreen-100 tw-text-picklegreen-800',
  warning: 'tw-bg-sunnyyellow-100 tw-text-sunnyyellow-800',
  danger: 'tw-bg-flushpink-100 tw-text-flushpink-800',
  bold: 'tw-font-semibold',
  normal: 'tw-font-normal',
  states: {
    default: 'tw-bg-gray-300 tw-text-white',
    [AdminTicketState.archived]: 'tw-bg-flushpink-100 tw-text-flushpink-800',
    [AdminTicketState.assigned]: 'tw-bg-skyblue-100 tw-text-skyblue-800',
    [AdminTicketState.canceled]: 'tw-bg-flushpink-100 tw-text-flushpink-800',
    [AdminTicketState.closed]: 'tw-bg-picklegreen-100 tw-text-picklegreen-800',
    [AdminTicketState.customerClarification]: 'tw-bg-skyblue-100 tw-text-skyblue-800',
    [AdminTicketState.customerReview]: 'tw-bg-skyblue-100 tw-text-skyblue-800',
    [AdminTicketState.draft]:
      'tw-bg-white tw-border-solid tw-border tw-border-neutral-200 tw-text-peppercorn-600 tw-bg-white',
    [AdminTicketState.inactive]: 'tw-bg-neutral-100 tw-text-neutral-800',
    [AdminTicketState.incompleteCustomerClarificationReceived]: 'tw-bg-sunnyyellow-100 tw-text-sunnyyellow-800',
    [AdminTicketState.incompleteCustomerReviewFailed]: 'tw-bg-sunnyyellow-100 tw-text-sunnyyellow-800',
    [AdminTicketState.incompleteNeedsMoreTime]: 'tw-bg-sunnyyellow-100 tw-text-sunnyyellow-800',
    [AdminTicketState.incompleteReviewFailed]: 'tw-bg-sunnyyellow-100 tw-text-sunnyyellow-800',
    [AdminTicketState.inProgress]: 'tw-bg-cornflower-100 tw-text-cornflower-800',
    [AdminTicketState.open]: 'tw-bg-neutral-100 tw-text-neutral-800',
    [AdminTicketState.paused]: 'tw-bg-sunnyyellow-100 tw-text-sunnyyellow-800',
    [AdminTicketState.qualityReview]: 'tw-bg-skyblue-100 tw-text-skyblue-800',
  },
  qaReviewStates: {
    default: 'tw-bg-neutral-100 tw-text-neutral-800',
    [AdminQaReviewStates.inProgress]: 'tw-bg-cornflower-100 tw-text-cornflower-800',
    [AdminQaReviewStates.assigned]: 'tw-bg-skyblue-100 tw-text-skyblue-800',
    [AdminQaReviewStates.pending]: 'tw-bg-neutral-100 tw-text-neutral-800',
  },
  statuses: {
    default: 'tw-bg-gray-300 tw-text-white',
    [DesignRequestStatus.archived]: 'tw-bg-neutral-100 tw-text-neutral-800',
    [DesignRequestStatus.attention]: 'tw-bg-sunnyyellow-100 tw-text-sunnyyellow-800',
    [DesignRequestStatus.canceled]: 'tw-bg-flushpink-100 tw-text-flushpink-800',
    [DesignRequestStatus.completed]: 'tw-bg-picklegreen-100 tw-text-picklegreen-800',
    [DesignRequestStatus.draft]:
      'tw-bg-white tw-border-solid tw-border tw-border-neutral-200 tw-text-peppercorn-600 tw-bg-white',
    [DesignRequestStatus.inProgress]: 'tw-bg-cornflower-100 tw-text-cornflower-800',
    [DesignRequestStatus.onDeck]: 'tw-bg-neutral-100 tw-text-neutral-800',
    [DesignRequestStatus.review]: 'tw-bg-skyblue-100 tw-text-skyblue-800',
    [DesignRequestStatus.qualityReview]: 'tw-bg-cornflower-100 tw-text-cornflower-800',
    [DesignRequestStatus.preview]: 'tw-bg-skyblue-100 tw-text-skyblue-800',
    [DesignRequestStatus.wipOnDeck]: 'tw-bg-neutral-100 tw-text-neutral-800',
  },
}

export default function Badge({
  children,
  color,
  style = 'rounded',
  fontWeight = 'bold',
  className = '',
}: BadgeProps): ReactElement {
  return (
    <div className={cn(classNames.base, classNames[color], classNames[style], classNames[fontWeight], className)}>
      {children}
    </div>
  )
}

export function BetaBadge(): ReactElement {
  return (
    <Badge color="info" className="tw-ml-3">
      BETA
    </Badge>
  )
}

export function TicketStatusBadge({
  status,
  className = 'tw-ml-3',
}: {
  status: string
  className?: HTMLAttributes<HTMLDivElement>['className']
}): ReactElement {
  const badgeClassName = classNames.statuses[status] || classNames.statuses.default
  return (
    <Badge style="pill" color="light" className={cn(badgeClassName, className)}>
      {status}
    </Badge>
  )
}

export function TicketProductionStateBadge({ state }: { state: string }): ReactElement {
  const className = classNames.states[state] || classNames.states.default
  return (
    <Badge style="pill" color="light" className={className}>
      {state}
    </Badge>
  )
}

export function TicketReviewStateBadge({ state }: { state: AdminQaReviewStates }): ReactElement {
  const className = classNames.qaReviewStates[state]
  return (
    <Badge style="pill" color="light" className={className}>
      {state === AdminQaReviewStates.inProgress
        ? 'In Progress'
        : state === AdminQaReviewStates.assigned
          ? 'Assigned'
          : 'Pending'}
    </Badge>
  )
}

export function TicketTrainingBadge() {
  return (
    <Badge style="pill" color="light">
      Training Ticket
    </Badge>
  )
}

export function TicketCoveredBadge() {
  return (
    <Badge style="pill" color="light">
      Covered Ticket
    </Badge>
  )
}

export function TicketDesignerErrorBadge() {
  return (
    <Badge style="pill" color="light">
      Designer Error
    </Badge>
  )
}
