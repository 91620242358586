import Dropdown from 'lib/components/dropdown/dropdown'
import Brands from '../pages/request/brands/brands'
import { Brand } from 'interfaces/brand'
import BrandsProvider from '../pages/request/brands/brands-provider'

interface BrandsPopoverProps {
  onChange: (brand: Brand) => void
  brand: Brand
  companyId: number
}

export default function BrandsPopover({ onChange, brand, companyId }: BrandsPopoverProps): JSX.Element {
  const title = brand ? brand.name : 'Select Brand'

  return (
    <BrandsProvider>
      <div className="tw-px-2">
        <Dropdown icon="chevron" label="Brand Profile" title={title} size="sm">
          <Brands onSelect={onChange} companyId={companyId} brand={brand} />
        </Dropdown>
      </div>
    </BrandsProvider>
  )
}
