import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import GrowingTextInput from 'components/core/text-input/growing-text-input'
import { Brand } from 'interfaces/brand'
import { request, requestQuery } from 'lib/api/fetch-api'
import CopyTextButton from 'lib/components/buttons/copy-text-button'
import IconButton from 'lib/components/buttons/icon-button'
import { Drawer } from 'lib/components/drawer/drawer'
import { displayDate } from 'lib/util/date'
import {
  ArrowLeftIcon,
  ArrowRightToLineIcon,
  CheckIcon,
  CopyIcon,
  FolderOpenIcon,
  FoldersIcon,
  LinkIcon,
  LoaderCircleIcon,
  NotepadTextIcon,
  PencilIcon,
  Trash2Icon,
  TrashIcon,
  XIcon,
} from 'lucide-react'
import { useUserContext } from 'providers/user-provider'
import { ChangeEvent, useEffect, useState } from 'react'
import BrandsPopover from '../brands-popover'
import { toast } from 'lib/components/toast/toast'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import CompanyTaggingProvider, { ChangeTagAction } from 'providers/company-tagging-provider'
import CompanyTaggingPopover from '../company-tagging/company-tagging-popover'
import { CompanyTag } from 'lib/api/company-tags/company-tags'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'
import ProjectDeliverablesTable from './project-deliverables-table'
import { Project } from 'interfaces/project'
import AddDeliverablesTable from './add-deliverables-table'
import { Ticket } from 'interfaces/ticket'
import Button from 'components/core/button'
import Textarea from 'lib/components/textarea/textarea'
import CreateDeliverablesTable from './create-deliverables-table'
import Tooltip from 'lib/components/tooltip/tooltip'
import ExpandableText from 'lib/components/expandable-text/expandable-text'
import Link from 'lib/components/link/link'
import { Partner } from 'interfaces/partner'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { PATHS } from 'lib/constants/paths'
import Banner from 'lib/components/banner/banner'
type DrawerState = 'details' | 'add-deliverables' | 'remove-deliverables' | 'read-only' | 'create-deliverables'
export interface ProjectDrawerProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  projectId?: number
  onUpdate?: () => void
  project?: Project
  readOnly?: boolean
}

export default function ProjectDrawer({
  isOpen,
  setOpen,
  projectId,
  onUpdate,
  project: passedProject,
  readOnly,
}: ProjectDrawerProps) {
  const [drawerState, setDrawerState] = useState<DrawerState>(readOnly ? 'read-only' : 'details')
  const [deliverablesToRemove, setDeliverablesToRemove] = useState<Ticket[]>([])

  const { data, refetch, isFetching } = useQuery({
    queryKey: ['project', projectId],
    queryFn: requestQuery({ endpoint: 'getProject', query: { id: projectId } }),
    enabled: isOpen && !readOnly,
  })

  const fetchedProject = data?.data
  const project = readOnly ? passedProject || fetchedProject : fetchedProject

  const handleRemoveDeliverablesClick = (deliverablesToRemove: Ticket[]) => {
    setDeliverablesToRemove(deliverablesToRemove)
    setDrawerState('remove-deliverables')
  }

  const onUpdateProject = () => {
    refetch()
    onUpdate?.()
  }

  return (
    <Drawer isOpen={isOpen} setOpen={setOpen} size="md">
      {drawerState === 'read-only' && (
        <ProjectReadOnlyContent project={project} setOpen={setOpen} isLoading={isFetching} />
      )}
      {drawerState === 'details' && (
        <ProjectDetailsContent
          isOpen={isOpen}
          setOpen={setOpen}
          project={project}
          isLoading={isFetching}
          setDrawerState={setDrawerState}
          onRemoveDeliverablesClick={handleRemoveDeliverablesClick}
          onUpdate={onUpdateProject}
        />
      )}
      {drawerState === 'add-deliverables' && (
        <AddDeliverablesContent
          project={project}
          setDrawerState={setDrawerState}
          setOpen={setOpen}
          onUpdate={onUpdateProject}
        />
      )}
      {drawerState === 'remove-deliverables' && (
        <RemoveDeliverablesContent
          project={project}
          setDrawerState={setDrawerState}
          setOpen={setOpen}
          deliverablesToRemove={deliverablesToRemove}
          setDeliverablesToRemove={setDeliverablesToRemove}
        />
      )}
      {drawerState === 'create-deliverables' && (
        <CreateDeliverablesContent
          project={project}
          setDrawerState={setDrawerState}
          setOpen={setOpen}
          onUpdate={onUpdateProject}
        />
      )}
    </Drawer>
  )
}

interface ProjectReadOnlyContentProps {
  project: Project
  setOpen: (open: boolean) => void
  isLoading: boolean
}

function ProjectReadOnlyContent({ project, setOpen, isLoading }: ProjectReadOnlyContentProps) {
  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-w-full tw-items-center tw-gap-4">
          <IconButton size="xs" color="transparent" onClick={() => setOpen(false)} className="tw-mr-2">
            <ArrowRightToLineIcon className="tw-text-neutral-700" />
          </IconButton>
          <FoldersIcon className="lu-xl tw-text-neutral-700" />
          {!isLoading ? (
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2 tw-overflow-hidden">
              <Tooltip content={project?.name}>
                <h3 className="tw-m-0 tw-truncate tw-p-0">{project?.name}</h3>
              </Tooltip>
              <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-text-neutral-500">
                <div>Last updated on {displayDate(project?.updatedAt)}</div>
                {project?.projectTemplate?.partner && (
                  <>
                    <Divider />
                    <ProjectPartnerContent partner={project?.projectTemplate?.partner} />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
              <div>Loading...</div>
            </div>
          )}
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-overflow-y-auto">
        {!isLoading ? (
          <>
            <div className="tw-mb-4">
              <h5 className="tw-flex tw-items-center tw-gap-1">
                <NotepadTextIcon className="lu-md" />
                <span>Description</span>
              </h5>
              <ExpandableText text={project?.description ?? 'No description'} />
            </div>
            <ProjectDeliverablesTable projectId={project?.id} readOnly />
          </>
        ) : (
          <LoadingScreen />
        )}
      </Drawer.Body>
    </>
  )
}

interface ProjectDetailsContentProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  project: Project
  isLoading: boolean
  setDrawerState: (state: DrawerState) => void
  onRemoveDeliverablesClick: (deliverablesToRemove: Ticket[]) => void
  onUpdate?: () => void
}

function ProjectDetailsContent({
  isOpen,
  setOpen,
  project,
  isLoading,
  setDrawerState,
  onRemoveDeliverablesClick,
  onUpdate,
}: ProjectDetailsContentProps) {
  const [projectName, setProjectName] = useState<string>()
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedBrand, setSelectedBrand] = useState<Brand>()
  const { companyTags } = useAllCompanyTagsContext()
  const { user } = useUserContext()
  const [editDescription, setEditDescription] = useState(false)
  const [description, setDescription] = useState(project?.description)
  const [showProjectNameError, setShowProjectNameError] = useState(false)
  const queryClient = useQueryClient()
  const [showDeleteProject, setShowDeleteProject] = useState(false)

  const updateProjectMutation = useMutation({
    mutationFn: ({ brand, description, name }: { brand?: Brand; description?: string; name?: string }) => {
      return request({
        endpoint: 'updateProject',
        query: { id: project.id },
        body: {
          brand_id: brand?.id || null,
          description,
          name,
        },
      })
    },
    onSuccess: async (data: { data: Project }) => {
      toast.success('Project updated')

      queryClient.setQueryData(['project', project.id], (old: Project) => ({ ...old, ...data.data }))
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] })
      queryClient.invalidateQueries({ queryKey: ['project', project.id] })
    },
    onError: (error) => {
      toast.error('Failed to update project')
      console.error('Failed to update project', error)
    },
  })

  const duplicateProjectMutation = useMutation({
    mutationFn: () => {
      return request({
        endpoint: 'duplicateProject',
        query: { id: project.id },
      })
    },
    onSuccess: (response: { data: { id: number } }) => {
      toast.success('Project has been duplicated')
      window.location.href = `${PATHS.REQUESTS}?tab=draft&projectId=${response.data.id}&duplicate=true`
    },
    onError: () => {
      toast.error('Failed to duplicate project')
    },
  })

  const deleteProjectMutation = useMutation({
    mutationFn: () => {
      return request({
        endpoint: 'deleteProject',
        query: { id: project.id },
      })
    },
    onSuccess: () => {
      toast.success('Project deleted successfully')
      queryClient.invalidateQueries({ queryKey: ['projects'] })
      setOpen(false)
    },
    onError: (error) => {
      toast.error('Error deleting project')
      console.error(error)
    },
  })

  function handleUpdateTaggableState(tag: CompanyTag, action: ChangeTagAction) {
    switch (action) {
      case ChangeTagAction.Select:
        setSelectedTags([...selectedTags, tag])
        break
      case ChangeTagAction.Unselect:
      case ChangeTagAction.Delete:
        setSelectedTags(selectedTags.filter((selectedTag) => selectedTag.id !== tag.id))
        break
      case ChangeTagAction.Update:
        setSelectedTags(selectedTags.map((selectedTag) => (selectedTag.id === tag.id ? tag : selectedTag)))
        break
      default:
        break
    }
  }

  function handleDescriptionChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setDescription(event.target.value)
  }

  function resetDescription() {
    setDescription(project?.description)
    setEditDescription(false)
  }

  function saveDescription() {
    updateProjectMutation.mutate({ description: description.trim() })
    setEditDescription(false)
  }

  function saveProjectName() {
    if (projectName.trim() === project?.name) {
      return
    }

    if (projectName.trim() === '') {
      toast.error('Project name required')
      setShowProjectNameError(true)
      return
    }

    updateProjectMutation.mutate({ name: projectName.trim() })
  }

  const projectDrawerHeader = () => {
    if (isLoading) {
      return (
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <div>Loading...</div>
        </div>
      )
    }

    if (showDeleteProject) {
      return <h3 className="tw-truncate">Delete &quot;{project.name}&quot; project</h3>
    }

    return (
      <>
        <FolderOpenIcon className="lu-xl tw-text-neutral-700" />
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2" data-testid="project-name-input">
          <div className="tw-flex tw-items-center tw-gap-2">
            <GrowingTextInput
              value={projectName || ''}
              onChange={(e) => setProjectName(e.target.value)}
              onBlur={saveProjectName}
              name="project-name"
              className="tw-w-full"
              extraWidth={50}
              hasError={showProjectNameError}
            />
            {showProjectNameError && <div className="tw-text-red-500">Project name required</div>}
          </div>
          <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-text-neutral-500">
            <div>Last updated on {displayDate(project?.updatedAt)}</div>
            {project?.projectTemplate?.partner && (
              <>
                <Divider />
                <ProjectPartnerContent partner={project?.projectTemplate?.partner} />
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  const ProjectDrawerBody = () => {
    if (isLoading) {
      return <LoadingScreen />
    }

    if (showDeleteProject) {
      return (
        <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-2 tw-text-center">
            <div className="tw-rounded-md tw-bg-flushpink-50 tw-p-2">
              <TrashIcon className="lu-xl tw-text-flushpink-500" />
            </div>
            <h4 className="tw-m-0 tw-text-neutral-500">Delete project?</h4>
            <div className="tw-m-0 tw-max-w-prose tw-text-neutral-500">
              <p className="tw-m-0">Are you sure you want to delete the project &quot;{project.name}&quot;?</p>
              <p className="tw-m-0">
                The project will be deleted, but it&apos;s requests will remain available in the request queue.
              </p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <>
        {window.location.search.includes('duplicate=true') && (
          <Banner
            type="info"
            heading="Duplicate project"
            subheading="It may take a few minutes for all the project information to be duplicated."
          />
        )}
        <div className="tw-flex tw-items-center">
          <BrandsPopover
            onChange={(brand) => {
              setSelectedBrand(brand)
              updateProjectMutation.mutate({ brand })
            }}
            brand={selectedBrand}
            companyId={user?.companyId}
          />
          <div className="tw-ml-4 tw-mr-10 tw-h-5 tw-w-[1px] tw-bg-neutral-600" />
          <CompanyTaggingProvider
            selectedTags={selectedTags}
            updateTaggableState={handleUpdateTaggableState}
            taggableId={project?.id}
            taggableType="Project"
            warningMessage="Adding and removing project tags does not add nor remove tags from the associated requests"
          >
            <CompanyTaggingPopover />
          </CompanyTaggingProvider>
        </div>
        <div>
          <h5>
            <NotepadTextIcon className="lu-md" /> Description
          </h5>
          {editDescription ? (
            <div className="tw-flex tw-items-center tw-gap-2">
              <Textarea
                className="tw-flex-1"
                placeholder="Enter a description for the project"
                value={description}
                onChange={handleDescriptionChange}
              />
              <div className="tw-flex tw-gap-2">
                <IconButton color="secondary" onClick={saveDescription}>
                  <CheckIcon className="lu-sm" />
                </IconButton>
                <IconButton color="secondary" onClick={resetDescription}>
                  <XIcon className="lu-sm" />
                </IconButton>
              </div>
            </div>
          ) : (
            <div className="tw-flex tw-items-center tw-gap-2">
              <p className="tw-flex-1 tw-overflow-hidden tw-text-ellipsis tw-break-words">{description}</p>
              <IconButton
                className="tw-mb-4"
                color="secondary"
                onClick={() => setEditDescription(true)}
                dataTestid="edit-description"
              >
                <PencilIcon className="lu-sm" />
              </IconButton>
            </div>
          )}
        </div>
        <ProjectDeliverablesTable
          projectId={project?.id}
          handleAddDeliverablesClick={() => setDrawerState('add-deliverables')}
          handleRemoveDeliverablesClick={onRemoveDeliverablesClick}
          refetchProject={onUpdate}
        />
      </>
    )
  }

  useEffect(() => {
    function detailsHaveChanged() {
      return projectName !== project?.name || description !== project?.description || selectedTags !== project?.tags
    }

    if (!isOpen && detailsHaveChanged()) {
      onUpdate?.()
    }
  }, [description, isOpen, onUpdate, project?.description, project?.name, project?.tags, projectName, selectedTags])

  useEffect(() => {
    if (project) {
      setProjectName(project?.name)
      setSelectedBrand(project?.brand)
      setDescription(project?.description)
      setSelectedTags(project?.tags || [])
    }
  }, [project])

  useEffect(() => {
    setSelectedTags((prevSelectedTags) => {
      return prevSelectedTags
        .filter((selectedTag) => companyTags.some((companyTag) => companyTag.id === selectedTag.id))
        .map((selectedTag) => companyTags.find((tag) => tag.id === selectedTag.id))
    })
  }, [companyTags])

  const handleDuplicateProject = () => {
    duplicateProjectMutation.mutate()
  }

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-pb-2 tw-pt-4">
          <div className="tw-flex tw-w-full tw-items-center tw-gap-4">
            <IconButton size="xs" color="transparent" onClick={() => setOpen(false)} className="tw-mr-2">
              <ArrowRightToLineIcon className="tw-text-neutral-700" />
            </IconButton>
            {projectDrawerHeader()}
          </div>
          {!isLoading && !showDeleteProject && (
            <div className="tw-flex tw-gap-2">
              <CopyTextButton textToCopy={window.location.href} defaultIcon={<LinkIcon />} />
              <IconFlyoutMenu color="secondary" icon={['far', 'ellipsis-v']}>
                <IconFlyoutMenu.Button onClick={handleDuplicateProject}>
                  <CopyIcon className="tw-h-4 tw-w-4 tw-text-neutral-500" />
                  <span className="tw-ml-2 tw-text-neutral-500">Duplicate</span>
                </IconFlyoutMenu.Button>
                <IconFlyoutMenu.Button onClick={() => setShowDeleteProject(true)}>
                  <Trash2Icon className="tw-h-4 tw-w-4 tw-text-flushpink-500" />
                  <span className="tw-ml-2 tw-text-flushpink-500">Delete</span>
                </IconFlyoutMenu.Button>
              </IconFlyoutMenu>
            </div>
          )}
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-overflow-y-auto">
        <ProjectDrawerBody />
      </Drawer.Body>
      {showDeleteProject && (
        <Drawer.Footer
          onCancel={() => setShowDeleteProject(false)}
          onSubmit={deleteProjectMutation.mutate}
          submitText={
            deleteProjectMutation.isPending ? <LoaderCircleIcon className="tw-animate-spin" /> : 'Delete project'
          }
          submitColor="red"
        />
      )}
    </>
  )
}

interface DeliverablesContentProps {
  project: Project
  setDrawerState: (state: DrawerState) => void
  setOpen: (open: boolean) => void
  onUpdate: () => void
}

function AddDeliverablesContent({ project, setDrawerState, setOpen, onUpdate }: DeliverablesContentProps) {
  const trimmedProjectName = project?.name?.length > 40 ? `${project?.name?.substring(0, 40)}...` : project?.name
  const onFinished = (update = true) => {
    if (update) {
      onUpdate()
    }
    setDrawerState('details')
  }

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-items-center tw-gap-4">
          <IconButton
            size="xs"
            color="transparent"
            onClick={() => setDrawerState('details')}
            className="tw-mr-2"
            data-testid="BackButton"
          >
            <ArrowLeftIcon className="tw-text-neutral-700" />
          </IconButton>
          <h3 className="tw-m-0 tw-p-0">Add Requests to &quot;{trimmedProjectName}&quot;</h3>
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-pb-0 tw-pt-4">
        <div className="tw-mt-4">
          <AddDeliverablesTable
            projectId={project.id}
            onFinished={onFinished}
            handleCreateDeliverablesClick={() => setDrawerState('create-deliverables')}
          />
        </div>
      </Drawer.Body>
    </>
  )
}

interface RemoveDeliverablesContentProps {
  project: Project
  setDrawerState: (state: DrawerState) => void
  setOpen: (open: boolean) => void
  deliverablesToRemove: Ticket[]
  setDeliverablesToRemove: (deliverables: Ticket[]) => void
}

function RemoveDeliverablesContent({
  project,
  setDrawerState,
  setOpen,
  deliverablesToRemove,
  setDeliverablesToRemove,
}: RemoveDeliverablesContentProps) {
  const queryClient = useQueryClient()

  const handleCancel = () => {
    setDeliverablesToRemove([])
    setDrawerState('details')
  }

  const handleRemoveMutation = useMutation({
    mutationFn: () => {
      return request({
        endpoint: 'removeTicketsFromProject',
        query: { id: project.id },
        body: { ticket_ids: deliverablesToRemove.map((d) => Number(d.id)) },
      })
    },
    onSuccess: () => {
      toast.success('Requests removed')
      queryClient.invalidateQueries({ queryKey: ['projectDeliverables', project.id] })
      setDeliverablesToRemove([])
      setDrawerState('details')
    },
    onError: (error) => {
      toast.error('Failed to remove requests')
      console.error('Failed to remove requests', error)
    },
  })

  const handleRemove = () => {
    handleRemoveMutation.mutate()
  }

  const firstDeliverableName = `"${deliverablesToRemove[0]?.subject || `Untitled ${deliverablesToRemove[0]?.skillName}`}"`

  const deliverableText = () => {
    if (deliverablesToRemove.length > 1) {
      return (
        <span className="tw-font-semibold">
          {firstDeliverableName}
          <span className="tw-font-normal"> + {deliverablesToRemove.length - 1} more requests</span>
        </span>
      )
    }
    return <span className="tw-font-semibold">{firstDeliverableName}</span>
  }

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-items-center tw-gap-4">
          <IconButton
            size="xs"
            color="transparent"
            onClick={() => setDrawerState('details')}
            className="tw-mr-2"
            data-testid="BackButton"
          >
            <ArrowLeftIcon className="tw-text-neutral-700" />
          </IconButton>
          <h3 className="tw-m-0 tw-p-0">Remove Request(s) from Project?</h3>
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-overflow-y-auto">
        <h5 className="tw-text-normal tw-m-0 tw-break-all tw-p-0 tw-py-4 tw-font-normal">
          Are you sure you want to remove {deliverableText()} from the{' '}
          <span className="tw-font-semibold">&quot;{project.name}&quot;</span>?
        </h5>
        <p className="tw-text-md tw-m-0 tw-p-0">
          This will leave the requests as standalone requests without any project association.
        </p>
        <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
          <img src="/images/pickle_remove_deliverables_warning.png" alt="Remove ticket" className="tw-w-1/2" />
        </div>
        <div className="tw-flex tw-w-full tw-justify-end tw-gap-2 tw-bg-white tw-py-4">
          <Button onClick={handleCancel} color="lightGray">
            Cancel
          </Button>
          <Button onClick={handleRemove} color="red">
            Remove requests
          </Button>
        </div>
      </Drawer.Body>
    </>
  )
}

function CreateDeliverablesContent({ project, setDrawerState, setOpen, onUpdate }: DeliverablesContentProps) {
  const onFinished = () => {
    onUpdate()
    setDrawerState('details')
  }

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-items-center tw-gap-4">
          <IconButton
            size="xs"
            color="transparent"
            onClick={() => setDrawerState('details')}
            className="tw-mr-2"
            data-testid="BackButton"
          >
            <ArrowLeftIcon className="tw-text-neutral-700" />
          </IconButton>
          <h3 className="tw-m-0 tw-truncate tw-p-0">Create Requests for &quot;{project.name}&quot;</h3>
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-pb-0 tw-pt-4">
        <div className="tw-mt-4">
          <CreateDeliverablesTable
            projectId={project.id}
            onFinished={onFinished}
            onCancel={() => setDrawerState('add-deliverables')}
          />
        </div>
      </Drawer.Body>
    </>
  )
}
const Divider = () => <span className="tw-h-5 tw-w-[1px] tw-bg-neutral-500" />

function ProjectPartnerContent({ partner }: { partner: Partner }) {
  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      <img className="tw-h-8 tw-w-8 tw-rounded-md" src={partner?.logo} alt={partner?.name} />
      <span>{partner?.label}</span>
      <Link
        href={partner?.link}
        target="_blank"
        className="tw-text-sm tw-text-neutral-500 tw-underline"
        data-testid="partner-link"
      >
        {partner?.name}
      </Link>
      <Link
        href={partner?.blogPostLink}
        target="_blank"
        externalIcon
        className="tw-text-sm"
        data-testid="partner-blog-post-link"
      >
        Learn More
      </Link>
    </div>
  )
}
