import { Plus } from 'lucide-react'
import { LinkButton } from 'components/core/button'

export default function CreateButton() {
  return (
    <LinkButton
      url="/create"
      color="purple"
      data-testid="create-button"
      className="tw-flex tw-w-fit tw-items-center tw-gap-1"
    >
      <Plus className="tw-h-5 tw-w-5" />
      <span>Create</span>
    </LinkButton>
  )
}
