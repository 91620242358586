import { ReactElement, useEffect, useMemo, useRef } from 'react'
import { Card } from 'lib/components/card/card'
import { PenLine } from 'lucide-react'
import { useAdminMediaContext } from '../providers/admin-media-provider'
import { AdminTicketFile, AdminTicketFileState } from 'lib/api/admin/ticket-files/admin-ticket-files'
import AdminDeleteMediaButton from '../delete-media-button/admin-delete-media-button'
import { useS3PreviewImage } from 'lib/hooks/useS3PreviewImage'
import { cn } from 'lib/util/cn'
import { useThreeResizableContext } from 'lib/components/resizable/resizable'
import { isPlaceholder } from 'lib/util/file/file'

interface AdminMediaItemProps {
  file: AdminTicketFile
}

interface MediaItemPlaceholderProps {
  file: AdminTicketFile
}

const classNames = {
  overlayContainer: `tw-absolute
                            tw-flex
                            tw-justify-center
                            tw-items-center
                            tw-t-0
                            tw-l-0
                            tw-w-full
                            tw-h-full
                            tw-bg-opacity-60
                            tw-bg-black
                            tw-rounded-lg
                            tw-z-1`,

  imgName: 'tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-neutral-600',

  img: 'tw-w-full tw-h-full tw-object-cover tw-aspect-square tw-overflow-hidden',

  removeButtonRelativeContainer: 'tw-relative tw-h-0 tw-w-0',
  mediaItemContainer: 'tw-flex tw-flex-col tw-gap-6 tw-items-center tw-py-6',
  removeButtonAbsoluteContainer: 'tw-absolute tw--bottom-5 tw-left-36 tw-z-10',
  annotationIconRelativeContainer: 'tw-relative tw-h-0 tw-w-0',
  annotationIconAbsoluteContainer:
    'tw-absolute tw--bottom-3 tw--left-2 tw-bg-cornflower-500 tw-text-white tw-rounded-md tw-px-3 tw-flex tw-items-center tw-z-20',
}

export default function AdminMediaItem({ file }: AdminMediaItemProps): ReactElement {
  const containerRef = useRef(null)
  const { selectedFile, setExtractedPreviewIndex, setSelectedFile } = useAdminMediaContext()
  const { middleRef } = useThreeResizableContext()

  const isSelected = file.id === selectedFile?.id

  const previewUrl = useS3PreviewImage(file)

  useEffect(() => {
    if (isSelected) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isSelected])

  const annotationsCount = useMemo(() => {
    if (file.isExtractable) {
      return file.extractedPages.reduce((acc, pdf) => acc + pdf.annotations.length, 0)
    }
    return file.annotations?.length || 0
  }, [file.annotations?.length, file.extractedPages, file.isExtractable])

  function handleSelectFile() {
    setSelectedFile(file)
    if (file.isExtractable && file.extractedPages.length > 0) {
      setExtractedPreviewIndex(0)
    }
    middleRef?.current?.expand()
  }

  return (
    <Card size="custom" onClick={handleSelectFile} isSelected={isSelected} ref={containerRef}>
      <Card.Body>
        {annotationsCount > 0 && (
          <div className={classNames.annotationIconRelativeContainer} data-testid="annotation-count-container">
            <div className={classNames.annotationIconAbsoluteContainer}>
              <PenLine className="lu-light lu-sm" />
              <span className="tw-pl-2">{annotationsCount}</span>
            </div>
          </div>
        )}
        <div className={classNames.removeButtonRelativeContainer}>
          <div className={classNames.removeButtonAbsoluteContainer}>
            <AdminDeleteMediaButton file={file} icon="times" />
          </div>
        </div>
        <div className="tw-relative tw-flex tw-aspect-square tw-w-44 tw-min-w-20 tw-max-w-44 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-lg tw-text-center">
          {(file?.preview || file?.state === AdminTicketFileState.Rejected) && (
            <Overlay label={file?.state === AdminTicketFileState.Rejected ? "WON'T BE DELIVERED" : 'PREVIEW'} />
          )}
          {isPlaceholder(previewUrl) ? (
            <MediaItemPlaceholder file={file} />
          ) : (
            <img key={file.id} src={previewUrl} alt={file.name} className={classNames.img} />
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

function MediaItemPlaceholder({ file }: MediaItemPlaceholderProps): ReactElement {
  const previewUrl = useS3PreviewImage(file)
  const src = `/images/${previewUrl}`
  return <img key={file.id} src={src} alt={file.name} className={cn(classNames.img, 'tw-max-w-25 tw-object-none')} />
}

function Overlay({ label }: { label: string }): ReactElement {
  return (
    <div className={classNames.overlayContainer} data-testid="image-overlay">
      <h4 className="tw-m-0 tw-text-white">{label}</h4>
    </div>
  )
}
