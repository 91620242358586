import { CircleCheck } from 'lucide-react'
import { cn } from 'lib/util/cn'

const ApprovedBadge = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        'tw-relative tw-flex tw-h-min tw-w-min tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-picklegreen-100',
        className,
      )}
    >
      <CircleCheck className="tw-h-4 tw-w-4 tw-shrink-0 tw-text-picklegreen-800" />
    </div>
  )
}

export default ApprovedBadge
