import { SubscriptionType } from 'interfaces/project-template'
import { PenToolIcon, BrushIcon, PresentationIcon, VideoIcon, ClapperboardIcon } from 'lucide-react'

const SUBSCRIPTION_TYPE_ICONS = {
  'Graphic Design': PenToolIcon,
  'Custom Illustration': BrushIcon,
  'Presentation Design': PresentationIcon,
  'Motion Graphics': VideoIcon,
  'Video Editing': ClapperboardIcon,
}

export function SubscriptionTypeIcon({ type }: { type: SubscriptionType | string }) {
  const Icon = SUBSCRIPTION_TYPE_ICONS[type] || null
  return (
    <span className="tw-flex tw-items-center tw-gap-1">
      <Icon className="tw-h-4 tw-w-4" />
      <span>{type}</span>
    </span>
  )
}
