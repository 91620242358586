import { cva, VariantProps } from 'class-variance-authority'
import { LucideIcon } from 'lucide-react'
import { ReactElement } from 'react'

const closeButtonContainer = cva('tw-absolute tw-z-10', {
  variants: {
    position: {
      left: 'tw-left-[-1px] tw-top-1/2',
      right: 'tw-right-[-1px] tw-top-1/2',
      top: 'tw-top-[-1px] tw-left-1/2',
      bottom: 'tw-left-1/2',
    },
  },
})

const closeButton = cva('tw-bg-white tw-border tw-border-gray-200 btn btn-icon tw-cursor-pointer', {
  variants: {
    position: {
      left: 'tw-rounded-l-none tw-rounded-r-md tw-border-l-0 tw-px-0',
      right: 'tw-rounded-r-none tw-rounded-l-md tw-border-r-0 tw-px-0',
      top: 'tw-rounded-b-none tw-rounded-t-md tw-border-b-0 tw-py-0',
      bottom: 'tw-rounded-t-none tw-rounded-b-md tw-border-t-0 tw-py-0',
    },
  },
})

type CloseButtonProps = {
  onClick: () => void
  position: VariantProps<typeof closeButtonContainer>['position']
  Icon: LucideIcon
}

function CloseButton({ onClick, position, Icon }: CloseButtonProps): ReactElement {
  return (
    <div className={closeButtonContainer({ position })}>
      <button
        type="button"
        onClick={onClick}
        className={closeButton({
          position,
        })}
        data-testid="close-button"
      >
        <Icon className="tw-h-6 tw-w-6" />
      </button>
    </div>
  )
}

export default CloseButton
